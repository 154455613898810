import AccordionDetails from "@material-ui/core/AccordionDetails";
import {Button, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@material-ui/core";
import React from "react";
import Grid from "@material-ui/core/Grid";
import {SubObjetivo} from "./subobjetivo";

export const ObjetivoDetails = ({subobjetivos, showAddSubObjetivo, onAddSubObjetivo, onDeleteSubObjetivo}) =>
    <AccordionDetails>
        <Grid container direction={"column"}>
            <Grid item className={'d-flex'}>
                {subobjetivos.length > 0 ? (
                    <Table className={'ml-5 mr-5 mt-2 mb-2'} size={"small"}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Tipo</TableCell>
                                <TableCell align="right">Valor</TableCell>
                                <TableCell align="right">Peso</TableCell>
                                <TableCell align="right"> </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                subobjetivos.map(subobjetivo =>
                                    <SubObjetivo
                                        subobjetivo={subobjetivo}
                                        onDeleteSubObjetivo={onDeleteSubObjetivo}
                                    />
                                )
                            }
                            <PesoTableSummary subobjetivos={subobjetivos}/>
                        </TableBody>
                    </Table>
                ) : (
                    <Typography
                        align={"center"}
                        variant={"body2"}
                        color={"textSecondary"}
                        className={'mt-2 mb-2'}
                        style={{width: '100%'}}
                    >
                        No hay subobjetivos agregados
                    </Typography>
                )}
            </Grid>
            <Grid item>
                <Button
                    variant={"text"}
                    color={"primary"}
                    size={"small"}
                    className={'ml-5'}
                    onClick={onAddSubObjetivo}
                    disabled={!showAddSubObjetivo}
                >
                    Agregar Subobjetivo
                </Button>
            </Grid>
        </Grid>
    </AccordionDetails>

const PesoTableSummary = ({subobjetivos}) => {
    const pesosSum = sumPesos(subobjetivos);
    return (
        <TableRow>
            <TableCell colSpan={2} className={'border-0'}> </TableCell>
            <TableCell
                colSpan={1}
                align="right"
                className={pesosSum < 100 ? 'color-error font-weight-bold' : ''}
            >
                {pesosSum < 100 ? 'Porcentaje insuficiente' : ''} {pesosSum}%
            </TableCell>
        </TableRow>
    )
}

const sumPesos = subobjetivos => subobjetivos.reduce((sum, subobjetivo) => sum + parseFloat(subobjetivo.peso), 0);
