import {IconButton, TableCell, TableRow, Tooltip, Typography} from "@material-ui/core";
import {DeleteRounded} from "@material-ui/icons";
import React, {useState} from "react";
import {formatObjetivoUnidad, formatObjetivoValor} from "assets/utils";
import {DeleteSubObjetivoDialog} from "components/objetivos/general/configuracion/dialogs/delete.subObjetivo.dialog";

export const SubObjetivo = ({subobjetivo, onDeleteSubObjetivo}) => {
    const [deleteSubObjetivoOpen, setDeleteSubObjetivoOpen] = useState(false);
    const [editBtnPesoVisible, setEditBtnPesoVisible] = useState(false);

    return (
        <>
            <TableRow
                key={subobjetivo.numsubobj}
                onMouseEnter={() => setEditBtnPesoVisible(true)}
                onMouseLeave={() => setEditBtnPesoVisible(false)}
            >
                <TableCell component="th" scope="row">
                    {subobjetivo.nombre}
                    <Typography
                        variant={"caption"}
                        color={"textSecondary"}
                        component={"span"}
                    > - {subobjetivo.tiposubobj_nombre}
                    </Typography>
                </TableCell>
                <TableCell align="right">
                    {formatObjetivoValor(subobjetivo.valor, subobjetivo.unidad)}
                    <Typography variant={"caption"} color={"textSecondary"}> {formatObjetivoUnidad(subobjetivo.unidad)}</Typography>
                </TableCell>
                <TableCell align="right">{subobjetivo.peso}%</TableCell>
                <TableCell align="right" width={'2.5rem'}>
                    <Tooltip title={'Eliminar Subobjetivo'}>
                        <IconButton
                            style={{width: '1.7rem', height: '1.7rem', visibility: editBtnPesoVisible ? 'visible' : 'hidden'}}
                            onClick={() => setDeleteSubObjetivoOpen(true)}
                        >
                            <DeleteRounded fontSize={"medium"}/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
            </TableRow>

            <DeleteSubObjetivoDialog
                open={deleteSubObjetivoOpen}
                onDelete={() => {
                    setDeleteSubObjetivoOpen(false);
                    onDeleteSubObjetivo(subobjetivo.idsubobj);
                }}
                onCancel={() => setDeleteSubObjetivoOpen(false)}
                subobjetivo={subobjetivo}
            />
        </>
    )
}
