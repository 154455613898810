import BaseService from './base.service'

export default class ListadosService extends BaseService {
    listaPreciosPDF(type, data, fnSuccess, fnError) {
        this.getApi()({
            url: '/listados/listaPrecios/pdf',
            method: 'post',
            params: {
                type: type
            },
            data: {
                data: data
            },
            responseType: 'blob'
        })
            .then(response => {
                const mimetype = 'application/pdf';
                const file = new Blob(
                    [response.data],
                    {type: mimetype});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al generar la lista de precios')));
    }

    listaPreciosExcel(data, fnSuccess, fnError) {
        this.getApi()({
            url: '/listados/listaPrecios/excel',
            method: 'post',
            data: data,
            responseType: 'blob'
        })
            .then(response => {
                const mimetype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                const file = new Blob(
                    [response.data],
                    {type: mimetype});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al generar la lista de precios')));
    }

    getPlantillaPrecios(fnSuccess, fnError) {
        this.getApi()({
            url: '/listados/listaPrecios/excel/plantilla',
            method: 'get',
            responseType: 'blob'
        })
            .then(response => {
                const mimetype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                const file = new Blob(
                    [response.data],
                    {type: mimetype});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al descargar la plantilla de precios')));
    }

    uploadPlantillaPrecios(data, update, fnSuccess, fnError) {
        return this.getApi().post(`/listados/listaPrecios/excel/plantilla?${update ? 'update' : ''}`, data)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al subir la plantilla')));
    }

    clientesInhabilitados(idVendedor, fnSuccess, fnError) {
        this.getApi()({
            url: '/listados/clientesInhabilitados',
            method: 'post',
            params: {
                vendedor: idVendedor
            },
            responseType: 'blob'
        })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al generar el PDF de clientes inhabilitados')));
    }

    clientes(filteredIds, fnSuccess, fnError) {
        this.getApi()({
            url: '/listados/clientes',
            method: 'post',
            data: {filteredIds},
            responseType: 'blob'
        })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al generar el PDF de clientes')));
    }

    documentosImpagos(clientes, vendedores, fechaCorte, tipo, fnSuccess, fnError) {
        this.getApi()({
            url: '/listados/documentosImpagos',
            method: 'post',
            data: {
                clientes: clientes,
                vendedores: vendedores,
                fecha: fechaCorte,
                tipo: tipo
            },
            responseType: 'blob'
        })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al generar el PDF de documentos impagos')));
    }

    pedidosPendientes(clientes, vendedores, proveedores, fechaInicio, fechaFin, tipo, agrupar, fnSuccess, fnError) {
        this.getApi()({
            url: '/listados/pedidosPendientes',
            method: 'post',
            data: {
                clientes: clientes,
                vendedores: vendedores,
                proveedores: proveedores,
                fechaInicio: fechaInicio,
                fechaFin: fechaFin,
                tipo: tipo,
                agrupar: agrupar,
            },
            responseType: 'blob'
        })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al generar el PDF de pedidos pendientes')));
    }

    estadisticasVentas(clientes, proveedores, vendedores, fechaInicio, fechaFin, listado, fnSuccess, fnError) {
        this.getApi()({
            url: '/listados/estadisticasVentas',
            method: 'post',
            data: {
                clientes: clientes,
                proveedores: proveedores,
                vendedores: vendedores,
                fechaInicio: fechaInicio,
                fechaFin: fechaFin,
                listado: listado,
            },
            responseType: 'blob'
        })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al generar el PDF de estadísticas de ventas')));
    }

    cuentasCorrientes(clientes, vendedores, fechaInicio, fechaFin, fnSuccess, fnError) {
        this.getApi()({
            url: '/listados/cuentasCorrientes',
            method: 'post',
            data: {
                clientes: clientes,
                vendedores: vendedores,
                fechaInicio: fechaInicio,
                fechaFin: fechaFin
            },
            responseType: 'blob'
        })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al generar el PDF de cuentas corrientes')));
    }

    cobranzasTransitorias(clientes, vendedores, tipoFiltro, desde, hasta, visualizar, agrupar, ordenar, status, fnSuccess, fnError) {
        this.getApi()({
            url: '/listados/cobranzasTransitorias',
            method: 'post',
            data: {
                clientes: clientes,
                vendedores: vendedores,
                tipoFiltro: tipoFiltro,
                desde: desde,
                hasta: hasta,
                visualizar: visualizar,
                agrupar: agrupar,
                ordenar: ordenar,
                status: status,
            },
            responseType: 'blob'
        })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al generar el PDF de cobranzas transitorias')));
    }

    rendicionCobranzas(cobranzas, fnSuccess, fnError) {
        this.getApi()({
            url: '/listados/rendicionCobranzas',
            method: 'post',
            data: {
                cobranzas: cobranzas,
            },
            responseType: 'blob'
        })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al generar la rendición de cobranzas')));
    }

    notasCreditoAtrasadas(type, desde, hasta, ordenar, fnSuccess, fnError) {
        const mimeType = type === 'PDF' ? 'application/pdf':'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

        this.getApi()({
            url: '/listados/notasCreditoAtrasadas',
            method: 'post',
            data: {type, desde, hasta, ordenar},
            responseType: 'blob'
        })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: mimeType});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener las notas de crédito atrasadas')));
    }

    stockArticulos(settings, filteredIds, quantityOfMonths, orderField, orderDirection, fnSuccess, fnError) {
        this.getApi()({
            url: '/listados/stockArticulos',
            method: 'post',
            data: {settings, filteredIds, quantityOfMonths, orderField, orderDirection},
            responseType: 'blob'
        })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener el stock de artículos')));
    }

    stockArticulosArmados(filteredIds, orderField, orderDirection, fnSuccess, fnError) {
        this.getApi()({
            url: '/listados/stockArticulosArmados',
            method: 'post',
            data: {filteredIds, orderField, orderDirection},
            responseType: 'blob'
        })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener el stock de artículos armados')));
    }

    compraVentaArticulos(filters, fuente, fnSuccess, fnError) {
        this.getApi()({
            url: `/listados/compraVentaArticulos?fuente=${fuente}`,
            method: 'post',
            data: filters,
            responseType: 'blob'
        })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener el listado de compra venta de artículos')));
    }

    compradoresPorPeriodo(filters, filtro, fnSuccess, fnError) {
        this.getApi()({
            url: `/listados/compradoresPorPeriodo?filtro=${filtro}`,
            method: 'post',
            data: filters,
            responseType: 'blob'
        })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener el listado de clientes compradores')));
    }
}
