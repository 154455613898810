import React, {Component} from 'react';
import {withSnackbar} from "notistack";
import {MainContext} from "contexts/main.context";
import {Button, Grid, Tooltip} from "@material-ui/core";
import {CloudDownloadRounded, CloudUploadRounded} from "@material-ui/icons";
import ListadosService from "services/listados.service";
import {DiferenciasDialog} from "components/stock/listasPrecio/diferencias.dialog";

export class Plantilla extends Component {
    state = {
        loadingPlantilla: false,
        diferenciasDialogOpen: false,
        diferencias: [],
        formData: null,
    };

    ListadosService = new ListadosService(this.context);

    downloadPlantilla = () => {
        this.ListadosService.getPlantillaPrecios(
            file => {
                const fileURL = URL.createObjectURL(file);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = fileURL;
                a.download = `Plantilla-LP-Berner.xlsx`;
                a.click();
                window.URL.revokeObjectURL(fileURL);
                this.props.enqueueSnackbar('El archivo se descargó correctamente. Revise su carpeta de descargas.');
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    uploadPlantilla = event => {
        if (event.target.files.length <= 0) {
            this.props.enqueueSnackbar('Debe seleccionar un archivo para subir', {variant: "error"});
            return false;
        }

        var ext = event.target.files[0].name.match(/\.([^\.]+)$/)[1];
        if (ext !== 'xlsx') {
            this.props.enqueueSnackbar('Debe subir un archivo con extensión .xlsx', {variant: "error"});
            return false;
        }

        this.setState({loadingPlantilla: true});

        // Paso los parámetros a un formData para poder enviar el archivo (plantilla)
        var formData = new FormData();
        formData.append('plantilla', event.target.files[0]);
        // Limpio el input por si quieren subir varias veces.
        event.target.value = '';

        this.setState({formData: formData});

        this.ListadosService.uploadPlantillaPrecios(formData, false,
            success => {
                this.setState(prevState => {
                    prevState.loadingPlantilla = false;
                    prevState.diferencias = success.data.result;
                    prevState.diferenciasDialogOpen = true;
                    return prevState
                });
            },
            error => {
                this.setState({loadingPlantilla: false})
                this.props.enqueueSnackbar(error, {variant: 'error'})
            }
        );
    }

    onUpdatePlantilla = () => {
        this.ListadosService.uploadPlantillaPrecios(this.state.formData, true,
            success => {
                this.setState({loadingPlantilla: false}, this.closeDiferenciasDialog);
                this.props.enqueueSnackbar("La plantilla fue actualizada correctamente", {variant: 'success'})
            },
            error => {
                this.setState({loadingPlantilla: false})
                this.props.enqueueSnackbar(error, {variant: 'error'})
            }
        );
    }

    openDiferenciasDialog = () => this.setState({diferenciasDialogOpen: true});
    closeDiferenciasDialog = () => this.setState({diferenciasDialogOpen: false});

    render() {
        const {diferenciasDialogOpen, diferencias} = this.state;

        return (
            <>
                <Grid container justifyContent={"space-around"} className={"mt-2"} spacing={5}>
                    <Grid item>
                        <Tooltip title={'Seleccione esta opción para descargar la plantilla actual'}>
                            <Button
                                variant={"contained"}
                                size={"large"}
                                color={"primary"}
                                onClick={this.downloadPlantilla}
                                startIcon={<CloudDownloadRounded/>}
                            >
                                Descargar Plantilla
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Grid item>
                        <input accept=".xlsx" className={'d-none'} name="plantilla-file" id="plantilla-file" type="file" onChange={this.uploadPlantilla}/>
                        <label htmlFor="plantilla-file">
                            <Tooltip title={'Seleccione esta opción para subir una nueva plantilla'}>
                                <Button
                                    variant={"contained"}
                                    size={"large"}
                                    startIcon={<CloudUploadRounded/>}
                                    component={"span"}
                                >
                                    Actualizar Plantilla
                                </Button>
                            </Tooltip>
                        </label>
                    </Grid>
                </Grid>
                <DiferenciasDialog
                    open={diferenciasDialogOpen}
                    diferencias={diferencias}
                    onConfirm={this.onUpdatePlantilla}
                    onCancel={this.closeDiferenciasDialog}
                />
            </>
        )
    }
}

Plantilla.contextType = MainContext;
Plantilla = withSnackbar(Plantilla);
