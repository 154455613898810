import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {CotizacionesClientes} from 'components/ventas/cotizaciones/clientes/cotizaciones.clientes';
import {CotizacionesList} from 'components/ventas/cotizaciones/list/cotizaciones.list';
import {CotizacionesCreate} from 'components/ventas/cotizaciones/create/cotizaciones.create';
import {CotizacionesView} from "components/ventas/cotizaciones/view/cotizaciones.view";

const basePath = '/ventas/cotizaciones';

export class Cotizaciones extends Component {
    render() {
        return (
            <div className='niquel-cotizaciones niquel-view-with-bottom-margin'>
                <Switch>
                    <Route path={`${basePath}/:cliente/view/:cotizacion`} component={CotizacionesView}/>
                    <Route path={`${basePath}/:cliente/create/ref/:refidcoti`} component={CotizacionesCreate}/>
                    <Route path={`${basePath}/:cliente/create`} component={CotizacionesCreate}/>
                    <Route path={`${basePath}/:cliente/edit/:editidcoti`} component={CotizacionesCreate}/>
                    <Route exact path={`${basePath}/:cliente`} component={CotizacionesList}/>
                    <Route path={`${basePath}`} component={CotizacionesClientes}/>
                </Switch>
            </div>
        )
    }
}