import React, {useEffect, useReducer, useState} from 'react'
import {useEstadisticasService, useListadosService} from "services/hooks";
import {generalReducer} from "components/helpers/reducers/reducers";
import {INITIAL_FILTER_SETTINGS_STATE} from "components/helpers/initialFilterSettingsState";
import {useSnackbar} from "notistack";
import Grid from "@material-ui/core/Grid";
import {CardChart} from "components/estadisticas/dashboards/ventas/card.chart";
import {CompradoVendidoTable} from "components/stock/compradoVendido/compradoVendido.table";
import {Box, Fab, MenuItem, TextField, Tooltip} from "@material-ui/core";
import {SettingsRounded} from "@material-ui/icons";
import SubHeader from "components/helpers/subHeader/subHeader";
import VentasSettings from "components/estadisticas/settings/ventas.settings";
import {ExportOptions} from "../../helpers/exportOptions/export.options";

const FUENTES = [{id: 'REMITOS', description: 'Remitos'}, {id: 'FACTURAS', description: 'Facturación'}];

export function CompradoVendido() {
    const [_settingsState, dispatch] = useReducer(generalReducer, INITIAL_FILTER_SETTINGS_STATE);
    const [settingsState, setSettingsState] = useState(INITIAL_FILTER_SETTINGS_STATE);
    const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
    const [compraVentaArticulos, setCompraVentaArticulos] = useState(null);

    const [fuente, setFuente] = useState('REMITOS');

    const EstadisticasService = useEstadisticasService();
    const ListadosService = useListadosService();

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    useEffect(() => {
        EstadisticasService.getCompraVentaArticulos(
            settingsState,
            fuente,
            success => setCompraVentaArticulos(success.data.result),
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }, [settingsState, fuente]);

    const onConfirmSettingsDialog = () => {
        setSettingsState(_settingsState);
        setSettingsDialogOpen(false);
    }
    const onCancelSettingsDialog = () => {
        dispatch({payload: settingsState, type: 'fullstatechange'});
        setSettingsDialogOpen(false);
    }

    const getPDF = fnSuccess => {
        ListadosService.compraVentaArticulos(
            settingsState,
            fuente,
            file => fnSuccess(file),
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    };

    return (
        <>
            <Box className='niquel-estadisticas-main'>
                <SubHeader title={'Comprado/Vendido'}/>
                <Box className='container mt-3 mb-3'>
                    <Box className={'mt-3'}>
                        <Grid container spacing={1}>
                            <Grid item sm={12}>
                                <CardChart
                                    title={'Compras y ventas de Artículos'}
                                    subtitle={'por volúmen en litros o unidades según corresponda'}
                                    rightContent={
                                        <TextField
                                            select
                                            label="Fuente"
                                            value={fuente}
                                            onChange={event => setFuente(event.target.value)}
                                        >
                                            {FUENTES.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.description}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    }
                                >
                                    <CompradoVendidoTable
                                        data={compraVentaArticulos}
                                        // height={400}
                                    />
                                </CardChart>
                            </Grid>
                        </Grid>
                    </Box>
                    <Tooltip title={'Configurar filtros'}>
                        <Box className={'niquel-fab-top-btn'}>
                            <Fab onClick={() => setSettingsDialogOpen(true)} color={"default"}>
                                <SettingsRounded/>
                            </Fab>
                        </Box>
                    </Tooltip>
                </Box>
            </Box>
            <VentasSettings
                open={settingsDialogOpen}
                title={'Filtros y confirguración'}
                onCancel={onCancelSettingsDialog}
                onConfirm={onConfirmSettingsDialog}
                fullScreen={true}
                maxWidth={'lg'}
                state={_settingsState}
                dispatch={dispatch}
            />
            <ExportOptions
                enableDownload
                enableMail
                enablePrint
                enableShow
                getPDF={getPDF}
                fileName={`Comprado_Vendido.pdf`}
                // mailTo={cliente.email}
                // mailCc={vendedor.email}
                mailCCEnabled={true}
                mailSubject={`Comprado Vendido`}
                mailBody={`Estimado,  \n\nSe adjunta el listado de compra y venta de artículos.`}
            />
        </>
    )
}