import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent} from "@material-ui/core";
import {ReactGrid} from "components/helpers/reactGrid/reactGrid";
import {PedidoStatusFormatter, sortDate, sortNumeric} from "assets/utils";
import {MainContext} from "contexts/main.context";
import {withSnackbar} from "notistack";
import memoize from 'memoize-one';
import {InfoButton} from "components/despacho/hojasDeRuta/operation/pedido.details";

export class AddPedidosDialog extends Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onCancel}
                fullScreen={this.props.fullScreen}
                // TransitionComponent={this.props.TransitionComponent}
                transitionDuration={200}
                maxWidth={"lg"}
                fullWidth={true}
                classes={{
                    paperFullScreen: 'niquel-dialog-full-screen'
                }}
            >
                <AddPedidosDialogContent {...this.props}/>
            </Dialog>
        )
    }
}

function CantDirecciones(props) {
    const {row} = props;
    return <span>({row.cantidad_direccion_entrega})</span>;
}

function DirEntrega(props) {
    const {row} = props;
    const diff = row.entrega !== row.ingreso;
    return (
        <div style={{
            fontWeight: diff ? 'bold' : '',
            color: diff ? 'red' : ''
        }}>
            {row.entrega}
        </div>
    );
}

class AddPedidosDialogContent extends Component {
    state = {
        table: {
            columns: [
                {name: 'id', title: 'ID'},
                // {name: 'zona', title: 'Zona'},
                {name: 'ingreso', title: 'Ingreso'},
                {name: 'custom4', title: 'Entrega'},
                {name: 'cliente', title: 'Cliente'},
                // {name: 'id_direccion_entrega', title: 'ID Entrega'},
                {name: 'custom1', title: ' '},
                {name: 'direccion_entrega', title: 'Dir Entrega'},
                // {name: 'direccion_entrega2', title: 'Dir Entrega2'},
                {name: 'litros', title: 'Litros'},
                {name: 'custom2', title: 'Estado'},
                {name: 'custom3', title: ' '},
            ],
            columnExtensions: [
                {columnName: 'id', width: 80, compare: sortNumeric, direction: 'desc'},
                {columnName: 'ingreso', width: 110, compare: sortDate},
                {columnName: 'custom4', width: 110, component: DirEntrega, compare: sortDate},
                {columnName: 'custom1', width: 26, component: CantDirecciones, sortingEnabled: false},
                {columnName: 'litros', width: 80, compare: sortNumeric},
                {columnName: 'custom2', width: 250, component: PedidoStatusFormatter, align: 'center'},
                {columnName: 'custom3', width: 60, component: InfoButton, sortingEnabled: false},
            ],
            rowExtensions: {
                color: pedido => pedido && pedido.tiene_articulos_facturados ? '#00945d38' : ''
            },
            data: [],
            selected: [],
            all: true
        },
    }

    filterPedidos = memoize(
        (pedidos, excluded) => pedidos.filter(pedido => excluded.indexOf(pedido.id) === -1)
    );

    componentDidMount() {
        // Asigno los pedidos a la tabla.
        this.setState(prevState => {
            prevState.table.data = this.filterPedidos(this.props.pedidos, this.props.excluded.map(exc => exc.id))
            return prevState;
        });
    }

    onSelect = (field, selected) => this.setState(prevState => (prevState[field].selected = selected));
    onConfirm = () => {
        let success = this.props.onConfirm(this.state.table.selected.map(id => this.state.table.data.find(pedido => pedido.id === id)));
        if (success)
            this.props.onCancel();
    };

    render() {
        const {onConfirm, onCancel} = this.props;
        const {table} = this.state;

        // Viewport Height
        const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        const tableHeight = viewportHeight - 150;

        return (
            <React.Fragment>
                {/*<DialogTitle id="alert-dialog-title">{title}</DialogTitle>*/}
                <DialogContent>
                    <ReactGrid
                        elevation={0}
                        enableVirtualMode={true}
                        // height={tableHeight}
                        columns={table.columns}
                        columnExtensions={table.columnExtensions}
                        rowExtensions={table.rowExtensions}
                        getRowId={row => row.id}
                        rows={table.data}
                        title={`Pedidos pendientes de entrega`}
                        showResultCount={true}
                        enableFiltering={true}
                        enableSorting={true}
                        enableSearch={true}
                        enableSelection={true}
                        selectedRows={table.selected}
                        onSelectionChange={selected => this.onSelect('table', selected)}
                        onDelete={selected => this.onSelect('table', [])}
                        showSelectAll={true}
                    />
                </DialogContent>
                <DialogActions>
                    {/*Muestro Confirm*/}
                    {onCancel && (
                        <Button onClick={onCancel} color={"primary"}>
                            Cancelar
                        </Button>
                    )}

                    {/*Muestro Cancel*/}
                    {onConfirm && (
                        <Button onClick={this.onConfirm} color={"primary"} autoFocus>
                            Confirmar
                        </Button>
                    )}
                </DialogActions>
            </React.Fragment>
        )
    }
}

AddPedidosDialogContent.contextType = MainContext;
AddPedidosDialogContent = withSnackbar(AddPedidosDialogContent);
