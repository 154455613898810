import React, {useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import {ReactGrid} from "components/helpers/reactGrid/reactGrid";
import {sortNumeric} from "assets/utils";

export function AddArticulosDialog(props) {
    return (
        <Dialog
            open={props.open}
            onClose={props.onClose}
            fullScreen={props.fullScreen}
            // TransitionComponent={this.props.TransitionComponent}
            transitionDuration={200}
            maxWidth={"lg"}
            fullWidth={true}
            classes={{
                paperFullScreen: 'niquel-dialog-full-screen'
            }}
        >
            <AddArticulosDialogContent {...props}/>
        </Dialog>
    )
}

function AddArticulosDialogContent(props) {
    const [selectedIds, setSelectedIds] = useState([]);

    const tableConfig = {
        columns: [
            {name: 'id', title: 'ID'},
            {name: 'detalle', title: 'Detalle'}
        ],
        columnExtensions: [
            {columnName: 'id', width: 120, compare: sortNumeric, direction: 'desc'}
        ]
    };

    const onConfirm = () => {
        props.onConfirm(selectedIds.map(id => props.articulos.find(articulo => articulo.id === id)));
        props.onClose();
    };

    return (
        <>
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
                <ReactGrid
                    title={`Artículos`}
                    elevation={0}
                    enableVirtualMode={true}
                    // height={tableHeight}
                    columns={tableConfig.columns}
                    columnExtensions={tableConfig.columnExtensions}
                    rowExtensions={tableConfig.rowExtensions}
                    getRowId={row => row.id}
                    rows={props.articulos}
                    showResultCount={true}
                    enableFiltering={true}
                    enableSorting={true}
                    enableSearch={true}
                    selectedRows={selectedIds}
                    onSelectionChange={selected => setSelectedIds(selected)}
                    enableSelection={true}
                    showSelectAll={true}
                    // onDelete={selected => this.onSelect('table', [])}
                />
            </DialogContent>
            <DialogActions>
                {/*Muestro Confirm*/}
                {props.onClose && (
                    <Button onClick={props.onClose} color={"primary"}>
                        Cerrar
                    </Button>
                )}

                {/*Muestro Cancel*/}
                {props.onConfirm && (
                    <Button onClick={onConfirm} color={"primary"} autoFocus>
                        Confirmar
                    </Button>
                )}
            </DialogActions>
        </>
    )
}
