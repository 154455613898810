import {CardChart} from "../card.chart";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {Divider, Switch, Tooltip, Typography} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {VentasMensualesChart} from "../charts/ventas.mensuales.chart";
import {VentasTotalesChart} from "../charts/ventas.totales.chart";
import React, {useEffect, useState} from "react";
import {TIPOS_GRAFICO} from "../tipos.grafico";
import {useSnackbar} from "notistack";
import {useEstadisticasService} from "../../../../../services/hooks";
import {imageChartToPDF, tableChartToPDF} from "../../../../../assets/utils";

export const VentasMensualesCard = ({settingsState, printRef}) => {
    const [ventasMensuales, setVentasMensuales] = useState([]);
    const [ventasMensualesAnterior, setVentasMensualesAnterior] = useState(false);
    const [tipoVentasMensuales, setTipoVentasMensuales] = useState(TIPOS_GRAFICO[0]);

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const EstadisticasService = useEstadisticasService();

    const chartTitle = "Ventas Mensuales";
    const chartSubtitle = tipoVentasMensuales.description;

    useEffect(() => {
        EstadisticasService.getVentasMensuales(
            settingsState,
            success => setVentasMensuales(success.data.result),
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }, [settingsState]);

    return (
        <CardChart
            title={chartTitle}
            subtitle={chartSubtitle}
            rightContent={
                <>
                    <FormControlLabel
                        className={'mr-5'}
                        labelPlacement={"start"}
                        control={
                            <Tooltip title={'Ver año anterior'}>
                                <Switch
                                    checked={ventasMensualesAnterior}
                                    onChange={() => setVentasMensualesAnterior(!ventasMensualesAnterior)}
                                    name="checkedB"
                                    color="primary"
                                />
                            </Tooltip>
                        }
                        label={<Typography variant={"body2"}>ver período anterior</Typography>}
                    />
                    <TextField
                        select
                        value={tipoVentasMensuales}
                        onChange={event => setTipoVentasMensuales(event.target.value)}
                    >
                        {TIPOS_GRAFICO.map((tipo, index) => (
                            <MenuItem key={index} value={tipo}>{tipo.description}</MenuItem>
                        ))}
                    </TextField>
                </>
            }
            printRef={printRef}
            exportFn={imageChartToPDF.bind(null, printRef, chartTitle, chartSubtitle, settingsState.dates)}
        >
            <VentasMensualesChart
                data={ventasMensuales}
                anterior={ventasMensualesAnterior}
                height={300}
                value={tipoVentasMensuales.id}
            />
            <Divider/>
            <VentasTotalesChart
                data={ventasMensuales.total}
                anterior={ventasMensualesAnterior}
                height={120}
                value={tipoVentasMensuales.id}
            />
        </CardChart>
    )
}
