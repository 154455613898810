import React, {Component} from 'react'
import SideBarContent from "components/sideBar/content/sideBar.content";
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheckRounded';
import AttachMoneyIcon from '@material-ui/icons/AttachMoneyRounded';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccountRounded';
import ListAltcon from '@material-ui/icons/ListAltRounded';
import HomeIcon from '@material-ui/icons/HomeRounded';
import DirectionsBusIcon from '@material-ui/icons/DirectionsBusRounded';
import {BuildRounded, InsertChartRounded, MyLocationRounded} from "@material-ui/icons";

const aMainMenu = [
    {
        key: 'home',
        title: 'Home',
        icon: <HomeIcon/>,
        withLink: true,
        authorizedRoles: ["A", "O", "S", "V", "L"],
    },
    {
        key: 'divider'
    },
    {
        key: 'ventas',
        title: 'Ventas',
        icon: <AttachMoneyIcon/>,
        authorizedRoles: ["A", "O", "S", "V", "L"],
        subMenu: [
            {
                key: 'clientes',
                title: 'Clientes',
                icon: '',
                authorizedRoles: ["A", "O", "S", "V"]
            },
            {
                key: 'cotizaciones',
                title: 'Cotizaciones',
                icon: '',
                authorizedRoles: ["A", "O", "S", "V"]
            },
            {
                key: 'pedidos',
                title: 'Pedidos',
                icon: '',
                authorizedRoles: ["A", "O", "S", "V"]
            },
            {
                key: 'facturas',
                title: 'Facturas',
                icon: '',
                authorizedRoles: ["A", "O", "S", "V"]
            },
            // {
            //     key: 'facturasXfecha',
            //     title: 'Facturas por Fecha',
            //     icon: '',
            //     authorizedRoles: ["A", "O", "S", "V"]
            // },
            {
                key: 'remitos',
                title: 'Remitos',
                icon: '',
                authorizedRoles: ["A", "O", "S", "V", "L"]
            },
            {
                key: 'notasCredito',
                title: 'Notas de Crédito',
                icon: '',
                authorizedRoles: ["A", "O", "S", "V"]
            },
            {
                key: 'cobranzas',
                title: 'Cobranzas',
                icon: '',
                authorizedRoles: ["A", "O", "S", "V"],
                authorizedCompanies: ["localhost", "bernerbsas", "bernerbahia", "bernerolavarria"],
            },
            {
                key: 'AFIP',
                title: 'AFIP',
                icon: '',
                authorizedRoles: ["A", "O", "L"],
                authorizedCompanies: ["localhost", "bernerbsas", "bernerbahia", "bernerolavarria"],
            }
        ]
    },
    {
        key: 'aprobaciones',
        title: 'Aprobaciones',
        icon: <SupervisorAccountIcon/>,
        authorizedRoles: ["A"],
        subMenu: [
            {
                key: 'cotizaciones',
                title: 'Cotizaciones Prepago',
                icon: '',
                authorizedRoles: ["A"],
                authorizedCompanies: ["localhost", "bernerbsas", "bernerbahia", "bernerolavarria"],
            },
            {
                key: 'recepciones/cobranza',
                title: 'Recepciones de Cobranza',
                icon: '',
                authorizedRoles: ["A"],
                authorizedCompanies: ["localhost", "bernerbsas", "bernerbahia", "bernerolavarria"],
            },
            {
                key: 'recepciones/cotizacion',
                title: 'Recepciones de Cotización',
                icon: '',
                authorizedRoles: ["A"],
                authorizedCompanies: ["localhost", "bernerbsas", "bernerbahia", "bernerolavarria"],
            },
            {
                key: 'rendiciones/cobranza',
                title: 'Rendiciones De Cobranza',
                icon: '',
                authorizedRoles: ["A"],
                authorizedCompanies: ["localhost", "bernerbsas", "bernerbahia", "bernerolavarria"],
            }
        ]
    },
    {
        key: 'stock',
        title: 'Stock',
        icon: <PlaylistAddCheckIcon/>,
        authorizedRoles: ["A", "O", "S", "V", "L"],
        subMenu: [
            {
                key: 'articulos',
                title: 'Artículos',
                icon: '',
            },
            {
                key: 'articulosArmados',
                title: 'Artículos Armados',
                icon: '',
                authorizedRoles: ["A", "O", "S"],
            },
            {
                key: 'compradoVendido',
                title: 'Comprado/Vendido',
                icon: '',
                authorizedRoles: ["A", "O"],
            },
            {
                key: 'listasPrecio',
                title: 'Listas de Precio',
                icon: '',
                authorizedRoles: ["A", "O", "S", "V"],
            },
            {
                key: 'equivalenciasFiltros',
                title: 'Equivalencias de Filtros',
                icon: '',
                authorizedRoles: ["A", "O", "S", "V"],
                authorizedCompanies: ["localhost", "bernerbsas", "bernerbahia", "bernerolavarria"],
            }
        ]
    },
    {
        key: 'despacho',
        title: 'Despacho',
        icon: <DirectionsBusIcon/>,
        authorizedRoles: ["A", "O", "D", "H", "L"],
        subMenu: [
            {
                key: 'hojasDeRuta',
                title: 'Hojas de Ruta',
                icon: '',
                authorizedRoles: ["A", "O", "D", "L"],
            },
            {
                key: 'pedidosLibres',
                title: 'Pedidos Libres',
                icon: '',
                authorizedRoles: ["A", "O", "L"],
            },
            {
                key: 'entregas',
                title: 'Entregas',
                icon: '',
                authorizedRoles: ["A", "O", "H", "L"],
            }
        ]
    },
    {
        key: 'picking',
        title: 'Picking',
        icon: <DirectionsBusIcon/>,
        authorizedRoles: ["E"],
        withLink: true
    },
    {
        key: 'divider'
    },
    {
        key: 'listados',
        title: 'Listados',
        icon: <ListAltcon/>,
        authorizedRoles: ["A", "O", "S", "V"],
        subMenu: [
            {
                key: 'clientesInhabilitados',
                title: 'Clientes Inhabilitados',
                icon: '',
            },
            {
                key: 'documentosImpagos',
                title: 'Documentos Impagos',
                icon: '',
            },
            {
                key: 'pedidosPendientes',
                title: 'Pedidos Pendientes',
                icon: '',
            },
            {
                key: 'estadisticasVentas',
                title: 'Estadísticas de Ventas',
                icon: '',
            },
            {
                key: 'cuentasCorrientes',
                title: 'Cuentas Corrientes',
                icon: '',
            },
            {
                key: 'cobranzasTransitorias',
                title: 'Cobranzas Transitorias',
                icon: '',
                authorizedCompanies: ["localhost", "bernerbsas", "bernerbahia", "bernerolavarria"],
            },
            {
                key: 'rendicionCobranzas',
                title: 'Rendiciones Manuales',
                icon: '',
                authorizedCompanies: ["localhost", "bernerbsas", "bernerbahia", "bernerolavarria"],
            },
            {
                key: 'notasCreditoAtrasadas',
                title: 'Notas de Crédito Atrasadas',
                icon: '',
                authorizedCompanies: ["localhost", "bernerbsas", "bernerbahia", "bernerolavarria"],
                authorizedRoles: []
            },
            {
                key: 'clientesCompras',
                title: 'Clientes con Compras',
                icon: '',
                authorizedCompanies: ["localhost", "bernerbsas", "bernerbahia", "bernerolavarria"],
                authorizedRoles: ["A", "O", "S", "V"]
            }
        ]
    },
    {
        key: 'estadisticas',
        title: 'Estadísticas',
        icon: <InsertChartRounded/>,
        authorizedRoles: ["A", "O", "S", "V"],
        withLink: true
    },
    {
        key: 'objetivos',
        title: 'Objetivos',
        icon: <MyLocationRounded/>,
        authorizedRoles: ["A", "S", "V"],
        subMenu: [
            {
                key: 'configuracion_general',
                title: 'Configuración General',
                icon: '',
                authorizedRoles: ["A", "S"],
            },
            {
                key: 'revision_general',
                title: 'Revisión General',
                icon: '',
                authorizedRoles: ["A", "S", "V"]
            },
            // {
            //     key: 'checkin',
            //     title: 'Check-in Cliente',
            //     icon: '',
            //     authorizedRoles: ["A"]
            // },
            {
                key: 'revision_visitas_cliente',
                title: 'Visitas a Cliente',
                icon: '',
                authorizedRoles: [""]
            },
            {
                key: 'visitas_cliente',
                title: 'Visitas a Cliente',
                icon: '',
                authorizedRoles: ["A", "S", "V"]
            }
        ]
    },
    {
        key: 'divider'
    },
    {
        key: 'herramientas',
        title: 'Herramientas',
        icon: <BuildRounded/>,
        authorizedRoles: ["A", "O", "S", "V", "H", "L"],
        subMenu: [
            {
                key: 'notificaciones',
                title: 'Notificaciones',
                icon: '',
            },
            {
                key: 'agenda',
                title: 'Agenda',
                icon: '',
                authorizedRoles: ["A", "O", "S", "V"]
            }
        ]
    }
];

export class SideBarContainer extends Component {
    render() {
        return (
            <SideBarContent mainMenu={aMainMenu}/>
        )
    }
}
