import BaseService from './base.service'

export default class RemitosService extends BaseService {
    getAll(desde, fnSuccess, fnError) {
        return this.getApi().get(`/remitos?desde=${desde}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getById(id, fnSuccess, fnError) {
        return this.getApi().get(`/remitos?id=${id}`)
            .then(response => {
                if (response.data.count === 1)
                    response.data.result = response.data.result[0];
                fnSuccess(response);
            })
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getByCliente(cliente, fnSuccess, fnError) {
        return this.getApi().get(`/remitos?cliente=${cliente}&withRecibo`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getArticulosById(id, fnSuccess, fnError) {
        return this.getApi().get(`/remitos/articulos?id=${id}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getPDF(id, fnSuccess, fnError) {
        this.getApi()({
            url: '/remitos/pdf',
            method: 'get',
            params: {
                id: id
            },
            responseType: 'blob'
        })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al generar el PDF del remito')));
    }
}
