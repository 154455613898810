import React, {Component} from 'react'
import {Box, MenuItem, TextField} from "@material-ui/core/index";
import {ReactGrid} from 'components/helpers/reactGrid/reactGrid';
import {RemitosService} from "services";
import {withSnackbar} from "notistack";
import {sortDate, sortNumeric} from "assets/utils";
import {MainContext} from "contexts/main.context";
import SubHeader from "components/helpers/subHeader/subHeader";

const FILTROS_DESDE = [{
    id: 'mes',
    description: 'Desde 30 días'
}, {
    id: 'anio',
    description: 'Desde un año'
}, {
    id: 'siempre',
    description: 'Desde siempre'
}];

export class RemitosList extends Component {
    state = {
        // cliente: {},
        desde: 'mes',
        columns: [
            {name: 'id', title: 'ID'},
            {name: 'remito', title: 'N° Documento'},
            {name: 'factura', title: 'N° Factura'},
            {name: 'ingreso', title: 'Ingreso'},
            {name: 'importe', title: 'Importe'},
            {name: 'cliente', title: 'Cliente'}
        ],
        columnExtensions: [
            {columnName: 'id', compare: sortNumeric, direction: 'desc', width: 100},
            {columnName: 'remito', width: 135},
            {columnName: 'factura', width: 135},
            {columnName: 'ingreso', compare: sortDate, direction: 'desc', width: 100},
            {columnName: 'importe', type: 'currency', compare: sortNumeric, width: 150}
        ],
        rows: []
    };

    // ClientesService = new ClientesService(this.context);
    RemitosService = new RemitosService(this.context);

    componentDidMount() {
        // this.ClientesService.getById(
        //     this.props.match.params.cliente,
        //     response => this.setState({cliente: response.data.result}),
        //     error => this.props.enqueueSnackbar(error, {variant: 'error'})
        // );
        this.loadRemitos();
    }

    loadRemitos = () => {
        this.RemitosService.getAll(
            this.state.desde,
            response => this.setState({rows: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'}));
    }

    navView = (row) => {
        this.props.history.push(`${this.props.match.url}/view/${row.id}`);
    };

    changeDesde = (desde) => {
        this.setState({desde}, this.loadRemitos);
    }

    render() {
        const {columns, columnExtensions, rows} = this.state;
        return (
            <Box className='niquel-remitos-list'>
                <SubHeader
                    title={'Remitos'}
                    subtitle={'Remitos de Venta'}
                />
                <Box className='container-xl mt-3 mb-3'>
                    <ReactGrid
                        title={`Remitos del sistema (${rows.length})`}
                        height={-200}
                        columns={columns}
                        columnExtensions={columnExtensions}
                        rows={rows}
                        showFilters={false}
                        rowClick={true}
                        onRowClick={this.navView}
                        customBtn1={
                            <TextField
                                select
                                // label="Ver desde"
                                className={'ml-2'}
                                style={{width: '150px'}}
                                value={this.state.desde}
                                onChange={event => this.changeDesde(event.target.value)}
                            >
                                {FILTROS_DESDE.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.description}
                                    </MenuItem>
                                ))}
                            </TextField>
                        }
                    />
                </Box>
            </Box>
        )
    }
}

RemitosList.contextType = MainContext;
RemitosList = withSnackbar(RemitosList);
