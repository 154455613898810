import {useHistory, useRouteMatch} from "react-router";
import React, {useEffect, useState} from "react";
import {useArticulosService, useHojasDeRutaService} from "services/hooks";
import {useSnackbar} from "notistack";
import {Button, Divider} from "@material-ui/core/index";
import {CardArticulo} from "components/picking/components/card.articulo";
import {CardHeader} from "components/picking/components/card.header";
import {ScanResultDialog} from "components/picking/components/scan.result.dialog";
import {useConfirm} from 'material-ui-confirm';
import Typography from "@material-ui/core/Typography";
import {ScanReceptor} from "components/picking/components/scan.receptor";

export function ScanArticulosView() {
    const [articulos, _setArticulos] = useState([]);
    const [scanResult, setScanResult] = useState({dialogOpen: false, found: false, articulo: undefined});

    const history = useHistory();
    const match = useRouteMatch();
    const {enqueueSnackbar} = useSnackbar();
    const confirm = useConfirm();

    const HojasDeRutaService = useHojasDeRutaService();
    const ArticulosService = useArticulosService();

    const articulosRef = React.useRef(articulos);

    const setArticulos = articulos => {
        articulosRef.current = articulos;
        _setArticulos(articulos);
    }

    useEffect(() => {
        HojasDeRutaService.getArticulosById(
            match.params.idHojaDeRuta,
            response => setArticulos(response.data.result.map(articulo => ({...articulo, scanned: 0}))),
            error => enqueueSnackbar(error, {variant: 'error'})
        );
    }, []);

    const findScannedArticulo = (scannedCode) => {
        return new Promise((resolve, reject) => {
            ArticulosService.getByBarcode(
                scannedCode,
                success => resolve(success.data.count > 0 ? success.data.result : undefined),
                error => enqueueSnackbar(error, {variant: 'error'})
            )
        })
    }

    const onScanCode = async (scannedCode) => {
        let scannedArticulo = await findScannedArticulo(scannedCode);
        scannedArticulo = articulosRef.current.find(articulo => (articulo.id === scannedArticulo?.id));

        if (scannedArticulo) {
            scannedArticulo.scanned++;
            setArticulos(articulosRef.current);
        }

        setScanResult({
            dialogOpen: true,
            found: !!scannedArticulo,
            scannedCode: scannedCode,
            articulo: scannedArticulo
        });
    };

    const onFinishCollect = () => {
        const notScanned = articulos.filter(articulo => !articulo.scanned);

        const navBack = () => history.push(`/picking/recolectarArticulos`);

        if (notScanned.length > 0) {
            confirm({
                title: '¿Seguro de terminar?',
                // description: '¿Seguro de terminar la recolección?',
                content: <div>
                    Hay <Typography component={"span"} variant={"body2"} color={"primary"} align={"right"}>
                        <b>{notScanned.length}</b>
                    </Typography> artículos sin escanear.
                </div>,
                confirmationText: 'Terminar',
                cancellationText: 'Cancelar'
            })
                .then(() => {
                    navBack();
                })
                .catch(() => {
                });
        } else
            navBack();
    }

    const closeResultDialog = () => setScanResult(prev => ({...prev, dialogOpen: false}));

    return (
        <>
            <ScanReceptor onScanCode={onScanCode} />
            <div style={{height: window.innerHeight - 47, overflowY: 'auto'}}>
                <CardHeader
                    textLeft={<>Hoja de Ruta: <b>{match.params.idHojaDeRuta}</b></>}
                    textRight={<><b>{articulos.length}</b> artículos</>}
                />
                {articulos.map((articulo, index) =>
                    <>
                        <CardArticulo
                            key={index}
                            id={articulo.id}
                            detalle={articulo.detalle}
                            cantidad={articulo.cantidad}
                            scanned={articulo.scanned}
                            onClick={() => {
                            }}
                        />
                        {index !== articulos.length - 1 && (
                            <Divider/>
                        )}
                    </>
                )}
                <Button
                    size={"medium"}
                    variant={"contained"}
                    className={'mt-2 mb-2'}
                    // disableElevation
                    color={"primary"}
                    fullWidth
                    style={{width: '90%', height: '3.5rem', margin: '0 5%'}}
                    onClick={() => {
                        onFinishCollect()
                    }}
                >
                    Terminar
                </Button>
            </div>
            <ScanResultDialog
                open={scanResult.dialogOpen}
                scanResult={scanResult}
                fullScreen
                onConfirm={() => {
                    closeResultDialog()
                }}
                confirmText={'Cerrar'}
            />
        </>
    )
}
