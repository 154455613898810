import React, {useEffect, useState} from 'react';
import SubHeader from "components/helpers/subHeader/subHeader";
import Box from "@material-ui/core/Box";
import {AppBar, Tab, Tabs, Toolbar} from "@material-ui/core";
import {Grid} from "@material-ui/core/index";
import Badge from "@material-ui/core/Badge";
import {Route, Switch, useHistory} from "react-router-dom";
import {useLocation, useRouteMatch} from "react-router";
import {CotizacionesList} from "./cotizaciones.list";

const basePath = '/aprobaciones/cotizaciones';
const TABS = ["pending", "approved", "rejected"];

export const Cotizaciones = () => {
    const [activeTab, setActiveTab] = useState(TABS[0]);

    const location = useLocation();
    const history = useHistory();
    const match = useRouteMatch();

    useEffect(function () {
        TABS.forEach(tab => {
            if (location.pathname.includes(tab))
                setActiveTab(tab);
        })
    }, []);

    const changeTab = (event, value) => {
        setActiveTab(value);
        history.push(`${match.url}/${value}`);
    };

    return (
        <>
            <div>
                <SubHeader
                    title={'Cotizaciones'}
                    subtitle={'Aprobar cotizaciones PREPAGO'}
                />
                <AppBar position="relative" elevation={0}>
                    <Toolbar variant="dense" color='primary'>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item md={4}>
                                <Tabs
                                    value={activeTab}
                                    onChange={changeTab}
                                    indicatorColor="secondary"
                                    textColor="inherit"
                                >
                                    <Tab label={<Badge color="secondary" className='pr-3'>Pendientes</Badge>} value={TABS[0]}/>
                                    <Tab label={<Badge color="secondary" className='pr-3'>Aprobadas</Badge>} value={TABS[1]}/>
                                    <Tab label={<Badge color="secondary" className='pr-3'>Rechazadas</Badge>} value={TABS[2]}/>
                                </Tabs>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Box className='container-xl mt-3 mb-3'>
                    <Switch>
                        <Route path={`${basePath}/${TABS[2]}`} render={() => <CotizacionesList key={TABS[2]} filter={TABS[2]}/>}/>
                        <Route path={`${basePath}/${TABS[1]}`} render={() => <CotizacionesList key={TABS[1]} filter={TABS[1]}/>}/>
                        <Route path={`${basePath}`} render={() => <CotizacionesList key={TABS[0]} filter={TABS[0]}/>}/>
                    </Switch>
                </Box>
            </div>
        </>
    )
}
