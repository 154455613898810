import React, {Component, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core/index";
import {TextField, Typography} from "@material-ui/core";

export class RejectDialog extends Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onCancel}
                fullScreen={this.props.fullScreen}
                // maxWidth={"md"}
                classes={{
                    paperFullScreen: 'niquel-dialog-full-screen'
                }}
            >
                <RejectDialogContent {...this.props}/>
            </Dialog>
        )
    }
}

const RejectDialogContent = ({title, onCancel, onConfirm}) => {
    const [rejectMessage, setRejectMessage] = useState("");

    return (
        <React.Fragment>
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <Typography variant={"body2"}>La cobranza quedará en estado de Observada y el ítem deberá ser eliminado por el vendedor.</Typography>

                <TextField
                    fullWidth
                    multiline
                    maxRows={3}
                    className={'mt-3 mb-4'}
                    label={'Motivo de Rechazo'}
                    value={rejectMessage}
                    onChange={event => setRejectMessage(event.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel} color="primary">
                    {'Cerrar'}
                </Button>
                <Button onClick={() => onConfirm(rejectMessage)} color="primary" autoFocus>
                    Confirmar
                </Button>
            </DialogActions>
        </React.Fragment>
    )
}
