export function generalReducer(state, action) {
    let {type, payload} = action;

    if (type === 'fullstatechange') {
        return {...payload};
    }

    type = type.split('.');

    if (type.length === 1) {
        const [name] = type;
        // state[name] = payload;
        // return state;
        return {...state, [name]: payload};
    }

    if (type.length === 2) {
        const [name1, name2] = type;
        // state[name1][name2] = payload;
        // return state;
        return {...state, [name1]: {...state[name1], [name2]: payload}};
    }

}
