import React, {Component} from 'react'
import {Box, Typography} from "@material-ui/core";
import {withSnackbar} from "notistack";
import {MainContext} from "contexts/main.context";
import SubHeader from "components/helpers/subHeader/subHeader";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";

export class AfipSelection extends Component {
    navCot = (tipo) => {
        this.props.history.push(`${this.props.match.url}/COT-${tipo}`);
    };

    render() {
        return (
            <Box className='niquel-afip-main'>
                <SubHeader
                    title={'AFIP'}
                    subtitle={'Servicios AFIP'}
                />
                <Box className='container-xl mt-3 mb-3'>
                    <Card className={'m-2 d-inline-flex'} style={{maxWidth: '30rem'}}>
                        <CardActionArea onClick={() => this.navCot('DOC')}>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h3">
                                    Generar COT x Documento
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Elija entre los documentos facturados para generar el archivo para COT y/o la carga automática de remito electrónico.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" color="primary" disableRipple>
                                    Acceder
                                </Button>
                            </CardActions>
                        </CardActionArea>
                    </Card>

                    <Card className={'m-2 d-inline-flex'} style={{maxWidth: '30rem'}}>
                        <CardActionArea onClick={() => this.navCot('RUT')}>
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="h3">
                                    Generar COT x Hoja de Ruta
                                </Typography>
                                <Typography variant="body2" color="textSecondary" component="p">
                                    Elija entre las hojas de ruta creadas para generar el archivo para COT y/o la carga automática de remito electrónico.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" color="primary" disableRipple>
                                    Acceder
                                </Button>
                            </CardActions>
                        </CardActionArea>
                    </Card>

                </Box>
            </Box>
        )
    }
}

AfipSelection.contextType = MainContext;
AfipSelection = withSnackbar(AfipSelection);
