import React from "react";
import {Checkbox, Grid, MenuItem, Switch, TextField, Tooltip, Typography, withStyles} from "@material-ui/core";
import {MainContext} from "contexts/main.context";
import {withSnackbar} from "notistack";
import MomentUtils from "@date-io/moment";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {regExpInteger, regExpPrice,} from "assets/utils.js";
import {InputPrice} from "assets/utils";
import FormControlLabel from "@material-ui/core/FormControlLabel";

class ChequeEditFields extends React.Component {
    onChange = (sField, value) => {
        let {depositado, numero, idelectro, banco, fecha, importe, repetido} = this.props.cheque;

        switch (sField) {
            case 'depositado':
                depositado = value;
                break;
            case 'numero':
                numero = regExpInteger.test(value) ? value : numero;
                break;
            case 'idelectro':
                idelectro = value;
                break;
            case 'banco':
                banco = this.props.bancos.find(_banco => _banco.id === value);
                break;
            case 'fecha':
                fecha = value;
                break;
            case 'importe':
                importe = regExpPrice.test(value) ? value : importe;
                break;
            case 'repetido':
                repetido = value;
                break;
            default:
                value = null;
        }

        this.props.onEdit({
            ...this.props.cheque,
            depositado: depositado,
            numero: numero,
            idelectro: idelectro,
            banco: banco,
            fecha: fecha,
            importe: importe,
            repetido: repetido
        });
    };

    render() {
        const {tipo, cheque, bancos} = this.props;

        return (
            <Grid container direction={"column"} spacing={2}>
                {tipo === 'CHE' && (
                    <Grid item style={{width: '100%'}}>
                        <TextField
                            required
                            fullWidth
                            label="ID Electrónico"
                            value={cheque.idelectro}
                            onChange={e => this.onChange('idelectro', e.target.value)}
                        />
                    </Grid>
                )}
                {tipo === 'CHF' && (
                    <Grid item style={{width: '100%'}}>
                        <FormControlLabel
                            className={'ml-0'}
                            label="Depositado"
                            labelPlacement="start"
                            checked={cheque.depositado}
                            onChange={() => this.onChange('depositado', !cheque.depositado)}
                            control={<Checkbox className={"ml-4"}/>}
                        />
                    </Grid>
                )}
                <Grid item style={{width: '100%'}}>
                    <TextField
                        style={{width: '73%'}}
                        required
                        label="Número"
                        value={cheque.numero}
                        error={isNaN(parseInt(cheque.numero)) || parseInt(cheque.numero) <= 0}
                        inputProps={{maxLength: 8}}
                        helperText={cheque.importe <= 0 ? 'Debe ingresar un número de hasta 8 dígitos' : ''}

                        onChange={e => this.onChange('numero', e.target.value)}
                    />
                    <Tooltip title={'Permitir número repetido'}>
                    <FormControlLabel
                        className={'ml-2'}
                        labelPlacement={"start"}
                        control={
                                <Checkbox
                                    checked={cheque.repetido}
                                    onChange={e => this.onChange('repetido', !e.target.value)}
                                    name="checkedB"
                                    color="primary"
                                />
                        }
                        label={<Typography variant={"caption"}>permitir repetido</Typography>}
                    />
                    </Tooltip>
                </Grid>
                <Grid item style={{width: '100%'}}>
                    <TextField
                        select
                        required
                        fullWidth
                        label="Banco"
                        value={cheque.banco ? cheque.banco.id : ''}
                        error={!cheque.banco}
                        helperText={!cheque.banco ? 'Debe elegir un banco' : ''}
                        onChange={e => this.onChange('banco', e.target.value)}
                    >
                        {bancos.map(option => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.id} | {option.description}
                            </MenuItem>
                        ))}
                    </TextField>
                </Grid>
                <Grid item style={{width: '100%'}}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                        <DatePicker
                            disableToolbar
                            autoOk
                            required
                            fullWidth
                            label="Fecha Cobro"
                            format="DD/MM/Y"
                            // disablePast={true}
                            value={cheque.fecha}
                            error={cheque.fecha == null}
                            helperText={cheque.importe == null ? 'Debe ingresar una fecha válida' : ''}
                            onChange={moment => this.onChange('fecha', moment)}
                        />
                    </MuiPickersUtilsProvider>
                </Grid>
                <Grid item style={{width: '100%'}}>
                    <TextField
                        required
                        fullWidth
                        label="Importe"
                        value={cheque.importe}
                        error={cheque.importe <= 0}
                        helperText={cheque.importe <= 0 ? 'Debe ingresar un importe mayor a cero' : ''}
                        InputProps={{
                            inputComponent: InputPrice,
                        }}
                        onChange={e => this.onChange('importe', e.target.value)}
                    />
                </Grid>
            </Grid>
        )
    }
}

ChequeEditFields.contextType = MainContext;
ChequeEditFields = withSnackbar(ChequeEditFields);

export default withStyles(null)(ChequeEditFields);
