import React, {useContext, useEffect, useState} from 'react';
import SubHeader from "components/helpers/subHeader/subHeader";
import Box from "@material-ui/core/Box";
import {Paper} from "@material-ui/core";
import {Grid} from "@material-ui/core/index";
import {useMastersService, useSchedulerService} from "services/hooks";
import {useSnackbar} from "notistack";
import {MainContext} from "contexts/main.context";
import moment from "moment"
import {
    AppointmentForm,
    Appointments,
    AppointmentTooltip,
    CurrentTimeIndicator,
    DateNavigator,
    DayView,
    DragDropProvider,
    MonthView,
    Resources,
    Scheduler,
    TodayButton,
    Toolbar,
    ViewSwitcher,
    WeekView
} from "@devexpress/dx-react-scheduler-material-ui";
import {EditingState, IntegratedEditing, ViewState} from "@devexpress/dx-react-scheduler";
import localization from './localization.json';
import {defaultContacts} from "assets/utils";
import {DescriptionRounded} from "@material-ui/icons";

const TooltipContent = (({
                             children, appointmentData, ...restProps
                         }) => (
    <AppointmentTooltip.Content {...restProps} appointmentData={appointmentData}>
        {appointmentData.notes?.length > 0 && (
            <Grid container alignItems="center" className={'mt-3'}>
                <Grid item xs={2}>
                    <DescriptionRounded
                        style={{
                            marginLeft: '1.3rem',
                            marginTop: '0.2rem',
                            color: 'gray'
                        }}
                    />
                </Grid>
                <Grid item xs={10} justifyContent={"center"}>
                    <span>{appointmentData.notes}</span>
                </Grid>
            </Grid>
        )}
    </AppointmentTooltip.Content>
));

export const Agenda = () => {
    const [contacts, setContacts] = useState(defaultContacts);
    const [appointments, setAppointments] = useState([]);
    const context = useContext(MainContext);
    const MastersService = useMastersService();
    const SchedulerService = useSchedulerService();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const userCanEditAppointments = !["S", "V"].includes(context.loggedUser.role);

    useEffect(function () {
        if (userCanEditAppointments)
            getUsers();
        getAppointments();
    }, []);

    const getUsers = () => {
        MastersService.getUsers(
            response => {
                let _contacts = response.data.result;
                // Filtro usuarios que no tienen agenda.
                // _contacts = _contacts.filter(contact => ['V', 'H', 'L', 'E'].includes(contact.role) === false);
                _contacts.forEach(contact => contact.type = 'Usuarios');
                _contacts = contacts.concat(_contacts);
                _contacts.forEach(contact => contact.text = contact.description);
                setContacts(_contacts);
            },
            error => enqueueSnackbar("Error al obtener los contactos", {variant: 'error'})
        );
    };

    const getAppointments = () => {
        SchedulerService.get(
            response => setAppointments(response.data.result.map(appointment => ({
                ...appointment.data,
                startDate: moment(appointment.data.startDate).toDate(),
                endDate: moment(appointment.data.endDate).toDate(),
                creator: appointment.creator
            }))),
            error => enqueueSnackbar("Error al obtener las reuniones", {variant: 'error'})
        );
    }

    const commitChanges = ({changed, added, deleted}) => {
        if (typeof changed !== "undefined")
            editAppointment(Object.keys(changed).pop(), Object.values(changed).pop());

        if (typeof added !== "undefined")
            addAppointment(added);

        if (typeof deleted !== "undefined")
            deleteAppointment(deleted);
    };

    const fixAllDayAppointment = (appointment) => {
        if (appointment.allDay) {
            let mStartDate = moment(appointment.startDate);
            let mEndDate = moment(appointment.endDate);
            appointment.startDate = mStartDate.set({"hour": 9, "minute": 0});
            appointment.endDate = mEndDate.set({"date": mStartDate.date(), "hour": 18, "minute": 0});
            appointment.allDay = false;
        }
        return appointment;
    };

    const editAppointment = (id, appointment) => {
        const fullAppointment = appointments.find(appointment => parseInt(appointment.id) === parseInt(id));

        if (!fullAppointment) {
            enqueueSnackbar("Error al editar la reunión");
            return false;
        }

        const creator = fullAppointment.creator;
        if (context.loggedUser.username !== creator) {
            enqueueSnackbar("Solo el creador puede editar esta reunión");
            return false;
        }

        appointment = fixAllDayAppointment(appointment);
        appointment.startDate = appointment.startDate ? moment(appointment.startDate).valueOf() : undefined;
        appointment.endDate = appointment.endDate ? moment(appointment.endDate).valueOf() : undefined;

        SchedulerService.edit(
            id,
            appointment,
            response => {
                getAppointments();
                enqueueSnackbar("La reunión se editó correctamente", {variant: 'success'})
            },
            error => enqueueSnackbar("Error al editar la reunión", {variant: 'error'})
        );
    };

    const addAppointment = appointment => {
        appointment = fixAllDayAppointment(appointment);
        appointment.startDate = moment(appointment.startDate).valueOf();
        appointment.endDate = moment(appointment.endDate).valueOf();

        SchedulerService.add(
            appointment,
            response => {
                getAppointments();
                enqueueSnackbar("La reunión se guardó correctamente", {variant: 'success'})
            },
            error => enqueueSnackbar("Error al guardar la reunión", {variant: 'error'})
        );
    };

    const deleteAppointment = id => {
        SchedulerService.delete(
            id,
            success => {
                getAppointments();
                enqueueSnackbar("La reunión se eliminó correctamente", {variant: 'success'})
            },
            error => enqueueSnackbar("Error al eliminar la reunión", {variant: 'error'})
        );
    };

    const schedulerResources = [{
        fieldName: 'users',
        title: 'Usuarios',
        instances: contacts,
        allowMultiple: true,
    }];

    return (
        <>
            <SubHeader
                title={'Agenda'}
            />
            <Box className='container-xl mt-3 mb-3'>
                <Box className='wrapper'>
                    <Grid container direction={"column"} spacing={2}>
                        <Paper>
                            <Scheduler
                                data={appointments}
                                locale={'es-419'}
                                height={window.innerHeight - 120}
                            >
                                <ViewState
                                    // currentDate={new Date()}
                                    defaultCurrentViewName="month"
                                    onDoubleClick={undefined}
                                />
                                <EditingState
                                    onCommitChanges={commitChanges}
                                />
                                <IntegratedEditing/>
                                <DayView
                                    startDayHour={8}
                                    endDayHour={19}
                                    displayName="Dia"
                                />
                                <WeekView
                                    startDayHour={8}
                                    endDayHour={19}
                                    displayName="Semana"
                                />
                                <WeekView
                                    name="work-week"
                                    displayName="Semana Laboral"
                                    excludedDays={[0, 6]}
                                    startDayHour={8}
                                    endDayHour={19}
                                />
                                <MonthView
                                    name="month"
                                    displayName="Mes"
                                />
                                <Toolbar/>
                                <ViewSwitcher/>
                                <DateNavigator/>
                                <TodayButton messages={localization.todayButton}/>
                                <Appointments/>
                                <AppointmentTooltip
                                    showCloseButton
                                    showOpenButton={userCanEditAppointments}
                                    showDeleteButton={userCanEditAppointments}
                                    contentComponent={TooltipContent}
                                />
                                <AppointmentForm
                                    readOnly={!userCanEditAppointments}
                                    messages={localization.appointmentForm}
                                />
                                <Resources
                                    data={schedulerResources}
                                    mainResourceName="users"
                                />
                                {userCanEditAppointments && <DragDropProvider/>}
                                <CurrentTimeIndicator/>
                            </Scheduler>
                        </Paper>
                    </Grid>
                </Box>
            </Box>
        </>
    )
}
