import React from "react";
import {Box, Typography} from "@material-ui/core";
import {SimpleDialog} from "components/helpers/dialogs/simple.dialog";

const DeleteSubObjetivoDialogBody = ({tiposubobj_nombre, nombre}) =>
    <Box>
        <Typography>Estás por eliminar el subobjetivo <b>{nombre}</b> ({tiposubobj_nombre})</Typography>
    </Box>

export const DeleteSubObjetivoDialog = ({subobjetivo, open, onDelete, onCancel}) => {
    return (
        <SimpleDialog
            open={open}
            maxWidth={'xs'}
            fullWidth
            title={'Eliminar Subobjetivo'}
            body={
                <DeleteSubObjetivoDialogBody
                    tiposubobj_nombre={subobjetivo.tiposubobj_nombre}
                    nombre={subobjetivo.nombre}
                />
            }
            onDelete={() => onDelete()}
            onCancel={onCancel}
        />
    )
}
