import React, {Component} from 'react'
import {Box, Grid, MenuItem, TextField} from "@material-ui/core/index";
import {ReactGrid} from 'components/helpers/reactGrid/reactGrid';
import {ClientesService, EmailService, ListadosService, MastersService, VendedoresService} from "services";
import {withSnackbar} from "notistack";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import 'moment/locale/es';
import {MainContext} from "contexts/main.context";
import SubHeader from "components/helpers/subHeader/subHeader";
import {MailDialog} from "components/helpers/dialogs/mail.dialog";
import SpeedDial from "@material-ui/lab/SpeedDial/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction/SpeedDialAction";
import PDFIcon from "@material-ui/icons/PictureAsPdf";
import DownloadIcon from "@material-ui/icons/GetAppRounded";
import ShowIcon from "@material-ui/icons/DesktopWindowsRounded";
import PrintIcon from "@material-ui/icons/PrintRounded";
import EmailIcon from "@material-ui/icons/EmailRounded";
import AscIcon from "@material-ui/icons/ArrowUpward";
import DescIcon from "@material-ui/icons/ArrowDownward";
import printJS from 'print-js'
import {PDFDialog} from "components/helpers/dialogs/pdf.dialog";
import InputAdornment from "@material-ui/core/InputAdornment";
import {InputInteger} from "assets/utils";

const STATUS = [
    {id: 'todas', description: 'TODAS'},
    {id: 'A', description: 'APROBADAS'},
    {id: 'P', description: 'PENDIENTES'},
    {id: 'O', description: 'OBSERVADAS'},
    {id: 'I', description: 'INGRESADAS'},
];

export class CobranzasTransitorias extends Component {
    state = {
        tipoFiltro: 'fecha',
        fecha_desde: (new MomentUtils()).date(new Date()).subtract(30, 'days'),
        fecha_hasta: (new MomentUtils()).date(new Date()),
        recibo_desde: 0,
        recibo_hasta: 69999,
        visualizar: 'detalles',
        agrupar: 'cliente',
        ordenar: {
            field: 'fecha',
            direction: 'asc'
        },
        status: 'todas',
        clientes: {
            columns: [
                {name: 'id', title: 'ID'},
                {name: 'razsoc', title: 'Razón Social'},
                {name: 'apellido', title: 'Apellido'},
                {name: 'localidad', title: 'Localidad'}
            ],
            columnExtensions: [
                {columnName: 'id', width: 100},
                {columnName: 'apellido', width: 120},
                {columnName: 'localidad', width: 150},
            ],
            data: [],
            selected: [],
            all: true
        },
        vendedores: {
            columns: [
                {name: 'id', title: 'ID'},
                {name: 'name', title: 'Nombre'}
            ],
            columnExtensions: [
                {columnName: 'id', width: 150},
            ],
            data: [],
            selected: [],
            all: true
        },
        pdfBlob: null,
        pdfUrl: '',
        mailDialogOpen: false,
        pdfDialogOpen: false,
        dialOpen: false,
    };

    tipoFiltro = [
        {
            id: 'fecha',
            description: 'por Fecha'
        },
        {
            id: 'recibo',
            description: 'por Recibo'
        }
    ];

    visualizar = [
        {
            id: 'encabezado',
            description: 'Cobranzas (solo encabezado)'
        },
        {
            id: 'detalles',
            description: 'Cobranzas (con detalle)'
        },
        {
            id: 'items',
            description: 'Totales por item'
        }
    ];

    agrupar = [
        {
            id: 'cliente',
            description: 'por Cliente'
        },
        {
            id: 'vendedor',
            description: 'por Vendedor'
        }
    ];

    ordenar_field = [
        {
            id: 'fecha',
            description: 'Fecha'
        },
        {
            id: 'recibo',
            description: 'Recibo'
        },
        {
            id: 'cliente',
            description: 'Cliente'
        },
        {
            id: 'vendedor',
            description: 'Vendedor'
        },
        {
            id: 'usuario',
            description: 'Usuario'
        }
    ];

    ordenar_direction = [
        {
            id: 'asc',
            description: <AscIcon fontSize={"small"}/>
        },
        {
            id: 'desc',
            description: <DescIcon fontSize={"small"}/>
        }
    ];

    MastersService = new MastersService(this.context);
    VendedoresService = new VendedoresService(this.context);
    ClientesService = new ClientesService(this.context);
    ListadosService = new ListadosService(this.context);
    EmailService = new EmailService(this.context);

    componentDidMount() {
        this.ClientesService.getAll(
            success => this.setState(prevState => (prevState.clientes.data = success.data.result)),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.VendedoresService.getAll(
            success => this.setState(prevState => (prevState.vendedores.data = success.data.result)),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    onChangeSwitchAll = field => this.setState(prevState => (prevState[field].all = !prevState[field].all));
    onChangeSelection = (field, value) => this.setState({[field]: value});

    onChangeOrdenar = (field, value) => this.setState(prevState => prevState.ordenar[field] = value);

    onSelect = (field, selected) => this.setState(prevState => (prevState[field].selected = selected));

    getPDF = (fnSuccess = () => {
    }) => {
        // Valido que haya al menos 1 cliente seleccionado.
        if (this.state.clientes.selected.length === 0 && this.state.clientes.all === false) {
            this.props.enqueueSnackbar('Debe seleccionar al menos un cliente para generar el listado.', {variant: 'error'});
            return false;
        }

        // Valido que haya al menos 1 vendedor seleccionado.
        if (this.state.vendedores.selected.length === 0 && this.state.vendedores.all === false) {
            this.props.enqueueSnackbar('Debe seleccionar al menos un vendedor para generar el listado.', {variant: 'error'});
            return false;
        }

        // Si seleccionó todos mando vacío (así lo espera el API)
        let clientes = [];
        if (this.state.clientes.all === false)
            clientes = this.state.clientes.data.filter((cliente, index) => this.state.clientes.selected.indexOf(index) !== -1);

        let vendedores = [];
        if (this.state.vendedores.all === false)
            vendedores = this.state.vendedores.data.filter((vendedor, index) => this.state.vendedores.selected.indexOf(index) !== -1);

        // Elijo los campos de filtro
        let desde = this.state.recibo_desde;
        let hasta = this.state.recibo_hasta;
        if (this.state.tipoFiltro === 'fecha') {
            desde = this.state.fecha_desde;
            hasta = this.state.fecha_hasta;
        }

        // Valido que desde < hasta
        if (desde > hasta) {
            this.props.enqueueSnackbar("Filtro DESDE debe ser menor que HASTA", {variant: 'error'})
            return;
        }

        // Formateo los campos de filtro fecha.
        if (this.state.tipoFiltro === 'fecha') {
            desde = desde.format('DD/MM/YY');
            hasta = hasta.format('DD/MM/YY');
        }

        const status = this.state.status !== 'todas' ? this.state.status : null;

        this.ListadosService.cobranzasTransitorias(
            clientes,
            vendedores,
            this.state.tipoFiltro,
            desde,
            hasta,
            this.state.visualizar,
            this.state.agrupar,
            this.state.ordenar,
            status,
            file => this.setState({pdfBlob: file}, fnSuccess),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    };

    // Show PDF
    onOpenPdfDialog = () => this.setState({pdfDialogOpen: true});
    onClosePdfDialog = () => this.setState({pdfDialogOpen: false});

    onShowPDF = () => {
        this.getPDF(
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                this.setState({pdfUrl: fileURL}, this.onOpenPdfDialog);
            }
        );
    };

    // Print PDF
    onPrintPDF = () => {
        this.getPDF(
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                printJS(fileURL);
            }
        );
    };

    // Download PDF
    onDownloadPdf = () => {
        this.getPDF(
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = fileURL;
                a.download = `Cobranzas Transitorias`;
                a.click();
                window.URL.revokeObjectURL(fileURL);
                this.props.enqueueSnackbar('El archivo se descargó correctamente. Revise su carpeta de descargas.', {variant: 'success'});
            }
        );
    };

    // Email
    onOpenMailDialog = () => this.setState({mailDialogOpen: true});

    dialActions = [
        {icon: <EmailIcon/>, name: 'Enviar por Email', action: this.onOpenMailDialog},
        {icon: <PrintIcon/>, name: 'Imprimir', action: this.onPrintPDF},
        {icon: <DownloadIcon/>, name: 'Descargar', action: this.onDownloadPdf},
        {icon: <ShowIcon/>, name: 'Ver en Pantalla', action: this.onShowPDF},
    ];

    onCloseMailDialog = () => this.setState({mailDialogOpen: false});

    sendMail = mailData => {
        let sendMail = () => {
            // Paso los parámetros a un formData para poder enviar el blob (attachment)
            var formData = new FormData();
            formData.append('attachment', this.state.pdfBlob);
            formData.append('data', JSON.stringify(mailData));

            this.EmailService.send(
                formData,
                response => {
                    this.onCloseMailDialog();
                    this.props.enqueueSnackbar('El email se envió correctamente', {variant: 'success'});
                },
                error => this.props.enqueueSnackbar(error, {variant: 'error'})
            );
        };

        if (mailData.attachFile)
            this.getPDF(() => sendMail());
        else
            sendMail();
    };

    onDialOpen = () => this.setState({dialOpen: true});
    onDialClose = () => this.setState({dialOpen: false});

    render() {
        const {
            tipoFiltro, fecha_desde, fecha_hasta, recibo_desde, recibo_hasta, visualizar, agrupar, ordenar, status,
            clientes, vendedores, mailDialogOpen, pdfDialogOpen, pdfUrl, dialOpen,
        } = this.state;
        return (
            <React.Fragment>
                <Box className='niquel-listados-cobranzasTransitorias'>
                    <SubHeader
                        title={'Cobranzas Transitorias'}
                    />
                    <Box className='container-xl mt-3 mb-3'>
                        <Box className='wrapper'>
                            <Grid container spacing={4}>
                                <Grid item container spacing={1} className={'w-auto'}>
                                    <Grid item>
                                        <TextField
                                            select
                                            label="Tipo Filtro"
                                            value={tipoFiltro}
                                            onChange={event => this.onChangeSelection('tipoFiltro', event.target.value)}
                                        >
                                            {this.tipoFiltro.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.description}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    {tipoFiltro === 'fecha' && (
                                        <>
                                            <Grid item>
                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                    <DatePicker
                                                        autoOk
                                                        style={{width: '6rem'}}
                                                        label="Desde"
                                                        format="DD/MM/Y"
                                                        value={fecha_desde}
                                                        error={fecha_desde > fecha_hasta}
                                                        onChange={moment => this.onChangeSelection('fecha_desde', moment)}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                            <Grid item>
                                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                                    <DatePicker
                                                        autoOk
                                                        style={{width: '6rem'}}
                                                        label="Hasta"
                                                        format="DD/MM/Y"
                                                        value={fecha_hasta}
                                                        error={fecha_hasta < fecha_desde}
                                                        onChange={moment => this.onChangeSelection('fecha_hasta', moment)}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Grid>
                                        </>
                                    )}
                                    {tipoFiltro !== 'fecha' && (
                                        <>
                                            <Grid item>
                                                <TextField
                                                    label='Desde'
                                                    value={recibo_desde}
                                                    onChange={event => this.onChangeSelection('recibo_desde', event.target.value)}
                                                    error={parseInt(recibo_desde) > parseInt(recibo_hasta)}
                                                    InputProps={{
                                                        inputComponent: InputInteger,
                                                        startAdornment: <InputAdornment position="start">X0006</InputAdornment>,
                                                        style: {width: '7.5rem'}
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item>
                                                <TextField
                                                    label='Hasta'
                                                    value={recibo_hasta}
                                                    onChange={event => this.onChangeSelection('recibo_hasta', event.target.value)}
                                                    error={parseInt(recibo_hasta) < parseInt(recibo_desde)}
                                                    InputProps={{
                                                        inputComponent: InputInteger,
                                                        startAdornment: <InputAdornment position="start">X0006</InputAdornment>,
                                                        style: {width: '7.5rem'}
                                                    }}
                                                />
                                            </Grid>
                                        </>
                                    )}
                                </Grid>
                                <Grid item container spacing={2} className={'w-auto'}>
                                    <Grid item>
                                        <TextField
                                            select
                                            label="Visualizar"
                                            value={visualizar}
                                            onChange={event => this.onChangeSelection('visualizar', event.target.value)}
                                        >
                                            {this.visualizar.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.description}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    {visualizar === 'encabezado' && (
                                        <Grid item>
                                            <TextField
                                                select
                                                label="Agrupar"
                                                value={agrupar}
                                                onChange={event => this.onChangeSelection('agrupar', event.target.value)}
                                            >
                                                {this.agrupar.map(option => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.description}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                    )}
                                    {visualizar !== 'items' && (
                                    <Grid item className={'d-flex align-items-end'}>
                                        <TextField
                                            select
                                            label="Ordenar"
                                            value={ordenar.field}
                                            onChange={event => this.onChangeOrdenar('field', event.target.value)}
                                        >
                                            {this.ordenar_field.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.description}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        <TextField
                                            select
                                            className={'ml-1'}
                                            value={ordenar.direction}
                                            onChange={event => this.onChangeOrdenar('direction', event.target.value)}
                                        >
                                            {this.ordenar_direction.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.description}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    )}
                                    <Grid item>
                                        <TextField
                                            select
                                            label="Status"
                                            value={status}
                                            onChange={event => this.onChangeSelection('status', event.target.value)}
                                        >
                                            {STATUS.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.description}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Box className='mt-3'>
                                <ReactGrid
                                    columns={clientes.columns}
                                    columnExtensions={clientes.columnExtensions}
                                    rows={clientes.data}
                                    title={'Clientes'}
                                    height={300}
                                    enableFiltering={false}
                                    enableSorting={true}
                                    enableSearch={true}
                                    enableSelection={true}
                                    selectedRows={clientes.selected}
                                    onSelectionChange={selected => this.onSelect('clientes', selected)}
                                    onDelete={selected => this.onSelect('clientes', [])}
                                    showSelectAll={false}
                                    showSwitchAll={true}
                                    switchAllValue={clientes.all}
                                    onChangeSwitchAll={() => this.onChangeSwitchAll('clientes')}
                                />
                            </Box>
                            {(this.context.loggedUser.role === 'A' || this.context.loggedUser.role === 'S') && (
                                <Box className='mt-2'>
                                    <ReactGrid
                                        columns={vendedores.columns}
                                        columnExtensions={vendedores.columnExtensions}
                                        rows={vendedores.data}
                                        title={'Vendedores'}
                                        height={300}
                                        enableFiltering={false}
                                        enableSorting={true}
                                        enableSearch={true}
                                        enableSelection={true}
                                        selectedRows={vendedores.selected}
                                        onSelectionChange={selected => this.onSelect('vendedores', selected)}
                                        onDelete={selected => this.onSelect('vendedores', [])}
                                        showSelectAll={false}
                                        showSwitchAll={true}
                                        switchAllValue={vendedores.all}
                                        onChangeSwitchAll={() => this.onChangeSwitchAll('vendedores')}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <Box className='niquel-fab-btn'>
                        <SpeedDial
                            ariaLabel="cobranzas_transitorias"
                            icon={<PDFIcon/>}
                            onBlur={this.onDialClose}
                            onClick={this.onDialOpen}
                            onClose={this.onDialClose}
                            onFocus={this.onDialOpen}
                            onMouseEnter={this.onDialOpen}
                            onMouseLeave={this.onDialClose}
                            open={dialOpen}
                        >
                            {this.dialActions.map(action => (
                                <SpeedDialAction
                                    key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        action.action()
                                    }}
                                />
                            ))}
                        </SpeedDial>
                    </Box>
                </Box>
                <MailDialog
                    open={mailDialogOpen}
                    onConfirm={this.sendMail}
                    onCancel={this.onCloseMailDialog}
                    fileName={`Cobranzas_Transitorias.pdf`}
                    attachFile={true}
                    // to={cliente.email}
                    subject={`Cobranzas Transitorias`}
                    body={`Estimado,  \n\nSe adjunta el listado de cobranzas transitorias.`}
                />
                <PDFDialog
                    open={pdfDialogOpen}
                    onClose={this.onClosePdfDialog}
                    pdfUrl={pdfUrl}
                    pdfName={`Cobranzas_Transitorias.pdf`}
                />
            </React.Fragment>
        )
    }
}


CobranzasTransitorias.contextType = MainContext;
CobranzasTransitorias = withSnackbar(CobranzasTransitorias);
