import {ReactGrid} from "../../helpers/reactGrid/reactGrid";
import React, {useEffect, useState} from "react";
import {sortNumeric} from "../../../assets/utils";
import {useSnackbar} from "notistack";
import {useCobranzasService, useListadosService} from "../../../services/hooks";
import {IconButton, Tooltip} from "@material-ui/core";
import InfoIcon from "@material-ui/icons/InfoRounded";
import {SimpleDialog} from "../../helpers/dialogs/simple.dialog";
import {CobranzasView} from "../../ventas/cobranzas/view/cobranzas.view";
import PDFIcon from "@material-ui/icons/PictureAsPdf";

export const Revisar = () => {
    const [rendiciones, setRendiciones] = useState([2]);
    const [infoDialog, setInfoDialog] = useState({open: false});

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const CobranzasService = useCobranzasService();
    const ListadosService = useListadosService();
    const rendicionesRef = React.useRef(rendiciones);

    useEffect(() => {
        getRendiciones();
    }, []);

    const getRendiciones = () => {
        CobranzasService.getRendiciones(
            {},
            response => {
                setRendiciones(response.data.result);
                rendicionesRef.current = response.data.result;
            },
            error => enqueueSnackbar(error, {variant: 'error'})
        );
    }

    const getPDF = (rendicionesIds, fnSuccess = () => {
    }) => {
        ListadosService.rendicionCobranzas(
            rendicionesIds,
            fnSuccess,
            error => enqueueSnackbar(error, {variant: 'error'})
        );
    };

    // Download PDF
    const onDownloadPdf = (rendicionesIds) => {
        getPDF(
            rendicionesIds,
            (PDFBlob) => {
                const fileURL = URL.createObjectURL(PDFBlob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = fileURL;
                a.download = `Rendición Cobranzas`;
                a.click();
                window.URL.revokeObjectURL(fileURL);
                enqueueSnackbar('El archivo se descargó correctamente. Revise su carpeta de descargas.', {variant: 'success'});
            }
        );
    };

    const InfoBtn = ({row}) =>
        <Tooltip title={'Detalles de la cobranza'}>
            <IconButton onClick={() => setInfoDialog({open: true, ...row})}>
                <InfoIcon fontSize={"small"}/>
            </IconButton>
        </Tooltip>

    const PDFBtn = ({row}) => {
        const firstRelatedRecibo = rendicionesRef.current.find(rendicion => rendicion.timestamp === row.timestamp);

        if (row.idrecibo !== firstRelatedRecibo.idrecibo)
            return ("");

        const rendicionesIds = rendicionesRef.current
            .filter(rendicion => rendicion.timestamp === row.timestamp)
            .map(rendicion => rendicion.idrecibo);

        return (
            <Tooltip title={'Detalles de la rendición'}>
                <IconButton onClick={() => onDownloadPdf(rendicionesIds)}>
                    <PDFIcon/>
                </IconButton>
            </Tooltip>
        )
    }

    const RENDICIONES_TABLE = {
        columns: [
            {name: 'custom1', title: ' '},
            {name: 'idrecibo', title: 'Id'},
            {name: 'usuario', title: 'Usuario'},
            {name: 'fecha', title: 'Fecha de Recepción'},
            {name: 'custom2', title: ' '},
        ],
        columnExtensions: [
            {columnName: 'custom1', component: InfoBtn, width: 70},
            {columnName: 'id', compare: sortNumeric, direction: 'desc'},
            {columnName: 'custom2', component: PDFBtn, width: 70},
            // {columnName: 'usuario', width: 90},
            // {columnName: 'fecha', compare: sortNumeric},
        ],
        groupingExtensions: [
            {columnName: 'fecha'},
            // {columnName: 'direccion_entrega'},
        ],
        totalSummaryItemsExtensions: [
            // {columnName: 'id', type: 'count'},
            // {columnName: 'litros', type: 'sum'},
        ],
        groupSummaryItemsExtensions: [
            // {columnName: 'idrecibo', type: 'count', showInGroupFooter: false, alignByColumn: false},
            // {columnName: 'litros', type: 'sum', showInGroupFooter: false, alignByColumn: true}
        ],
        // summaryMessages: {count: 'Cantidad', sum: 'Litros'},
        // groupMessages: {count: 'Pedidos', sum: 'Litros'}
    };

    return (
        <>
            <ReactGrid
                enableVirtualMode={false}
                columns={RENDICIONES_TABLE.columns}
                columnExtensions={RENDICIONES_TABLE.columnExtensions}
                groupingExtensions={RENDICIONES_TABLE.groupingExtensions}
                totalSummaryItemsExtensions={RENDICIONES_TABLE.totalSummaryItemsExtensions}
                groupSummaryItemsExtensions={RENDICIONES_TABLE.groupSummaryItemsExtensions}
                // summaryMessages={RENDICIONES_TABLE.summaryMessages}
                // groupMessages={RENDICIONES_TABLE.groupMessages}
                getRowId={row => row.id}
                rows={rendiciones}
                title={`Rendiciones manuales recibidas`}
                showResultCount={true}
                enableFiltering={false}
                enableSorting={true}
                enableSearch={true}
                enableSelection={false}
                enableGrouping={true}
                enableSummarizing={true}
                // selectedRows={RENDICIONES_TABLE.selected}
                showSelectAll={true}
            />
            <SimpleDialog
                open={infoDialog.open}
                onCancel={() => setInfoDialog({open: false})}
                maxWidth={'lg'}
                fullWidth={true}
                title={'Cobranza'}
                body={<CobranzasView match={{params: {cobranza: infoDialog.idrecibo, cliente: infoDialog.idcliente}}}/>}
                cancelText={"Cerrar"}
            />
        </>
    )
}
