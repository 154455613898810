import React, {useContext, useEffect, useState} from 'react';
import SubHeader from "components/helpers/subHeader/subHeader";
import Box from "@material-ui/core/Box";
import {Fab, MenuItem, TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {Grid} from "@material-ui/core/index";
import {SimpleDialog} from "components/helpers/dialogs/simple.dialog";
import {AddRounded} from "@material-ui/icons";
import {useMastersService, useNotificationsService} from "services/hooks";
import {useSnackbar} from "notistack";
import {NotificationsList} from "components/herramientas/notificaciones/notificationsList";
import {MainContext} from "contexts/main.context";
import Tooltip from "@material-ui/core/Tooltip";
import {defaultContacts} from "assets/utils";

const tipos = [{
    id: 'sent',
    description: 'Enviadas'
}, {
    id: 'received',
    description: 'Recibidas'
}];

export const Notificaciones = () => {
    const [newDialogOpen, setNewDialogOpen] = useState(false);
    const [contacts, setContacts] = useState(defaultContacts);
    const [notification, setNotification] = useState('');
    const [selectedContacts, setSelectedContacts] = useState([]);
    const [notifications, setNotifications] = useState({from: [], to: []});
    const [viewType, setViewType] = useState('received');

    const context = useContext(MainContext);
    const MastersService = useMastersService();
    const NotificationsService = useNotificationsService();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    useEffect(function () {
        if (context.loggedUser.role === 'A' || context.loggedUser.role === 'O') {
            MastersService.getUsers(
                response => {
                    let results = response.data.result;
                    results.forEach(result => result.type = 'Usuarios');
                    setContacts(prevContacts => prevContacts.concat(results));
                },
                error => enqueueSnackbar("Error al obtener los contactos", {variant: 'error'})
            );
        }

        getNotifications('received');
        getNotifications('sent');
    }, []);

    const getNotifications = (fromTo) => {
        NotificationsService.get(
            fromTo,
            success => setNotifications(prevState => ({...prevState, [fromTo]: success.data.result})),
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }

    const sendNotificacion = () => {
        NotificationsService.push(
            {
                to: selectedContacts.map(contact => contact.id),
                msg: notification
            },
            success => {
                enqueueSnackbar("La notificación se envió correctamente", {variant: 'success'});
                getNotifications('received');
                getNotifications('sent');
                setNewDialogOpen(false);
            },
            error => enqueueSnackbar(error, {variant: 'error'})
        );
    }

    return (
        <>
            <SubHeader
                title={'Notificaciones'}
            />
            <Box className='container-xl mt-3 mb-3'>
                <Box className='wrapper'>
                    <Grid container direction={"column"} spacing={2}>
                        <Grid item>
                            <TextField
                                select
                                label="Tipo"
                                value={viewType}
                                onChange={event => setViewType(event.target.value)}
                            >
                                {tipos.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.description}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <NotificationsList
                                data={notifications[viewType]}
                                sentReceived={viewType}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            {(context.loggedUser.role === 'A' || context.loggedUser.role === 'O') && (
                <Tooltip title={'Enviar notificación'}>
                    <Box className={'niquel-fab-btn'}>
                        <Fab onClick={() => setNewDialogOpen(true)} color={"primary"}>
                            <AddRounded/>
                        </Fab>
                    </Box>
                </Tooltip>
            )}
            <SimpleDialog
                open={newDialogOpen}
                maxWidth={'sm'}
                fullWidth
                title={'Nueva Notificación'}
                onCancel={() => setNewDialogOpen(false)}
                onConfirm={sendNotificacion}
                confirmText={"Enviar"}
                body={
                    <Grid container direction={"column"} spacing={2}>
                        <Grid item>
                            <Autocomplete
                                multiple
                                fullWidth
                                id="grouped-demo"
                                options={contacts}
                                value={selectedContacts}
                                onChange={(event, newValue) => setSelectedContacts(newValue)}
                                groupBy={(option) => option.type}
                                getOptionLabel={(option) => option.description}
                                renderInput={(params) => <TextField {...params} label="Destinatarios" variant="standard"/>}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                label="Texto de la notificación"
                                multiline
                                fullWidth
                                rows={10}
                                value={notification}
                                onChange={event => setNotification(event.target.value)}
                                variant="filled"
                            />
                        </Grid>
                    </Grid>
                }
            />
        </>
    )
}
