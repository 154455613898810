import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@material-ui/core";
import PropTypes from "prop-types";

export class SimpleDialog extends Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onCancel}
                fullScreen={this.props.fullScreen}
                keepMounted={this.props.keepMounted}
                maxWidth={this.props.maxWidth}
                fullWidth={this.props.fullWidth}
                classes={{
                    paperFullScreen: 'niquel-dialog-full-screen'
                }}
            >
                <SimpleDialogContent {...this.props}/>
            </Dialog>
        )
    }
}

class SimpleDialogContent extends Component {
    render() {
        const {title, body, onConfirm, onCancel, onDelete, confirmText = "Confirmar", cancelText = "Cancelar", deleteText = "Eliminar"} = this.props;

        return (
            <React.Fragment>
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" component={'div'}>
                        <Typography variant={"body2"} component={"div"}>
                            {body}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/*Muestro Cancel*/}
                    {onCancel && (
                        <Button onClick={onCancel}>
                            {cancelText}
                        </Button>
                    )}

                    {/*Muestro Confirm*/}
                    {onConfirm && (
                        <Button onClick={onConfirm} color={"primary"} autoFocus>
                            {confirmText}
                        </Button>
                    )}

                    {/*Muestro Delete*/}
                    {onDelete && (
                        <Button onClick={onDelete} className={'color-error hover-error'} autoFocus>
                            {deleteText}
                        </Button>
                    )}
                </DialogActions>
            </React.Fragment>
        )
    }
}

// SimpleDialog.defaultProps = {
//     open: PropTypes.bool,
//     fullscreen: PropTypes.bool,
//     keepMounted: PropTypes.bool,
//     maxWidth: PropTypes.number,
//     fullWidth: PropTypes.bool,
//     body: PropTypes.element,
//     onConfirm: PropTypes.func,
//     onCancel: PropTypes.func,
//     onDelete: PropTypes.func,
//     confirmText: PropTypes.string,
//     cancelText: PropTypes.string,
//     deleteText: PropTypes.string
// }
