import React from "react";
import {Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemSecondaryAction, ListItemText, Typography} from "@material-ui/core/index";
import {formatPrice} from "assets/utils";
import DialogContentText from "@material-ui/core/DialogContentText/index";

export class CotizacionReferenciaDialog extends React.Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={(event, reason) => this.props.onCancel(event, reason)}
                maxWidth={'sm'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <CotizacionReferenciaDialogContent {...this.props}/>
            </Dialog>
        );
    }
}

class CotizacionReferenciaDialogContent extends React.Component {
    state = {
        selected: {}
    };

    onSelect = cotizacion => {
        if (!this.props.isPedido || (!cotizacion.vencida && !cotizacion.isprepago))
            this.setState({selected: cotizacion})
    };

    render() {
        const {onConfirm, onCancel, cotizaciones, cliente, isPedido = false} = this.props;
        const {selected} = this.state;

        return (
            <React.Fragment>
                <DialogTitle id="alert-dialog-title">Cotización de Referencia</DialogTitle>

                <DialogContent>
                    {cotizaciones.length > 0 && (
                        <DialogContentText>Se muestran las cotizaciones ingresadas para {cliente.razsoc} ({cliente.id})</DialogContentText>
                    )}
                    {cotizaciones.length > 0 && (
                        <List dense>
                            {cotizaciones.map((cotizacion, index) => {
                                return (
                                    <ListItem key={index} button onClick={() => this.onSelect(cotizacion)}>
                                        <ListItemText
                                            primary={
                                                <React.Fragment>
                                                    <Typography variant={"body2"} component={'span'}>
                                                        Cotización {cotizacion.id} ({formatPrice(cotizacion.imptotal, true)})
                                                    </Typography>
                                                    {cotizacion.isprepago && (
                                                        <Typography variant={"body2"} component={'span'} color={"textPrimary"}><b> - PREPAGO</b></Typography>
                                                    )}
                                                    {!cotizacion.isprepago && cotizacion.estado && (
                                                        <Typography variant={"body2"} component={'span'} color={"primary"}><b> - UTILIZADA</b></Typography>
                                                    )}
                                                    {!cotizacion.isprepago && cotizacion.vencida && (
                                                        <Typography variant={"body2"} component={'span'} color={"error"}><b> - VENCIDA</b></Typography>
                                                    )}
                                                </React.Fragment>
                                            }
                                            secondary={`Ingreso: ${cotizacion.ingreso} - Entrega: ${cotizacion.entrega}`}
                                        />
                                        {(!isPedido || (!cotizacion.vencida && !cotizacion.isprepago)) &&
                                            <ListItemSecondaryAction>
                                                <Checkbox
                                                    edge="end"
                                                    onClick={() => this.onSelect(cotizacion)}
                                                    checked={cotizacion.id === selected.id}
                                                />
                                            </ListItemSecondaryAction>
                                        }
                                    </ListItem>
                                );
                            })}
                        </List>
                    )}
                    {cotizaciones.length < 1 && (
                        <Typography variant={"body2"} align={"center"} color={"textSecondary"} className='mb-2'>
                            No se encontraron cotizaciones para el cliente seleccionado
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="primary">
                        Cancelar
                    </Button>
                    <Button disabled={Object.keys(selected).length === 0} onClick={() => onConfirm(selected)} color="primary" autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </React.Fragment>
        )
    }
}
