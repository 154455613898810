import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core/index";
import PropTypes from "prop-types";
import {Tooltip} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import AttachIcon from "@material-ui/icons/AttachFileRounded";
import Chip from "@material-ui/core/Chip";
import {regExpEmail} from "assets/utils";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import {MainContext} from "contexts/main.context";
import {withSnackbar} from "notistack";
import RemoveIcon from "@material-ui/icons/DeleteRounded";

export class MailDialog extends Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onCancel}
                fullScreen={this.props.fullScreen}
                classes={{
                    paperFullScreen: 'niquel-dialog-full-screen'
                }}
            >
                <MailDialogContent {...this.props}/>
            </Dialog>
        )
    }
}

class MailDialogContent extends Component {
    state = {
        to: '',
        cc: '',
        cco: '',
        answer: '',
        subject: '',
        body: '',
        fileName: '',
        attachFile: false,
        ccEnabled: false,
        ccoEnabled: false
    };

    componentDidMount() {
        this.setState({...this.props});
    }

    onOpenCC = () => this.setState({ccEnabled: true});
    onOpenCCO = () => this.setState({ccoEnabled: true});

    confirm = () => {
        // Valido que los datos ingresados sean correctos
        let bValid = true;

        bValid = bValid && this.isValidEmail(this.state.to);
        bValid = bValid && this.state.subject !== '';
        bValid = bValid && this.state.body !== '';

        if (this.state.ccEnabled)
            bValid = bValid && this.isValidEmail(this.state.cc);
        if (this.state.ccoEnabled)
            bValid = bValid && this.isValidEmail(this.state.cco);

        if (!bValid)
            this.props.enqueueSnackbar('Algunos campos están incompletos o no son direcciones de email válidas.');
        else {
            let data = {...this.state};
            data.body += '\n\n' + this.props.hidden_body;

            this.props.onConfirm(data);
        }
    };

    isValidEmail = email => {
        let bReturn = true;
        let emails = email ? email.split(';') : [];
        emails.forEach(_email => bReturn = bReturn && regExpEmail.test(_email));
        return bReturn;
    };

    render() {
        const {onCancel} = this.props;
        const {to, cc, cco, subject, body, fileName, attachFile, ccEnabled, ccoEnabled} = this.state;

        return (
            <React.Fragment>
                <DialogTitle id="alert-dialog-title">Enviar Mail</DialogTitle>
                <DialogContent>
                    <Grid container justifyContent={"space-between"} alignItems={"center"}>
                        <Grid item>
                            {attachFile && (
                                <Chip
                                    onDelete={() => this.setState({attachFile: false})}
                                    icon={<AttachIcon fontSize={"small"}/>}
                                    label={fileName}
                                />
                            )}
                        </Grid>
                        <Grid item>
                            {!ccEnabled && (
                                <Tooltip title={'agregar copia'}>
                                    <Button
                                        hidden={ccEnabled}
                                        size={"small"}
                                        color="primary"
                                        onClick={this.onOpenCC}
                                    >
                                        CC
                                    </Button>
                                </Tooltip>
                            )}
                            {!ccoEnabled && (
                                <Tooltip title={'agregar copia oculta'}>
                                    <Button
                                        hidden={ccoEnabled}
                                        size={"small"}
                                        color="primary"
                                        onClick={this.onOpenCCO}
                                    >
                                        CCO
                                    </Button>
                                </Tooltip>
                            )}
                        </Grid>
                    </Grid>
                    {/*<TextField*/}
                    {/*    autoFocus*/}
                    {/*    margin="dense"*/}
                    {/*    label={'De'}*/}
                    {/*    helperText={'Quién envía el mail (el mail se responderá a esta dirección)'}*/}
                    {/*    value={answer}*/}
                    {/*    onChange={event => this.setState({answer: event.target.value})}*/}
                    {/*    fullWidth*/}
                    {/*/>*/}
                    <TextField
                        autoFocus
                        margin="dense"
                        label={'Para'}
                        helperText={'Destinatario/s del mail (destinatario1@gmail.com;destinatario2@hotmail.com)'}
                        error={!this.isValidEmail(to)}
                        value={to}
                        onChange={event => this.setState({to: event.target.value})}
                        fullWidth
                    />
                    {ccEnabled && (
                        <TextField
                            autoFocus
                            margin="dense"
                            label={'Con copia'}
                            helperText={'Destinatario/s secundario/s (secundario1@gmail.com;secundario2@hotmail.com)'}
                            error={!this.isValidEmail(cc)}
                            value={cc}
                            onChange={event => this.setState({cc: event.target.value})}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => this.setState({ccEnabled: false})}
                                        >
                                            <RemoveIcon/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                    {ccoEnabled && (
                        <TextField
                            autoFocus
                            margin="dense"
                            label={'Con copia oculta'}
                            helperText={'Destinatarios ocultos (oculto1@gmail.com;oculto2@hotmail.com)'}
                            error={!this.isValidEmail(cco)}
                            value={cco}
                            onChange={event => this.setState({cco: event.target.value})}
                            fullWidth
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={() => this.setState({ccoEnabled: false})}
                                        >
                                            <RemoveIcon/>
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    )}
                    <TextField
                        autoFocus
                        margin="dense"
                        label={'Asunto'}
                        error={subject === ''}
                        value={subject}
                        onChange={event => this.setState({subject: event.target.value})}
                        fullWidth
                    />
                    <TextField
                        autoFocus
                        margin="dense"
                        label={'Mensaje'}
                        value={body}
                        error={body === ''}
                        onChange={event => this.setState({body: event.target.value})}
                        fullWidth
                        multiline
                        rows={4}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={this.confirm} color="primary" autoFocus>
                        Enviar
                    </Button>
                </DialogActions>
            </React.Fragment>
        )
    };
}

MailDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    to: PropTypes.string,
    cc: PropTypes.string,
    cco: PropTypes.string,
    answer: PropTypes.string,
    subject: PropTypes.string,
    body: PropTypes.string,
    hidden_body: PropTypes.string,
    fileName: PropTypes.string,
    attachFile: PropTypes.bool,
};

MailDialog.defaultProps = {
    hidden_body: ''
}

MailDialog.contextType = MainContext;
MailDialog = withSnackbar(MailDialog);
