import React, {useEffect, useReducer, useState} from 'react'
import {Box, Grid} from "@material-ui/core/index";
import 'moment/locale/es';
import SubHeader from "components/helpers/subHeader/subHeader";
import {ExportOptions} from "../../helpers/exportOptions/export.options";
import {MainContext} from "../../../contexts/main.context";
import {useEstadisticasService, useListadosService} from "../../../services/hooks";
import VentasSettings from "../../estadisticas/settings/ventas.settings";
import {generalReducer} from "../../helpers/reducers/reducers";
import {INITIAL_FILTER_SETTINGS_STATE} from "../../helpers/initialFilterSettingsState";
import {Fab, MenuItem, TextField, Tooltip} from "@material-ui/core";
import {SettingsRounded} from "@material-ui/icons";
import {CardChart} from "../../estadisticas/dashboards/ventas/card.chart";
import {useSnackbar} from "notistack";
import {ClientesComprasTable} from "./clientesCompras.table";
import {ReactGrid} from "../../helpers/reactGrid/reactGrid";

export const ClientesCompras = () => {
    const FILTROS = [{id: 'CON_COMPRA', description: 'con compras'}, {id: 'SIN_COMPRA', description: 'sin compras'}];
    const TABLE = {
        columns: [
            {name: 'id', title: 'ID'},
            {name: 'razsoc', title: 'Razón Social'},
        ],
        columnExtensions: [
            {columnName: 'id', width: 150}
        ]
    };

    const [_settingsState, dispatch] = useReducer(generalReducer, INITIAL_FILTER_SETTINGS_STATE);
    const [settingsState, setSettingsState] = useState(INITIAL_FILTER_SETTINGS_STATE);
    const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);

    const [filtro, setFiltro] = useState(FILTROS[0].id);
    const [clientes, setClientes] = useState([]);

    const EstadisticasService = useEstadisticasService();
    const ListadosService = useListadosService(MainContext);

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    useEffect(() => {
        EstadisticasService.getCompradoresPorPeriodo(
            settingsState,
            filtro,
            success => setClientes(success.data.result),
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }, [settingsState, filtro]);

    const getPDF = fnSuccess => {
        ListadosService.compradoresPorPeriodo(
            settingsState,
            filtro,
            file => fnSuccess(file),
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    };

    const onConfirmSettingsDialog = () => {
        setSettingsState(_settingsState);
        setSettingsDialogOpen(false);
    }

    const onCancelSettingsDialog = () => {
        dispatch({payload: settingsState, type: 'fullstatechange'});
        setSettingsDialogOpen(false);
    }

    const FiltroSelector = () => {
        return (
            <TextField
                select
                label="Filtro"
                value={filtro}
                onChange={event => setFiltro(event.target.value)}
                style={{marginLeft: '1rem'}}
            >
                {FILTROS.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.description}
                    </MenuItem>
                ))}
            </TextField>
        )
    }

    return (
        <React.Fragment>
            <Box className='niquel-listados-cobranzasTransitorias'>
                <SubHeader
                    title={'Clientes Compras'}
                />
                <Box className='container-xl mt-3 mb-3'>
                    <Box className='wrapper'>
                        <Grid container spacing={1}>
                            <Grid item sm={12}>
                                    <ReactGrid
                                        columns={TABLE.columns}
                                        columnExtensions={TABLE.columnExtensions}
                                        rows={clientes}
                                        title={'Clientes'}
                                        subtitle={`${FILTROS.find(_filtro => _filtro.id === filtro).description} en el período del ${settingsState.dates.from.format("DD/MM/YYYY")} al ${settingsState.dates.to.format("DD/MM/YYYY")}`}
                                        showResultCount
                                        height={-200}
                                        enableFiltering={false}
                                        enableSorting={true}
                                        enableSearch={true}
                                        enableSelection={false}
                                        customBtn1={<FiltroSelector/>}
                                    />
                            </Grid>
                        </Grid>
                    </Box>
                    <Tooltip title={'Configurar filtros'}>
                        <Box className={'niquel-fab-top-btn'}>
                            <Fab onClick={() => setSettingsDialogOpen(true)} color={"default"}>
                                <SettingsRounded/>
                            </Fab>
                        </Box>
                    </Tooltip>
                </Box>
                <VentasSettings
                    open={settingsDialogOpen}
                    title={'Filtros y confirguración'}
                    onCancel={onCancelSettingsDialog}
                    onConfirm={onConfirmSettingsDialog}
                    fullScreen={true}
                    maxWidth={'lg'}
                    state={_settingsState}
                    dispatch={dispatch}
                    ignoreLocalidades
                    ignoreClasificaciones
                />
                <ExportOptions
                    enableDownload
                    enableMail
                    enablePrint
                    enableShow
                    getPDF={getPDF}
                    fileName={`Listado_clientes_compras.pdf`}
                    // mailTo={cliente.email}
                    // mailCc={vendedor.email}
                    mailCCEnabled={true}
                    mailSubject={`Listado_clientes_compras`}
                    mailBody={`Estimado cliente,  \n\nSe adjunta el listado de clientes con compras.`}
                />
            </Box>
        </React.Fragment>
    )
}
