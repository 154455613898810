import React, {Component} from 'react';
import {BottomNavigation, MenuItem, TextField, Typography} from "@material-ui/core";
import {MainContext} from "contexts/main.context";
import SubHeader from "components/helpers/subHeader/subHeader";
import SwipeableViews from 'react-swipeable-views';
import NuevasIcon from '@material-ui/icons/AssignmentRounded';
import CompletadasIcon from '@material-ui/icons/HistoryRounded';
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Badge from "@material-ui/core/Badge";
import {CardEntrega} from "components/despacho/entregas/main/card.entrega";
import Box from "@material-ui/core/Box";
import {isMobile} from "react-device-detect";
import HojasDeRutaService from "services/hojasDeRuta.service";
import {withSnackbar} from "notistack";
import {Button} from "@material-ui/core/index";
import MastersService from "services/masters.service";

export class EntregasMain extends Component {
    HojasDeRutaService = new HojasDeRutaService(this.context);
    MastersService = new MastersService(this.context);

    state = {
        tabActive: 0,
        abiertas: [],
        completadas: [],
        skip: 0,
        choferes: [],
        choferSelected: 'ALL'
    };

    componentDidMount() {
        this.getEntregas();
    }

    getEntregas = () => {
        // Reemplazar por hojas de ruta no completadas.
        this.HojasDeRutaService.getEntregas(
            this.state.skip,
            response => {
                const abiertas = response.data.result.filter(hoja => (hoja.tipo !== 'ENV' || hoja.facturada) && !hoja.completada);
                const completadas = response.data.result.filter(hoja => hoja.completada);
                this.setState({
                    abiertas: this.state.abiertas.concat(abiertas),
                    completadas: this.state.completadas.concat(completadas)
                })
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    changeTab = (event, value) => {
        this.setState({tabActive: value});
    };

    navPedidos = id => {
        this.props.history.push(`${this.props.match.url}/${id}/pedidos`);
    }

    onShowMore = () => this.setState({skip: this.state.skip + 1}, this.getEntregas);

    render() {
        const {tabActive, abiertas, completadas, choferes, choferSelected} = this.state;
        const viewHeight = isMobile ? 'calc(100vh - 11.5rem)' : 'calc(100vh - 11rem)';
        return (
            <div>
                <SubHeader
                    title={'Entregas'}
                    subtitle={'Selección de entrega'}
                />
                {/*<Box className='container-xl mt-3 mb-3'>*/}
                {/*    {this.context.role !== 'H' && (*/}
                {/*        <TextField*/}
                {/*            select*/}
                {/*            label="Chofer"*/}
                {/*            value={choferSelected}*/}
                {/*            onChange={event => this.props.onChangeHeaderData('chofer', event.target.value)}*/}
                {/*        >*/}
                {/*            {choferes.map(option => (*/}
                {/*                <MenuItem key={option.id} value={option.id}>*/}
                {/*                    {option.description}*/}
                {/*                </MenuItem>*/}
                {/*            ))}*/}
                {/*        </TextField>*/}
                {/*    )}*/}
                {/*</Box>*/}
                <Box>
                    <SwipeableViews
                        index={tabActive}
                        onChangeIndex={index => this.changeTab(null, index)}
                        resistance
                        style={{height: '100%'}}
                        slideStyle={{height: '100%'}}
                    >
                        <Box style={{height: viewHeight}}>
                            {abiertas.map((hoja, index) =>
                                <CardEntrega
                                    key={index}
                                    id={hoja.id}
                                    fecha={hoja.fecha}
                                    vehiculo={hoja.vehiculo_desc}
                                    litros={hoja.litros}
                                    cant_pedidos={hoja.cant_pedidos}
                                    pedidos_completados={hoja.pedidos_completados}
                                    salida={hoja.salida}
                                    onClick={() => this.navPedidos(hoja.id)}
                                />
                            )}
                            {abiertas.length === 0 ? (
                                <Box className={'h-100 d-flex align-items-center justify-content-center'}>
                                    <Typography variant={"body1"} color={"textSecondary"}>
                                        No hay entregas abiertas
                                    </Typography>
                                </Box>
                            ) : (
                                <Button
                                    fullWidth
                                    style={{height: '3.5rem'}}
                                    onClick={this.onShowMore}
                                >
                                    Ver más
                                </Button>
                            )}
                        </Box>
                        <Box style={{height: viewHeight}}>
                            {completadas.map((hoja, index) =>
                                <CardEntrega
                                    key={index}
                                    id={hoja.id}
                                    fecha={hoja.fecha}
                                    vehiculo={hoja.vehiculo_desc}
                                    litros={hoja.litros}
                                    cant_pedidos={hoja.cant_pedidos}
                                    pedidos_completados={hoja.pedidos_completados}
                                    salida={hoja.salida}
                                    onClick={() => this.navPedidos(hoja.id)}
                                />
                            )}
                            {completadas.length === 0 ? (
                                <Box className={'h-100 d-flex align-items-center justify-content-center'}>
                                    <Typography variant={"body1"} color={"textSecondary"}>
                                        No hay entregas completadas
                                    </Typography>
                                </Box>
                            ) : (
                                <Button
                                    fullWidth
                                    style={{height: '3.5rem'}}
                                    onClick={this.onShowMore}
                                >
                                    Ver más
                                </Button>
                            )}
                        </Box>
                    </SwipeableViews>
                    <BottomNavigation
                        value={tabActive}
                        onChange={this.changeTab}
                        showLabels
                        style={{height: '4.5rem'}}
                    >
                        <BottomNavigationAction label="Abiertas" icon={
                            <Badge badgeContent={abiertas.length} color="primary">
                                <NuevasIcon/>
                            </Badge>
                        }/>
                        <BottomNavigationAction label="Completadas" icon={<CompletadasIcon/>}/>
                    </BottomNavigation>
                </Box>
            </div>
        )
    }
}

EntregasMain.contextType = MainContext;
EntregasMain = withSnackbar(EntregasMain);
