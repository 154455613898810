// Si empiezan con R son Retenciones
// Si empiezan con CH son Cheques
export const TIPOS = [
    {id: 'EFE', description: 'Efectivo'},
    {id: 'CHF', description: 'Cheques físicos', clase: 'CHEQUE'},
    {id: 'CHE', description: 'Cheques electrónicos', clase: 'CHEQUE'},
    {id: 'CAN', description: 'Compensaciones'},
    {id: 'TRF', description: 'Transferencia Bancaria', clase: 'TRANSFERENCIA'},
    {id: 'TRP', description: 'Transferencia Provisoria', clase: 'TRANSFERENCIA'},
    {id: 'AEF', description: 'Acreditación Efectivo'},
    {id: 'RSS', description: 'Retención Seguridad Social', clase: 'RETENCION'},
    {id: 'RIV', description: 'Retención IVA', clase: 'RETENCION'},
    {id: 'RGA', description: 'Retención Ganancias', clase: 'RETENCION'},
    {id: 'RIB', description: 'Retención Ing. Brutos', clase: 'RETENCION'},
    {id: 'SCC', description: 'Saldo en Cuenta Corriente', disabled: true},
    {id: 'CHX', description: 'Cheques', disabled: true, clase: 'CHEQUE'},

    {id: 'RCO', description: 'Recibo de Cobranza', disabled: true}, // Item especial para recibos de cobranza amarillo
];
