import React, {useContext, useEffect, useState} from 'react';
import SubHeader from "components/helpers/subHeader/subHeader";
import Box from "@material-ui/core/Box";
import {Divider, MenuItem, TextField, Typography} from "@material-ui/core";
import {useObjetivosService, useVendedoresService} from "services/hooks";
import {useSnackbar} from "notistack";
import {Grid} from "@material-ui/core/index";
import {Objetivo} from "components/objetivos/general/revision/objetivo";
import MomentUtils from "@date-io/moment";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {checkForErrors} from "components/objetivos/utils";
import Button from "@material-ui/core/Button";
import {NavLink} from "react-router-dom";
import {MainContext} from "contexts/main.context";

const moment = require('moment');

export function Revision() {
    const context = useContext(MainContext);
    const VendedoresService = useVendedoresService();
    const ObjetivosService = useObjetivosService();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [vendedores, setVendedores] = useState([]);
    const [vendedorSelected, setVendedorSelected] = useState({});
    const [objetivos, setObjetivos] = useState([]);
    const [periodo, setPeriodo] = useState(new Date());
    const [tiposObjetivo, setTiposObjetivo] = useState([]);
    const [tiposSubObjetivo, setTiposSubObjetivo] = useState([]);

    // const [cumplimiento, setCumplimiento] = useState(_CUMPLIMIENTO);
    const [cumplimiento, setCumplimiento] = useState([]);

    const [errorState, setErrorState] = useState(false);

    useEffect(() => loadVendedores(), []);
    useEffect(() => loadTiposObjetivo(), []);
    useEffect(() => loadTiposSubObjetivo(), []);
    useEffect(() => loadObjetivos(), [vendedorSelected]);
    useEffect(() => loadCumplimiento(), [vendedorSelected, periodo]);
    useEffect(() => {
        const thereIsAnError = checkForErrors(objetivos);
        setErrorState(thereIsAnError);
    }, [objetivos]);

    const loadVendedores = () => {
        VendedoresService.getAll(
            response => {
                setVendedores(response.data.result);
                setVendedorSelected(response.data.result[0]);
            },
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }

    const loadTiposObjetivo = () => {
        ObjetivosService.getTiposObjetivo(
            response => setTiposObjetivo(response.data.result),
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }

    const loadTiposSubObjetivo = () => {
        ObjetivosService.getTiposSubObjetivo(
            response => setTiposSubObjetivo(response.data.result),
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }

    const loadObjetivos = () => {
        vendedorSelected.id &&
        ObjetivosService.getAll(
            vendedorSelected.id,
            response => setObjetivos(response.data.result),
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }

    const loadCumplimiento = () => {
        vendedorSelected.id &&
        ObjetivosService.getCumplimiento(
            vendedorSelected.id,
            moment(periodo).format("M"),
            response => setCumplimiento(response.data.result),
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }

    return (
        <>
            <Box className='niquel-objetivos'>
                <SubHeader
                    title={'Objetivos'}
                    subtitle={`Revisión`}
                />
                <Box className='container-xl mt-3 mb-3'>
                    <Grid container spacing={2} direction={"column"}>
                        <Grid container item justifyContent={"space-between"}>
                            {["A", "S"].includes(context.loggedUser.role) && (
                                <TextField
                                    select
                                    label="del vendedor"
                                    className='mr-2 select'
                                    value={vendedorSelected}
                                    onChange={event => setVendedorSelected(event.target.value)}
                                >
                                    {vendedores.map((vendedor, i) => (
                                        <MenuItem key={i} value={vendedor}>
                                            {vendedor.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                            <Box>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DatePicker
                                        // disableToolbar
                                        autoOk
                                        className='mr-3'
                                        label="Mes de revisión"
                                        // format="DD/MM/Y"
                                        value={periodo}
                                        views={["year", "month"]}
                                        onChange={moment => setPeriodo(moment)}
                                        InputProps={{
                                            style: {width: '8rem'},
                                        }}
                                    />
                                </MuiPickersUtilsProvider>
                            </Box>
                        </Grid>
                        {errorState === false ? (
                            <Grid item>
                                {cumplimiento.map(objetivo =>
                                    <Objetivo
                                        {...objetivo}
                                        key={objetivo.idobjetivo}
                                        tiposSubObjetivo={tiposSubObjetivo}
                                    />
                                )}
                            </Grid>
                        ) : (
                            <Typography
                                variant={"body2"}
                                component={"span"}
                                className={'mt-5'}
                                align={"center"}
                                color={"textSecondary"}
                            >
                                {"Los objetivos de este vendedor no están correctamente configurados"}
                                {context.loggedUser.role === "A" && (
                                    <Button
                                        variant={"outlined"}
                                        size={"small"}
                                        color={"primary"}
                                        component={NavLink}
                                        to={'/objetivos/general/configuracion'}
                                        className={'ml-2'}
                                    >
                                        Configurar
                                    </Button>
                                )}
                            </Typography>
                        )}
                        <Divider className={'mt-4 mb-4'}/>
                        {/*<Grid item>*/}
                        {/*    <Typography>Composición de Objetivos</Typography>*/}
                        {/*    <Box style={{height: `350px`, width: '100%'}}>*/}

                        {/*    </Box>*/}
                        {/*</Grid>*/}
                    </Grid>
                </Box>
            </Box>
        </>
    )
}
