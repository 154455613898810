import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, Typography} from "@material-ui/core";
import 'components/listados/clientesInhabilitados/dialogs/clientesInhabilitados.dialog.scss';
import DialogContentText from "@material-ui/core/DialogContentText";
import {withSnackbar} from "notistack";
import {ListadosService} from "services";
import {MainContext} from "contexts/main.context";

export class ClientesInhabilitadosDialog extends React.Component {
    ListadosService = new ListadosService(this.context);

    onPrint = () => {
        this.ListadosService.clientesInhabilitados(
            this.props.vendedor.id,
            file => {
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    };

    render() {
        const {open, onCancel, vendedor, clientesInhabilitados} = this.props;

        return (
            <Dialog
                open={open}
                onClose={onCancel}
                fullWidth
                maxWidth={'sm'}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Clientes Inhabilitados</DialogTitle>
                <DialogContent>
                    {clientesInhabilitados.length > 0 && (
                        <React.Fragment>
                            <DialogContentText>{clientesInhabilitados.length} clientes inhabilitados para el vendedor {vendedor.name}</DialogContentText>
                            <List dense className={'clientesInhabilitados-list'}>
                                {clientesInhabilitados.map((cliente, index) => {
                                    return (
                                        <ListItem key={index}>
                                            <ListItemText
                                                primary={`${cliente.id.padStart(5, 0)} - ${cliente.razsoc}`}
                                                secondary={`CUIT: ${cliente.cuit} - Teléfono: ${cliente.telefono}, ${cliente.localidad}`}
                                            />
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </React.Fragment>
                    )}
                    {clientesInhabilitados.length < 1 && (
                        <Typography variant={"body2"} align={"center"} color={"textSecondary"} className='mb-2'>
                            No se encontraron clientes inhabilitados para el vendedor seleccionado
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button disabled={clientesInhabilitados.length < 1} onClick={this.onPrint} color="primary" autoFocus>
                        Imprimir
                    </Button>
                    <Button onClick={onCancel} color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

ClientesInhabilitadosDialog.contextType = MainContext;
ClientesInhabilitadosDialog = withSnackbar(ClientesInhabilitadosDialog);