const drawerWidth = 220;

const colors = {
    background: '#262f3d',
    backgroundOpen: '#161d27',
    itemHover: 'rgba(255,255,255,.08)',
    fontPrimary: '#FFF',
    fontSelected: '#00b9a8',
    fontSecondary: 'rgba(255, 255, 255, 0.7)',
    iconHover: '#FFF',
    divider: 'rgba(255, 255, 255, 0.12)',
    scroll: 'rgb(190, 193, 197)'
};

const styles = theme => ({
    sideBar: {
        position: 'absolute',
        zIndex: '1201'
    },
    toolbar: {
        ...theme.mixins.toolbar,
        minHeight: '2.9rem !important',
        display: 'flex',
        alignItems: 'center',
        padding: '0 .75rem',
        color: '#FFF',
    },
    niquelLogo: {
        width: '32px'
    },
    niquelTitle: {
        fontFamily: 'roboto',
        fontSize: '1.2rem',
        fontWeight: '300',
        paddingLeft: '.5rem',
        opacity: 1,
        transition: theme.transitions.create('opacity', {
            easing: `cubic-bezier(0.4, 0.0, 1, 1)`,
            duration: theme.transitions.duration.enteringScreen,
        }),
        '&.hidden': {
            opacity: 0,
            transition: theme.transitions.create('opacity', {
                easing: `cubic-bezier(0.0, 0.0, 0.2, 1)`,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }
    },
    paperDark: {
        backgroundColor: '#262f3d'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: `cubic-bezier(0.4, 0.0, 0.2, 1)`,
            duration: theme.transitions.duration.enteringScreen,
        }),

    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: `cubic-bezier(0.4, 0.0, 0.2, 1)`,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(7),
        },
        '&::-webkit-scrollbar': {
            width: '0'
        }
    },
    btnAction: {
        cursor: 'pointer',
        '&:hover button': {
            color: colors.iconHover
        },
        display: 'flex',
        justifyContent: 'center',
        '&.open': {
            justifyContent: 'flex-end'
        },
    },
    ListContainer: {
        '&.open': {
            backgroundColor: colors.backgroundOpen,
            paddingBottom: '.9rem',
            borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
        }
    },
    List: {
        paddingTop: 0
    },
    LinkActive: {
        '& span, & svg': {
            color: colors.fontSelected + '!important'
        }
    },
    ListItem: {
        '&:hover': {
            backgroundColor: colors.itemHover,
        },
        '&.open': {
            '&:hover': {
                backgroundColor: colors.backgroundOpen
            }
        }
    },
    ListItemSubMenu: {
        paddingLeft: '1.9rem',
        height: '2.2rem'
    },
    ListItemTextSubMenu: {
        padding: '0 .3rem',
        '& span': {
            fontSize: '.8rem',
            fontWeight: '500',
            color: colors.fontSecondary
        },
    },
    ListItemText: {
        padding: '0 .3rem',
        '& span': {
            fontSize: '.9rem',
            fontWeight: '500',
            letterSpacing: '.25px',
            color: colors.fontPrimary
        },
        opacity: 1,
        transition: theme.transitions.create('opacity', {
            easing: `cubic-bezier(0.4, 0.0, 1, 1)`,
            duration: theme.transitions.duration.enteringScreen,
        }),
        '&.hidden': {
            opacity: 0,
            transition: theme.transitions.create('opacity', {
                easing: `cubic-bezier(0.0, 0.0, 0.2, 1)`,
                duration: theme.transitions.duration.enteringScreen,
            }),
        }
    },
    ListItemIcon: {
        minWidth: '1.9rem',
        color: colors.fontSecondary
    },
    ListItemIconSubMenu: {
        marginRight: 0
    },
    Divider: {
        backgroundColor: colors.divider
    },
    optionsWrapper: {
        height: 'calc(100vh - 48px - 48px)'
    }
});

export default styles;