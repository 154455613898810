import {isMobile} from "react-device-detect";

const drawerWidth = 220;

const styles = theme => ({
    appBar: {
        left: '0px',
        width: isMobile ? '100%' : `calc(100% - ${theme.spacing(7)}px)`,
        marginLeft: isMobile ? '0' : theme.spacing(7),
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: `cubic-bezier(0.4, 0.0, 0.2, 1)`,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: isMobile ? '0' : drawerWidth,
        width: isMobile ? '100%' : `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: `cubic-bezier(0.4, 0.0, 0.2, 1)`,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    hide: {
        display: 'none',
    },
    grow: {
        flexGrow: 1,
    },
    'account-btn': {
        boxShadow: 'none'
    },
    'server-indicator': {
        boxShadow: 'none',
    }
});

export default styles;
