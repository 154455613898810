import React, {useContext, useEffect} from 'react';
import {withRouter, useHistory} from 'react-router-dom';
import {MainContext} from "contexts/main.context";
import './home.scss';
import {Box, CardHeader, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Button from "@material-ui/core/Button";
import {LINE_DATA, MyResponsiveLine} from "components/helpers/charts/test";
const moment = require('moment');

export function Home() {
    const context = useContext(MainContext);
    const history = useHistory();

    useEffect(() => context.setHeaderTitle('Home'), []);

    const getSaludo = () => {
        const hora = parseInt(moment().format('H'));
        console.log(hora);

            if (hora >= 5 && hora < 13)
                return "Buenos días";
            else if (hora >= 13 && hora < 20)
                return "Buenas tardes";
            else if (hora >= 20 && hora < 5)
                return "Buenas noches";
            else
                return "Hola"

    }

    return (
        <div className='niquel-home container-fluid'>
            <Box className='container-xl mt-3 mb-3'>
                <Box>
                    <Typography variant={"h6"} align={"left"}>
                        {getSaludo()}, {context.loggedUser.name}
                    </Typography>
                    {/*<Typography variant={"body2"} align={"left"}>*/}
                    {/*    Un resúmen de tus números*/}
                    {/*</Typography>*/}
                </Box>
                {/*<Box className={'mt-3'}>*/}
                {/*    <Grid container spacing={3}>*/}
                {/*        <Grid item>*/}
                {/*            <Card className={'niquel-stats-card'}>*/}
                {/*                <Grid container direction={"row"}>*/}
                {/*                    <Grid item>*/}
                {/*                        <CardContent>*/}
                {/*                            <Typography color="textSecondary" variant={"body2"} gutterBottom>*/}
                {/*                                Cotizaciones*/}
                {/*                            </Typography>*/}
                {/*                            <Typography variant="h5" component="span">*/}
                {/*                                28*/}
                {/*                            </Typography>*/}
                {/*                            <Typography className={'ml-2'} variant={"caption"} color={"primary"}>*/}
                {/*                                +25%*/}
                {/*                            </Typography>*/}
                {/*                            <Typography variant="body2" component="p">*/}
                {/*                                en el último mes*/}
                {/*                            </Typography>*/}
                {/*                        </CardContent>*/}
                {/*                    </Grid>*/}
                {/*                    <Grid item className={'align-self-center'}>*/}
                {/*                        <CardContent>*/}
                {/*                            <Button*/}
                {/*                                variant="outlined"*/}
                {/*                                color="primary"*/}
                {/*                                size="small"*/}
                {/*                                onClick={() => history.push(`ventas/cotizaciones`)}*/}
                {/*                            >*/}
                {/*                                Ingresar*/}
                {/*                            </Button>*/}
                {/*                        </CardContent>*/}
                {/*                    </Grid>*/}
                {/*                </Grid>*/}
                {/*            </Card>*/}
                {/*        </Grid>*/}
                {/*        <Grid item>*/}
                {/*            <Card className={'niquel-stats-card'}>*/}
                {/*                <Grid container direction={"row"}>*/}
                {/*                    <Grid item>*/}
                {/*                        <CardContent>*/}
                {/*                            <Typography color="textSecondary" variant={"body2"} gutterBottom>*/}
                {/*                                Pedidos*/}
                {/*                            </Typography>*/}
                {/*                            <Typography variant="h5" component="span">*/}
                {/*                                9*/}
                {/*                            </Typography>*/}
                {/*                            <Typography className={'ml-2'} variant={"caption"} color={"error"}>*/}
                {/*                                -12%*/}
                {/*                            </Typography>*/}
                {/*                            <Typography variant="body2" component="p">*/}
                {/*                                en el último mes*/}
                {/*                            </Typography>*/}
                {/*                        </CardContent>*/}
                {/*                    </Grid>*/}
                {/*                    <Grid item className={'align-self-center'}>*/}
                {/*                        <CardContent>*/}
                {/*                            <Button*/}
                {/*                                variant="outlined"*/}
                {/*                                color="primary"*/}
                {/*                                size="small"*/}
                {/*                                onClick={() => history.push(`ventas/pedidos`)}*/}
                {/*                            >*/}
                {/*                                Ingresar*/}
                {/*                            </Button>*/}
                {/*                        </CardContent>*/}
                {/*                    </Grid>*/}
                {/*                </Grid>*/}
                {/*            </Card>*/}
                {/*        </Grid>*/}
                {/*        <Grid item>*/}
                {/*            <Card className={'niquel-stats-card'}>*/}
                {/*                <Grid container direction={"row"}>*/}
                {/*                    <Grid item>*/}
                {/*                        <CardContent>*/}
                {/*                            <Typography color="textSecondary" variant={"body2"} gutterBottom>*/}
                {/*                                Cobranzas*/}
                {/*                            </Typography>*/}
                {/*                            <Typography variant="h5" component="span">*/}
                {/*                                $2.567.500,50*/}
                {/*                            </Typography>*/}
                {/*                            <Typography className={'ml-2'} variant={"caption"} color={"primary"}>*/}
                {/*                                +17%*/}
                {/*                            </Typography>*/}
                {/*                            <Typography variant="body2" component="p">*/}
                {/*                                en el último mes*/}
                {/*                            </Typography>*/}
                {/*                        </CardContent>*/}
                {/*                    </Grid>*/}
                {/*                    <Grid item className={'align-self-center'}>*/}
                {/*                        <CardContent>*/}
                {/*                            <Button*/}
                {/*                                variant="outlined"*/}
                {/*                                color="primary"*/}
                {/*                                size="small"*/}
                {/*                                onClick={() => history.push(`ventas/cobranzas`)}*/}
                {/*                            >*/}
                {/*                                Nueva*/}
                {/*                            </Button>*/}
                {/*                        </CardContent>*/}
                {/*                    </Grid>*/}
                {/*                </Grid>*/}
                {/*            </Card>*/}
                {/*        </Grid>*/}
                {/*    </Grid>*/}
                {/*</Box>*/}
                {/*<Box className={'mt-3'}>*/}
                {/*    <Card>*/}
                {/*        <CardHeader*/}
                {/*            title="Pedidos"*/}
                {/*            subheader="realizados hasta Febrero de 2020"*/}
                {/*        />*/}
                {/*        <CardContent>*/}
                {/*            <Box className={'line-chart'}>*/}
                {/*                <MyResponsiveLine data={LINE_DATA}/>*/}
                {/*            </Box>*/}
                {/*        </CardContent>*/}
                {/*    </Card>*/}
                {/*</Box>*/}
            </Box>
        </div>
    )
}
