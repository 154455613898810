import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {NavLink, withRouter} from "react-router-dom";
import {MainContext} from "contexts/main.context";
import {Collapse, Divider, Drawer, IconButton, List, ListItem, ListItemIcon, ListItemText, SwipeableDrawer, Tooltip, withStyles} from "@material-ui/core";
import appLogo from '../../../assets/img/app-logo.svg';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightIcon from '@material-ui/icons/ChevronRightRounded';
import ExpandLess from '@material-ui/icons/ExpandLessRounded';
import ExpandMore from '@material-ui/icons/ExpandMoreRounded';
import {isMobile, osName} from 'react-device-detect';
import {requestFullScreen} from "assets/utils";
import styles from './sideBar.styles';
import {enviroment} from "enviroment";

function CustomDrawer(props) {
    return isMobile ?
        <SwipeableDrawer
            onClose={() => props.context.setSidebarVisible(false)}
            onOpen={() => props.context.setSidebarVisible(true)}
            open={props.context.sidebarVisible}
            className={props.className}
            classes={props.classes}
            variant={"temporary"}
        >
            {props.children}
        </SwipeableDrawer> :
        <Drawer
            variant={"permanent"}
            open={props.open}
            className={props.className}
            classes={props.classes}
        >
            {props.children}
        </Drawer>
}

export default class SideBarContent extends React.Component {
    state = {
        menu: {
            home: {link: true, open: false},
            administracion: {open: false},
            ventas: {open: false},
            aprobaciones: {open: false},
            stock: {open: false},
            despacho: {open: false},
            picking: {link: true, open: false},
            listados: {open: false},
            estadisticas: {link: true, open: false},
            objetivos: {open: false},
            herramientas: {open: false}
        },
    };

    componentDidMount() {
        for (let menu in this.state.menu)
            if (this.props.location.pathname.indexOf(`/${menu}`) > -1) {
                this.setState(prevState => {
                    prevState.menu[menu].open = true;
                    return prevState;
                });
                break;
            }
    }

    toggleMenu = (sKey) => {
        // Si no existía la propiedad no hago nada.
        if (this.state.menu[sKey] === undefined)
            return;

        this.context.openSidebar();

        this.setState(prevState => ({
            menu: {
                ...prevState.menu,
                [sKey]: {open: !prevState.menu[sKey].open}
            }
        }));
    };

    onClickLink = (e, mainMenuItem) => {
        if (mainMenuItem.disabled)
            e.preventDefault();

        if (!isMobile && window.innerWidth < this.context.MIN_EXPANDED_WIDTH)
            this.context.closeSidebar();

        if (isMobile)
            this.context.setSidebarVisible(false);

        if ((isMobile || osName === 'Android'))
            requestFullScreen();
    };

    shouldBeVisible = menu => {
        let shouldSee = true;

        if (typeof menu.authorizedRoles !== "undefined" && menu.authorizedRoles.includes(this.context.loggedUser.role) === false)
            shouldSee = shouldSee && false;

        if (typeof menu.authorizedCompanies !== "undefined" && menu.authorizedCompanies.includes(this.context.loggedUser.cmpid) === false)
            shouldSee = shouldSee && false;

        return shouldSee;
    }

    render() {
        const {classes, mainMenu} = this.props;
        const {sidebarOpen, openSidebar, closeSidebar} = this.context;

        return (
            <div className={classes.sideBar}>
                <CustomDrawer
                    className={classNames(classes.drawer, {[classes.drawerOpen]: sidebarOpen, [classes.drawerClose]: !sidebarOpen})}
                    classes={{
                        paper: classNames(classes.paperDark, {[classes.drawerOpen]: sidebarOpen, [classes.drawerClose]: !sidebarOpen}),
                    }}
                    open={sidebarOpen}
                    context={this.context}
                >
                    <div className={classes.toolbar}>
                        <img alt='' src={appLogo} className={classes.niquelLogo}/>
                        <div className={classNames(classes.niquelTitle, {hidden: !sidebarOpen})}>
                            Niquel <span style={{fontSize: '.75rem'}}>({enviroment.niquelVersion})</span>
                        </div>
                    </div>
                    <Divider classes={{root: classes.Divider}}/>
                    <div className={classNames(classes.optionsWrapper, {'niquel-scroll': !(isMobile || osName === 'Android')})}>
                        <List classes={{root: classes.List}}>
                            {
                                mainMenu.map((mainMenuItem, index) => (
                                    mainMenuItem.key === 'divider' ? (<Divider key={index} classes={{root: classes.Divider}}/>) :
                                        this.shouldBeVisible(mainMenuItem) === false ? '' :
                                            (
                                                <div key={index}
                                                     className={classNames(classes.ListContainer, {open: sidebarOpen && this.state.menu[mainMenuItem.key] && this.state.menu[mainMenuItem.key].open && !this.state.menu[mainMenuItem.key].link})}>
                                                    <Tooltip title={mainMenuItem.title} placement="right" disableHoverListener={sidebarOpen}>
                                                        <NavLink to={`/${mainMenuItem.key}`}
                                                                 style={{textDecoration: 'none', cursor: 'default', color: 'inherit'}}
                                                                 activeClassName={this.state.menu[mainMenuItem.key].link ? classes.LinkActive : ''}
                                                                 key={index}
                                                                 onClick={e => e.preventDefault()}
                                                        >
                                                            <ListItem
                                                                button
                                                                className={classNames(classes.ListItem, {open: sidebarOpen && this.state.menu[mainMenuItem.key] && this.state.menu[mainMenuItem.key].open})}
                                                                onClick={(e) => {
                                                                    if (this.state.menu[mainMenuItem.key].link) {
                                                                        this.onClickLink(e, mainMenuItem.key);
                                                                        this.props.history.push(`/${mainMenuItem.key}`);
                                                                    } else
                                                                        this.toggleMenu(mainMenuItem.key)
                                                                }}
                                                                disabled={mainMenuItem.disabled}
                                                            >
                                                                <ListItemIcon className={classes.ListItemIcon}>
                                                                    {mainMenuItem.icon}
                                                                </ListItemIcon>
                                                                <ListItemText
                                                                    classes={{root: classes.ListItemText}}
                                                                    className={classNames(classes.ListItemText, {hidden: !sidebarOpen})} primary={mainMenuItem.title}
                                                                />
                                                                {
                                                                    this.state.menu[mainMenuItem.key] && this.state.menu[mainMenuItem.key].link !== true ?
                                                                        this.state.menu[mainMenuItem.key].open ?
                                                                            <ExpandLess className={classes.ListItemIcon}/> :
                                                                            <ExpandMore className={classes.ListItemIcon}/> : ''
                                                                }
                                                            </ListItem>
                                                        </NavLink>
                                                    </Tooltip>
                                                    {
                                                        mainMenuItem.subMenu ? mainMenuItem.subMenu.map((mainMenuSubItem, index) =>
                                                            this.shouldBeVisible(mainMenuSubItem) === false ? '' :
                                                                (
                                                                    <NavLink to={`/${mainMenuItem.key}/${mainMenuSubItem.key}`}
                                                                             style={{textDecoration: 'none', cursor: 'default', color: 'inherit'}}
                                                                             activeClassName={classes.LinkActive}
                                                                             key={index}
                                                                             onClick={(e) => this.onClickLink(e, mainMenuItem)}
                                                                    >
                                                                        <Collapse
                                                                            in={sidebarOpen && this.state.menu[mainMenuItem.key] && this.state.menu[mainMenuItem.key].open}
                                                                            timeout="auto"
                                                                            unmountOnExit
                                                                        >
                                                                            <List component="div" disablePadding>
                                                                                <ListItem
                                                                                    button
                                                                                    className={classNames(classes.ListItem, classes.ListItemSubMenu)}
                                                                                    disabled={mainMenuSubItem.disabled}
                                                                                >
                                                                                    <ListItemText
                                                                                        inset
                                                                                        primary={mainMenuSubItem.title}
                                                                                        className={classes.ListItemTextSubMenu}
                                                                                    />
                                                                                </ListItem>
                                                                            </List>
                                                                        </Collapse>
                                                                    </NavLink>
                                                                )) : ''
                                                    }
                                                </div>
                                            )
                                ))
                            }
                        </List>
                    </div>
                    {!isMobile && (
                        <div
                            className={classNames(classes.btnAction, {open: sidebarOpen})}
                            onClick={sidebarOpen ? closeSidebar : openSidebar}
                        >
                            {
                                sidebarOpen ?
                                    <IconButton classes={{root: classes.ListItemIcon}} disableRipple={true}> <ChevronLeftIcon/> </IconButton> :
                                    <IconButton classes={{root: classes.ListItemIcon}} disableRipple={true}> <ChevronRightIcon/> </IconButton>
                            }
                        </div>
                    )}
                </CustomDrawer>
            </div>
        )
    }
}

SideBarContent.propTypes = {
    classes: PropTypes.object.isRequired,
};

SideBarContent.contextType = MainContext;
SideBarContent = withStyles(styles)(SideBarContent);
SideBarContent = withRouter(SideBarContent);
