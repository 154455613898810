import React, {Component} from 'react'
import {Box} from "@material-ui/core/index";
import {CotizacionesOperation} from 'components/ventas/cotizaciones/operation/cotizaciones.operation';
import {ClientesService, CotizacionesService, EmailService, MastersService, VendedoresService} from "services";
import {calcImporteByArticulo} from 'assets/utils';
import MomentUtils from "@date-io/moment/build/index";
import 'moment/locale/es';
import {MainContext} from "contexts/main.context";
import {withSnackbar} from "notistack";
import {MailDialog} from "components/helpers/dialogs/mail.dialog";
import SpeedDial from "@material-ui/lab/SpeedDial/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction/SpeedDialAction";
import PDFIcon from "@material-ui/icons/PictureAsPdf";
import DownloadIcon from "@material-ui/icons/GetAppRounded";
import ShowIcon from "@material-ui/icons/DesktopWindowsRounded";
import PrintIcon from "@material-ui/icons/PrintRounded";
import EmailIcon from "@material-ui/icons/EmailRounded";
import printJS from 'print-js'
import {PDFDialog} from "components/helpers/dialogs/pdf.dialog";
import {PDFTypeDialog} from "components/ventas/cotizaciones/view/dialog/pdfTypeDialog.dialog";

export const TIPOS = [
    {id: 'TRF', description: 'Transferencia Bancaria'},
    {id: 'RSS', description: 'Retención Seguridad Social'},
    {id: 'RIV', description: 'Retención IVA'},
    {id: 'RGA', description: 'Retención Ganancias'},
    {id: 'RIB', description: 'Retención Ing. Brutos'},
];

export class CotizacionesView extends Component {
    state = {
        cliente: {},
        vendedor: {},
        cotizaciones: [],
        id: this.props.match.params.cotizacion,
        condComerciales: [],
        vendedores: [],
        talremitos: [],
        headerData: {
            bonificacion: 0,
            listaprecio: '1',
            condcomercial: '',
            notacompra: '',
            usuario: '',
            vendedor: '',
            ingreso: (new MomentUtils()).date(new Date()),
            entrega: (new MomentUtils()).date(new Date()),
            leyenda1: '',
            leyenda2: '',
            leyenda3: '',
            leyenda4: '',
            isProforma: false,
            isPrepago: false,
            aprob_coti: false,
            ingre_tran: false,
            aprob_tran: false,
            recha_tran: false,
            talremito: ''
        },
        selectedArticulos: [],
        generatedId: '',
        importes: {
            subtotal: 0,
            bonificado: 0,
            gravado: 0,
            iva: 0,
            iibb: 0,
            total: 0,
            totalPrepago: 0,
            exento: 0,
            interes: 0,
            flete: 0
        },
        itemsDeCobranza: [],
        confirmationDialogOpen: false,
        successDialogOpen: false,
        pdfBlob: null,
        pdfUrl: '',
        pdfType: 'normal', // normal | extended
        pdfSuccessFn: () => {
        },
        mailDialogOpen: false,
        pdfDialogOpen: false,
        dialOpen: false,
        pdfTypeDialogOpen: false
    };

    CotizacionesService = new CotizacionesService(this.context);
    MastersService = new MastersService(this.context);
    VendedoresService = new VendedoresService(this.context);
    ClientesService = new ClientesService(this.context);
    EmailService = new EmailService(this.context);

    componentDidMount() {
        this.ClientesService.getById(
            this.props.match.params.cliente,
            response => this.setState({cliente: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.CotizacionesService.getByCliente(
            this.props.match.params.cliente,
            response => this.setState({cotizaciones: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.loadCotizacionData();

        this.MastersService.getTalonariosRemito(
            response => this.setState({talremitos: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    loadCotizacionData = () => {
        this.CotizacionesService.getById(
            this.state.id,
            response => {
                const {...res} = response.data.result;
                const headerData = {
                    bonificacion: res.bonificacion || 0,
                    listaprecio: res.listaprecio || '1',
                    condcomercial: res.condcomercial || '',
                    notacompra: res.notacompra || '',
                    usuario: res.usuario || '',
                    vendedor: res.vendedor && res.vendedor > 0 ? res.vendedor : '',
                    ingreso: res.ingreso ? (new MomentUtils()).parse(res.ingreso, 'D/MM/Y') : '',
                    entrega: res.entrega ? (new MomentUtils()).parse(res.entrega, 'D/MM/Y') : '',
                    leyenda1: res.leyenda1 || '',
                    leyenda2: res.leyenda2 || '',
                    leyenda3: res.leyenda3 || '',
                    leyenda4: res.leyenda4 || '',
                    talremito: res.talremito || '',
                    isProforma: res.tipo === 'PRO',
                    isPrepago: res.isprepago,
                    aprob_coti: res.aprob_coti,
                    ingre_tran: res.ingre_tran,
                    aprob_tran: res.aprob_tran,
                    recha_tran: res.recha_tran
                };

                this.setState({headerData});

                this.CotizacionesService.getImpuestosById(
                    this.state.id,
                    response => {
                        const impuestos = response.data.result.filter(impuesto => parseInt(impuesto.alicuota) !== 0 && parseInt(impuesto.alicuota) !== 1);

                        this.setState(prevState => {
                            prevState.impuestos = impuestos;
                            prevState.importes = prevState.importes || {};
                            let importes = prevState.importes;
                            importes.subtotal = res.impsubtotal;
                            importes.bonificado = res.impbonifica;
                            importes.gravado = res.impgravado;
                            importes.iva = res.impiva;
                            importes.total = res.imptotal;
                            importes.exento = res.impexento;
                            importes.interes = res.impinteres;
                            importes.flete = res.impflet;
                            importes.iibb = impuestos.reduce((sum, impuesto) => sum + parseFloat(impuesto.importe), 0);
                            importes.totalPrepago = importes.total * (1 - 0.3);
                            return prevState;
                        });
                    }
                )

                this.VendedoresService.getById(
                    res.vendedor,
                    response => this.setState({vendedor: response.data.result}),
                    error => this.props.enqueueSnackbar(error, {variant: 'error'})
                );
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.CotizacionesService.getArticulosById(
            this.state.id,
            response => {
                let articulos = response.data.result;
                articulos.forEach(articulo => articulo.importe = calcImporteByArticulo(articulo.cantidad, articulo.precio, articulo.bonificacion));
                this.setState({
                    selectedArticulos: articulos,
                    totalArticulos: articulos.reduce((count, articulo) => (count + parseInt(articulo.cantidad)), 0)
                });
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.CotizacionesService.getPrepagoItems(
            this.state.id,
            response => {
                let itemsDeCobranza = response.data.result.map(item => ({...item, fecha: (new MomentUtils()).parse(item.fecha, 'D/MM/Y')}));
                this.setState({
                    itemsDeCobranza: itemsDeCobranza
                });
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    };

    onChangeDocument = id => this.setState({id, pdfBlob: null}, () => this.loadCotizacionData());

    onOpenPDFTypeDialog = () => this.setState({pdfTypeDialogOpen: true});
    onClosePDFTypeDialog = () => this.setState({pdfTypeDialogOpen: false});

    getPDF = (fnSuccess = () => {
    }) => {
        this.setState({pdfSuccessFn: fnSuccess}, this.onOpenPDFTypeDialog)
    };

    _getPDF = bonificacion => {
        this.CotizacionesService.getPDF(
            this.state.id,
            bonificacion.dias,
            bonificacion.porcentaje,
            file => this.setState({pdfBlob: file}, this.state.pdfSuccessFn),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        )
    }

    // Show PDF
    onOpenPdfDialog = () => this.setState({pdfDialogOpen: true});
    onClosePdfDialog = () => this.setState({pdfDialogOpen: false});

    onShowPDF = () => {
        this.getPDF(
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                this.setState({pdfUrl: fileURL}, this.onOpenPdfDialog);
                // window.open(fileURL);
            }
        );
    };

    // Print PDF
    onPrintPDF = () => {
        this.getPDF(
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                printJS(fileURL);
            }
        );
    };

    // Download PDF
    onDownloadPdf = () => {
        this.getPDF(
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = fileURL;
                a.download = `Cotización ${this.state.id}`;
                a.click();
                window.URL.revokeObjectURL(fileURL);
            }
        );
    };

    // Email
    onOpenMailDialog = () => this.setState({mailDialogOpen: true});

    dialActions = [
        {icon: <EmailIcon/>, name: 'Enviar por Email', action: this.onOpenMailDialog},
        {icon: <PrintIcon/>, name: 'Imprimir', action: this.onPrintPDF},
        {icon: <DownloadIcon/>, name: 'Descargar', action: this.onDownloadPdf},
        {icon: <ShowIcon/>, name: 'Ver en Pantalla', action: this.onShowPDF},
    ];

    onCloseMailDialog = () => this.setState({mailDialogOpen: false});

    sendMail = mailData => {
        let sendMail = () => {
            // Paso los parámetros a un formData para poder enviar el blob (attachment)
            var formData = new FormData();
            formData.append('attachment', this.state.pdfBlob);
            formData.append('data', JSON.stringify(mailData));

            this.EmailService.send(
                formData,
                response => {
                    this.onCloseMailDialog();
                    this.props.enqueueSnackbar('El email se envió correctamente', {variant: 'success'});
                },
                error => this.props.enqueueSnackbar(error, {variant: 'error'})
            );
        };

        if (mailData.attachFile)
            this.getPDF(() => sendMail());
        else
            sendMail();
    };

    onDialOpen = () => this.setState({dialOpen: true});

    onDialClose = () => this.setState({dialOpen: false});

    render() {
        const {
            cliente, vendedor, cotizaciones, id, condComerciales, vendedores, talremitos, articulos, headerData, selectedArticulos, generatedId, importes,
            totalArticulos, mailDialogOpen, pdfDialogOpen, pdfUrl, dialOpen, pdfTypeDialogOpen, itemsDeCobranza
        } = this.state;

        return (
            <React.Fragment>
                <Box className='niquel-cotizaciones-view'>
                    <CotizacionesOperation
                        operation='VIEW'
                        cliente={cliente}
                        cotizaciones={cotizaciones}
                        id={id}
                        condComerciales={condComerciales}
                        vendedores={vendedores}
                        talremitos={talremitos}
                        articulos={articulos}
                        headerData={headerData}
                        selectedArticulos={selectedArticulos}
                        generatedId={generatedId}
                        importes={importes}
                        totalArticulos={totalArticulos}
                        onChangeDocument={this.onChangeDocument}
                        itemsDeCobranza={itemsDeCobranza}
                        tipos={TIPOS}
                    />
                    <Box className='niquel-fab-btn'>
                        <SpeedDial
                            ariaLabel="crear_cotizacion"
                            icon={<PDFIcon/>}
                            onBlur={this.onDialClose}
                            onClick={this.onDialOpen}
                            onClose={this.onDialClose}
                            onFocus={this.onDialOpen}
                            onMouseEnter={this.onDialOpen}
                            onMouseLeave={this.onDialClose}
                            open={dialOpen}
                        >
                            {this.dialActions.map(action => (
                                <SpeedDialAction
                                    key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        action.action()
                                    }}
                                />
                            ))}
                        </SpeedDial>
                    </Box>
                </Box>
                <MailDialog
                    open={mailDialogOpen}
                    onConfirm={this.sendMail}
                    onCancel={this.onCloseMailDialog}
                    fileName={`Cotización_${this.state.id}.pdf`}
                    attachFile={true}
                    to={cliente.email}
                    cc={vendedor.email}
                    ccEnabled={true}
                    subject={`Cotización ${this.state.id}`}
                    body={`Estimado cliente,  \n\nSe adjunta la cotización número ${this.state.id}.`}
                />
                <PDFDialog
                    open={pdfDialogOpen}
                    onClose={this.onClosePdfDialog}
                    pdfUrl={pdfUrl}
                    pdfName={`Cotización_${this.state.id}.pdf`}
                />
                <PDFTypeDialog
                    open={pdfTypeDialogOpen}
                    confirmText={"Aceptar"}
                    cancelText={"Cancelar"}
                    title={"Bonificación de artículos"}
                    body={""}
                    onConfirm={bonificacion => {
                        this._getPDF(bonificacion);
                        this.onClosePDFTypeDialog()
                    }}
                    onCancel={() => {
                        this.onClosePDFTypeDialog()
                    }}
                />
            </React.Fragment>
        )
    }
}

CotizacionesView.contextType = MainContext;
CotizacionesView = withSnackbar(CotizacionesView);
