import React, {PureComponent} from "react";
import {MapContainer, Marker, TileLayer, Tooltip} from "react-leaflet";
import L from 'leaflet'
import MarkerClusterGroup from "react-leaflet-markercluster";
import {
    refPinBlackColor,
    refPinBlueColor,
    refPinDarkGreenColor,
    refPinDarkGreyColor,
    refPinDarkRedColor,
    refPinGreenColor,
    refPinGreyColor,
    refPinOrangeColor,
    refPinRedColor,
    refPinYellowColor
} from "assets/utils";

const markerBasePath = '/markers';

const NMarkerIcon = (color, value) => new L.Icon({
    iconUrl: `${markerBasePath}/${color}/${value}.svg`,
    iconRetinaUrl: `${markerBasePath}/${color}/${value}.svg`,
    iconAnchor: [5, 55],
    popupAnchor: [18, -40],
    iconSize: [45, 75],
    shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
    shadowSize: [21, 21],
    shadowAnchor: [-11, 19],
    tooltipAnchor: [18, -40]
})

const NDivMarkerIcon = (text, colors) => {
    const strokeColor = color => {
        return color === 'transparent' ? 'none' : '#000000';
    }

    let textColor = [refPinGreyColor, refPinYellowColor].indexOf(colors[0]) > -1 ? '#333' : '#fcfcfc';

    return new L.DivIcon({
        className: 'custom-icon',
        iconSize: [40, 52],
        iconAnchor: [20, 52],
        shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
        shadowSize: [21, 21],
        shadowAnchor: [-0, 0],
        tooltipAnchor: [1, -45],
        html: `
            <svg width="40" height="52" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <g stroke="null" id="svg_27">
                        <path stroke="${strokeColor(colors[2])}" fill="${colors[2]}" d="m20.09322,0.34329c-7.76838,0 -14.05134,6.28296 -14.05134,14.05134c0,10.53851 14.05134,26.09535 14.05134,26.09535s14.05135,-15.55684 14.05135,-26.09535c0,-7.76838 -6.28296,-14.05134 -14.05135,-14.05134zm0,19.06968c-2.77012,0 -5.01834,-2.24822 -5.01834,-5.01834s2.24822,-5.01833 5.01834,-5.01833s5.01834,2.24822 5.01834,5.01833s-2.24822,5.01834 -5.01834,5.01834z" id="svg_26" stroke-width="0.3"/>
                    </g>
                    <g stroke="null" id="svg_24">
                        <path stroke="${strokeColor(colors[1])}" fill="${colors[1]}" d="m20.09509,5.20518c-7.85357,0 -14.20542,6.35185 -14.20542,14.20542c0,10.65407 14.20542,26.3815 14.20542,26.3815s14.20543,-15.72744 14.20543,-26.3815c0,-7.85357 -6.35186,-14.20542 -14.20543,-14.20542zm0,19.27879c-2.8005,0 -5.07337,-2.27287 -5.07337,-5.07337s2.27287,-5.07336 5.07337,-5.07336s5.07337,2.27287 5.07337,5.07336s-2.27287,5.07337 -5.07337,5.07337z" id="svg_23" stroke-width="0.3"/>
                    </g>
                    <g stroke="null" id="svg_7">
                        <path stroke="${strokeColor(colors[0])}" fill="${colors[0]}" d="m20.13362,11.23711c-7.85357,0 -14.20542,6.35185 -14.20542,14.20542c0,10.65407 14.20542,26.3815 14.20542,26.3815s14.20542,-15.72743 14.20542,-26.3815c0,-7.85357 -6.35185,-14.20542 -14.20542,-14.20542zm0,19.27879c-2.8005,0 -5.07337,-2.27287 -5.07337,-5.07337s2.27287,-5.07337 5.07337,-5.07337s5.07337,2.27287 5.07337,5.07337s-2.27287,5.07337 -5.07337,5.07337z" id="svg_2" stroke-width="0.3"/>
                        <ellipse stroke="none" ry="6.90024" rx="6.14302" id="svg_3" cy="25.43481" cx="20.17688" fill="${colors[0]}"/>
                    </g>
                    <text transform="matrix(1.4232896780781628,0,0,1.4232896780781628,-18.239298488613077,-18.815582442568054) " stroke="null" xml:space="preserve" text-anchor="start" font-family="Helvetica, Arial, sans-serif" font-size="11" id="svg_4" y="37.60338" x="19.4" stroke-width="0" font-weight="bold" fill="${textColor}">${text}</text>
                </g>
            </svg>`
    })
};

function NMarker(props) {
    const {direccion, color, onClick} = props;

    let offset = 0.0001;
    if (direccion.agregada)
        offset *= -1;

    let Icon;
    if (color === 'lightweight') {
        Icon = new L.Icon.Default();
        Icon.options.shadowSize = [0, 0];
    } else {
        const iconValue = direccion.pedidos ? Math.min(direccion.pedidos.length, 9) : 'original';
        Icon = NMarkerIcon(color, iconValue);
    }

    const position = {lat: (parseFloat(direccion.latitud) + offset), lng: (parseFloat(direccion.longitud) + offset)};

    return (
        <Marker icon={Icon} position={position} eventHandlers={{click: onClick}}>
            <Tooltip direction="top" opacity={0.9}>{direccion.cliente || direccion.razsoc}</Tooltip>
        </Marker>
    )
}

function NDivMarker(props) {
    const {direccion, fixedColor, onClick} = props;

    const position = {lat: parseFloat(direccion.latitud), lng: parseFloat(direccion.longitud)};

    let colors = (new Array(3)).fill("transparent");
    let added = 0;

    if (fixedColor) colors[0] = fixedColor;
    else {
        const status = (new Array(6).fill(false));

        direccion.pedidos.forEach(pedido => {
            added += pedido.agregado ? 1 : 0;
            status[0] = status[0] || pedido.estadio === 'N' || pedido.estadio === '';
            status[1] = status[1] || pedido.estadio === 'P';
            status[2] = status[2] || pedido.estadio === 'R';
            status[3] = status[3] || (pedido.estadio === 'F' && !pedido.estado);
            status[4] = status[4] || (pedido.estadio === 'F' && pedido.estado);
            status[5] = status[5] || pedido.estadio === 'D';
            status[6] = status[6] || pedido.estadio === 'E';
            status[7] = status[7] || pedido.estadio === 'H';
            status[8] = status[8] || pedido.estadio === 'I';
        });

        if (status[8]) colors.unshift(refPinDarkGreyColor);
        if (status[7]) colors.unshift(refPinDarkRedColor);
        if (status[6]) colors.unshift(refPinDarkGreenColor);
        if (status[5]) colors.unshift(refPinBlackColor);
        if (status[4]) colors.unshift(refPinGreenColor);
        if (status[3]) colors.unshift(refPinOrangeColor);
        if (status[2]) colors.unshift(refPinRedColor);
        if (status[1]) colors.unshift(refPinYellowColor);
        if (status[0]) colors.unshift(refPinGreyColor);
    }

    let Icon = NDivMarkerIcon(`${added}/${direccion.pedidos.length}`, colors);

    return (
        <Marker icon={Icon} position={position} eventHandlers={{click: onClick}}>
            <Tooltip direction="top" opacity={0.9}>{direccion.cliente || direccion.razsoc}</Tooltip>
        </Marker>
    )
}

const NMarkers = ({direcciones, onMarkerClick}) => {
    return direcciones.map(direccion => (
        Object.keys(direccion.data).map(key => (
            <NMarker
                key={key}
                direccion={direccion.data[key]}
                color={direccion.color}
                onClick={e => onMarkerClick(direccion.data[key])}
            />
        ))
    ))
}

const NDivMarkers = ({direcciones, onMarkerClick}) => {
    return direcciones.map(direccion => (
        Object.keys(direccion.data).map(key => (
            <NDivMarker
                key={key}
                direccion={direccion.data[key]}
                color={direccion.color}
                onClick={e => onMarkerClick(direccion.data[key])}
            />
        ))
    ))
}

const BERNER_COORDS = {lat: -34.6577037, lng: -58.5820609};

export class Map extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            center: BERNER_COORDS,
            zoom: 11,
            markers: [],
            detailsDrawerOpen: false
        }
    }

    toggleDetailsDrawer = (open, event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) return;
        this.setState({detailsDrawerOpen: open});
    };

    render() {
        console.log('Render map');
        const {enableClustering, height, width, direcciones, onMarkerClick, withPedidos} = this.props;
        const {center, zoom} = this.state;

        return (
            <div style={{width: width, height: height, display: 'flex'}}>
                <MapContainer center={center} zoom={zoom} style={{flex: 1}}>
                    <TileLayer
                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png"
                        // url="https://{s}.tile.jawg.io/jawg-sunny/{z}/{x}/{y}{r}.png"
                        // url="https://a.tiles.mapbox.com/v3/mapbox.world-bright/{z}/{x}/{y}.png"
                        // url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
                        // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        // url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.png"
                    />

                    {withPedidos &&
                    <NDivMarkers
                        direcciones={direcciones}
                        onMarkerClick={onMarkerClick}
                    />
                    }

                    {!withPedidos && enableClustering &&
                    <MarkerClusterGroup disableClusteringAtZoom={enableClustering ? 14 : 1} spiderfyOnMaxZoom={false}>
                        <NMarkers
                            direcciones={direcciones}
                            onMarkerClick={onMarkerClick}
                        />
                    </MarkerClusterGroup>
                    }

                    {!withPedidos && !enableClustering &&
                    <NMarkers
                        direcciones={direcciones}
                        onMarkerClick={onMarkerClick}
                    />
                    }

                    {/* Agrego a Berner en amarillo*/}
                    <Marker icon={NDivMarkerIcon('', [refPinBlueColor, "transparent", "transparent"])} position={BERNER_COORDS}>
                        <Tooltip direction="top" opacity={0.9}>Berner Bs As</Tooltip>
                    </Marker>

                </MapContainer>
            </div>
        )
    }
}
