import {Box} from "@material-ui/core";
import SpeedDial from "@material-ui/lab/SpeedDial/SpeedDial";
import PDFIcon from "@material-ui/icons/PictureAsPdf";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction/SpeedDialAction";
import React, {useState} from "react";
import EmailIcon from "@material-ui/icons/EmailRounded";
import PrintIcon from "@material-ui/icons/PrintRounded";
import DownloadIcon from "@material-ui/icons/GetAppRounded";
import ShowIcon from "@material-ui/icons/DesktopWindowsRounded";
import printJS from "print-js";
import {useSnackbar} from "notistack";
import {MailDialog} from "components/helpers/dialogs/mail.dialog";
import {PDFDialog} from "components/helpers/dialogs/pdf.dialog";
import {useEmailService} from "services/hooks";

export const ExportOptions = ({
        enableMail = false, enablePrint = false, enableShow = false, enableDownload = false,
        getPDF = () => {}, fileName, mailTo, mailCC, mailCCEnabled, mailSubject, mailBody
    }) => {
    const [mailDialogOpen, setMailDialogOpen] = useState(false);
    const [pdfDialogOpen, setPdfDialogOpen] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [dialOpen, setDialOpen] = useState(false);

    const EmailService = useEmailService();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    // Print PDF
    const onPrintPDF = () => getPDF(blob => {
        const fileURL = URL.createObjectURL(blob);
        printJS(fileURL);
    });

    // Download PDF
    const onDownloadPdf = () => getPDF(blob => {
        const fileURL = URL.createObjectURL(blob);
        var a = document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        a.href = fileURL;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(fileURL);
        enqueueSnackbar('El archivo se descargó correctamente. Revise su carpeta de descargas.', {variant: 'success'});
    });

    // Show PDF
    const onOpenPdfDialog = () => setPdfDialogOpen(true);
    const onClosePdfDialog = () => setPdfDialogOpen(false);

    const onShowPDF = () => getPDF(blob => {
        const fileURL = URL.createObjectURL(blob);
        setPdfUrl(fileURL);
        onOpenPdfDialog();
    });

    // Email
    const onOpenMailDialog = () => setMailDialogOpen(true);
    const onCloseMailDialog = () => setMailDialogOpen(false);

    const sendMail = mailData => {
        let sendMail = (blob) => {
            // Paso los parámetros a un formData para poder enviar el blob (attachment)
            var formData = new FormData();
            formData.append('attachment', blob);
            formData.append('data', JSON.stringify(mailData));

            EmailService.send(
                formData,
                response => {
                    onCloseMailDialog();
                    enqueueSnackbar('El email se envió correctamente', {variant: 'success'});
                },
                error => enqueueSnackbar(error, {variant: 'error'})
            );
        };

        if (mailData.attachFile)
            getPDF((blob) => sendMail(blob));
        else
            sendMail();
    };

    // Dial
    const onDialOpen = () => setDialOpen(true);
    const onDialClose = () => setDialOpen(false);

    const dialActions = [
        {icon: <EmailIcon/>, name: 'Enviar por Email', action: onOpenMailDialog, enabled: enableMail},
        {icon: <PrintIcon/>, name: 'Imprimir', action: onPrintPDF, enabled: enablePrint},
        {icon: <DownloadIcon/>, name: 'Descargar', action: onDownloadPdf, enabled: enableDownload},
        {icon: <ShowIcon/>, name: 'Ver en Pantalla', action: onShowPDF, enabled: enableShow},
    ];

    return (
        <>
            <Box className='niquel-fab-btn'>
                <SpeedDial
                    ariaLabel="articulos"
                    icon={<PDFIcon/>}
                    onBlur={onDialClose}
                    onClick={onDialOpen}
                    onClose={onDialClose}
                    onFocus={onDialOpen}
                    onMouseEnter={onDialOpen}
                    onMouseLeave={onDialClose}
                    open={dialOpen}
                >
                    {dialActions.filter(action => action.enabled).map(action => (
                        <SpeedDialAction
                            key={action.name}
                            icon={action.icon}
                            tooltipTitle={action.name}
                            onClick={(e) => {
                                e.preventDefault();
                                action.action()
                            }}
                            className={'seba'}
                        />
                    ))}
                </SpeedDial>
            </Box>
            <MailDialog
                open={mailDialogOpen}
                onConfirm={sendMail}
                onCancel={onCloseMailDialog}
                fileName={fileName}
                attachFile={true}
                to={mailTo}
                cc={mailCC}
                ccEnabled={mailCCEnabled}
                subject={mailSubject}
                body={mailBody}
            />
            <PDFDialog
                open={pdfDialogOpen}
                onClose={onClosePdfDialog}
                pdfUrl={pdfUrl}
                pdfName={fileName}
            />
        </>
    )
}
