export const sumPesos = objetivos => objetivos.reduce((sum, objetivo) => sum + parseFloat(objetivo.peso), 0);

export const checkForErrors = (objetivos) => {
    if (!Array.isArray(objetivos) || objetivos.length <= 0)
        return 'No hay ningún objetivo para este vendedor';

    let pesoTotal = sumPesos(objetivos);
    if (pesoTotal !== 100)
        return `El peso de los objetivos suma ${pesoTotal}%. Debe sumar 100%`;

    for (let i in objetivos) {
        let error = checkForErrorsObjetivo(objetivos[i]);
        if (error)
            return 'Algunos objetivos tienen errores'
    }

    return false;
}

export const checkForErrorsObjetivo = ({tipoobj, tipoobj_nombre, peso, subobjetivos}) => {
    const sumPesos = subobjetivos => subobjetivos.reduce((sum, subobjetivo) => sum + parseFloat(subobjetivo.peso), 0);

    if (typeof tipoobj == "undefined")
        return 'El tipo de objetivo no es válido';

    if (typeof tipoobj_nombre !== 'string' || tipoobj_nombre.length <= 0)
        return 'El nombre del objetivo no es válido';

    const pPeso = parseFloat(peso);
    if (typeof pPeso < 0 || pPeso > 100)
        return 'El peso del objetivo no es válido';

    if (!Array.isArray(subobjetivos) || subobjetivos.length <= 0)
        return 'No hay ningún subobjetivo cargado';

    if (sumPesos(subobjetivos) !== 100)
        return 'El peso de los subobjetivos no suma 100%';

    return false;
}
