import {useHistory} from "react-router-dom";
import {useRouteMatch} from "react-router";
import React, {useEffect, useState} from "react";
import {useHojasDeRutaService} from "services/hooks";
import {useSnackbar} from "notistack";
import {CardHoja} from "components/picking/components/card.hoja";
import {CardPedido} from "components/picking/components/card.pedido";
import {CardHeader} from "components/picking/components/card.header";

export function SelectPedidoView() {
    const [pedidos, setPedidos] = useState([]);

    const history = useHistory();
    const match = useRouteMatch();
    const {enqueueSnackbar} = useSnackbar();
    const HojasDeRutaService = useHojasDeRutaService();

    useEffect(() => {
        HojasDeRutaService.getPedidosById(
            match.params.idHojaDeRuta,
            response => setPedidos(response.data.result),
            error => enqueueSnackbar(error, {variant: 'error'})
        );
    }, []);

    const navToScan = id => {
        history.push(`${match.url}/${id}`);
    }

    return (
        <div>
            <CardHeader
                textLeft={'Hoja de Ruta: ' + match.params.idHojaDeRuta}
                textRight={pedidos.length + ' pedidos'}
            />
            {pedidos.map((pedido, index) =>
                <CardPedido
                    key={index}
                    id={pedido.id}
                    fecha={pedido.fecha}
                    cliente={pedido.cliente}
                    onClick={() => navToScan(pedido.id)}
                />
            )}
        </div>
    )
}
