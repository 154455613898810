import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {Clientes} from "./clientes/clientes";
import {Cotizaciones} from "./cotizaciones/cotizaciones";
import {Pedidos} from "./pedidos/pedidos";
import {Facturas} from "./facturas/facturas";
import {Remitos} from "./remitos/remitos";
import {NotasCredito} from "./notasCredito/notasCredito";
import {Cobranzas} from "./cobranzas/cobranzas";
import {Afip} from "./afip/afip";
import {MainContext} from "contexts/main.context";

const basePath = '/ventas';

export class Ventas extends Component {
    componentDidMount() {
        this.context.setHeaderTitle('Ventas');
    }

    render() {
        return (
            <div className='niquel-ventas'>
                <Switch>
                    <Route path={`${basePath}/clientes`} component={Clientes}/>
                    <Route path={`${basePath}/cotizaciones`} component={Cotizaciones}/>
                    <Route path={`${basePath}/pedidos`} component={Pedidos}/>
                    <Route path={`${basePath}/facturas`} component={Facturas}/>
                    <Route path={`${basePath}/remitos`} component={Remitos}/>
                    <Route path={`${basePath}/notasCredito`} component={NotasCredito}/>
                    <Route path={`${basePath}/cobranzas`} component={Cobranzas}/>
                    <Route path={`${basePath}/afip`} component={Afip}/>
                </Switch>
            </div>
        )
    }
}

Ventas.contextType = MainContext;
