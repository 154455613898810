import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";
import {Typography} from "@material-ui/core/index";

export class ObservaDialog extends Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onCancel}
                fullScreen={this.props.fullScreen}
                disableBackdropClick
                disableEscapeKeyDown
                fullWidth
                classes={{
                    paperFullScreen: 'niquel-dialog-full-screen'
                }}
            >
                <ObservaDialogContent {...this.props}/>
            </Dialog>
        )
    }
}

class ObservaDialogContent extends Component {
    MAX_CHARS = 50;

    state = {
        msg: this.props.observa,
        chars_left: this.MAX_CHARS
    }

    onChangeMsg = e => {
        const chars_left = this.MAX_CHARS - e.target.value.length;

        if (chars_left >= 0)
            this.setState({msg: e.target.value, chars_left: chars_left});
    }

    onConfirm = () => {
        this.props.onConfirm(this.state.msg);
        this.props.onCancel();
    }

    render() {
        const {onCancel} = this.props;
        const {msg, chars_left} = this.state;

        return (
            <React.Fragment>
                <DialogTitle id="alert-dialog-title">
                    <Typography variant={"h6"}>Observación general</Typography>
                    <Typography variant={"body2"} color={"textSecondary"}>
                        Es una leyenda que aparecerá en las pantallas de edición y visualización.<br/>
                        Será visible para todos los usuarios de Niquel, no así para los clientes.
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        fullWidth
                        autoFocus
                        label={"Observación"}
                        value={msg}
                        onChange={this.onChangeMsg}
                        helperText={`Caracteres restantes permitidos (${chars_left})`}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        color={"primary"}
                        onClick={this.onConfirm}
                    >
                        Confirmar
                    </Button>
                </DialogActions>
            </React.Fragment>
        )
    }
}
