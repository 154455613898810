import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@material-ui/core";

export class ConfirmDialog extends Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onCancel}
                fullScreen={this.props.fullScreen}
                classes={{
                    paperFullScreen: 'niquel-dialog-full-screen'
                }}
            >
                <ConfirmDialogContent {...this.props}/>
            </Dialog>
        )
    }
}

class ConfirmDialogContent extends Component {
    render() {
        const {operation, vehiculo, chofer, pedidos, onConfirm, onCancel} = this.props;

        const litros = pedidos.reduce((litros, pedido) => litros += pedido.litros, 0);

        return (
            <React.Fragment>
                <DialogTitle id="alert-dialog-title">{operation === 'CREATE' ? '¿Generar nueva hoja de ruta?' : '¿Editar hoja de ruta?'}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" component={'div'}>
                        {litros > vehiculo.capacidad && (
                            <Typography variant={"body2"} className={'mb-3'}>
                                <b className={'text-danger'}>ATENCIÓN: </b>
                                la cantidad de litros seleccionada supera la capacidad del camión
                            </Typography>
                        )}
                        <Typography variant={"body2"}>
                            Estás por {operation === 'CREATE' ? 'generar una nueva' : 'editar una'} hoja de ruta para el vehículo <b>{vehiculo.description}</b> y
                            chofer <b>{chofer.description}</b> por un total de <b>{pedidos.length} pedidos </b>
                            ({litros} / {vehiculo.capacidad} lts)
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/*Muestro Cancel*/}
                    {onCancel && (
                        <Button onClick={onCancel} color="primary">
                            Cancelar
                        </Button>
                    )}

                    {/*Muestro Confirm*/}
                    {onConfirm && (
                        <Button onClick={onConfirm} color={"primary"} autoFocus>
                            Confirmar
                        </Button>
                    )}
                </DialogActions>
            </React.Fragment>
        )
    }
}
