import React, {Component, useEffect, useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItem, ListItemText, Typography} from "@material-ui/core";
import {formatPrice} from "assets/utils";
import {useUtilsService} from "services/hooks";
import {useSnackbar} from "notistack";
import MomentUtils from "@date-io/moment";

export class ImpagosDialog extends Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onCancel}
                fullScreen={this.props.fullScreen}
                classes={{
                    paperFullScreen: 'niquel-dialog-full-screen'
                }}
            >
                <ImpagosDialogContent {...this.props} />
            </Dialog>
        )
    }
}

const ImpagosDialogContent = ({cliente, fechaRecibo, onClose}) => {
    const UtilsService = useUtilsService();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [documentosImpagos, setDocumentosImpagos] = useState([]);

    useEffect(() => {
        UtilsService.getDocumentosImpagos(
            cliente.id,
            response => {
                const documentos = response.data.result;
                documentos.forEach(documento => {
                    const ingreso = (new MomentUtils()).parse(documento.ingreso, 'D/MM/Y');
                    documento.dias = fechaRecibo.diff(ingreso, 'days');
                });

                setDocumentosImpagos(documentos);
            },
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }, []);

    return (
        <>
            <DialogTitle id="alert-dialog-title">Documentos Impagos</DialogTitle>

            <DialogContent style={{minWidth: '450px'}}>
                {documentosImpagos.length > 0 && (
                    <React.Fragment>
                        <DialogContentText>del cliente {cliente.razsoc} ({cliente.id})</DialogContentText>
                        <List dense>
                            {documentosImpagos.map(documento => (
                                <ListItem key={documento.id}>
                                    <ListItemText
                                        primary={`${documento.tipocompdocu} ${documento.numcompdocu}`}
                                        secondary={`${documento.ingreso} (${documento.dias} días)`}
                                        // secondary={`${documento.ingreso}`}
                                        primaryTypographyProps={{variant: "body2", color: "textSecondary"}}
                                        secondaryTypographyProps={{variant: 'body2', color: "textPrimary"}}
                                    />
                                    <ListItemText
                                        primary={`Pendiente: ${formatPrice(documento.pendiente, true)}`}
                                        secondary={`Total: ${formatPrice(documento.importedocu, true)}`}
                                        primaryTypographyProps={{variant: "body2", color: "textPrimary"}}
                                        secondaryTypographyProps={{variant: 'body2', color: "textSecondary"}}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </React.Fragment>
                )}

                {documentosImpagos.length <= 0 && (
                    <Typography variant={"body2"} align={"center"}>No hay documentos impagos</Typography>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cerrar
                </Button>
            </DialogActions>
        </>
    )
}
