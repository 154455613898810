import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {HojasDeRuta} from "./hojasDeRuta/hojasDeRuta";
import {Entregas} from "./entregas/entregas";
import {MainContext} from "contexts/main.context";
import {PedidosLibres} from "./pedidosLibres/pedidosLibres";

const basePath = '/despacho';

export class Despacho extends Component {
    componentDidMount() {
        this.context.setHeaderTitle('Despacho');
    }

    render() {
        return (
            <div className='niquel-despacho'>
                <Switch>
                    <Route path={`${basePath}/hojasDeRuta`} component={HojasDeRuta}/>
                    <Route path={`${basePath}/pedidosLibres`} component={PedidosLibres}/>
                    <Route path={`${basePath}/entregas`} component={Entregas}/>
                </Switch>
            </div>
        )
    }
}

Despacho.contextType = MainContext;
