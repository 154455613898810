import Accordion from "@material-ui/core/Accordion";
import {Divider, Grid} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {AddSubObjetivoDialog} from "components/objetivos/general/configuracion/dialogs/add.subObjetivo.dialog";
import {DeleteObjetivoDialog} from "components/objetivos/general/configuracion/dialogs/delete.objetivo.dialog";
import {ObjetivoSummary} from "components/objetivos/general/configuracion/objetivo.summary";
import {ObjetivoDetails} from "components/objetivos/general/configuracion/objetivo.details";
import {ObjetivoActions} from "components/objetivos/general/configuracion/objetivo.actions";
import {useObjetivosService} from "services/hooks";
import {useSnackbar} from "notistack";

export const Objetivo = ({
                             idobjetivo,
                             tipoobj,
                             tipoobj_nombre,
                             nombre,
                             idproveed,
                             peso,
                             subobjetivos,
                             tiposSubObjetivo,
                             codigosSubObjetivo,
                             onUpdateObjetivo,
                             checkForErrorsObjetivo
                         }) => {
    const [addSubObjetivoOpen, setAddSubObjetivoOpen] = useState(false);
    const [deleteObjetivoOpen, setDeleteObjetivoOpen] = useState(false);
    const [errorState, setErrorState] = useState(false);

    const ObjetivosService = useObjetivosService();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    useEffect(() => {
        const thereIsAnError = checkForErrorsObjetivo({tipoobj, tipoobj_nombre, peso, subobjetivos});
        setErrorState(thereIsAnError);
    }, [tipoobj, tipoobj_nombre, peso, subobjetivos]);

    const deleteObjetivo = () => {
        ObjetivosService.deleteObjetivo(
            idobjetivo,
            response => {
                enqueueSnackbar("Se eliminó correctamente el objetivo", {variant: 'success'})
                onUpdateObjetivo();
            },
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }

    const editPesoGeneral = (pesoGeneral) => {
        ObjetivosService.editPesoGeneral(
            idobjetivo,
            pesoGeneral,
            response => {
                enqueueSnackbar("Se editó correctamente el peso general objetivo", {variant: 'success'})
                onUpdateObjetivo();
            },
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }

    const deleteSubObjetivo = idSubobj => {
        ObjetivosService.deleteSubObjetivo(
            idobjetivo,
            idSubobj,
            response => {
                enqueueSnackbar("Se eliminó correctamente el subobjetivo", {variant: 'success'})
                onUpdateObjetivo();
            },
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }

    const addSubObjetivo = (tipo, codigo, valor, unidad, peso) => {
        ObjetivosService.addSubObjetivo(
            idobjetivo,
            tipo,
            codigo,
            valor,
            unidad,
            peso,
            response => {
                enqueueSnackbar("El nuevo subobjetivo se agregó correctamente", {variant: 'success'})
                onUpdateObjetivo();
            },
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }

    return (
        <>
            <Grid container spacing={2} alignItems={"center"}>
                <Grid item sm={12}>
                    <Accordion
                        elevation={0}
                        className={'border'}
                        square
                    >
                        <ObjetivoSummary
                            tipo={tipoobj_nombre}
                            nombre={nombre}
                            peso={peso}
                            cantSubObjetivos={subobjetivos.length}
                            errorState={errorState}
                            onEditPesoGeneral={editPesoGeneral}
                        />
                        <ObjetivoDetails
                            subobjetivos={subobjetivos}
                            showAddSubObjetivo={![1].includes(tipoobj)}
                            onAddSubObjetivo={() => setAddSubObjetivoOpen(true)}
                            onDeleteSubObjetivo={deleteSubObjetivo}
                        />
                        <Divider/>
                        <ObjetivoActions
                            onDelete={() => setDeleteObjetivoOpen(true)}
                        />
                    </Accordion>
                </Grid>
            </Grid>
            <AddSubObjetivoDialog
                open={addSubObjetivoOpen}
                tipoobj={tipoobj}
                tiposSubObjetivo={
                    tiposSubObjetivo
                        .filter(tipo => [9999, tipoobj].includes(tipo.tipoobj))
                        .filter(tipo => ['', idproveed].includes(tipo.proveed))
                }
                codigosSubObjetivo={codigosSubObjetivo}
                onConfirm={(tipo, codigo, valor, unidad, peso) => {
                    setAddSubObjetivoOpen(false);
                    addSubObjetivo(tipo, codigo, valor, unidad, peso);
                }}
                onCancel={() => setAddSubObjetivoOpen(false)}
            />
            <DeleteObjetivoDialog
                open={deleteObjetivoOpen}
                onDelete={() => {
                    setDeleteObjetivoOpen(false);
                    deleteObjetivo(idobjetivo);
                }}
                onCancel={() => setDeleteObjetivoOpen(false)}
                tipoobj_nombre={tipoobj_nombre}
                nombre={nombre}
                subobjetivos={subobjetivos}
            />
        </>
    )
}
