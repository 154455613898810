import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent} from "@material-ui/core";
import CheckCircleOutlineRounded from "@material-ui/icons/CheckCircleOutlineRounded";
import {Grid, Typography} from "@material-ui/core/index";
import {HighlightOffRounded, WarningRounded} from "@material-ui/icons";

export class ScanResultDialog extends Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onCancel}
                fullScreen={this.props.fullScreen}
                keepMounted={this.props.keepMounted}
                maxWidth={this.props.maxWidth}
                fullWidth={this.props.fullWidth}
                classes={{
                    paper: this.props.scanResult.found ?
                        this.props.scanResult.articulo.scanned > 1 ?
                            'niquel-dialog-yellow' :
                            'niquel-dialog-green' :
                        'niquel-dialog-red',
                    paperFullScreen: 'niquel-dialog-full-screen',
                }}
            >
                <ArticuloFoundDialogContent {...this.props}/>
            </Dialog>
        )
    }
}

class ArticuloFoundDialogContent extends Component {
    render() {
        const {
            scanResult,
            foundText = undefined,
            notFoundText = "No recolectar unidades",
            onConfirm,
            onCancel,
            confirmText = "Confirmar",
            cancelText = "Cancelar"
        } = this.props;

        return (
            <React.Fragment>
                <DialogContent>
                    <Grid container className={'h-100'} direction={"column"} alignItems={"center"} justifyContent={"space-evenly"}>
                        <Grid item className={'d-flex'}>
                            {scanResult.found ?
                                scanResult.articulo.scanned > 1 ?
                                    <WarningRounded style={{color: 'white', fontSize: '80'}}/> :
                                    <CheckCircleOutlineRounded style={{color: 'white', fontSize: '80'}}/> :
                                <HighlightOffRounded style={{color: 'white', fontSize: '80'}}/>
                            }
                        </Grid>
                        <Grid item>
                            <Typography
                                align={"center"}
                                variant={"h6"}
                                style={{color: 'white'}}
                                className={'mb-5'}
                            >
                                {scanResult.scannedCode || 'CÓDIGO INVÁLIDO'}
                            </Typography>
                            <Typography
                                align={"center"}
                                variant={"h6"}
                                style={{color: 'white'}}
                            >
                                {scanResult.articulo ? scanResult.articulo.id : ''}
                            </Typography>
                            <Typography
                                align={"center"}
                                variant={"body1"}
                                style={{color: 'white'}}
                            >
                                {scanResult.articulo ? scanResult.articulo.detalle : 'No hay detalles del artículo escaneado'}
                            </Typography>
                        </Grid>
                        {/*<Grid item>*/}
                        {/*    <Typography variant={"h6"} style={{color: 'white'}}>Pertenece a la hoja de ruta</Typography>*/}
                        {/*</Grid>*/}
                        <Grid item>
                            <Typography
                                className={'mt-4'}
                                style={{color: 'white'}}
                                variant={"h6"}
                                align={"center"}
                            >
                                {scanResult.found ?
                                    <>
                                        {
                                            foundText ? (
                                                <>
                                                    {foundText}
                                                </>
                                            ) : <>
                                                Recolectar <Typography variant={"h4"} component={"span"}>
                                                <b>{scanResult.articulo ? scanResult.articulo.cantidad : '0'}</b>
                                                </Typography> {scanResult.articulo && parseInt(scanResult.articulo.cantidad) === 1 ? 'unidad' : 'unidades'}
                                                {scanResult.articulo.scanned > 1 && <Typography align={"center"}>(Este artículo ya fue escaneado anteriormente)</Typography>}
                                            </>
                                        }
                                    </> :
                                    <>
                                        {notFoundText}
                                    </>
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {/*Muestro Cancel*/}
                    {onCancel && (
                        <Button onClick={onCancel} fullWidth variant={"contained"}>
                            {cancelText}
                        </Button>
                    )}

                    {/*Muestro Confirm*/}
                    {onConfirm && (
                        <Button onClick={onConfirm} style={{color: 'white', borderColor: 'white', height: '3.5rem'}} fullWidth variant={"outlined"}>
                            {confirmText}
                        </Button>
                    )}
                </DialogActions>
            </React.Fragment>
        )
    }
}
