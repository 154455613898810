import React, {Component} from 'react'
import {Box} from "@material-ui/core/index";
import {PedidoStatusFormatter, sortDate, sortNumeric} from 'assets/utils';
import {MainContext} from "contexts/main.context";
import {ReactGrid} from "components/helpers/reactGrid/reactGrid";
import {InfoButton} from "components/despacho/hojasDeRuta/operation/pedido.details";

export class List extends Component {
    state = {
        columns: [
            {name: 'id', title: 'ID Pedido'},
            {name: 'ingreso', title: 'Ingreso'},
            {name: 'entrega', title: 'Entrega'},
            {name: 'cliente', title: 'Cliente'},
            {name: 'importe_bonificado', title: 'Importe'},
            {name: 'custom1', title: 'Estado'},
            {name: 'custom2', title: ' '},
            {name: 'custom3', title: ' '},
        ],
        columnExtensions: [
            {columnName: 'id', width: 120, compare: sortNumeric, direction: 'desc'},
            {columnName: 'ingreso', compare: sortDate, align: 'center'},
            {columnName: 'entrega', compare: sortDate, align: 'center'},
            {columnName: 'importe_bonificado', type: 'currency', compare: sortNumeric, align: 'right'},
            {columnName: 'custom1', width: 250, component: PedidoStatusFormatter, align: 'center'},
            {columnName: 'custom2', width: 50, component: InfoButton, align: 'center', sortingEnabled: false},
            {columnName: 'custom3', type: 'icon', component: this.props.actionButton, sortingEnabled: false, width: 70},
        ],
        dialogs: {
            delete: false,
            notification: false,
            status: false
        },
    };

    componentDidMount() {
        this.props.getData();
    }

    openDialog = (dialog) => this.setState(prevState => prevState.dialogs[dialog] = true);
    closeDialog = (dialog) => this.setState(prevState => prevState.dialogs[dialog] = false);

    render() {
        const {columns, columnExtensions} = this.state;
        const {gridTitle, gridRows} = this.props;

        return (
            <Box className='container-xl mt-3 mb-3'>
                <ReactGrid
                    title={`${gridTitle} (${gridRows.length})`}
                    height={-250}
                    columns={columns}
                    columnExtensions={columnExtensions}
                    rows={gridRows}
                    showFilters={false}
                />
            </Box>
        )
    }
}

List.contextType = MainContext;
