import {CardChart} from "../card.chart";
import TextField from "@material-ui/core/TextField";
import {TIPOS_GRAFICO} from "../tipos.grafico";
import MenuItem from "@material-ui/core/MenuItem";
import {RankingVendedoresChart} from "../charts/ranking.vendedores.chart";
import React, {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {useEstadisticasService} from "../../../../../services/hooks";
import {imageChartToPDF, tableChartToPDF} from "../../../../../assets/utils";

export const RankingVendedoresCard = ({settingsState, printRef}) => {
    const [rankingVendedores, setRankingVendedores] = useState([]);
    const [tipoRankingVendedores, setTipoRankingVendedores] = useState(TIPOS_GRAFICO[0]);

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const EstadisticasService = useEstadisticasService();

    const chartTitle = 'Ranking Vendedores';
    const chartSubtitle = tipoRankingVendedores.description;

    useEffect(() => {
        EstadisticasService.getRankingVendedores(
            settingsState,
            success => setRankingVendedores(success.data.result),
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }, [settingsState]);

    return (
        <CardChart
            title={chartTitle}
            subtitle={chartSubtitle}
            rightContent={
                <TextField
                    select
                    value={tipoRankingVendedores}
                    onChange={event => setTipoRankingVendedores(event.target.value)}
                >
                    {TIPOS_GRAFICO.map((tipo, index) => (
                        <MenuItem key={index} value={tipo}>{tipo.description}</MenuItem>
                    ))}
                </TextField>
            }
            printRef={printRef}
            exportFn={imageChartToPDF.bind(null, printRef, chartTitle, chartSubtitle, settingsState.dates)}
        >
            <RankingVendedoresChart
                data={rankingVendedores}
                height={420}
                id={'nombre'}
                value={tipoRankingVendedores.porc_id}
            />
        </CardChart>
    )
}
