import React from 'react';
import classNames from 'classnames';
import {Badge, Button, Dialog, DialogActions, DialogContent, DialogTitle, Tab, Tabs, Typography, withMobileDialog, withStyles} from '@material-ui/core';
import SwipeableViews from 'react-swipeable-views';
import ArticulosTable from './articulos.table';
import ArticuloEditFields from 'components/helpers/articulos/articulo.edit.fields';
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";

const styles = {
    itemList: {
        overflowY: 'auto',
        maxHeight: '350px'
    }
};

const getInitialState = () => ({
    tabActive: 0,
    articulosSelectedId: [],
    articulosSelectedData: [],
    bIsValidSelection: true
});

class ArticulosAddDialog extends React.Component {
    state = getInitialState();

    changeTab = (event, value) => this.setState({tabActive: value});

    changeTabIndex = index => this.setState({tabActive: index});

    changeArticulosSelection = (articulosSelectedId, articulosSelectedData) => this.setState(prevState => {
        // Busco en las nueva selección algún artículo que existiera anteriormente y los mergeo.
        // De esa forma si ya habían modificado alguno se mantiene la modificación.
        for (let i in articulosSelectedData) {
            if (prevState.articulosSelectedId.find(id => id === articulosSelectedData[i].id)) {
                const articuloPrevIndex = prevState.articulosSelectedId.findIndex(id => id === articulosSelectedData[i].id);
                articulosSelectedData[i] = {...prevState.articulosSelectedData[articuloPrevIndex]};
            }
        }

        prevState.articulosSelectedId = articulosSelectedId;
        prevState.articulosSelectedData = articulosSelectedData;
        prevState.bIsValidSelection = prevState.articulosSelectedData.every(articulo => articulo.isValid !== false);
        return prevState;
    });

    removeArticuloSelection = id => this.setState(prevState => {
        prevState.articulosSelectedId = prevState.articulosSelectedId.filter(_id => _id !== id);
        prevState.articulosSelectedData = prevState.articulosSelectedData.filter(articuloData => articuloData.id !== id);
        prevState.bIsValidSelection = prevState.articulosSelectedData.every(articulo => articulo.isValid !== false);
        return prevState;
    });

    editArticulo = (index, oArticulo) => {
        this.setState(prevState => {
            prevState.articulosSelectedData[index] = {...prevState.articulosSelectedData[index], ...oArticulo};
            prevState.bIsValidSelection = prevState.articulosSelectedData.every(articulo => articulo.isValid !== false);
            return prevState;
        });
    };

    onClose = () => {
        this.props.onClose();
        this.setState(getInitialState());
    };

    onConfirm = () => this.props.onConfirm(this.state.articulosSelectedData, () => this.setState(getInitialState()));

    render() {
        const {classes, theme, open, fullScreen, articulos, listaprecio, mode, depositos, setDefaultsArticulo} = this.props;
        const {tabActive, articulosSelectedId, articulosSelectedData, bIsValidSelection} = this.state;

        return (
            <Dialog
                fullScreen={fullScreen}
                open={open}
                onClose={this.onClose}
                maxWidth={"md"}
                fullWidth={true}
                classes={{
                    paperFullScreen: 'niquel-dialog-full-screen'
                }}
            >
                <DialogTitle>
                    Agregar artículos <Typography variant="body1" color="textSecondary">Seleccione los artículos que desea agregar y configure sus valores</Typography>
                </DialogTitle>
                <DialogContent className='pb-0'>
                    <Tabs
                        value={tabActive}
                        onChange={this.changeTab}
                        indicatorColor="primary"
                        textColor="primary"
                        variant={"fullWidth"}
                    >
                        <Tab label="Artículos"/>
                        <Tab label={
                            <Badge color="secondary" className='pr-2' badgeContent={articulosSelectedData.length}>
                                Valores
                            </Badge>
                        }/>
                    </Tabs>
                    <SwipeableViews
                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                        index={tabActive}
                        onChangeIndex={this.changeTabIndex}
                    >
                        <div className='pb-2'>
                            <ArticulosTable
                                articulos={articulos}
                                articulosSelectedId={articulosSelectedId}
                                listaprecio={listaprecio}
                                changeArticulosSelection={this.changeArticulosSelection}
                                setDefaultsArticulo={setDefaultsArticulo}
                            />
                        </div>
                        <div className={classNames(classes.itemList, 'pt-3 d-flex h-100 justify-content-center')}>
                            {articulosSelectedData.length > 0 && (
                                <Box className='w-100 ml-3 mr-3'>
                                    {articulosSelectedData.map((articulo, index) => (
                                        <div key={index}>
                                            <Box className='mb-4 mt-4 p-2 rounded' style={{backgroundColor: 'rgb(249, 249, 249)'}}>
                                                <ArticuloEditFields
                                                    key={index}
                                                    id={articulo.id}
                                                    armado={articulo.armado}
                                                    fromArmado={articulo.fromArmado}
                                                    detalle={articulo.detalle}
                                                    cantidad={articulo.cantidad}
                                                    precio={articulo.precio}
                                                    bonificacion={articulo.bonificacion}
                                                    bonificacion_cliente={articulo.bonificacion_cliente}
                                                    deposito={articulo.deposito}
                                                    afacturar={articulo.afacturar}
                                                    adescargar={articulo.adescargar}
                                                    mode={mode}
                                                    depositos={depositos}
                                                    onEditArticulo={articulo => this.editArticulo(index, articulo)}
                                                    onRemoveArticulo={this.removeArticuloSelection}
                                                    showDelete={true}
                                                />
                                            </Box>
                                            {index !== articulosSelectedData.length - 1 && (
                                                <Divider/>
                                            )}
                                        </div>
                                    ))}
                                </Box>
                            )}

                            {articulosSelectedData.length < 1 && (
                                <Typography variant={"body2"} className='text-center align-self-center'>
                                    Seleccione un artículo para configurar sus valores
                                </Typography>
                            )}
                        </div>
                    </SwipeableViews>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onClose} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={this.onConfirm}
                        color="primary"
                        autoFocus
                        disabled={articulosSelectedData.length < 1 || !bIsValidSelection}
                    >
                        Agregar
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default withMobileDialog()(withStyles(styles, {withTheme: true})(ArticulosAddDialog));
