import React, {Component} from "react";
import PropTypes from "prop-types";
import {AppBar, Grid, MenuItem, TextField, Toolbar, Tooltip, Typography} from "@material-ui/core";
import './subHeader.styles.scss'
import {IconButton} from "@material-ui/core/index";
import {NavigateBeforeRounded, NavigateNextRounded, SkipNextRounded, SkipPreviousRounded} from "@material-ui/icons";
import Paper from "@material-ui/core/Paper";

const NavItemsSelect = ({title, selectedItem, items, onChangeItem, descriptionField, descriptionSecondaryField}) => {
    return (
        <>
            <Typography variant={"caption"} component={'div'} className='text-light'>{title}</Typography>
            <TextField
                select
                value={selectedItem}
                onChange={event => onChangeItem(event.target.value)}
                classes={{
                    root: 'sel-options'
                }}
                SelectProps={{
                    classes: {
                        select: 'select',
                        icon: 'icon'
                    }
                }}
                InputProps={{
                    disableUnderline: true
                }}
            >
                {items.map((option, key) => (
                    <MenuItem key={key} value={option.id} className={'d-flex align-items-end'}>
                        {/*{optionsDescriptionField !== 'id' ? `${option.id} - ` : ''}*/}
                        {option[descriptionField]}
                        {descriptionSecondaryField && (
                            <Typography
                                variant={"caption"}
                                style={{
                                    opacity: '.8',
                                    marginLeft: '3px'
                                }}
                            >
                                {` — ${option[descriptionSecondaryField]}`}
                            </Typography>
                        )}
                    </MenuItem>
                ))}
            </TextField>
        </>
    );
}

const NavItemsButton = ({icon, tooltip, isDisabled, onClick}) => {
    const color = `rgba(255,255,255,${isDisabled ? '0.5' : '1'})`;
    return (
        <Tooltip title={tooltip}>
            <IconButton size={"medium"} style={{color}} disabled={isDisabled} onClick={onClick}>
                {icon}
            </IconButton>
        </Tooltip>
    )
};

const NavItemsButtons = ({items, selectedItem, onChangeItem, descriptionField}) => {
    if (items.length < 1)
        return '';

    const currentItemIndex = items.findIndex(item => item.id === selectedItem);
    const disablePrevious = currentItemIndex === (items.length - 1);
    const disableNext = currentItemIndex === 0;

    const firstIndex = items.length - 1;
    const previousIndex = currentItemIndex + 1;
    const nextIndex = currentItemIndex - 1;
    const lastIndex = 0;

    const tooltipFirst = (!disablePrevious) ? 'Primero: ' + items[firstIndex][descriptionField] : '';
    const tooltipPrevious = (!disablePrevious) ? 'Anterior: ' + items[previousIndex][descriptionField] : '';
    const tooltipNext = (!disableNext) ? 'Siguiente: ' + items[nextIndex][descriptionField] : '';
    const tooltipLast = (!disableNext) ? 'Último: ' + items[lastIndex][descriptionField] : '';

    const onNavIndex = index => onChangeItem(items[index].id);

    return (
        <>
            <NavItemsButton icon={<SkipPreviousRounded/>} tooltip={tooltipFirst} isDisabled={disablePrevious}
                            onClick={() => onNavIndex(firstIndex)}/>
            <NavItemsButton icon={<NavigateBeforeRounded/>} tooltip={tooltipPrevious} isDisabled={disablePrevious}
                            onClick={() => onNavIndex(previousIndex)}/>
            <NavItemsButton icon={<NavigateNextRounded/>} tooltip={tooltipNext} isDisabled={disableNext}
                            onClick={() => onNavIndex(nextIndex)}/>
            <NavItemsButton icon={<SkipNextRounded/>} tooltip={tooltipLast} isDisabled={disableNext}
                            onClick={() => onNavIndex(lastIndex)}/>
        </>
    )
}

export class SubHeader extends Component {
    render() {
        const {
            title, subtitle, cliente, optionsTitle, options, optionSelectedId, optionsDescriptionField = 'id',
            optionsDescriptionSecondaryField, onChangeOption
        } = this.props;

        return (
            <>
                <AppBar position="relative" elevation={0}>
                    <Toolbar variant="dense" color='primary' className='pl-4 pr-4'>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item md={4}>
                                <Typography variant="h5" component={'span'} align="left">{title}
                                    <Typography variant="body1" align="left"
                                                className='text-light'>{subtitle}</Typography>
                                </Typography>
                            </Grid>
                            <Grid item container direction={"row-reverse"} sm={8} spacing={3} alignItems={"center"}>
                                {options && (
                                    <>
                                        <Grid item>
                                            <NavItemsSelect
                                                title={optionsTitle}
                                                selectedItem={optionSelectedId}
                                                items={options}
                                                onChangeItem={onChangeOption}
                                                descriptionField={optionsDescriptionField}
                                                descriptionSecondaryField={optionsDescriptionSecondaryField}
                                            />
                                        </Grid>
                                    </>
                                )}

                                {cliente && (
                                    <Grid item>
                                        <Typography variant={"caption"} component={'div'} className='text-light'>
                                            Cliente {cliente.id} {cliente.inhabilitado ? ' (INHABILITADO)' : ''}
                                        </Typography>
                                        <Typography variant="body2" component={'div'}
                                                    align="right">{cliente.razsoc}</Typography>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                {options && (
                    <Paper style={{
                        position: 'fixed',
                        left: 0,
                        right: 0,
                        margin: '0 auto',
                        width: '193px',
                        bottom: '1.5rem',
                        zIndex: '1202',
                        background: 'rgba(90,90,90)',
                        borderRadius: '30px 30px'
                    }}>
                        <div className={'d-flex align-items-center'}>
                            <NavItemsButtons
                                title={optionsTitle}
                                selectedItem={optionSelectedId}
                                items={options}
                                onChangeItem={onChangeOption}
                                descriptionField={optionsDescriptionField}
                                descriptionSecondaryField={optionsDescriptionSecondaryField}
                            />
                        </div>
                    </Paper>
                )}
            </>
        )
    }
}

SubHeader.propTypes = {
    title: PropTypes.string.isRequired,
    cliente: PropTypes.object,
    optionsTitle: PropTypes.string,
    options: PropTypes.array,
    optionSelectedId: PropTypes.string,
    onChangeOption: PropTypes.func
};

export default SubHeader;
