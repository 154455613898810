import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent} from "@material-ui/core";
import {Grid, Typography} from "@material-ui/core/index";
import {HighlightOffRounded} from "@material-ui/icons";

export class ScanErrorDialog extends Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onCancel}
                fullScreen={this.props.fullScreen}
                keepMounted={this.props.keepMounted}
                maxWidth={this.props.maxWidth}
                fullWidth={this.props.fullWidth}
                classes={{
                    paper: 'niquel-dialog-red',
                    paperFullScreen: 'niquel-dialog-full-screen',
                }}
            >
                <ScanErrorDialogContent {...this.props}/>
            </Dialog>
        )
    }
}

class ScanErrorDialogContent extends Component {
    render() {
        const {scanResult, onConfirm, onCancel, confirmText = "Confirmar", cancelText = "Cancelar"} = this.props;

        return (
            <React.Fragment>
                {/*<DialogTitle id="alert-dialog-title">{title}</DialogTitle>*/}
                <DialogContent>
                    <Grid container className={'h-100'} direction={"column"} alignItems={"center"} justifyContent={"space-evenly"}>
                        <Grid item className={'d-flex'}>
                            <HighlightOffRounded style={{color: 'white', fontSize: '80'}}/>
                        </Grid>
                        <Grid item>
                            <Typography
                                align={"center"}
                                variant={"h6"}
                                style={{color: 'white'}}
                            >
                                {scanResult.scannedCode || 'CÓDIGO INVÁLIDO'}
                            </Typography>
                            <Typography
                                align={"center"}
                                variant={"body2"}
                                style={{color: 'white'}}
                            >
                                {scanResult.articulo ? scanResult.articulo.detalle : 'No hay detalles del artículo escaneado'}
                            </Typography>
                        </Grid>
                        {/*<Grid item>*/}
                        {/*    <Typography variant={"h6"} style={{color: 'white'}}>Pertenece a la hoja de ruta</Typography>*/}
                        {/*</Grid>*/}
                        <Grid item>
                            <Typography
                                className={'mt-5'}
                                style={{color: 'white'}}
                                variant={"h6"}
                            >
                                <Typography variant={"h6"} align={"center"}>Este artículo no pertenece al pedido en validación, revisar.</Typography>
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    {/*Muestro Cancel*/}
                    {onCancel && (
                        <Button onClick={onCancel} fullWidth variant={"contained"}>
                            {cancelText}
                        </Button>
                    )}

                    {/*Muestro Confirm*/}
                    {onConfirm && (
                        <Button onClick={onConfirm} style={{color: 'white', borderColor: 'white', height: '3.5rem'}} fullWidth variant={"outlined"}>
                            {confirmText}
                        </Button>
                    )}
                </DialogActions>
            </React.Fragment>
        )
    }
}
