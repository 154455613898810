import React, {Component} from 'react';
import {UtilsService} from 'services';
import {withSnackbar} from "notistack";
import {MainContext} from "contexts/main.context";
import {ItemList} from "./item";
import {ConfirmDialog} from "components/aprobaciones/recepciones/cobranzas/dialogs/confirm.dialog";
import {Box, Grid, IconButton, MenuItem, TextField, Tooltip} from "@material-ui/core";
import PrintIcon from "@material-ui/icons/PrintRounded";
import printJS from "print-js";
import MomentUtils from "@date-io/moment";
import {formatPrice} from "assets/utils";
import {RefreshRounded} from "@material-ui/icons";
import {RejectDialog} from "components/aprobaciones/recepciones/cobranzas/dialogs/reject.dialog";
import {InfoDialog} from "./dialogs/info.dialog";
import {TIPOS} from "./tipos";

export class Pendientes extends Component {
    state = {
        items: null,
        tmp_item: null,
        filter_tipo: 'todo',
        infoDialogOpen: false,
        confirmDialogOpened: false,
        rejectDialogOpen: false,
        tipos: []
    };

    UtilsService = new UtilsService(this.context);

    componentDidMount() {
        this.getRecepciones()
            .then(this.filterTipoRecepcion)
            .then(this.setPendientes)
    }

    onChangeFilter = (field, value) => this.setState({[field]: value});

    filterTipoRecepcion = () => new Promise((resolve, reject) => {
        if (this.props.tipoRecepcion === "cotizacion")
            var tipos = TIPOS.filter(tipo => tipo.id === "PREPAGO");
        else
            var tipos = TIPOS.filter(tipo => tipo.id !== "PREPAGO");

        // this.setState(prevState => prevState.filter_tipo = prevState.tipos[0].id);
        this.setState({tipos: tipos, filter_tipo: tipos[0].id}, resolve());
    });

    getRecepciones = () => new Promise((resolve, reject) => {
        this.UtilsService.getRecepcionesCobranza(
            {
                filter: 'PENDIENTES',
                sortBy: 'fecha',
                sortDir: 'ASC'
            },
            response => {
                this.setState({items: response.data.result}, resolve());
            },
            error => {
                this.props.enqueueSnackbar(error, {variant: 'error'});
                reject();
            }
        );
    });

    setPendientes = () => this.props.setPendientes(
        this.state.items.filter(item => item.recibido !== true && item.rechazado !== true && this.state.tipos.map(tipo => tipo.id).includes(item.tipo)).length
    );

    onUpdateItem = (anular = false, item, updateMessage = "") => {
        // Anulo la recepción
        item.anular = anular;
        item.updateMessage = updateMessage;

        // FIX 28/04/21: Si se anula un ítem no recibido se rechaza la recepción.
        if (item.recibido === false && anular === true)
            item.rechazado = true;

        this.UtilsService.updateRecepcionesCobranza(
            item,
            response => {
                this.setState(prevState => {
                    item.recibido = !anular;
                    return prevState;
                }, this.setPendientes);

                let status = item.rechazado ? 'rechazado' : anular ? 'no recibido' : 'recibido';

                this.props.enqueueSnackbar(`El ítem se marcó como ${status}`, {variant: 'success'})
                this.closeConfirmDialog();
                this.closeRejectDialog();
            },
            error => {
                this.props.enqueueSnackbar(error, {variant: 'error'});
                this.setPendientes();
            }
        )
    }

    openConfirmDialog = (item, index) => this.setState({confirmDialogOpened: true, tmp_item: item});
    closeConfirmDialog = () => this.setState({confirmDialogOpened: false});

    openRejectDialog = (item) => this.setState({tmp_item: item, rejectDialogOpen: true});
    closeRejectDialog = () => this.setState({rejectDialogOpen: false});
    onRejectItem = (rejectMessage) => this.onUpdateItem(true, this.state.tmp_item, rejectMessage);

    onInfoPress = item => this.setState({tmp_item: item, infoDialogOpen: true})
    closeInfoDialog = () => this.setState({infoDialogOpen: false});

    filterItems = (items, tipo) => {
        if (items) {
            const tipos = (tipo !== 'todo') ? [tipo] : this.state.tipos.map(tipo => tipo.id);
            items = items.filter(item => tipos.includes(item.tipo));
        }

        return items;
    }

    onPrint = () => {
        let tipoDesc = this.state.tipos.find(tipo => tipo.id === this.state.filter_tipo).name;
        let tipoFirstWord = tipoDesc.split(" ")[0];

        // Clono el array para modificar el formato del precio.
        let printable = [];
        let filteredItems = this.filterItems(this.state.items, this.state.filter_tipo);
        filteredItems.forEach(item => printable.push({...item}));
        printable.map(item => {
            item.importe = formatPrice(item.importe, true);
            item.cuenta_desc = item.cuenta_desc ? item.cuenta_desc : '';
        });

        printJS({
            printable: printable,
            type: 'json',
            properties: [
                {field: 'fecha', displayName: 'Fecha'},
                {field: 'importe', displayName: 'Importe'},
                {field: 'numero', displayName: 'Número'},
                {field: 'cuenta_desc', displayName: 'Cuenta'},
                {field: 'banco_desc', displayName: 'Banco'},
                {field: 'cliente', displayName: 'Cliente'},
                {field: 'vendedor', displayName: 'Vendedor'},
            ],
            documentTitle: 'Niquel_Recepciones_' + tipoFirstWord + '_' + (new MomentUtils()).date(new Date()).format("DD/M/Y"),
            style: `
                @import url('https://fonts.googleapis.com/css?family=Roboto');
                @font-face {
                  font-family: 'Roboto';
                  font-style: normal;
                  font-weight: 400;
                  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu72xKOzY.woff2) format('woff2');
                  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
                } 
                * {
                    font-family: "Roboto";
                }
                th,td {
                    text-align: center;
                    font-size: 14px
                }
                td {
                    font-size: 12px
                }
                td:nth-child(5), td:nth-child(6), td:nth-child(7) {
                    font-size: 10px
                }
            `,
            // gridHeaderStyle: 'border: none; align: center',
            // gridStyle: 'align: center',
            header: '<div style="margin-bottom: 20px">' +
                '<h4 align="center">Recepciones - ' + tipoDesc + ' (' + printable.length + ')' + '</h4>' +
                '</div>',
        })
    }

    render() {
        const {items, confirmDialogOpened, tmp_item, filter_tipo, infoDialogOpen, rejectDialogOpen} = this.state;
        let filteredItems = this.filterItems(items, filter_tipo);

        return (
            <Box className='container-xl mt-3 mb-3'>
                <Grid container spacing={2} alignItems={"center"} justifyContent={"space-between"}>
                    <Grid item>
                        <TextField
                            select
                            label="Tipo"
                            className='mr-2'
                            value={filter_tipo}
                            onChange={event => this.onChangeFilter('filter_tipo', event.target.value)}
                        >
                            {this.state.tipos.map(tipo => (
                                <MenuItem key={tipo.id} value={tipo.id}>
                                    {tipo.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                    <Grid item>
                        <Tooltip title={"Imprimir"}>
                            <IconButton onClick={this.onPrint}>
                                <PrintIcon/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={"Refrescar"}>
                            <IconButton onClick={this.getRecepciones}>
                                <RefreshRounded/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Box className={'mt-3'}>
                    <ItemList
                        items={filteredItems}
                        type={'PENDIENTE'}
                        noResultsText={'No hay items pendientes'}
                        onPrimaryAction={(item, index) => {
                            if (item.recibido)
                                this.onUpdateItem(true, item);
                            else
                                this.openConfirmDialog(item, index)
                        }}
                        // onSecondaryAction={this.openConfirmDialog}
                        onRejectItem={(item, index) => this.openRejectDialog(item)}
                        onInfoPress={this.onInfoPress}
                    />
                </Box>
                <ConfirmDialog
                    open={confirmDialogOpened}
                    item={tmp_item}
                    onClose={this.closeConfirmDialog}
                    onConfirm={item => this.onUpdateItem(false, item)}
                />
                <InfoDialog
                    open={infoDialogOpen}
                    item={tmp_item}
                    onClose={this.closeInfoDialog}
                />
                <RejectDialog
                    open={rejectDialogOpen}
                    title={'¿Seguro quiere rechazar el ítem?'}
                    onCancel={this.closeRejectDialog}
                    onConfirm={this.onRejectItem}
                    confirmText={'Rechazar'}
                />
            </Box>
        )
    }
}

Pendientes.contextType = MainContext;
Pendientes = withSnackbar(Pendientes);
