import Accordion from "@material-ui/core/Accordion";
import {AccordionSummary, Grid, LinearProgress, MenuItem, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography} from "@material-ui/core";
import {ExpandMoreRounded} from "@material-ui/icons";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import React, {useState} from "react";
import {formatPercentage, formatPrice, formatQuantity, InputPercentage} from "assets/utils";
import {_TIPOS} from "components/objetivos/general/configuracion/tipos.mock";
import Box from "@material-ui/core/Box";

const formatValor = (valor, unidad) => {
    let formatted;

    switch (unidad) {
        case 'QTY':
            formatted = valor;
            break;
        case 'IMP':
            formatted = formatPrice(valor, true);
            break;
        default:
            formatted = valor;
    }

    return formatted;
}

const formatUnidad = unidad => {
    let formatted;

    switch (unidad) {
        case 'QTY':
            formatted = 'unid.';
            break;
        case 'IMP':
            formatted = 'imp.';
            break;
        default:
            formatted = unidad;
    }

    return formatted;
}

const sumPesos = subobjetivos => subobjetivos.reduce((sum, subobjetivo) => sum + parseFloat(subobjetivo.peso), 0);

const PesoTableSummary = ({subobjetivos}) => {
    const pesosSum = sumPesos(subobjetivos);
    return (
        <TableRow>
            <TableCell colSpan={2} className={'border-0'}> </TableCell>
            <TableCell
                colSpan={1}
                align="right"
                className={pesosSum < 100 ? 'color-error font-weight-bold' : ''}
            >
                {pesosSum < 100 ? 'Porcentaje insuficiente' : ''} {pesosSum}%
            </TableCell>
        </TableRow>
    )
}

const ObjetivoSummary = ({tipo, nombre, peso, porcentaje_logrado, porcentaje_variacion, cantSubObjetivos, editing, errorState}) =>
    <AccordionSummary
        expandIcon={editing ? '' : <ExpandMoreRounded/>}
        focusVisibleClassName={'bg-white'}
        style={{
            pointerEvents: editing ? 'none' : 'all'
        }}
    >
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
            <Grid item>
                <Typography>
                    {editing ? (
                        <TextField
                            select
                            className={'mb-2'}
                            fullWidth={true}
                            label={"Tipo"}
                            style={{
                                width: '13rem',
                                pointerEvents: 'all'
                            }}
                            value={tipo}
                            onClick={e => e.stopPropagation()}
                            // onChange={event => this.props.onChangeTipo(item.id, event.target.value)}
                        >
                            {_TIPOS.map(option => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.nombre} - <Typography variant={"body2"} component={"span"} color={"textSecondary"}> - {option.tipo}</Typography>
                                </MenuItem>
                            ))}
                        </TextField>
                    ) : (
                        <>
                            {nombre} <Typography variant={"body2"} component={"span"} color={"textSecondary"}> - {tipo}</Typography>
                        </>
                    )}
                </Typography>
                <Typography variant={"body2"} color={"textSecondary"}>
                    {cantSubObjetivos} subobjetivos
                </Typography>
            </Grid>
            <Grid container item spacing={7} alignItems={"center"} alignContent={"center"} className={'w-auto'}>
                <Grid item>
                    <Typography variant={"caption"} color={"textSecondary"} component={"span"}>peso general</Typography>
                    <Typography component={"span"} className={'ml-1'}>{peso}%</Typography>
                </Grid>
                <Grid item>
                    {editing ? (
                        <TextField
                            value={porcentaje_logrado}
                            variant={"outlined"}
                            InputProps={{
                                inputComponent: InputPercentage,
                                style: {
                                    width: '4.3rem',
                                    pointerEvents: 'all'
                                },
                            }}
                            onClick={e => e.stopPropagation()}
                        />
                    ) : (
                        <>
                            <Box className={'d-flex justify-content-between align-items-center'}>
                                <Typography variant={"caption"} color={"textSecondary"}>Cumplimiento general</Typography>
                                <span>
                                    <Typography variant={"caption"} align={"right"}>{formatPercentage(porcentaje_logrado)}%</Typography>
                                    {/*<Tooltip title={'variación respecto al período anterior'}>*/}
                                    {/*    <Typography variant={"caption"} className={'ml-1'} color={porcentaje_variacion > 0 ? "primary" : "error"}>*/}
                                    {/*        ({(porcentaje_variacion > 0 ? "+" : "") + formatQuantity(porcentaje_variacion)}%)*/}
                                    {/*    </Typography>*/}
                                    {/*</Tooltip>*/}
                                </span>
                            </Box>
                            <LinearProgress
                                value={porcentaje_logrado}
                                variant={"determinate"}
                                style={{height: '22px', width: '200px'}}
                                color={porcentaje_logrado > 50 ? "primary" : "secondary"}
                            />
                        </>
                    )}
                </Grid>
                {/*<Grid item className={'d-flex'}>*/}
                {/*    {errorState === false ? (*/}
                {/*        <CheckCircleOutlineRounded style={{width: '1.7rem', height: '1.7rem'}} color={"primary"}/>*/}
                {/*    ) : (*/}
                {/*        <Tooltip title={errorState}>*/}
                {/*            <HighlightOffRounded style={{width: '1.7rem', height: '1.7rem'}} color={"error"}/>*/}
                {/*        </Tooltip>*/}
                {/*    )}*/}
                {/*</Grid>*/}
            </Grid>
        </Grid>
    </AccordionSummary>

const ObjetivoDetails = ({subobjetivos}) =>
    <AccordionDetails>
        <Table className={'ml-5 mr-5 mt-2 mb-5'} size={"small"}>
            <TableHead>
                <TableRow>
                    <TableCell>Tipo</TableCell>
                    <TableCell align="right">Valor Objetivo</TableCell>
                    <TableCell align="right">Peso</TableCell>
                    <TableCell align="right">Cumplimiento</TableCell>
                    <TableCell align="right"> </TableCell>
                    <TableCell align="right"> </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {subobjetivos.map((subobjetivo) => {
                    return (
                        <TableRow key={1}>
                            <TableCell component="th" scope="row">
                                {subobjetivo.nombre}
                                <Typography
                                    variant={"caption"}
                                    color={"textSecondary"}
                                    component={"span"}
                                > - {subobjetivo.tiposubobj_nombre}
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                {formatValor(subobjetivo.valor, subobjetivo.unidad)}
                                <Typography variant={"caption"} color={"textSecondary"}> {formatUnidad(subobjetivo.unidad)}</Typography>
                            </TableCell>
                            <TableCell align="right">{subobjetivo.peso}%</TableCell>
                            <TableCell align="right">
                                {formatValor(subobjetivo.valor_logrado, subobjetivo.unidad)}
                                <Typography variant={"caption"} color={"textSecondary"}> {formatUnidad(subobjetivo.unidad)}</Typography>
                            </TableCell>
                            <TableCell>
                                <LinearProgress
                                    value={subobjetivo.porcentaje_logrado}
                                    variant={"determinate"}
                                    className={'mr-2'}
                                    color={subobjetivo.porcentaje_logrado > 50 ? "primary" : "secondary"}
                                    style={{height: '15px', width: '200px', display: 'inline-block'}}
                                />
                                <Typography variant={"caption"}>
                                    <b>{formatPercentage(subobjetivo.porcentaje_logrado)}%</b>
                                </Typography>
                                {/*<Tooltip title={'variación respecto al período anterior'}>*/}
                                {/*    <Typography variant={"caption"} className={'ml-1'} color={subobjetivo.porcentaje_variacion > 0 ? "primary" : "error"}>*/}
                                {/*        ({(subobjetivo.porcentaje_variacion > 0 ? "+" : "") + formatQuantity(subobjetivo.porcentaje_variacion)}%)*/}
                                {/*    </Typography>*/}
                                {/*</Tooltip>*/}
                            </TableCell>
                            <TableCell align="right" width={'2.5rem'}>
                                {/*<Tooltip title={"Detalles"}>*/}
                                {/*    <IconButton style={{width: '1.7rem', height: '1.7rem'}}>*/}
                                {/*        <ZoomIn/>*/}
                                {/*    </IconButton>*/}
                                {/*</Tooltip>*/}
                            </TableCell>
                        </TableRow>
                    )
                })}
            </TableBody>
        </Table>
    </AccordionDetails>

export const Objetivo = ({tipoobj_nombre, nombre, peso, porcentaje_logrado, porcentaje_variacion, subobjetivos}) => {
    const [editing, setEditing] = useState(false);

    return (
        <>
            <Grid container spacing={2} alignItems={"center"}>
                <Grid item sm={12}>
                    <Accordion
                        elevation={0}
                        className={'border'}
                        square
                    >
                        <ObjetivoSummary
                            tipo={tipoobj_nombre}
                            nombre={nombre}
                            peso={peso}
                            porcentaje_logrado={porcentaje_logrado}
                            porcentaje_variacion={porcentaje_variacion}
                            cantSubObjetivos={subobjetivos.length}
                            editing={editing}
                        />
                        <ObjetivoDetails
                            subobjetivos={subobjetivos}
                        />
                    </Accordion>
                </Grid>
            </Grid>
        </>
    )
}
