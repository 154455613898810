import React, {Component} from 'react';
import {MainContext} from "contexts/main.context";
import SubHeader from "components/helpers/subHeader/subHeader";
import Box from "@material-ui/core/Box";
import {isMobile} from "react-device-detect";
import {CardPedido} from "components/despacho/entregas/pedidos/card.pedido";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import {Typography} from "@material-ui/core";
import HojasDeRutaService from "services/hojasDeRuta.service";
import {withSnackbar} from "notistack";

export class EntregasPedidos extends Component {
    HojasDeRutaService = new HojasDeRutaService(this.context);

    state = {
        hojaDeRuta: {},
        pedidos: []
    };

    componentDidMount() {
        const id_entrega = this.props.match.params.entrega;
        this.loadHojaDeRuta(id_entrega);
    }

    // Carga una hoja de ruta por id para visualizarla.
    loadHojaDeRuta = (id) => {
        this.HojasDeRutaService.getById(id,
            success => this.setState({hojaDeRuta: success.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.HojasDeRutaService.getPedidosById(id,
            success => this.setState({pedidos: success.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    changeTab = (event, value) => {
        this.setState({tabActive: value});
    };

    navDetalles = id => {
        this.props.history.push(`${this.props.match.url}/${id}/detalles`);
    }

    render() {
        const {hojaDeRuta, pedidos} = this.state;

        const procesados = pedidos.reduce((cant, pedido) => (pedido.estadio === 'E' || pedido.estadio === 'R') ? cant + 1 : cant, 0);

        const viewHeight = isMobile ? 'calc(100vh - 11.5rem)' : 'calc(100vh - 11rem)';
        return (
            <div>
                <SubHeader
                    title={'Entregas'}
                />
                <Box className={'m-4'}>
                    <Grid container direction={"column"} spacing={2}>
                        <Grid item>
                            <Typography variant={"h5"} align={"center"}>¡Entregando pedidos!</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={"body1"} align={"center"}
                                        color={"textSecondary"}>Entrega {hojaDeRuta.id} - {hojaDeRuta.vehiculo_desc} - {hojaDeRuta.litros} lts</Typography>
                        </Grid>
                        <Grid item>
                            <LinearProgress variant="determinate" value={(procesados) * 100 /(pedidos.length)}/>
                            <Typography variant={"caption"} color={"textSecondary"}>{procesados} de {pedidos.length} pedidos completados</Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box style={{height: viewHeight}}>
                    {pedidos.map((pedido, index) =>
                        <CardPedido
                            key={index}
                            id={pedido.id}
                            direccion={pedido.direccion_entrega}
                            cliente={pedido.cliente}
                            estado={pedido.estadio}
                            onClick={() => this.navDetalles(pedido.id)}
                        />
                    )}
                </Box>
            </div>
        )
    }
}

EntregasPedidos.contextType = MainContext;
EntregasPedidos = withSnackbar(EntregasPedidos);
