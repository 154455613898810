import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {EntregasMain} from "components/despacho/entregas/main/entregas.main";
import {EntregasPedidos} from "components/despacho/entregas/pedidos/entregas.pedidos";
import {EntregasDetalles} from "components/despacho/entregas/detalles/entregas.detalles";
import {EntregasEntregar} from "components/despacho/entregas/entregar/entregas.entregar";
import {EntregasRetornar} from "components/despacho/entregas/retornar/entregas.retornar";

const basePath = '/despacho/entregas';

export class Entregas extends Component {
    render() {
        return (
            <div className='niquel-entregas'>
                <Switch>
                    <Route path={`${basePath}/:entrega/pedidos/:pedido/retornar`} component={EntregasRetornar}/>
                    <Route path={`${basePath}/:entrega/pedidos/:pedido/entregar`} component={EntregasEntregar}/>
                    <Route path={`${basePath}/:entrega/pedidos/:pedido/detalles`} component={EntregasDetalles}/>
                    <Route path={`${basePath}/:entrega/pedidos`} component={EntregasPedidos}/>
                    <Route path={`${basePath}`} component={EntregasMain}/>
                </Switch>
            </div>
        )
    }
}
