import React, {useContext, useEffect, useState} from 'react'
import {Box, Grid} from "@material-ui/core/index";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import 'moment/locale/es';
import {ReactGrid} from "components/helpers/reactGrid/reactGrid";
import {CobranzaStatusFormatter, sortDate, sortNumeric} from "assets/utils";
import {Button, IconButton, MenuItem, TextField, Tooltip, Typography} from "@material-ui/core";
import {useSnackbar} from "notistack";
import {useCobranzasService, useListadosService, useVendedoresService} from "../../../../services/hooks";
import {MainContext} from "../../../../contexts/main.context";
import {SimpleDialog} from "../../../helpers/dialogs/simple.dialog";
import {AdditionalData} from "../../../helpers/cobranzas/additional.data.card";
import PermMediaRounded from "@material-ui/icons/PermMediaRounded";
import InfoIcon from "@material-ui/icons/InfoRounded";
import {CobranzasView} from "../../../ventas/cobranzas/view/cobranzas.view";
import {RefreshRounded} from "@material-ui/icons";
import {ExportOptions} from "../../../helpers/exportOptions/export.options";

const STATUSES = [{
    id: "A",
    desc: "Pendientes"
}, {
    id: "S",
    desc: "Aprobadas"
}, {
    id: "O",
    desc: "Observadas"
}]

export const Digital = () => {
    const [desde, setDesde] = useState((new MomentUtils()).date(new Date()).subtract(90, 'days'));
    const [hasta, setHasta] = useState((new MomentUtils()).date(new Date()));
    const [vendedor, setVendedor] = useState("todos");
    const [vendedores, setVendedores] = useState([]);
    const [status, setStatus] = useState("A");
    const [cobranzas, setCobranzas] = useState([]);
    const [selected, setSelected] = useState([]);
    const [comprobantesDialog, setComprobantesDialog] = useState({open: false});
    const [infoDialog, setInfoDialog] = useState({open: false});

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const VendedoresService = useVendedoresService();
    const ListadosService = useListadosService();
    const CobranzasService = useCobranzasService();
    const context = useContext(MainContext);

    const ApproveBtn = ({id}) =>
        <Button variant={"outlined"} color={"primary"} onClick={() => changeStatus(id, "S")} size={"small"}>
            Aprobar
        </Button>;

    const RejectBtn = ({id}) =>
        <Button variant={"outlined"} className={'border-error ml-3'} style={{color: 'red'}} onClick={() => changeStatus(id, "O")} size={"small"}>
            Rechazar
        </Button>;

    const CancelBtn = ({id}) =>
        <Button variant={"outlined"} className={'ml-3'} onClick={() => changeStatus(id, "A")} size={"small"}>
            Cancelar
        </Button>;

    const ComprobantesBtn = ({row}) =>
        <Tooltip title={'Comprobantes de la Cobranza'}>
            <IconButton onClick={() => setComprobantesDialog({open: true, ...row})}>
                <PermMediaRounded fontSize={"small"}/>
            </IconButton>
        </Tooltip>

    const InfoBtn = ({row}) =>
        <Tooltip title={'Detalles de la cobranza'}>
            <IconButton onClick={() => setInfoDialog({open: true, ...row})}>
                <InfoIcon fontSize={"small"}/>
            </IconButton>
        </Tooltip>

    const Custom = ({row}) => {
        switch (row.status) {
            case 'S':
                return <>
                    <Typography color={"primary"} variant={"body2"} className={'font-weight-bold'}>
                        Aprobada <CancelBtn id={row.id}/>
                    </Typography>
                </>
            case 'O':
                return <Typography color={"error"} variant={"body2"} className={'font-weight-bold'}>
                    Observada
                </Typography>
            default:
                return <><ApproveBtn id={row.id}/><RejectBtn id={row.id}/></>
        }
    }

    const COBRANZAS_TABLE = {
        columns: [
            {name: 'custom1', title: ' '},
            {name: 'id', title: 'ID'},
            {name: 'numrec', title: 'Recibo'},
            {name: 'fecharecibo', title: 'Fecha'},
            {name: 'razsoc', title: 'Cliente'},
            {name: 'vendedor', title: 'Vendedor'},
            {name: 'custom2', title: 'Estado'},
            {name: 'custom3', title: ' '},
            {name: 'custom4', title: ' '}
        ],
        columnExtensions: [
            {columnName: 'custom1', width: 50, component: InfoBtn, align: 'center'},
            {columnName: 'id', width: 70, compare: sortNumeric, direction: 'desc'},
            {columnName: 'numrec', width: 90, compare: sortNumeric},
            {columnName: 'fecharecibo', width: 100, compare: sortDate},
            // {columnName: 'vendedor', width: 200},
            {columnName: 'custom2', component: CobranzaStatusFormatter, align: 'center'},
            {columnName: 'custom3', width: 50, component: ComprobantesBtn, align: 'center'},
            {columnName: 'custom4', width: 220, component: Custom, align: 'center'},
        ]
    };

    const changeStatus = (idcobranza, action = "") => {
        CobranzasService.changeStatus(
            idcobranza,
            action,
            response => {
                getCobranzas();
            },
            error => enqueueSnackbar("Error al actualizar la cobranza", {variant: 'error'})
        )
    }

    const getCobranzas = () => {
        let vendedores = [];
        if (vendedor !== 'todos') vendedores.push(vendedor);

        CobranzasService.getFiltered({
                desde: desde.format('DD/MM/YY'),
                hasta: hasta.format('DD/MM/YY'),
                status: status,
                vendedores: vendedores,
                tipo: 'D',
                withDetails: true
            },
            response => setCobranzas(response.data.result),
            error => enqueueSnackbar(error, {variant: 'error'})
        );
    }

    const getVendedores = () => {
        VendedoresService.getAll(
            response => setVendedores(response.data.result),
            error => enqueueSnackbar(error, {variant: 'error'})
        );
    }

    useEffect(() => {
        getCobranzas();
    }, [desde, hasta, vendedor, status]);

    useEffect(() => {
        if (context.loggedUser.role === 'A' || context.loggedUser.role === 'S')
            getVendedores();
    }, []);

    const getPDF = (fnSuccess) => {
        if (selected.length === 0) {
            enqueueSnackbar("Debe seleccionar al menos una cobranza", {variant: 'error'})
            return false;
        }

        if (selected.length > 130) {
            enqueueSnackbar("El máximo de cobranzas es 130", {variant: 'error'})
            return false;
        }

        ListadosService.rendicionCobranzas(
            selected,
            file => fnSuccess(file),
            error => enqueueSnackbar(error, {variant: 'error'})
        );
    };

    return (
        <>
            <Box>
                <Box className='container-xl mt-3 mb-3'>
                    <Box className='wrapper'>
                        <Grid container spacing={4}>
                            <Grid item container spacing={1} className={'w-auto'}>
                                <Grid item>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            autoOk
                                            style={{width: '6rem'}}
                                            label="Desde"
                                            format="DD/MM/Y"
                                            value={desde}
                                            error={desde > hasta}
                                            onChange={moment => setDesde(moment)}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            autoOk
                                            style={{width: '6rem'}}
                                            label="Hasta"
                                            format="DD/MM/Y"
                                            value={hasta}
                                            error={hasta < desde}
                                            onChange={moment => setHasta(moment)}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        select
                                        label="Estado"
                                        className='mr-2'
                                        value={status}
                                        onChange={event => setStatus(event.target.value)}
                                    >
                                        {STATUSES.map(status => (
                                            <MenuItem key={status.id} value={status.id}>
                                                {status.desc}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        select
                                        label="Vendedor"
                                        className='mr-2'
                                        value={vendedor}
                                        onChange={event => setVendedor(event.target.value)}
                                    >
                                        {[{id: 'todos', name: 'TODOS'}].concat(vendedores).map(vendedor => (
                                            <MenuItem key={vendedor.id} value={vendedor.id}>
                                                {vendedor.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <Tooltip title={"Refrescar"}>
                                        <IconButton onClick={getCobranzas}>
                                            <RefreshRounded/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box className={'mt-3'}>
                            <ReactGrid
                                title={`Rendiciones Digitales`}
                                height={-250}
                                columns={COBRANZAS_TABLE.columns}
                                columnExtensions={COBRANZAS_TABLE.columnExtensions}
                                getRowId={row => row.id}
                                rows={cobranzas}
                                showResultCount={true}
                                enableFiltering={false}
                                enableSorting={true}
                                enableSearch={true}
                                enableSelection={true}
                                selectedRows={selected}
                                onSelectionChange={selected => setSelected(selected)}
                                onDelete={selected => setSelected([])}
                                showSelectAll={true}
                            />
                        </Box>
                    </Box>
                </Box>
                <SimpleDialog
                    title={"Comprobantes"}
                    open={comprobantesDialog.open}
                    body={
                        <AdditionalData
                            idrecibo={comprobantesDialog?.id}
                            items={comprobantesDialog?.items}
                            editMode={false}
                        />
                    }
                    fullWidth={true}
                    maxWidth={'md'}
                    onCancel={() => setComprobantesDialog({open: false})}
                />
                <SimpleDialog
                    open={infoDialog.open}
                    onCancel={() => setInfoDialog({open: false})}
                    maxWidth={'lg'}
                    fullWidth={true}
                    title={'Cobranza'}
                    body={<CobranzasView match={{params: {cobranza: infoDialog.id, cliente: infoDialog.idcliente}}}/>}
                    cancelText={"Cerrar"}
                />
                <ExportOptions
                    enableDownload
                    enableMail
                    enablePrint
                    enableShow
                    getPDF={getPDF}
                    fileName={`Rendición Digital.pdf`}
                    // mailTo={cliente.email}
                    mailCc={vendedor.email}
                    mailCCEnabled={true}
                    mailSubject={`Rendición Digital`}
                    mailBody={`Estimado, se adjunta la rendición digital.`}
                />
            </Box>
        </>
    )
}