import React, {useEffect, useState} from 'react';
import SubHeader from "components/helpers/subHeader/subHeader";
import Box from "@material-ui/core/Box";
import {MenuItem, TextField, Tooltip} from "@material-ui/core";
import {useObjetivosService, useVendedoresService} from "services/hooks";
import {useSnackbar} from "notistack";
import {Button, Divider, Grid, Typography} from "@material-ui/core/index";
import {AddObjetivoDialog} from "components/objetivos/general/configuracion/dialogs/add.objetivo.dialog";
import {ResponsivePie} from "@nivo/pie";
import FilterNoneRounded from "@material-ui/icons/FilterNoneRounded";
import {CopyObjetivosDialog} from "components/objetivos/general/configuracion/dialogs/copy.objetivos.dialog";
import CheckCircleOutlineRounded from "@material-ui/icons/CheckCircleOutlineRounded";
import {HighlightOffRounded} from "@material-ui/icons";
import {checkForErrors, checkForErrorsObjetivo, sumPesos} from "components/objetivos/utils";
import {Objetivo} from "./objetivo";

export function Configuracion() {
    const [vendedores, setVendedores] = useState([]);
    const [vendedorSelected, setVendedorSelected] = useState({});
    const [objetivos, setObjetivos] = useState([]);
    const [tiposObjetivo, setTiposObjetivo] = useState([]);
    const [tiposSubObjetivo, setTiposSubObjetivo] = useState([]);
    const [codigosSubObjetivo, setCodigosSubObjetivo] = useState([]);
    const [proveedoresObjetivo, setProveedoresObjetivo] = useState([]);
    const [addObjetivoOpen, setAddObjetivoOpen] = useState(false);
    const [copyObjetivosOpen, setCopyObjetivosOpen] = useState(false);
    const [errorState, setErrorState] = useState(false);

    const VendedoresService = useVendedoresService();
    const ObjetivosService = useObjetivosService();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    useEffect(() => loadVendedores(), []);
    useEffect(() => loadObjetivos(), [vendedorSelected]);
    useEffect(() => loadTiposObjetivo(), []);
    useEffect(() => loadTiposSubObjetivo(), []);
    useEffect(() => loadProveedoresObjetivo(), []);
    useEffect(() => loadCodigosSubObjetivo(), []);
    useEffect(() => {
        const thereIsAnError = checkForErrors(objetivos);
        setErrorState(thereIsAnError);
    }, [objetivos]);

    const loadVendedores = () => {
        VendedoresService.getAll(
            response => {
                setVendedores(response.data.result);
                setVendedorSelected(response.data.result[0]);
            },
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }

    const loadObjetivos = () => {
        vendedorSelected.id &&
        ObjetivosService.getAll(
            vendedorSelected.id,
            response => setObjetivos(response.data.result),
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }

    const loadTiposObjetivo = () => {
        ObjetivosService.getTiposObjetivo(
            response => setTiposObjetivo(response.data.result),
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }

    const loadProveedoresObjetivo = () => {
        ObjetivosService.getProveedoresObjetivo(
            response => setProveedoresObjetivo(response.data.result),
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }

    const loadTiposSubObjetivo = () => {
        ObjetivosService.getTiposSubObjetivo(
            response => setTiposSubObjetivo(response.data.result),
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }

    const loadCodigosSubObjetivo = () => {
        ObjetivosService.getCodigosSubObjetivo(
            response => setCodigosSubObjetivo(response.data.result),
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }

    const addObjetivo = (tipo, proveedor, peso) => {
        ObjetivosService.addObjetivo(
            vendedorSelected.id,
            tipo,
            proveedor,
            peso,
            response => {
                enqueueSnackbar("El nuevo objetivo se agregó correctamente", {variant: 'success'});
                loadObjetivos();
            },
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }

    const copyObjetivos = (idVendedor) => {
        ObjetivosService.copyObjetivos(
            idVendedor,
            vendedorSelected.id,
            response => {
                enqueueSnackbar("Los objetivos se copiaron correctamente", {variant: 'success'});
                loadObjetivos();
            },
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }

    return (
        <>
            <Box className='niquel-objetivos'>
                <SubHeader
                    title={'Objetivos'}
                    subtitle={`Configuración`}
                />
                <Box className='container-xl mt-3 mb-3'>
                    <Grid container spacing={2} direction={"column"}>
                        <Grid container item justifyContent={"space-between"} alignContent={"center"}>
                            <Grid item className={'d-flex align-items-center'}>
                                <TextField
                                    select
                                    label="del vendedor"
                                    className='mr-2 select'
                                    value={vendedorSelected}
                                    onChange={event => setVendedorSelected(event.target.value)}
                                >
                                    {vendedores.map((vendedor, i) => (
                                        <MenuItem key={i} value={vendedor}>
                                            {vendedor.name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item className={'d-flex'}>
                                <Button
                                    variant={"outlined"}
                                    size={"small"}
                                    color={"primary"}
                                    onClick={() => setCopyObjetivosOpen(true)}
                                >
                                    <FilterNoneRounded className={'mr-2'} fontSize={"small"}/> Copiar objetivos de
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid item>
                            {
                                objetivos.map(objetivo =>
                                    <Objetivo
                                        {...objetivo}
                                        key={objetivo.idobjetivo}
                                        tiposSubObjetivo={tiposSubObjetivo}
                                        codigosSubObjetivo={codigosSubObjetivo}
                                        onUpdateObjetivo={loadObjetivos}
                                        checkForErrorsObjetivo={checkForErrorsObjetivo}
                                    />
                                )
                            }
                        </Grid>
                        <Grid item container justifyContent={"space-between"}>
                            <Button
                                variant={"text"}
                                color={"primary"}
                                onClick={() => setAddObjetivoOpen(true)}
                            >
                                Agregar Objetivo
                            </Button>
                            <Box className={'ml-5'}>
                                {/*<Typography variant={"caption"} color={"textSecondary"}>estado de configuración</Typography>*/}
                                {errorState === false ? (
                                    <Tooltip title={'Sin errores'}>
                                        <Box className={'d-flex align-items-center'}>
                                            <CheckCircleOutlineRounded style={{width: '2rem', height: '2rem'}}
                                                                       color={"primary"}/>
                                            <Typography
                                                variant={"body2"}
                                                component={"span"}
                                                className={'ml-1'}
                                                color={"primary"}
                                            >
                                                {"Los objetivos están correctamente configurados"}
                                            </Typography>
                                        </Box>
                                    </Tooltip>
                                ) : (
                                    <Tooltip title={errorState}>
                                        <Box className={'d-flex align-items-center'}>
                                            <HighlightOffRounded style={{width: '2rem', height: '2rem'}}
                                                                 color={"error"}/>
                                            <Typography
                                                variant={"body2"}
                                                component={"span"}
                                                className={'color-error font-weight-bold ml-1'}
                                            >
                                                {errorState}
                                            </Typography>
                                        </Box>
                                    </Tooltip>
                                )}
                            </Box>
                        </Grid>
                        <Divider className={'mt-4 mb-4'}/>
                        {objetivos.length > 0 && sumPesos(objetivos) === 100 && (
                            <Grid item>
                                <Typography>Composición de Objetivos</Typography>
                                <Box style={{height: `350px`, width: '100%'}}>
                                    <ResponsivePie
                                        data={objetivos}
                                        id={'nombre'}
                                        value={'peso'}
                                        // valueFormat="%"
                                        margin={{top: 10, right: 170, bottom: 15, left: 170}}
                                        innerRadius={0}
                                        padAngle={1}
                                        cornerRadius={3}
                                        colors={{scheme: 'nivo'}}
                                        borderColor={{from: 'color', modifiers: [['darker', '0']]}}
                                        arcLinkLabelsSkipAngle={10}
                                        arcLinkLabelsColor={{from: 'color', modifiers: []}}
                                        arcLabelsSkipAngle={10}
                                        defs={[
                                            {
                                                id: 'dots',
                                                type: 'patternDots',
                                                background: 'inherit',
                                                color: 'rgba(255, 255, 255, 0.3)',
                                                size: 4,
                                                padding: 1,
                                                stagger: true
                                            },
                                            {
                                                id: 'lines',
                                                type: 'patternLines',
                                                background: 'inherit',
                                                color: 'rgba(255, 255, 255, 0.3)',
                                                rotation: -45,
                                                lineWidth: 6,
                                                spacing: 10
                                            }
                                        ]}
                                        // tooltip={(e) => VendedoresTooltip(value, e.datum)}
                                    />
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            </Box>
            <AddObjetivoDialog
                open={addObjetivoOpen}
                tiposObjetivo={tiposObjetivo}
                proveedoresObjetivo={proveedoresObjetivo}
                onConfirm={(tipo, proveedor, peso) => {
                    setAddObjetivoOpen(false)
                    addObjetivo(tipo, proveedor, peso);
                }}
                onCancel={() => setAddObjetivoOpen(false)}
            />
            <CopyObjetivosDialog
                open={copyObjetivosOpen}
                vendedorActual={vendedorSelected}
                vendedores={vendedores}
                onConfirm={(idVendedor) => {
                    setCopyObjetivosOpen(false);
                    copyObjetivos(idVendedor);
                }}
                onCancel={() => setCopyObjetivosOpen(false)}
            />
        </>
    )
}
