import React, {Component} from 'react';
import {Box, Button, Tooltip, Typography} from "@material-ui/core";
import {EmailService} from 'services';
import {withSnackbar} from "notistack";
import {ReactGrid} from "components/helpers/reactGrid/reactGrid";
import {MainContext} from "contexts/main.context";
import {MailDialog} from "components/helpers/dialogs/mail.dialog";
import SpeedDial from "@material-ui/lab/SpeedDial/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction/SpeedDialAction";
import DownloadIcon from "@material-ui/icons/GetAppRounded";
import EmailIcon from "@material-ui/icons/EmailRounded";
import {Icon} from '@iconify/react';
import xlsxIcon from '@iconify-icons/mdi/file-excel';
import ListadosService from "services/listados.service";
import MomentUtils from "@date-io/moment";
import Switch from "@material-ui/core/Switch";
import {CloudUploadRounded} from "@material-ui/icons";
import Grid from "@material-ui/core/Grid";
import {sortNumeric} from "../../../assets/utils";

export class Excel extends Component {
    state = {
        columns: [
            {name: 'hoja', title: 'Hoja'}
        ],
        rows: [
            {hoja: "Shell LCC Plazo"},
            {hoja: "Shell LCC Clientes"},
            {hoja: "Shell LCC Esp"},
            {hoja: "Shell Completo"},
            {hoja: "PROMOS"},
            {hoja: "Tecfil"},
            {hoja: "Osram"},
            {hoja: "LISTAS MOTOS"},
            {hoja: "BOSCH FILTROS"},
            {hoja: "BOSCH ESCOBILLAS PLAZOS"},
            {hoja: "BOSCH ESCOBILLAS CLIENTES"},
            {hoja: "MAHLE"},
            {hoja: "WEGA"}
        ],
        selected: [],
        compare: false,
        fileComparation: null,
        all: false,
        dialOpen: false,
        mailDialogOpen: false,
    };

    EmailService = new EmailService(this.context);
    ListadosService = new ListadosService(this.context);

    componentDidMount() {
        this.addPROVGENERALSheet();
    }

    addPROVGENERALSheet = () => {
        if (this.userCanViewPROVGENERALSheet()) {
            this.setState(prevState => {
                prevState.rows.push({
                    hoja: 'PROVGENERAL'
                });
            }, this.context.updateWindowDimensions);
        }
    }

    // Declaratividad
    userCanViewPROVGENERALSheet = () => {
        const authorizedRoles = ['A','O'];
        const actualRole = this.context.loggedUser.role;
        return authorizedRoles.includes(actualRole);
    }

    onChangeSwitchAll = () => this.setState(prevState => (prevState.all = !prevState.all));

    onSelect = selected => {
        this.setState({
            selected: selected
        });
    };

    getFile = (fnSuccess = () => {
    }) => {
        let hojas = this.state.selected;

        var formData = new FormData();

        // Paso los parámetros a un formData para poder enviar el archivo (lista de precios)
        if (this.state.compare)
            formData.append('lista_vieja', this.state.fileComparation);

        formData.append('hojas', JSON.stringify(hojas));

        this.ListadosService.listaPreciosExcel(
            formData,
            file => this.setState({fileBlob: file}, fnSuccess),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        )
    };

    // Download File
    onDownloadFile = () => {
        let dateString = (new MomentUtils()).date().format("D_M_Y");
        this.getFile(
            () => {
                const fileURL = URL.createObjectURL(this.state.fileBlob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = fileURL;
                a.download = `Lista_de_precios_${dateString}.xlsx`;
                a.click();
                window.URL.revokeObjectURL(fileURL);
                this.props.enqueueSnackbar('El archivo se descargó correctamente. Revise su carpeta de descargas.');
            }
        );
    };

    // Email
    onOpenMailDialog = () => this.setState({mailDialogOpen: true});

    dialActions = [
        {icon: <EmailIcon/>, name: 'Enviar por Email', action: this.onOpenMailDialog},
        {icon: <DownloadIcon/>, name: 'Descargar', action: this.onDownloadFile}
    ];

    onCloseMailDialog = () => this.setState({mailDialogOpen: false});

    sendMail = mailData => {
        let sendMail = () => {
            // Paso los parámetros a un formData para poder enviar el blob (attachment)
            var formData = new FormData();
            formData.append('attachment', this.state.fileBlob);
            formData.append('data', JSON.stringify(mailData));

            this.EmailService.send(
                formData,
                response => {
                    this.onCloseMailDialog();
                    this.props.enqueueSnackbar('El email se envió correctamente', {variant: 'success'});
                },
                error => this.props.enqueueSnackbar(error, {variant: 'error'})
            );
        };

        if (mailData.attachFile)
            this.getFile(() => sendMail());
        else
            sendMail();
    };

    onDialOpen = () => this.setState({dialOpen: true});
    onDialClose = () => this.setState({dialOpen: false});

    toggleCompare = () => this.setState({compare: !this.state.compare});

    onUploadLista = event => {
        if (event.target.files.length <= 0) {
            this.props.enqueueSnackbar('Debe seleccionar un archivo para subir', {variant: "error"});
            return false;
        }

        var ext = event.target.files[0].name.match(/\.([^\.]+)$/)[1];
        if (ext !== 'xlsx') {
            this.props.enqueueSnackbar('Debe subir un archivo con extensión .xlsx', {variant: "error"});
            return false;
        }

        this.setState({fileComparation: event.target.files[0]});
    }

    render() {
        const {columns, rows, selected, compare, all, dialOpen, mailDialogOpen, fileComparation} = this.state;
        return (
            <React.Fragment>
                {(this.context.loggedUser.role === "A" || this.context.loggedUser.role === "O") && (
                    <Grid container className={"m-2"}>
                        <Box>
                            <Switch
                                checked={compare}
                                onChange={this.toggleCompare}
                                name="checkedA"
                                inputProps={{'aria-label': 'secondary checkbox'}}
                            />
                            <Typography variant={"body2"} component={"span"}>Comparar precios</Typography>
                        </Box>
                        {compare && (
                            <Box className={"ml-5"}>
                                <input accept=".xlsx" className={'d-none'} name="plantilla-file" id="plantilla-file" type="file" onChange={this.onUploadLista}/>
                                <label htmlFor="plantilla-file">
                                    <Tooltip title={'Subir lista anterior'}>
                                        <Button
                                            variant={"contained"}
                                            size={"small"}
                                            startIcon={<CloudUploadRounded/>}
                                            component={"span"}
                                        >
                                            Subir lista anterior
                                        </Button>
                                    </Tooltip>
                                </label>
                            </Box>
                        )}
                    </Grid>
                )}
                <ReactGrid
                    title={'Hojas a Descargar'}
                    columns={columns}
                    rows={rows}
                    height={-300}
                    enableFiltering={false}
                    onDelete={selected => this.onSelect([])}
                    selectedRows={selected}
                    onSelectionChange={this.onSelect}
                    enableSelection={true}
                    showSelectAll={true}
                    enableSorting={true}
                    getRowId={row => row.hoja}
                    // showSwitchAll={true}
                    // switchAllValue={all}
                    // onChangeSwitchAll={() => this.onChangeSwitchAll()}
                />
                <Box className='niquel-fab-btn'>
                    <SpeedDial
                        ariaLabel="lista_precio_grupo"
                        icon={<Icon icon={xlsxIcon} width={28}/>}
                        onBlur={this.onDialClose}
                        onClick={this.onDialOpen}
                        onClose={this.onDialClose}
                        onFocus={this.onDialOpen}
                        onMouseEnter={this.onDialOpen}
                        onMouseLeave={this.onDialClose}
                        open={dialOpen}
                    >
                        {this.dialActions.map(action => (
                            <SpeedDialAction
                                key={action.name}
                                icon={action.icon}
                                tooltipTitle={action.name}
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (all === false && selected.length < 1)
                                        this.props.enqueueSnackbar('Debe seleccionar al menos una hoja', {variant: 'error'});
                                    else if (compare && fileComparation === null)
                                        this.props.enqueueSnackbar('Debe subir la lista anterior para poder hacer la comparación de precios', {variant: 'error'});
                                    else
                                        action.action()
                                }}
                            />
                        ))}
                    </SpeedDial>
                </Box>
                <MailDialog
                    open={mailDialogOpen}
                    onConfirm={this.sendMail}
                    onCancel={this.onCloseMailDialog}
                    fileName={'Lista_de_precios_' + (new MomentUtils()).date().format("D_M_Y") + '.xlsx'}
                    attachFile={true}
                    // to={cliente.email}
                    subject={`Lista Precio`}
                    body={`Estimado,  \n\nSe adjunta la lista de precios.`}
                />
            </React.Fragment>
        )
    }
}

Excel.contextType = MainContext;
Excel = withSnackbar(Excel);
