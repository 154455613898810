import React, {Component} from 'react';
import {MainContext} from "contexts/main.context";
import SubHeader from "components/helpers/subHeader/subHeader";
import Box from "@material-ui/core/Box";
import {isMobile} from "react-device-detect";
import Grid from "@material-ui/core/Grid";
import {Button, Typography} from "@material-ui/core";
import {PhotoCameraRounded, CheckRounded} from "@material-ui/icons";
import PedidosService from "services/pedidos.service";
import {HojasDeRutaService} from "services";
import {withSnackbar} from "notistack";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

export class EntregasEntregar extends Component {
    HojasDeRutaService = new HojasDeRutaService(this.context);
    PedidosService = new PedidosService(this.context);

    state = {
        recibo: false,
        loading: false
    };

    componentDidMount() {

    }

    entregarPedido = () => this.PedidosService.changeStatus(
        this.props.match.params.pedido,
        'E',
        success => {
            this.props.enqueueSnackbar(`Se marcó el pedido ${this.props.match.params.pedido} como entregado`, {variant: 'success'});
            this.props.history.goBack();
            this.props.history.goBack();
        },
        error => this.props.enqueueSnackbar(error, {variant: 'error'})
    );

    uploadInforme = event => {
        this.setState({loading: true});

        // Paso los parámetros a un formData para poder enviar el archivo (remito)
        var formData = new FormData();
        formData.append('recibo', event.target.files[0]);
        // Limpio el input por si quieren subir varias veces.
        event.target.value = '';
        // formData.append('data', JSON.stringify(""));

        const id_pedido = this.props.match.params.pedido;
        const id_entrega = this.props.match.params.entrega;
        this.HojasDeRutaService.uploadRecibo(id_pedido, id_entrega, formData,
            success => this.setState({loading: false, recibo: true}),
            error => {
                this.setState({loading: false})
                this.props.enqueueSnackbar(error, {variant: 'error'})
            }
        );
    }

    render() {
        const {loading, recibo} = this.state;
        const viewHeight = isMobile ? 'calc(100vh - 11.5rem)' : 'calc(100vh - 11rem)';
        return (
            <div>
                <SubHeader
                    title={'Entregas'}
                />
                <Box className={'m-4'}>
                    <Grid container direction={"column"} spacing={3}>
                        <Grid item>
                            <Typography variant={"h6"} className={'font-weight-normal'} align={"center"}>
                                Entregar pedido completo
                            </Typography>
                            <Typography variant={"body1"} color={"textSecondary"} className={'font-weight-normal mt-2'} align={"center"}>
                                Para confirmar la entrega suba una foto del remito
                            </Typography>
                        </Grid>
                        <Grid item className={'text-center'}>
                            <input accept="image/*;capture=camera" className={'d-none'} id="recibo-file" type="file" onChange={this.uploadInforme}/>
                            <label htmlFor="recibo-file">
                                <div className={'position-relative'}>
                                    <IconButton color="primary" aria-label="upload picture" size={"medium"} component="span">
                                        {!recibo && <PhotoCameraRounded fontSize={"large"}/>}
                                        {recibo && <CheckRounded fontSize={"large"}/>}
                                    </IconButton>
                                    {loading && <CircularProgress size={50} style={{
                                        color: 'rgba(0, 121, 107, 0.8)',
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: -25,
                                        marginLeft: -25
                                    }}/>}
                                </div>
                            </label>
                        </Grid>
                        <Grid item>
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                className={'w-100'}
                                style={{height: '3.5rem'}}
                                disabled={!recibo}
                                onClick={this.entregarPedido}
                            >
                                Confirmar
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        )
    }
}

EntregasEntregar.contextType = MainContext;
EntregasEntregar = withSnackbar(EntregasEntregar);
