import React, {Component} from 'react'
import {Box, Typography} from "@material-ui/core";
import {withSnackbar} from "notistack";
import {MainContext} from "contexts/main.context";
import SubHeader from "components/helpers/subHeader/subHeader";
import Button from "@material-ui/core/Button";
import CardActions from "@material-ui/core/CardActions";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";

export class FacturasSelection extends Component {
    navTo = (route) => {
        this.props.history.push(`${this.props.match.url}/${route}`);
    };

    render() {
        return (
            <Box className='niquel-facturas-selection'>
                <SubHeader
                    title={'Facturas'}
                    subtitle={'Selección de Vista'}
                />
                <Box className='container-xl mt-3 mb-3'>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Card className={'m-2 d-inline-flex w-100'}>
                                <CardActionArea onClick={() => this.navTo('xcliente')}>
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h3">
                                            Facturas por Cliente
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            Revisar facturas listadas por cliente
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button size="small" color="primary" disableRipple>
                                            Acceder
                                        </Button>
                                    </CardActions>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item xs={6}>
                            <Card className={'m-2 d-inline-flex w-100'}>
                                <CardActionArea onClick={() => this.navTo('xfecha')}>
                                    <CardContent>
                                        <Typography gutterBottom variant="h5" component="h3">
                                            Facturas por Fecha de Ingreso
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            Revisar facturas listadas por fecha de ingreso
                                        </Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button size="small" color="primary" disableRipple>
                                            Acceder
                                        </Button>
                                    </CardActions>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        )
    }
}

FacturasSelection.contextType = MainContext;
FacturasSelection = withSnackbar(FacturasSelection);
