import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {MainContext} from "contexts/main.context";
import {SelectionView} from "components/picking/OperationSelection/operationSelection";
import {SelectHojaDeRutaView} from "components/picking/components/selectHojaDeRuta.view";
import {SelectPedidoView} from "components/picking/components/selectPedido.view";
import {ScanArticulosView as ScanArticulosRecolectar} from "components/picking/RecolectarArticulos/scanArticulos.view";
import {ScanArticulosView as ScanArticulosValidarDespacho} from "components/picking/ValidarDespachoPedido/scanArticulos.view";
import {ScanArticuloView as ScanArticulo} from "components/picking/EscanearArticulo/scanArticulo.view";

const basePath = '/picking';

export class Picking extends Component {
    componentDidMount() {
        this.context.setHeaderTitle('Picking');
    }

    render() {
        return (
            <div className='niquel-picking' style={{top: '0.4rem', position: 'relative'}}>
                <Switch>
                    <Route path={`${basePath}/escanearArticulo`} component={ScanArticulo}/>
                    <Route path={`${basePath}/recolectarArticulos/:idHojaDeRuta`} component={ScanArticulosRecolectar}/>
                    <Route path={`${basePath}/recolectarArticulos`} component={SelectHojaDeRutaView}/>
                    <Route path={`${basePath}/validarDespachoPedido/:idHojaDeRuta/:idPedido`} component={ScanArticulosValidarDespacho}/>
                    <Route path={`${basePath}/validarDespachoPedido/:idHojaDeRuta`} component={SelectPedidoView}/>
                    <Route path={`${basePath}/validarDespachoPedido`} component={SelectHojaDeRutaView}/>
                    <Route path={`${basePath}`} component={SelectionView}/>
                </Switch>
            </div>
        )
    }
}

Picking.contextType = MainContext;
