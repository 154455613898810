import React, {Component} from "react";
import {Button, Dialog, DialogContent, DialogContentText, DialogTitle, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {formatPrice} from "assets/utils";
import {MainContext} from "contexts/main.context";

export class ConfirmationDialog extends Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onCancel}
                fullScreen={this.props.fullScreen}
                classes={{
                    paperFullScreen: 'niquel-dialog-full-screen'
                }}
            >
                <ConfirmationDialogContent {...this.props}/>
            </Dialog>
        )
    }
}

class ConfirmationDialogContent extends Component {
    render() {
        const {cliente, headerData, cobranza, operation, id, onConfirm, onSave, onCancel, _equivalenteDescuento} = this.props;

        const puedeGuardar = ((this.context.loggedUser.role !== 'V' && this.context.loggedUser.role !== 'S') || this.context.loggedUser.level <= 5);

        return (
            <React.Fragment>
                {operation === 'CREATE' && (
                    <DialogTitle id="alert-dialog-title">Generar cobranza</DialogTitle>
                )}
                {operation === 'EDIT' && (
                    <DialogTitle id="alert-dialog-title">Editar de cobranza</DialogTitle>
                )}
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" component={'div'}>
                        {operation === 'CREATE' && (
                            <Typography variant={"body2"}>
                                Estás por generar una nueva cobranza para el cliente <b>{cliente.razsoc}</b>
                            </Typography>
                        )}
                        {operation === 'EDIT' && (
                            <Typography variant={"body2"}>
                                Estás por editar la cobranza {id} del cliente <b>{cliente.razsoc}</b>
                            </Typography>
                        )}
                        <Grid container direction={"column"} className={'mt-2 mb-2'}>
                            <Grid container direction={"row"} className={'mt-2'}>
                                <Typography variant={"caption"}>Total a cancelar (documentos)</Typography>
                                <div style={{flex: '1 1 auto', borderBottom: '1px solid #e4e4e4', margin: '0 0.3rem 0.3rem 0.3rem'}}/>
                                <Typography variant={"body2"} align={"right"}>
                                    {formatPrice(cobranza.documentos.total, true)}
                                </Typography>
                            </Grid>
                            <Grid container direction={"row"} className={'mt-3'}>
                                <Typography variant={"caption"}>Total a cobrar (items)</Typography>
                                <div style={{flex: '1 1 auto', borderBottom: '1px solid #e4e4e4', margin: '0 0.3rem 0.3rem 0.3rem'}}/>
                                <Typography variant={"body2"} align={"right"}>
                                    {formatPrice(cobranza.items.total, true)}
                                </Typography>
                            </Grid>
                            <Grid container direction={"row"}>
                                <Typography variant={"caption"}>Promedio dias de pago (ponderado)</Typography>
                                <div style={{flex: '1 1 auto', borderBottom: '1px solid #e4e4e4', margin: '0 0.3rem 0.3rem 0.3rem'}}/>
                                <Typography variant={"body2"} align={"right"}>
                                    {cobranza.items.promedio_ponderado === 0 ? `Pago al día (0)` : `Pago a ${cobranza.items.promedio_ponderado} días`}
                                </Typography>
                            </Grid>
                            <Grid container direction={"row"}>
                                <Typography variant={"caption"} color={"primary"}>Porcentaje descuento *</Typography>
                                <div style={{flex: '1 1 auto', borderBottom: '1px solid #e4e4e4', margin: '0 0.3rem 0.3rem 0.3rem'}}/>
                                <Typography variant={"body2"} align={"right"} color={"primary"}>
                                    {cobranza.descuento.porcentaje}%
                                </Typography>
                            </Grid>
                            <Grid container direction={"row"}>
                                <Typography variant={"caption"} color={"primary"}>Importe descuento (nota de crédito)</Typography>
                                <div style={{flex: '1 1 auto', borderBottom: '1px solid #e4e4e4', margin: '0 0.3rem 0.3rem 0.3rem'}}/>
                                <Typography variant={"body2"} align={"right"} color={"primary"}>
                                    {formatPrice(cobranza.descuento.importe, true)} ({formatPrice(cobranza.descuento.importe_sin_iva, true)} +
                                    IVA: {formatPrice(cobranza.descuento.iva, true)})
                                </Typography>
                            </Grid>
                            {cobranza.ajustes_plazo.total > 0 && (
                                <Grid container direction={"row"}>
                                    <Typography variant={"caption"}>Ajuste Plazo</Typography>
                                    <div style={{flex: '1 1 auto', borderBottom: '1px solid #e4e4e4', margin: '0 0.3rem 0.3rem 0.3rem'}}/>
                                    <Typography variant={"body2"} align={"right"}>
                                        {formatPrice(cobranza.ajustes_plazo.total, true)}
                                    </Typography>
                                </Grid>
                            )}
                            <Grid container direction={"row"} className={'mt-3'}>
                                <Typography variant={"caption"}>Saldo</Typography>
                                <div style={{flex: '1 1 auto', borderBottom: '1px solid #e4e4e4', margin: '0 0.3rem 0.3rem 0.3rem'}}/>
                                <Typography variant={"body2"} align={"right"}>
                                    {formatPrice(cobranza.saldo_cliente, true)}
                                </Typography>
                            </Grid>
                        </Grid>

                        {operation === 'CREATE' && puedeGuardar && cobranza.descuento.importe > 0 && (
                            <Typography variant={"body2"} className={'mt-3'}>
                                <b>ATENCIÓN:</b> Esta cobranza tiene descuento.
                                Si desea incluir la nota de crédito correspondiente por ajuste plazo <b>en este recibo</b> elija la opción
                                <Typography component={"span"} variant={"body2"} color={"secondary"} className={'font-weight-bold'}> GUARDAR</Typography>.
                                De lo contrario elija la opción
                                <Typography component={"span"} variant={"body2"} color={"primary"} className={'font-weight-bold'}> CONFIRMAR</Typography>.
                            </Typography>
                        )}

                        {cobranza.saldo_cliente > 0 && (
                            <Typography variant={"body2"} className={'mt-3'} style={{color: "#004eff"}}>
                                <b>ATENCIÓN:</b> Queda un saldo a favor del cliente de <b>{formatPrice(cobranza.saldo_cliente, true)}</b>.
                                Se ingresará ese monto como saldo positivo en la cuenta corriente del cliente.
                            </Typography>
                        )}

                        {operation === 'EDIT' && headerData.status === 'G' && _equivalenteDescuento.error && (
                            <Typography variant={"body2"} className={'mt-3'} color={"error"}>
                                <b>ATENCIÓN:</b> El descuento equivalente de esta cobranza no coincide con el descuento original.&nbsp;
                                {this.context.loggedUser.role !== 'A' ?
                                    <>Si confirma la edición, este recibo quedará pendiente de ser revisado por un administrador.</> :
                                    <>Por ser administrador <b>puede editar este recibo</b></>}
                            </Typography>
                        )}

                        <Typography variant={"caption"} component={"div"} className={'mt-4'}>
                            * El descuento se aplica sobre el importe neto de los documentos (sin percepciones).
                        </Typography>

                    </DialogContentText>
                </DialogContent>
                <Grid container justifyContent={"space-between"} className={'p-2'}>
                    <Grid item>
                        <Button onClick={onCancel}>
                            Cerrar
                        </Button>
                    </Grid>
                    <Grid item>
                        {operation === 'CREATE' && puedeGuardar && cobranza.descuento.importe > 0 && (
                            <Button onClick={onSave} color="secondary" autoFocus>
                                Guardar
                            </Button>
                        )}
                        <Button onClick={onConfirm} color="primary" autoFocus>
                            Confirmar
                        </Button>
                    </Grid>
                </Grid>
            </React.Fragment>
        )
    }
}

ConfirmationDialogContent.contextType = MainContext;
