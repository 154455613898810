import {IconButton, Paper, Typography} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import React from "react";
import DownloadIcon from "@material-ui/icons/GetAppRounded";
import Tooltip from "@material-ui/core/Tooltip";

export const CardChart = ({title, subtitle = '', children, rightContent, showExport = true, printRef, exportFn}) => {
    return (
        <Paper ref={printRef}>
            <Box className={'d-flex align-items-center justify-content-between'}>
                <Box className={'pt-3 pb-3 pl-4'}>
                    <Typography variant={"body1"} color={"textSecondary"}>{title}</Typography>
                    <Typography variant={"body2"} color={"textSecondary"}>{subtitle}</Typography>
                </Box>
                <Box className={'pr-4'} alignItems={"center"} display={"flex"}>
                    {rightContent}
                    {showExport && (
                        <Tooltip title={"Exportar"}>
                            <IconButton color="inherit" onClick={exportFn}>
                                <DownloadIcon style={{color: "darkslategray"}}/>
                            </IconButton>
                        </Tooltip>
                    )}
                </Box>
            </Box>
            <Box style={{background: "white"}}>
                {children}
            </Box>
        </Paper>
    )
}
