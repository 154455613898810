import {Grid, Paper} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";

export function CardHeader(props) {
    const {textLeft, textRight, textLeftBottom, textRightBottom, onClick} = props;
    return (
        <>
            <Paper className={'d-flex align-items-center'} style={{background: 'rgba(0, 150, 136, 1)', borderRadius: 0, padding: '0 9px 0 17px'}}>
                <Grid container alignItems={"center"} style={{padding: '0.6rem 0.5rem 0.2rem 1rem !important'}}>
                    <Grid item xs={12}>
                        <Grid className={'d-flex'} justifyContent={"space-between"}>
                            <Typography variant={"body1"} style={{color: 'white'}} gutterBottom component={"flex"} align={"left"}>
                                {textLeft}
                            </Typography>
                            <Typography variant={"body1"} style={{color: 'white'}} component={"flex"} align={"right"}>
                                {textRight}
                            </Typography>
                        </Grid>
                        <Grid className={'d-flex'} justifyContent={"space-between"}>
                            {textLeftBottom && (
                                <Typography variant={"body1"} style={{color: 'white'}} gutterBottom component={"flex"} align={"left"}>
                                    {textLeftBottom}
                                </Typography>
                            )}
                            {textRightBottom && (
                                <Typography variant={"body1"} style={{color: 'white'}} component={"flex"} align={"right"}>
                                    {textRightBottom}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </>
    );
}
