import {Button} from "@material-ui/core/index";
import {useHistory} from "react-router-dom";
import {useRouteMatch} from "react-router";
import {CropFreeRounded, LocalShippingRounded} from "@material-ui/icons";
import React from "react";
import ForkliftIcon from "assets/img/forklift_white.svg";
import ChecklistIcon from "assets/img/checklist_white.svg";
import StockIcon from "assets/img/stock_white.svg";
import Box from "@material-ui/core/Box";

export function SelectionView() {
    const history = useHistory();
    const match = useRouteMatch();

    const navTo = to => {
        history.push(`${match.url}/${to}`);
    }

    const OptionButton = props =>
        <Button
            disabled={props.disabled}
            variant={"contained"}
            color={"primary"}
            style={{height: '6rem', width: '75%', display: 'block', margin: '1.5rem auto'}}
            onClick={props.onClick}
        >
            {props.children}
        </Button>

    return (
        <>
            <OptionButton onClick={() => navTo('escanearArticulo')}>
                {/*<img src={} width={'45px'}/>*/}
                <CropFreeRounded fontSize={"large"}/>
                <Box>Solo Escanear</Box>
            </OptionButton>
            <OptionButton onClick={() => navTo('recolectarArticulos')}>
                <img src={ForkliftIcon} width={'40px'}/>
                <Box>Recolectar Artículos</Box>
            </OptionButton>
            <OptionButton onClick={() => navTo('validarDespachoPedido')}>
                <img src={ChecklistIcon} width={'35px'}/>
                <Box>Validar Despacho de Pedido</Box>
            </OptionButton>
            <OptionButton disabled>
                <img src={StockIcon} width={'35px'}/>
                <Box>Ingreso de Mercadería</Box>
            </OptionButton>
        </>
    )
}
