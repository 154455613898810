import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {HojasDeRutaList} from 'components/despacho/hojasDeRuta/list/hojasDeRuta.list';
import {HojasDeRutaCreate} from 'components/despacho/hojasDeRuta/create/hojasDeRuta.create';

const basePath = '/despacho/hojasDeRuta';

export class HojasDeRuta extends Component {
    render() {
        return (
            <div className='niquel-hojasDeRuta'>
                <Switch>
                    <Route path={`${basePath}/view/:hojaDeRuta`} component={HojasDeRutaCreate}/>
                    <Route path={`${basePath}/edit/:hojaDeRuta`} component={HojasDeRutaCreate}/>
                    <Route path={`${basePath}/create`} component={HojasDeRutaCreate}/>
                    <Route path={`${basePath}`} component={HojasDeRutaList}/>
                </Switch>
            </div>
        )
    }
}
