import React, {Component} from 'react'
import {Box, Grid} from "@material-ui/core/index";
import {ClientesService, CobranzasService, EmailService, ListadosService, MastersService, VendedoresService} from "services";
import {withSnackbar} from "notistack";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import 'moment/locale/es';
import {MainContext} from "contexts/main.context";
import {MailDialog} from "components/helpers/dialogs/mail.dialog";
import SpeedDial from "@material-ui/lab/SpeedDial/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction/SpeedDialAction";
import PDFIcon from "@material-ui/icons/PictureAsPdf";
import DownloadIcon from "@material-ui/icons/GetAppRounded";
import ShowIcon from "@material-ui/icons/DesktopWindowsRounded";
import PrintIcon from "@material-ui/icons/PrintRounded";
import EmailIcon from "@material-ui/icons/EmailRounded";
import printJS from 'print-js'
import {PDFDialog} from "components/helpers/dialogs/pdf.dialog";
import {ReactGrid} from "components/helpers/reactGrid/reactGrid";
import {sortDate, sortNumeric} from "assets/utils";
import {Checkbox, FormControlLabel, MenuItem, TextField} from "@material-ui/core";

export class Generar extends Component {
    state = {
        fecha_desde: (new MomentUtils()).date(new Date()).subtract(30, 'days'),
        fecha_hasta: (new MomentUtils()).date(new Date()),
        vendedor: 'todos',
        cobranzas: {
            columns: [
                {name: 'id', title: 'ID'},
                {name: 'numrec', title: 'Recibo'},
                {name: 'fecharecibo', title: 'Fecha'},
                {name: 'razsoc', title: 'Cliente'},
                {name: 'vendedor', title: 'Vendedor'}
            ],
            columnExtensions: [
                {columnName: 'id', width: 100, compare: sortNumeric, direction: 'desc'},
                {columnName: 'numrec', width: 100, compare: sortNumeric},
                {columnName: 'fecharecibo', width: 150, compare: sortDate},
                {columnName: 'vendedor', width: 250},
            ],
            data: [],
            selected: [],
            all: true
        },
        vendedores: [],
        pdfBlob: null,
        pdfUrl: '',
        mailDialogOpen: false,
        pdfDialogOpen: false,
        dialOpen: false,
        filterChequeElectro: false
    };

    MastersService = new MastersService(this.context);
    VendedoresService = new VendedoresService(this.context);
    ClientesService = new ClientesService(this.context);
    ListadosService = new ListadosService(this.context);
    EmailService = new EmailService(this.context);
    CobranzasService = new CobranzasService(this.context);

    getCobranzas = () => {
        let vendedores = [];
        if (this.state.vendedor !== 'todos')
            vendedores.push(this.state.vendedor);

        this.CobranzasService.getFiltered({
                desde: this.state.fecha_desde.format('DD/MM/YY'),
                hasta: this.state.fecha_hasta.format('DD/MM/YY'),
                status: 'A',
                vendedores: vendedores,
                filterChequeElectro: this.state.filterChequeElectro
            },
            response => this.setState(prevState => prevState.cobranzas.data = response.data.result),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    getVendedores = () => {
        this.VendedoresService.getAll(
            response => this.setState({vendedores: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    componentDidMount() {
        this.getCobranzas();

        if (this.context.loggedUser.role === 'A' || this.context.loggedUser.role === 'S')
            this.getVendedores();
    }

    onSelect = (field, selected) => this.setState(prevState => (prevState[field].selected = selected));
    onChange = (field, value) => {
        this.setState({[field]: value}, () => {
            this.getCobranzas();
        });
    };

    getPDF = (fnSuccess = () => {
    }) => {
        if (this.state.cobranzas.selected.length === 0) {
            this.props.enqueueSnackbar("Debe seleccionar al menos una cobranza para rendir", {variant: 'error'})
            return false;
        }

        if (this.state.cobranzas.selected.length > 130) {
            this.props.enqueueSnackbar("El máximo de cobranzas a rendir es 130", {variant: 'error'})
            return false;
        }

        this.ListadosService.rendicionCobranzas(
            this.state.cobranzas.selected,
            file => this.setState({pdfBlob: file}, fnSuccess),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    };

    // Show PDF
    onOpenPdfDialog = () => this.setState({pdfDialogOpen: true});
    onClosePdfDialog = () => this.setState({pdfDialogOpen: false});

    onShowPDF = () => {
        this.getPDF(
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                this.setState({pdfUrl: fileURL}, this.onOpenPdfDialog);
            }
        );
    };

    // Print PDF
    onPrintPDF = () => {
        this.getPDF(
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                printJS(fileURL);
            }
        );
    };

    // Download PDF
    onDownloadPdf = () => {
        this.getPDF(
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = fileURL;
                a.download = `Rendición Cobranzas`;
                a.click();
                window.URL.revokeObjectURL(fileURL);
                this.props.enqueueSnackbar('El archivo se descargó correctamente. Revise su carpeta de descargas.', {variant: 'success'});
            }
        );
    };

    // Email
    onOpenMailDialog = () => this.setState({mailDialogOpen: true});

    dialActions = [
        {icon: <EmailIcon/>, name: 'Enviar por Email', action: this.onOpenMailDialog},
        {icon: <PrintIcon/>, name: 'Imprimir', action: this.onPrintPDF},
        {icon: <DownloadIcon/>, name: 'Descargar', action: this.onDownloadPdf},
        {icon: <ShowIcon/>, name: 'Ver en Pantalla', action: this.onShowPDF},
    ];

    onCloseMailDialog = () => this.setState({mailDialogOpen: false});

    sendMail = mailData => {
        let sendMail = () => {
            // Paso los parámetros a un formData para poder enviar el blob (attachment)
            var formData = new FormData();
            formData.append('attachment', this.state.pdfBlob);
            formData.append('data', JSON.stringify(mailData));

            this.EmailService.send(
                formData,
                response => {
                    this.onCloseMailDialog();
                    this.props.enqueueSnackbar('El email se envió correctamente', {variant: 'success'});
                },
                error => this.props.enqueueSnackbar(error, {variant: 'error'})
            );
        };

        if (mailData.attachFile)
            this.getPDF(() => sendMail());
        else
            sendMail();
    };

    onDialOpen = () => this.setState({dialOpen: true});
    onDialClose = () => this.setState({dialOpen: false});

    onToggleFilterChequeElectro = () => this.onChange('filterChequeElectro', !this.state.filterChequeElectro);

    render() {
        const {
            fecha_desde, fecha_hasta, vendedor, cobranzas, vendedores, mailDialogOpen, pdfDialogOpen, pdfUrl, dialOpen, filterChequeElectro
        } = this.state;
        return (
            <>
                <Box>
                    <Grid container spacing={4}>
                        <Grid item container spacing={1} className={'w-auto'}>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DatePicker
                                        autoOk
                                        style={{width: '6rem'}}
                                        label="Desde"
                                        format="DD/MM/Y"
                                        value={fecha_desde}
                                        error={fecha_desde > fecha_hasta}
                                        onChange={moment => this.onChange('fecha_desde', moment)}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DatePicker
                                        autoOk
                                        style={{width: '6rem'}}
                                        label="Hasta"
                                        format="DD/MM/Y"
                                        value={fecha_hasta}
                                        error={fecha_hasta < fecha_desde}
                                        onChange={moment => this.onChange('fecha_hasta', moment)}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            {(this.context.loggedUser.role === 'A' || this.context.loggedUser.role === 'S') && (
                                <Grid item>
                                    <TextField
                                        select
                                        label="Vendedor"
                                        className='mr-2'
                                        value={vendedor}
                                        onChange={event => this.onChange('vendedor', event.target.value)}
                                    >
                                        {[{id: 'todos', name: 'TODOS'}].concat(vendedores).map(vendedor => (
                                            <MenuItem key={vendedor.id} value={vendedor.id}>
                                                {vendedor.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            )}
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={filterChequeElectro}
                                            onChange={this.onToggleFilterChequeElectro}
                                        />
                                    }
                                    label="Solo cheque electrónico"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box className={'mt-3'}>
                        <ReactGrid
                            title={`Cobranzas Aprobadas`}
                            height={-250}
                            columns={cobranzas.columns}
                            columnExtensions={cobranzas.columnExtensions}
                            getRowId={row => row.id}
                            rows={cobranzas.data}
                            showResultCount={true}
                            enableFiltering={true}
                            enableSorting={true}
                            enableSearch={true}
                            enableSelection={true}
                            selectedRows={cobranzas.selected}
                            onSelectionChange={selected => this.onSelect('cobranzas', selected)}
                            onDelete={selected => this.onSelect('cobranzas', [])}
                            showSelectAll={true}
                        />
                    </Box>
                    <Box className='niquel-fab-btn'>
                        <SpeedDial
                            ariaLabel="rendicion_cobranzas"
                            icon={<PDFIcon/>}
                            onBlur={this.onDialClose}
                            onClick={this.onDialOpen}
                            onClose={this.onDialClose}
                            onFocus={this.onDialOpen}
                            onMouseEnter={this.onDialOpen}
                            onMouseLeave={this.onDialClose}
                            open={dialOpen}
                        >
                            {this.dialActions.map(action => (
                                <SpeedDialAction
                                    key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        action.action()
                                    }}
                                />
                            ))}
                        </SpeedDial>
                    </Box>
                </Box>
                <MailDialog
                    open={mailDialogOpen}
                    onConfirm={this.sendMail}
                    onCancel={this.onCloseMailDialog}
                    fileName={`Rendicion_Cobranzas.pdf`}
                    attachFile={true}
                    // to={cliente.email}
                    subject={`Rendición Cobranzas`}
                    body={`Estimado,  \n\nSe adjunta el listado de rendición de cobranzas.`}
                />
                <PDFDialog
                    open={pdfDialogOpen}
                    onClose={this.onClosePdfDialog}
                    pdfUrl={pdfUrl}
                    pdfName={`Rendicion_Cobranzas.pdf`}
                />
            </>
        )
    }
}


Generar.contextType = MainContext;
Generar = withSnackbar(Generar);
