import {TableSelect} from "components/estadisticas/settings/table.select";
import React from "react";
import {useArticulosService, useClientesService, useMastersService, useProveedoresService, useVendedoresService} from "services/hooks";

export const VendedoresSelect = React.memo(({state, dispatch}) => {
    const endpoint = 'vendedores';
    const service = useVendedoresService();
    const {all, selected} = state;
    const _dispatch = args => dispatch({...args, type: `${endpoint}.${args.type}`});
    const vendedores = service.getAll.bind(service);
    return (
        <TableSelect
            title={'Vendedores'}
            getData={vendedores}
            all={all}
            selected={selected}
            showSwitchAll={true}
            columns={[
                {name: 'id', title: 'ID'},
                {name: 'name', title: 'Nombre'}
            ]}
            onChangeAll={all => _dispatch({type: 'all', payload: all})}
            onChangeSelected={selected => _dispatch({type: 'selected', payload: selected})}
        />
    )
})

export const ClientesSelect = React.memo(({state, dispatch}) => {
    const endpoint = 'clientes';
    const service = useClientesService();
    const {all, selected} = state;
    const _dispatch = args => dispatch({...args, type: `${endpoint}.${args.type}`});
    return (
        <TableSelect
            title={'Clientes'}
            getData={service.getAll.bind(service)}
            all={all}
            selected={selected}
            showSwitchAll={true}
            columns={[
                {name: 'id', title: 'ID'},
                {name: 'name', title: 'Nombre'}
            ]}
            onChangeAll={all => _dispatch({type: 'all', payload: all})}
            onChangeSelected={selected => _dispatch({type: 'selected', payload: selected})}
        />
    )
})

export const ArticulosSelect = React.memo(({state, dispatch}) => {
    const endpoint = 'articulos';
    const service = useArticulosService();
    const {all, selected} = state;
    const _dispatch = args => dispatch({...args, type: `${endpoint}.${args.type}`});
    return (
        <TableSelect
            title={'Artículos'}
            getData={service.getAll.bind(service, null)}
            getRowId={row => row.id}
            all={all}
            selected={selected}
            showSwitchAll={true}
            columns={[
                {name: 'id', title: 'ID'},
                {name: 'detalle', title: 'Detalle', style: {whiteSpace: 'break-spaces'}}
            ]}
            columnExtensions={[
                {columnName: 'id', width: 170}
            ]}
            onChangeAll={all => _dispatch({type: 'all', payload: all})}
            onChangeSelected={selected => _dispatch({type: 'selected', payload: selected})}
        />
    )
})

export const ProveedoresSelect = React.memo(({state, dispatch}) => {
    const endpoint = 'proveedores';
    const service = useProveedoresService();
    const {all, selected} = state;
    const _dispatch = args => dispatch({...args, type: `${endpoint}.${args.type}`});
    return (
        <TableSelect
            title={'Proveedores'}
            getData={service.getAll.bind(service, ["XX"])}
            getRowId={row => row.id}
            all={all}
            selected={selected}
            showSwitchAll={true}
            columns={[
                {name: 'id', title: 'ID'},
                {name: 'razsoc', title: 'Razón Social'},
                {name: 'apodo', title: 'Apodo'}
            ]}
            columnExtensions={[
                {columnName: 'id', width: 170}
            ]}
            onChangeAll={all => _dispatch({type: 'all', payload: all})}
            onChangeSelected={selected => _dispatch({type: 'selected', payload: selected})}
        />
    )
})

export const LocalidadesSelect = React.memo(({state, dispatch}) => {
    const endpoint = 'localidades';
    const service = useMastersService();
    const {all, selected} = state;
    const _dispatch = args => dispatch({...args, type: `${endpoint}.${args.type}`});
    return (
        <TableSelect
            title={'Localidades'}
            getData={service.getLocalidades.bind(service)}
            all={all}
            selected={selected}
            showSwitchAll={true}
            columns={[
                {name: 'id', title: 'ID'},
                {name: 'detalle', title: 'Localidad'},
                {name: 'provincia', title: 'Provincia'},
                {name: 'codpostal', title: 'Cod. Postal'}
            ]}
            columnExtensions={[
                {columnName: 'id', width: 120},
                {columnName: 'provincia', width: 120},
                {columnName: 'codpostal', width: 120},
            ]}
            onChangeAll={all => _dispatch({type: 'all', payload: all})}
            onChangeSelected={selected => _dispatch({type: 'selected', payload: selected})}
        />
    )
})
