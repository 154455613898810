import AccordionActions from "@material-ui/core/AccordionActions";
import {Button, Grid} from "@material-ui/core";
import React from "react";

export const ObjetivoActions = ({onDelete}) =>
    <AccordionActions>
        <Grid container justifyContent={"space-between"} className={'mr-2 ml-2'}>
            <Grid item>
                    <Button
                        size="small"
                        className={'color-error hover-error'}
                        onClick={onDelete}
                    >
                        Eliminar
                    </Button>
            </Grid>
        </Grid>
    </AccordionActions>
