import React from "react";
import Box from "@material-ui/core/Box";
import {formatPrice} from "assets/utils";
import {Paper, Typography} from "@material-ui/core";
import {ResponsiveBar} from "@nivo/bar";

export const VentasTotalesChart = React.memo(function ({height, data, anterior, value}) {
    const colors = {actual: "rgba(0, 150, 136, 0.8)", anterior: "rgba(36, 173, 160, 0.5)"};

    let showData = [];
    if (data) {
        showData.push({periodo: 'Total Período Actual', valor: data[value]['actual']});
        if (anterior)
            showData.push({periodo: 'Total Período Anterior', valor: data[value]['anterior']});
    }

    return (
        <Box style={{height: `${height}px`, width: '100%'}} className={'d-flex justify-content-center align-items-center'}>
            {(showData.length <= 0) ?
                <Typography align={"center"} color={"textSecondary"}>
                    No hay datos para mostrar
                </Typography> :
                <ResponsiveBar
                    data={showData}
                    keys={['valor']}
                    indexBy={'periodo'}
                    margin={{top: 20, right: 240, bottom: 20, left: 270}}
                    padding={0.3}
                    groupMode="grouped"
                    layout={'horizontal'}
                    valueScale={{type: 'linear'}}
                    indexScale={{type: 'band', round: true}}
                    colors={anterior ? [colors.actual, colors.anterior] : [colors.actual]}
                    colorBy={"indexValue"}
                    borderColor={{from: 'color', modifiers: [['darker', 1.6]]}}
                    axisLeft={{
                        tickSize: 0,
                        tickPadding: 10,
                        tickRotation: 0,
                        // legend: 'Período',
                        legendPosition: 'middle',
                        legendOffset: 40
                    }}
                    axisBottom={null}
                    enableGridY={false}
                    labelSkipWidth={50}
                    // labelTextColor={{from: 'color', modifiers: [['darker', 2.9]]}}
                    labelTextColor={"#333333"}
                    // labelFormat={value => formatPrice(value)}
                    label={d => `${formatPrice(d.value, value === 'importe')}`}
                    isInteractive={true}
                    tooltip={(props) => {
                        return (
                            <Paper elevation={1} className={'p-2'}>
                                <Typography variant={"body2"}>
                                    {props.indexValue}: <b>{formatPrice(props.value, value === 'importe')}</b>
                                </Typography>
                            </Paper>
                        )
                    }}
                    pointSize={10}
                    pointColor={{theme: 'background'}}
                    pointBorderWidth={2}
                    pointBorderColor={{from: 'serieColor'}}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                />
            }
        </Box>
    )
})
