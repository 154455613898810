import React, {useState} from "react";
import {IconButton} from "@material-ui/core/index";
import 'components/helpers/articulos/articuloDetails.dialog.scss';
import {FormatListBulletedRounded, InfoRounded} from "@material-ui/icons";
import {Tooltip} from "@material-ui/core";
import {ArticuloDetailsDialog} from "components/helpers/articulos/articulo.details.dialog";
import {ArticuloArmadoDetailsDialog} from "components/helpers/articulos/articuloArmado.details.dialog";

export const ArticuloDetailsButton = ({row}) => {
    const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);

    const onClick = (e) => {
        e.stopPropagation();
        setDetailsDialogOpen(true);
    };

    return (
        row.armado ? (
            <>
                <Tooltip title={'Componentes del armado'}>
                    <IconButton
                        onClick={(e) => onClick(e)}>
                        <FormatListBulletedRounded/>
                    </IconButton>
                </Tooltip>
                <ArticuloArmadoDetailsDialog
                    open={detailsDialogOpen}
                    articulo={row}
                    onCancel={() => setDetailsDialogOpen(false)}
                />
            </>
        ) : (
            <>
                <Tooltip title={'Info del artículo'}>
                    <IconButton
                        onClick={(e) => onClick(e)}>
                        <InfoRounded/>
                    </IconButton>
                </Tooltip>
                <ArticuloDetailsDialog
                    open={detailsDialogOpen}
                    id={row.id}
                    onCancel={() => setDetailsDialogOpen(false)}
                />
            </>
        )
    )
}
