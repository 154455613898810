import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {RemitosClientes} from 'components/ventas/remitos/clientes/remitos.clientes';
import {RemitosList} from 'components/ventas/remitos/list/remitos.list';
import {RemitosView} from "components/ventas/remitos/view/remitos.view";

const basePath = '/ventas/remitos';

export class Remitos extends Component {
    render() {
        return (
            <div className='niquel-remitos niquel-view-with-bottom-margin'>
                <Switch>
                    <Route path={`${basePath}/view/:remito`} component={RemitosView}/>
                    <Route exact path={`${basePath}`} component={RemitosList}/>
                    {/*<Route path={`${basePath}`} component={RemitosClientes}/>*/}
                </Switch>
            </div>
        )
    }
}
