import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {Notificaciones} from "./notificaciones/notificaciones";
import {Agenda} from "./agenda/agenda";
import {MainContext} from "contexts/main.context";

const basePath = '/herramientas';

export class Herramientas extends Component {
    componentDidMount() {
        this.context.setHeaderTitle('Herramientas');
    }

    render() {
        return (
            <div>
                <Switch>
                    <Route path={`${basePath}/notificaciones`} component={Notificaciones}/>
                    <Route path={`${basePath}/agenda`} component={Agenda}/>
                </Switch>
            </div>
        )
    }
}

Herramientas.contextType = MainContext;
