import React, {useEffect, useState} from "react";
import {useVendedoresService} from "services/hooks";
import {useSnackbar} from "notistack";
import {SimpleDialog} from "components/helpers/dialogs/simple.dialog";
import {Box, MenuItem, TextField} from "@material-ui/core";
import {TableSelect} from "components/estadisticas/settings/table.select";

const FILTERS = [
    {id: "ninguno", desc: "Ninguno"},
    {id: "nivel", desc: "por nivel de usuario"},
    {id: "vendedor", desc: "por vendedor"}
];

export const MapSettingsDialog = props => {
    const [filterType, setFilterType] = useState(FILTERS[0].id);
    const [nivelesState, setNivelesState] = useState({niveles: [], selected: ''});
    const [vendedoresState, setVendedoresState] = useState({all: '', selected: [], data: []});

    const VendedoresService = useVendedoresService();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    useEffect(() => {
        VendedoresService.getAll(
            success => {
                let vendedores = success.data.result;
                vendedores = vendedores.filter(vendedor => vendedor.nivel);

                let niveles = vendedores.map(vendedor => vendedor.nivel === '' ? "Ninguno" : vendedor.nivel);
                niveles = new Set(niveles);
                niveles = Array.from(niveles);

                setVendedoresState(prevState => ({...prevState, data: vendedores}));
                setNivelesState({niveles: niveles, selected: niveles[0]});
            },
            error => enqueueSnackbar(error, {variant: 'error'})
        );
    }, []);

    const onConfirm = () => {
        const selected = filterType === 'nivel' ? nivelesState.selected : vendedoresState.selected;

        if (filterType === 'vendedor' && selected.length <= 0) {
            enqueueSnackbar("Debe seleccionar al menos un vendedor", {variant: 'error'})
            return false;
        }

        const settings = {filterType, selected};
        props.onConfirm(settings);
    }

    return (
        <SimpleDialog
            title={props.title}
            open={props.open}
            keepMounted={props.keepMounted}
            body={
                <SettingsDialogContent
                    pedidosFilterType={filterType}
                    setPedidosFilterType={setFilterType}
                    filterTypes={FILTERS}
                    nivelesState={nivelesState}
                    setNivelesState={setNivelesState}
                    vendedoresState={vendedoresState}
                    setVendedoresState={setVendedoresState}
                />
            }
            fullWidth={true}
            onConfirm={onConfirm}
            onCancel={props.onCancel}
        />
    )
}

export const SettingsDialogContent = ({
                                          pedidosFilterType, setPedidosFilterType, filterTypes, nivelesState,
                                          setNivelesState, vendedoresState, setVendedoresState
                                      }) => {
    const VendedoresService = useVendedoresService();
    return (
        <>
            <Box className={'mb-3'}>
                <TextField
                    select
                    label="Filtro"
                    value={pedidosFilterType}
                    onChange={event => setPedidosFilterType(event.target.value)}
                    fullWidth
                >
                    {filterTypes.map((option, key) => (
                        <MenuItem key={key} value={option.id}>
                            {option.desc}
                        </MenuItem>
                    ))}
                </TextField>
            </Box>

            {pedidosFilterType === "nivel" && (
                <Box>
                    <TextField
                        select
                        label="Nivel"
                        value={nivelesState.selected}
                        onChange={event => setNivelesState(prevState => ({...prevState, selected: event.target.value}))}
                        fullWidth
                    >
                        {nivelesState.niveles.map((option, key) => (
                            <MenuItem key={key} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </TextField>
                </Box>
            )}

            {pedidosFilterType === "vendedor" && (
                <Box className={'mt-2'}>
                    <TableSelect
                        title={'Vendedores'}
                        getData={VendedoresService.getAll.bind(VendedoresService)}
                        getRowId={row => row.id}
                        all={false}
                        selected={vendedoresState.selected}
                        columns={[
                            {name: 'id', title: 'ID'},
                            {name: 'name', title: 'Nombre'}
                        ]}
                        onChangeAll={all => setVendedoresState(prevState => ({...prevState, all: !all}))}
                        onChangeSelected={selected => setVendedoresState(prevState => ({...prevState, selected: selected}))}
                    />
                </Box>
            )}
        </>
    )
}
