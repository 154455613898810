import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {ArticulosArmadosList} from "components/stock/articulosArmados/articulosArmados.list";
import {ArticulosArmadosCreate} from "components/stock/articulosArmados/articulosArmados.create";

const basePath = '/stock/articulosArmados';

export class ArticulosArmados extends Component {
    render() {
        return (
            <div className='niquel-articulosArmados'>
                <Switch>
                    <Route path={`${basePath}/view/:idarticulo`} component={ArticulosArmadosCreate}/>
                    <Route path={`${basePath}/edit/:idarticulo`} component={ArticulosArmadosCreate}/>
                    <Route path={`${basePath}/create`} component={ArticulosArmadosCreate}/>
                    <Route path={`${basePath}`} component={ArticulosArmadosList}/>
                </Switch>
            </div>
        )
    }
}
