import React from 'react';
import {CotizacionesView} from "../../../../ventas/cotizaciones/view/cotizaciones.view";
import ItemInfoDialog from "./item.info.dialog";
import {SimpleDialog} from "../../../../helpers/dialogs/simple.dialog";

export const InfoDialog = (props) => {
    return (
        <>
            {props.item?.tipo !== "PREPAGO" && <ItemInfoDialog {...props}/>}
            {props.item?.tipo === "PREPAGO" &&
                <SimpleDialog
                    open={props.open}
                    onCancel={props.onClose}
                    maxWidth={'lg'}
                    fullWidth={true}
                    title={'Cotización PREPAGO'}
                    body={<CotizacionesView match={{params: {cotizacion: props.item?.id, cliente: props.item?.cliente}}}/>}
                    cancelText={"Cerrar"}
                />
            }
        </>
    )
}
