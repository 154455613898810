import React from "react";
import {NotificationsService} from "services";
import {Badge, ClickAwayListener, Divider, Grow, IconButton, Link, List, ListItem, ListItemText, Paper, Popper, Tooltip, Typography} from "@material-ui/core";
import NotificationsIcon from "@material-ui/icons/Notifications";
import {MainContext} from "contexts/main.context";
import {NavLink} from "react-router-dom";
import {Button} from "@material-ui/core/index";

const moment = require('moment');

export class NotificationsMenu extends React.Component {
    state = {
        menuOpen: false,
        notifications: []
    };

    Notifications = new NotificationsService(this.context);

    setMenuOpen = (menuOpen) => this.setState({menuOpen: menuOpen});

    getNotifications = () => {
        this.Notifications.getTop(
            success => this.setState({notifications: success.data.result}, () => {
                this.setMenuOpen(true);
                this.context.notifications = 0;
            }),
            error => console.log("Error al obtener las notificaciones")
        )
    }

    render() {
        const {menuOpen, notifications} = this.state;
        const {context, anchorRef} = this.props;

        return (
            <>
                <Tooltip title={`Notificaciones (${context.notifications})`}>
                    <IconButton
                        color="inherit"
                        // ref={props.anchorRef}
                        onClick={() => this.getNotifications()}
                    >
                        <Badge badgeContent={context.notifications} color={"error"}>
                            <NotificationsIcon fontSize={"small"}/>
                        </Badge>
                    </IconButton>
                </Tooltip>
                <Popper
                    open={menuOpen}
                    anchorEl={anchorRef.current}
                    transition
                    disablePortal
                    placement={"bottom-end"}
                >
                    {({TransitionProps, placement}) => (
                        <Grow
                            {...TransitionProps}
                            style={{transformOrigin: 'top center'}}
                        >
                            <Paper id="">
                                <ClickAwayListener onClickAway={() => this.setMenuOpen(false)}>
                                    <List dense style={{maxWidth: '20rem', paddingBottom: '0px'}}>
                                        {notifications.length === 0 && (
                                            <ListItem>
                                                <ListItemText secondary={'Aún no tenés notificaciones'}/>
                                            </ListItem>
                                        )}

                                        {notifications.map((notification, key) => (
                                            <div key={key}>
                                                {key !== 0 && (<Divider/>)}
                                                <ListItem>
                                                    <ListItemText
                                                        primary={
                                                            <>
                                                                <Typography variant={"caption"}>de </Typography>
                                                                {notification.from_name === '' ? 'Niquel' : notification.from_name}
                                                                <Tooltip title={'nueva'}>
                                                                    <Badge
                                                                        style={{marginLeft: '10px'}}
                                                                        color={"error"}
                                                                        badgeContent={1 - notification.viewed}
                                                                        variant={"dot"}
                                                                        anchorOrigin={{
                                                                            vertical: 'top',
                                                                            horizontal: 'right',
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            </>
                                                        }
                                                        primaryTypographyProps={{
                                                            variant: 'caption',
                                                            style: {'fontWeight': 'bold'},
                                                            color: 'primary'
                                                        }}
                                                        secondary={
                                                            <>
                                                                <Typography
                                                                    component="span"
                                                                    variant="body2"
                                                                    // className={classes.inline}
                                                                    color="textPrimary"
                                                                >
                                                                    {
                                                                        notification.msg.length > 130 ?
                                                                            <>
                                                                                <span>{notification.msg.substring(0, 130).replace(/\n|\r|\t|\\n/g, ' ')}...</span>
                                                                                <Link component={NavLink} to={'/herramientas/notificaciones'} color={"primary"}
                                                                                      className={'ml-1'}><b>ver más</b></Link>
                                                                            </> :
                                                                            <span>{notification.msg.replace(/\n|\r|\t|\\n/g, ' ')}</span>
                                                                    }
                                                                </Typography>
                                                                <div>{notification.date}</div>
                                                            </>
                                                        }
                                                        secondaryTypographyProps={{
                                                            variant: 'caption',
                                                            component: 'div'
                                                        }}
                                                    />
                                                </ListItem>
                                            </div>
                                        ))}
                                        <Button component={NavLink} to={'/herramientas/notificaciones'} color={"primary"} fullWidth className={'border-top'}>
                                            Ver Todas
                                        </Button>
                                    </List>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </>
        )
    }
}

NotificationsMenu.contextType = MainContext;
