import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {formatPrice} from "assets/utils";

export const ConfirmationDialog = ({open, importe, cliente, direccionEntrega, onConfirm, onCancel, onOpenDireccionEntregaDialog}) => (
    <Dialog
        open={open}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{"¿Generar nuevo pedido?"}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                <p>
                    Estás por generar un nuevo pedido para el cliente <b>{cliente.razsoc}</b> por un total de <b>{formatPrice(importe, true)}</b>
                </p>
                <p>
                    La dirección de entrega seleccionada es
                    <b>{direccionEntrega.id ? ` ${direccionEntrega.id.padStart(5, '0')}-${direccionEntrega.direccion}` : ''}</b>
                    <Button color="primary" size={"small"} style={{marginLeft: '3px'}} onClick={() => {
                        // onCancel();
                        onOpenDireccionEntregaDialog();
                    }}>
                        Modificar
                    </Button>
                </p>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onCancel} color="primary">
                Cancelar
            </Button>
            <Button onClick={onConfirm} color="primary" autoFocus>
                Confirmar
            </Button>
        </DialogActions>
    </Dialog>
);