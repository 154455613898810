import React, {Component, useContext, useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import {MainContext} from "contexts/main.context";
import {EstadisticasGenerales} from "components/estadisticas/dashboards/estadisticas.generales";
import {EstadisticasVentas} from "components/estadisticas/dashboards/ventas/estadisticas.ventas";
import {EstadisticasVendedores} from "components/estadisticas/dashboards/estadisticas.vendedores";

const basePath = '/estadisticas';

export function Estadisticas() {
    const context = useContext(MainContext);

    useEffect(() =>{
        context.setHeaderTitle('Estadísticas');
    }, [])

    return (
        <div className='niquel-estadisticas'>
            <Switch>
                {/*<Route path={`${basePath}/generales`} component={EstadisticasGenerales}/>*/}
                {/*<Route path={`${basePath}/vendedores`} component={EstadisticasVendedores}/>*/}
                <Route path={`${basePath}`} component={EstadisticasVentas}/>
            </Switch>
        </div>
    )
}
