import React, {useContext, useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';
import {MainContext} from "contexts/main.context";
import {Configuracion} from "components/objetivos/general/configuracion/configuracion";
import {Revision} from "components/objetivos/general/revision/revision";
import {VisitasCliente} from "./visitas_cliente/visitas_cliente";

const basePath = '/objetivos';

export function Objetivos() {
    const context = useContext(MainContext);

    useEffect(() => {
        context.setHeaderTitle('Objetivos');
    }, [])

    return (
        <div className='niquel-objetivos'>
            <Switch>
                <Route path={`${basePath}/revision_general`} component={Revision}/>
                {/*<Route path={`${basePath}/visitas_cliente/:cliente/checkin`} component={Checkin}/>*/}
                <Route path={`${basePath}/visitas_cliente`} component={VisitasCliente}/>
                <Route path={`${basePath}`} component={Configuracion}/>
            </Switch>
        </div>
    )
}
