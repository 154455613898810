import React, {useEffect} from "react";

export const ScanReceptor = ({onScanCode}) => {
    useEffect(() => {
        const focusInterval = setInterval(() => {
            if (document.getElementById("inputScan"))
                document.getElementById("inputScan").focus();
        }, 500);

        return () => {
            clearInterval(focusInterval);
        }
    }, []);

    useEffect(() => {
        const inputScan = document.getElementById("inputScan");
        inputScan.addEventListener('paste', inputScanPasteEventListener);
        inputScan.addEventListener('keypress', inputScanKeyboardEventListener);
        return () => {
            inputScan.removeEventListener('paste', inputScanPasteEventListener);
            inputScan.removeEventListener('keypress', inputScanKeyboardEventListener)
        };
    }, [])

    const inputScanPasteEventListener = (event) => {
        const inputValue = event.clipboardData.getData('Text');
        onScanCode(inputValue);
        setTimeout(() => {
            document.getElementById("inputScan").value = '';
        }, 0);
    };

    const inputScanKeyboardEventListener = (event) => {
        const inputValue = event.target.value;

        // Si terminó de escanear el código.
        if (event.keyCode === 13 || event.keyCode === 9 || event.keyCode === 10) {
            onScanCode(inputValue);
            document.getElementById("inputScan").value = '';
        }
    };

    return (
        <textarea
            id={'inputScan'}
            style={{
                width: 500,
                opacity: 0,
                position: 'fixed'
            }}
            autoComplete={'off'}
            inputMode={"none"}
        />
    )
}
