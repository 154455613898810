import React, {Component} from 'react'
import {MainContext} from "contexts/main.context";
import {withSnackbar} from "notistack";
import {Box, Button, Grid, InputAdornment, MenuItem, TextField, Tooltip, Typography} from '@material-ui/core/index';
import ListIcon from '@material-ui/icons/List';
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers/index";
import MomentUtils from '@date-io/moment/build/index';
import 'moment/locale/es';
import ArticulosMainTable from "components/helpers/articulos/articulos.main.table";
import {LeyendasDialog} from 'components/helpers/dialogs/leyendas.dialog';
import {formatPrice} from "assets/utils";
import './facturas.operation.scss';
import SubHeader from "components/helpers/subHeader/subHeader";

export class FacturasOperation extends Component {
    state = {
        leyendasDialogOpen: false
    };

    onOpenLeyendasDialog = () => this.setState({leyendasDialogOpen: true});
    onCloseLeyendasDialog = () => this.setState({leyendasDialogOpen: false});
    onConfirmLeyendasDialog = (leyenda1, leyenda2, leyenda3, leyenda4) => {
        this.props.onChangeHeaderData('leyenda1', leyenda1);
        this.props.onChangeHeaderData('leyenda2', leyenda2);
        this.props.onChangeHeaderData('leyenda3', leyenda3);
        this.props.onChangeHeaderData('leyenda4', leyenda4);
        this.onCloseLeyendasDialog();
    };

    render() {
        const {loggedUser} = this.context;
        const {leyendasDialogOpen} = this.state;
        const {
            operation, cliente, documents, id, condComerciales, vendedores, tipoasientos, talfacturas, talremitos, jurisdicciones, transportes,
            articulos, bonificacionesLitros, headerData, selectedArticulos, importe, importeBonificado, totalArticulos, importeArticulos,
            totalLitros, maxArticulos, onChangeHeaderData, onAddArticulos, onEditArticulo, onRemoveArticulos, onChangeDocument
        } = this.props;
        const editable = operation === 'CREATE';

        return (
            <Box className='niquel-facturas-operation'>
                <SubHeader
                    title={'Facturas'}
                    subtitle={'Revisión de Factura'}
                    cliente={cliente}
                    options={documents}
                    optionsTitle={'Factura'}
                    optionSelectedId={id}
                    optionsDescriptionField={'factura'}
                    optionsDescriptionSecondaryField={'ingreso'}
                    onChangeOption={onChangeDocument}
                />
                <Box className='container-xl mt-3 mb-3'>
                    <Grid container spacing={3}>
                        <Grid container item md={10}>
                            <Grid container item alignItems='center' spacing={3}>
                                <Grid item>
                                    <TextField
                                        label="Bonificación"
                                        className='mr-2 input bonificacion'
                                        value={headerData.bonificacion}
                                        onChange={event => onChangeHeaderData('bonificacion', event.target.value)}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            readOnly: editable === false || (loggedUser.role !== 'A' && loggedUser.role !== 'O'),
                                        }}
                                    />
                                    <TextField
                                        select
                                        label="Lista Precio"
                                        className='mr-2 select listaPrecio'
                                        value={headerData.listaprecio}
                                        onChange={event => {
                                            onChangeHeaderData('listaprecio', event.target.value);
                                            if (selectedArticulos.length > 0)
                                                this.props.enqueueSnackbar('El cambio de lista de precios solo afectará a los nuevos items que se agreguen al pedido.')
                                        }}
                                        InputProps={{
                                            readOnly: editable === false
                                        }}
                                    >
                                        {['1', '2', '3', '4', '5', '6'].map(value => (
                                            <MenuItem key={value} value={value}>
                                                Lista {value}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    {(loggedUser.role === 'A' || loggedUser.role === 'S' || loggedUser.role === 'O') && (
                                        <TextField
                                            label="Usuario"
                                            className='mr-2 input'
                                            value={headerData.usuario}
                                            onChange={event => onChangeHeaderData('usuario', event.target.value)}
                                            InputProps={{
                                                readOnly: editable === false || (loggedUser.role !== 'A' && loggedUser.role !== 'O')
                                            }}
                                        />
                                    )}
                                    {(loggedUser.role === 'A' || loggedUser.role === 'S' || loggedUser.role === 'O') && (
                                        <TextField
                                            select
                                            label="Vendedor"
                                            className='mr-2 select'
                                            value={headerData.vendedor}
                                            onChange={event => onChangeHeaderData('vendedor', event.target.value)}
                                            InputProps={{
                                                readOnly: editable === false || (loggedUser.role !== 'A' && loggedUser.role !== 'O')
                                            }}
                                        >
                                            {vendedores.map(option => (
                                                <MenuItem key={option.id} value={option.id}>
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                    <TextField
                                        label="Comisión"
                                        className='mr-2 input comision'
                                        value={formatPrice(headerData.comision)}
                                        onChange={event => onChangeHeaderData('comision', event.target.value)}
                                        InputProps={{
                                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                            readOnly: editable === false || (loggedUser.role !== 'A' && loggedUser.role !== 'O')
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            disableToolbar
                                            autoOk
                                            className='mr-2 date'
                                            label="Ingreso"
                                            format="DD/MM/Y"
                                            disablePast={editable}
                                            value={headerData.ingreso}
                                            onChange={moment => onChangeHeaderData('ingreso', moment)}
                                            InputProps={{
                                                readOnly: editable === false,
                                                onClick: (e) => editable === false && e.stopPropagation()
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Tooltip title='Leyendas'>
                                    <Button color="primary" variant='outlined' className='ml-2' onClick={this.onOpenLeyendasDialog}>
                                        <ListIcon fontSize={"default"} className='mr-1'/>
                                        Leyendas
                                    </Button>
                                </Tooltip>
                                <Grid item>
                                    <TextField
                                        select
                                        label="Juris. IIBB"
                                        className='mr-2 select'
                                        value={headerData.jurisdiccion}
                                        onChange={event => onChangeHeaderData('jurisdiccion', event.target.value)}
                                        InputProps={{
                                            readOnly: editable === false
                                        }}
                                    >
                                        {jurisdicciones.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.description}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        select
                                        label="Cond. Comer."
                                        className='mr-2 select condComercial'
                                        value={headerData.condcomercial}
                                        onChange={event => onChangeHeaderData('condcomercial', event.target.value)}
                                        InputProps={{
                                            readOnly: editable === false
                                        }}
                                    >
                                        {condComerciales.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.description}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        label="Nota de Compra"
                                        className='mr-2 input'
                                        value={headerData.notacompra}
                                        onChange={event => onChangeHeaderData('notacompra', event.target.value)}
                                        InputProps={{
                                            readOnly: editable === false
                                        }}
                                    />
                                    <TextField
                                        select
                                        label="Transporte"
                                        className='mr-2 select'
                                        value={headerData.transporte}
                                        onChange={event => onChangeHeaderData('transporte', event.target.value)}
                                        InputProps={{
                                            readOnly: editable === false
                                        }}
                                    >
                                        {transportes.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.description}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        label="Factura"
                                        className='mr-2 input'
                                        value={headerData.factura}
                                        onChange={event => onChangeHeaderData('factura', event.target.value)}
                                        InputProps={{
                                            readOnly: editable === false || (loggedUser.role !== 'A' && loggedUser.role !== 'O')
                                        }}
                                    />
                                    <TextField
                                        label="Remito"
                                        className='mr-2 input'
                                        value={headerData.remito}
                                        onChange={event => onChangeHeaderData('remito', event.target.value)}
                                        InputProps={{
                                            readOnly: editable === false || (loggedUser.role !== 'A' && loggedUser.role !== 'O')
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label="Pedidos Asoc."
                                        className='mr-2 input'
                                        value={headerData.pedidos.join(' - ')}
                                        InputProps={{
                                            readOnly: true
                                        }}
                                    />
                                    <TextField
                                        select
                                        label="Tal. Factura"
                                        className='mr-2 select talonario'
                                        value={headerData.talfactura}
                                        onChange={event => onChangeHeaderData('talfactura', event.target.value)}
                                        InputProps={{
                                            readOnly: editable === false
                                        }}
                                    >
                                        {talfacturas.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.description}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        select
                                        label="Tal. Remito"
                                        className='mr-2 select talonario'
                                        value={headerData.talremito}
                                        onChange={event => onChangeHeaderData('talremito', event.target.value)}
                                        InputProps={{
                                            readOnly: editable === false
                                        }}
                                    >
                                        {talremitos.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.description}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                    <TextField
                                        select
                                        label="Tipo Asiento"
                                        className='mr-2 select talonario'
                                        value={headerData.tipoasiento}
                                        onChange={event => onChangeHeaderData('tipoasiento', event.target.value)}
                                        InputProps={{
                                            readOnly: editable === false
                                        }}
                                    >
                                        {tipoasientos.map(option => (
                                            <MenuItem key={option.id} value={option.id} title={option.description}>
                                                {option.description}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container item md={2} direction={"column"} spacing={1}>
                            <Tooltip title={
                                formatPrice(importeBonificado, true) +
                                ' + ' +
                                formatPrice(headerData.iva, true) +
                                ' + ' +
                                ' (total + IVA + IIBB)'
                            }>
                                <Grid item>
                                    <Typography variant={"caption"} display="block" align="right" color={"textSecondary"}>Total + IVA + IIBB</Typography>
                                    <Typography variant="h6" align="right">{formatPrice(importe, true)}</Typography>
                                </Grid>
                            </Tooltip>
                            <Tooltip
                                title={formatPrice(importeArticulos, true) + ' - ' + formatPrice(headerData.bonificacion) + '% (total - bonificación)'}
                            >
                                <Grid item>
                                    <Typography variant="caption" display="block" align="right" color={"textSecondary"}>Total Bonificado</Typography>
                                    <Typography variant="subtitle1" align='right'>{formatPrice(importeBonificado, true)}</Typography>
                                </Grid>
                            </Tooltip>
                            <Grid item>
                                <Typography variant="caption" display="block" align="right" color={"textSecondary"}>Total sin bonificar</Typography>
                                <Typography variant="subtitle1" align='right'>{formatPrice(importeArticulos, true)}</Typography>
                            </Grid>
                            {headerData.isPrepago && (
                                <Grid item>
                                    <Tooltip title={"Factura generada a partir de un pedido prepago"}>
                                        <Typography
                                            variant={"body1"}
                                            component={'div'}
                                            align={"right"}
                                            style={{
                                                color: 'rgba(0, 150, 136, 1)',
                                                // lineHeight: '0.9rem'
                                            }}
                                        >
                                            <b>FACTURA PREPAGA</b>
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>

                    <Box className='factura-articulos'>
                        <ArticulosMainTable
                            articulos={articulos}
                            selected={selectedArticulos}
                            editable={editable}
                            mode={'F'}
                            maxArticulos={maxArticulos}
                            listaprecio={`lista${headerData.listaprecio}`}
                            bonificacionesLitros={bonificacionesLitros}
                            totalArticulos={totalArticulos}
                            importeArticulos={importeArticulos}
                            totalLitros={totalLitros}
                            bonificacionGeneral={headerData.bonificacion}
                            cliente={cliente}
                            onAdd={onAddArticulos}
                            onEdit={onEditArticulo}
                            onRemove={onRemoveArticulos}
                        />
                    </Box>

                    <LeyendasDialog
                        open={leyendasDialogOpen}
                        editable={editable}
                        leyenda1={headerData.leyenda1}
                        leyenda2={headerData.leyenda2}
                        leyenda3={headerData.leyenda3}
                        leyenda4={headerData.leyenda4}
                        onConfirm={this.onConfirmLeyendasDialog}
                        onCancel={this.onCloseLeyendasDialog}
                    />
                </Box>
            </Box>
        )
    }
}

FacturasOperation.contextType = MainContext;
FacturasOperation = withSnackbar(FacturasOperation);

FacturasOperation.defaultProps = {
    operation: 'CREATE',
    cliente: {},
    documents: [],
    condComerciales: [],
    vendedores: [],
    articulos: [],
    headerData: {
        bonificacion: 0,
        listaprecio: '1',
        condcomercial: '',
        notacompra: '',
        usuario: '',
        vendedor: '',
        comision: 0,
        ingreso: (new MomentUtils()).date(new Date()),
        entrega: (new MomentUtils()).date(new Date()),
        leyenda1: '',
        leyenda2: '',
        leyenda3: '',
        leyenda4: ''
    },
    selectedArticulos: [],
    importe: 0,
    importeBonificado: 0
};
