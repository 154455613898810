import * as React from 'react';
import PropTypes from 'prop-types';
import {ReactGrid} from "components/helpers/reactGrid/reactGrid";
import {PedidoStatusFormatter, sortDate, sortNumeric} from "assets/utils";
import {ArticuloDetailsButton} from "components/helpers/articulos/articuloDetails";

export default class ArticulosTable extends React.PureComponent {
    columns = [
        {name: 'id', numeric: false, disablePadding: false, title: 'Artículo', width: 170},
        {name: 'detalle', numeric: false, disablePadding: false, title: 'Detalle', style: {whiteSpace: 'break-spaces'}},
        {name: 'custom1', title: ' '}
    ];

    columnExtensions = [
        {columnName: 'id', width: 170},
        {columnName: 'custom1', width: 150, component: ArticuloDetailsButton, align: 'center'}
    ];

    changeArticulosSelection = articulosSelectedId => {
        const articulosSelectedData = [];

        articulosSelectedId.forEach(id => {
            let articulo = this.props.articulos.find(articulo => articulo.id === id);
            this.props.setDefaultsArticulo(articulo);
            articulosSelectedData.push(articulo);
        });

        this.props.changeArticulosSelection(articulosSelectedId, articulosSelectedData);
    };

    render() {
        const {articulos} = this.props;

        return (
            <div className='niquel-articulosTable'>
                <ReactGrid
                    height={280}
                    columns={this.columns}
                    columnExtensions={this.columnExtensions}
                    rows={articulos}
                    getRowId={row => row.id}
                    enableFiltering={false}
                    enableSorting={true}
                    enableSearch={true}
                    enableSelection={true}
                    showSelectAll={false}
                    selectedRows={this.props.articulosSelectedId}
                    onSelectionChange={this.changeArticulosSelection}
                    elevation={0}
                />
            </div>
        );
    }
}

ArticulosTable.propTypes = {
    articulos: PropTypes.array.isRequired,
    articulosSelectedId: PropTypes.array.isRequired,
    changeArticulosSelection: PropTypes.func.isRequired
};

ArticulosTable.defaultProps = {
    articulos: []
};
