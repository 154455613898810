import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {NotasCreditoClientes} from 'components/ventas/notasCredito/clientes/notasCredito.clientes';
import {NotasCreditoList} from 'components/ventas/notasCredito/list/notasCredito.list';
import {NotasCreditoView} from "components/ventas/notasCredito/view/notasCredito.view";

const basePath = '/ventas/notasCredito';

export class NotasCredito extends Component {
    render() {
        return (
            <div className='niquel-notasCredito niquel-view-with-bottom-margin'>
                <Switch>
                    <Route path={`${basePath}/:cliente/view/:notaCredito`} component={NotasCreditoView}/>
                    <Route exact path={`${basePath}/:cliente`} component={NotasCreditoList}/>
                    <Route path={`${basePath}`} component={NotasCreditoClientes}/>
                </Switch>
            </div>
        )
    }
}
