import React, {Component} from 'react'
import {Box, Grid} from "@material-ui/core/index";
import {ReactGrid} from 'components/helpers/reactGrid/reactGrid';
import {ClientesService, EmailService, ListadosService, MastersService, VendedoresService} from "services";
import {withSnackbar} from "notistack";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import 'moment/locale/es';
import {MainContext} from "contexts/main.context";
import SubHeader from "components/helpers/subHeader/subHeader";
import {MailDialog} from "components/helpers/dialogs/mail.dialog";
import SpeedDial from "@material-ui/lab/SpeedDial/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction/SpeedDialAction";
import PDFIcon from "@material-ui/icons/PictureAsPdf";
import DownloadIcon from "@material-ui/icons/GetAppRounded";
import ShowIcon from "@material-ui/icons/DesktopWindowsRounded";
import PrintIcon from "@material-ui/icons/PrintRounded";
import EmailIcon from "@material-ui/icons/EmailRounded";
import printJS from 'print-js'
import {PDFDialog} from "components/helpers/dialogs/pdf.dialog";

export class CuentasCorrientes extends Component {
    state = {
        fechaInicio: (new MomentUtils()).date(new Date()).subtract(30, 'days'),
        fechaFin: (new MomentUtils()).date(new Date()),
        clientes: {
            columns: [
                {name: 'id', title: 'ID'},
                {name: 'razsoc', title: 'Razón Social'},
                {name: 'apellido', title: 'Apellido'},
                {name: 'localidad', title: 'Localidad'}
            ],
            columnExtensions: [
                {columnName: 'id', width: 100},
                {columnName: 'apellido', width: 120},
                {columnName: 'localidad', width: 150},
            ],
            data: [],
            selected: [],
            all: true
        },
        vendedores: {
            columns: [
                {name: 'id', title: 'ID'},
                {name: 'name', title: 'Nombre'}
            ],
            columnExtensions: [
                {columnName: 'id', width: 150},
            ],
            data: [],
            selected: [],
            all: true
        },
        pdfBlob: null,
        pdfUrl: '',
        mailDialogOpen: false,
        pdfDialogOpen: false,
        dialOpen: false,
    };

    MastersService = new MastersService(this.context);
    VendedoresService = new VendedoresService(this.context);
    ClientesService = new ClientesService(this.context);
    ListadosService = new ListadosService(this.context);
    EmailService = new EmailService(this.context);

    componentDidMount() {
        this.ClientesService.getAll(
            success => this.setState(prevState => (prevState.clientes.data = success.data.result)),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.VendedoresService.getAll(
            success => this.setState(prevState => (prevState.vendedores.data = success.data.result)),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    onChangeSwitchAll = field => this.setState(prevState => (prevState[field].all = !prevState[field].all));
    onChangeSelection = (field, value) => this.setState({[field]: value});

    onSelect = (field, selected) => {
        if (selected.length <= 23)
            this.setState(prevState => (prevState[field].selected = selected));
        else
            this.props.enqueueSnackbar(`Solo puede seleccionar hasta 23 ${field}.`, {variant: 'warning'});
    };

    getPDF = (fnSuccess = () => {
    }) => {
        // Valido que haya al menos 1 cliente seleccionado.
        if (this.state.clientes.selected.length === 0 && this.state.clientes.all === false) {
            this.props.enqueueSnackbar('Debe seleccionar al menos un cliente para generar el listado.', {variant: 'error'});
            return false;
        }

        // Valido que haya al menos 1 vendedor seleccionado.
        if (this.state.vendedores.selected.length === 0 && this.state.vendedores.all === false) {
            this.props.enqueueSnackbar('Debe seleccionar al menos un vendedor para generar el listado.', {variant: 'error'});
            return false;
        }

        // Si seleccionó todos mando vacío (así lo espera el API)
        let clientes = [];
        if (this.state.clientes.all === false)
            clientes = this.state.clientes.data.filter((cliente, index) => this.state.clientes.selected.indexOf(index) !== -1);

        let vendedores = [];
        if (this.state.vendedores.all === false)
            vendedores = this.state.vendedores.data.filter((vendedor, index) => this.state.vendedores.selected.indexOf(index) !== -1);

        this.ListadosService.cuentasCorrientes(
            clientes,
            vendedores,
            this.state.fechaInicio.format('DD/MM/YY'),
            this.state.fechaFin.format('DD/MM/YY'),
            file => this.setState({pdfBlob: file}, fnSuccess),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    };

    // Show PDF
    onOpenPdfDialog = () => this.setState({pdfDialogOpen: true});
    onClosePdfDialog = () => this.setState({pdfDialogOpen: false});

    onShowPDF = () => {
        this.getPDF(
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                this.setState({pdfUrl: fileURL}, this.onOpenPdfDialog);
            }
        );
    };

    // Print PDF
    onPrintPDF = () => {
        this.getPDF(
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                printJS(fileURL);
            }
        );
    };

    // Download PDF
    onDownloadPdf = () => {
        this.getPDF(
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = fileURL;
                a.download = `Cuentas Corrientes`;
                a.click();
                window.URL.revokeObjectURL(fileURL);
                this.props.enqueueSnackbar('El archivo se descargó correctamente. Revise su carpeta de descargas.', {variant: 'success'});
            }
        );
    };

    // Email
    onOpenMailDialog = () => this.setState({mailDialogOpen: true});

    dialActions = [
        {icon: <EmailIcon/>, name: 'Enviar por Email', action: this.onOpenMailDialog},
        {icon: <PrintIcon/>, name: 'Imprimir', action: this.onPrintPDF},
        {icon: <DownloadIcon/>, name: 'Descargar', action: this.onDownloadPdf},
        {icon: <ShowIcon/>, name: 'Ver en Pantalla', action: this.onShowPDF},
    ];

    onCloseMailDialog = () => this.setState({mailDialogOpen: false});

    sendMail = mailData => {
        let sendMail = () => {
            // Paso los parámetros a un formData para poder enviar el blob (attachment)
            var formData = new FormData();
            formData.append('attachment', this.state.pdfBlob);
            formData.append('data', JSON.stringify(mailData));

            this.EmailService.send(
                formData,
                response => {
                    this.onCloseMailDialog();
                    this.props.enqueueSnackbar('El email se envió correctamente', {variant: 'success'});
                },
                error => this.props.enqueueSnackbar(error, {variant: 'error'})
            );
        };

        if (mailData.attachFile)
            this.getPDF(() => sendMail());
        else
            sendMail();
    };

    onDialOpen = () => this.setState({dialOpen: true});
    onDialClose = () => this.setState({dialOpen: false});

    render() {
        const {fechaInicio, fechaFin, clientes, vendedores, mailDialogOpen, pdfDialogOpen, pdfUrl, dialOpen} = this.state;
        return (
            <React.Fragment>
                <Box className='niquel-listados-cuentasCorrientes'>
                    <SubHeader
                        title={'Cuentas Corrientes'}
                    />
                    <Box className='container-xl mt-3 mb-3'>
                        <Box className='wrapper'>
                            <Grid container spacing={2}>
                                <Grid item>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            autoOk
                                            style={{width: '6rem'}}
                                            label="Fecha Inicio"
                                            format="DD/MM/Y"
                                            value={fechaInicio}
                                            onChange={moment => this.onChangeSelection('fechaInicio', moment)}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            autoOk
                                            style={{width: '6rem'}}
                                            label="Fecha Fin"
                                            format="DD/MM/Y"
                                            value={fechaFin}
                                            onChange={moment => this.onChangeSelection('fechaFin', moment)}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                            </Grid>
                            <Box className='mt-3'>
                                <ReactGrid
                                    columns={clientes.columns}
                                    columnExtensions={clientes.columnExtensions}
                                    rows={clientes.data}
                                    title={'Clientes'}
                                    height={300}
                                    enableFiltering={false}
                                    enableSorting={true}
                                    enableSearch={true}
                                    enableSelection={true}
                                    selectedRows={clientes.selected}
                                    onSelectionChange={selected => this.onSelect('clientes', selected)}
                                    onDelete={selected => this.onSelect('clientes', [])}
                                    showSelectAll={false}
                                    showSwitchAll={true}
                                    switchAllValue={clientes.all}
                                    onChangeSwitchAll={() => this.onChangeSwitchAll('clientes')}
                                />
                            </Box>
                            {(this.context.loggedUser.role === 'A' || this.context.loggedUser.role === 'S') && (
                                <Box className='mt-2'>
                                    <ReactGrid
                                        columns={vendedores.columns}
                                        columnExtensions={vendedores.columnExtensions}
                                        rows={vendedores.data}
                                        title={'Vendedores'}
                                        height={300}
                                        enableFiltering={false}
                                        enableSorting={true}
                                        enableSearch={true}
                                        enableSelection={true}
                                        selectedRows={vendedores.selected}
                                        onSelectionChange={selected => this.onSelect('vendedores', selected)}
                                        onDelete={selected => this.onSelect('vendedores', [])}
                                        showSelectAll={false}
                                        showSwitchAll={true}
                                        switchAllValue={vendedores.all}
                                        onChangeSwitchAll={() => this.onChangeSwitchAll('vendedores')}
                                    />
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <Box className='niquel-fab-btn'>
                        <SpeedDial
                            ariaLabel="cuentas_corrientes"
                            icon={<PDFIcon/>}
                            onBlur={this.onDialClose}
                            onClick={this.onDialOpen}
                            onClose={this.onDialClose}
                            onFocus={this.onDialOpen}
                            onMouseEnter={this.onDialOpen}
                            onMouseLeave={this.onDialClose}
                            open={dialOpen}
                        >
                            {this.dialActions.map(action => (
                                <SpeedDialAction
                                    key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        action.action()
                                    }}
                                />
                            ))}
                        </SpeedDial>
                    </Box>
                </Box>
                <MailDialog
                    open={mailDialogOpen}
                    onConfirm={this.sendMail}
                    onCancel={this.onCloseMailDialog}
                    fileName={`Cuentas_Corrientes.pdf`}
                    attachFile={true}
                    // to={cliente.email}
                    subject={`Cuentas Corrientes`}
                    body={`Estimado,  \n\nSe adjunta el listado de cuentas corrientes.`}
                />
                <PDFDialog
                    open={pdfDialogOpen}
                    onClose={this.onClosePdfDialog}
                    pdfUrl={pdfUrl}
                    pdfName={`Cuentas_Corrientes.pdf`}
                />
            </React.Fragment>
        )
    }
}

CuentasCorrientes.contextType = MainContext;
CuentasCorrientes = withSnackbar(CuentasCorrientes);
