import Card from "@material-ui/core/Card";
import {Divider, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import NavIcon from "@material-ui/icons/ArrowForwardIosRounded";
import React from "react";
import CardContent from "@material-ui/core/CardContent";
import ButtonBase from "@material-ui/core/ButtonBase";
import {CheckCircleOutlineRounded, HighlightOffRounded, QueryBuilderRounded} from "@material-ui/icons";
import {formatDireccion, getPedidosStatusInfo} from "assets/utils";

export function CardPedido(props) {
    const {id, direccion, cliente, estado, onClick} = props;
    return (
        <>
            <Card className={'m-0 p-0'} elevation={0}>
                <ButtonBase style={{width: '100%', display: 'block', textAlign: 'left'}} onClick={onClick}>
                    <CardContent>
                        <Grid container alignItems={"center"}>
                            <Grid item xs={11}>
                                <Typography variant={"body1"} color="textSecondary" className={'mt-2'}>
                                    {cliente}
                                </Typography>
                                <Typography variant="body1">
                                    {formatDireccion(direccion)}
                                </Typography>
                                <Typography color="textSecondary" variant={"body2"} className={'align-items-center d-flex'} gutterBottom>
                                    <span className={'mr-2'}>Pedido {id}</span>
                                    {(estado === 'D' || estado === 'F') ?
                                        <QueryBuilderRounded color={"disabled"}/> :
                                        getPedidosStatusInfo(estado, true).icon
                                    }
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <NavIcon color={"primary"}/>
                            </Grid>
                        </Grid>
                    </CardContent>
                </ButtonBase>
            </Card>
            <Divider/>
        </>
    );
}
