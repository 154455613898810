import React from "react";
import Box from "@material-ui/core/Box";
import {formatQuantity} from "assets/utils";
import {Paper, Typography} from "@material-ui/core";
import {ResponsiveBar} from "@nivo/bar";

const moment = require('moment');

const formatId = (id, periodo = 'actual', fullYear = false) => {
    let [month, anioActual = ''] = id.split('_');

    let ret = `${moment(month, 'M').format('MMM')}`;

    if (!fullYear)
        anioActual = anioActual.slice(-2);

    return `${ret} ${anioActual}`;
}

export const VentasMensualesPorCalidadChart = React.memo(function ({height, data}) {
    const keys = ["cant_sp", "cant_sd"];
    const colors = {cant_sp: "rgba(255,179,26,0.8)", cant_sd: "rgba(0, 150, 136, 0.8)"};
    const descs = {cant_sp: "Premium", cant_sd: "Standard"};
    const porcs = {cant_sp: "porc_sp", cant_sd: "porc_sd"};

    return (
        <Box style={{height: `${height}px`, width: '100%'}} className={'d-flex justify-content-center align-items-center'}>
            {(data.length <= 0) ?
                <Typography align={"center"} color={"textSecondary"}>
                    No hay datos para mostrar
                </Typography> :
                <ResponsiveBar
                    data={data}
                    keys={keys}
                    indexBy={'id'}
                    margin={{top: 10, right: 80, bottom: 60, left: 80}}
                    padding={0.3}
                    groupMode="stacked"
                    valueScale={{type: 'linear'}}
                    indexScale={{type: 'band', round: true}}
                    // valueFormat={{format: '', enabled: false}}
                    colors={[colors.cant_sp, colors.cant_sd]}
                    borderColor={{from: 'color', modifiers: [['darker', 1.6]]}}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Meses',
                        legendPosition: 'middle',
                        legendOffset: 40,
                        format: (id) => formatId(id)
                    }}
                    axisLeft={{
                        tickSize: 0,
                        tickPadding: 15,
                        tickRotation: 0,
                        legend: 'Total Mensual',
                        legendPosition: 'middle',
                        legendOffset: -50,
                        format: '~s'
                    }}
                    enableGridY={false}
                    labelSkipWidth={50}
                    // labelTextColor={{from: 'color', modifiers: [['darker', 2.9]]}}
                    labelTextColor={"#333333"}
                    labelFormat={value => formatQuantity(value)}
                    label={d => `${formatQuantity(d.value)}`}
                    isInteractive={true}
                    tooltip={(props) => {
                        return (
                            <Paper elevation={1} className={'p-2'}>
                                <Typography variant={"body2"} component={"div"} className={"mb-2"} align={"center"}>
                                    <b>{formatId(props.indexValue, props.id, true)}</b>
                                </Typography>
                                <Typography variant={"body2"} component={"span"}>
                                    Total:
                                </Typography>
                                <Typography variant={"body2"}>
                                    <b>{formatQuantity(props.data["total"])} (100%)</b>
                                </Typography>
                                <Typography variant={"body2"} style={{color: colors[props.id]}} component={"span"} className={"mt-2"}>
                                    <b>{descs[props.id]}: </b>
                                </Typography>
                                <Typography variant={"body2"}>
                                    <b>{formatQuantity(props.value)} ({props.data[porcs[props.id]]}%)</b>
                                </Typography>
                            </Paper>
                        )
                    }}
                    pointSize={10}
                    pointColor={{theme: 'background'}}
                    pointBorderWidth={2}
                    pointBorderColor={{from: 'serieColor'}}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                    legends={[
                        {
                            dataFrom: 'keys',
                            data: keys.map(key => ({id: key, label: descs[key], color: colors[key]})),
                            anchor: 'top-right',
                            direction: 'column',
                            justify: false,
                            translateX: 90,
                            translateY: -10,
                            itemWidth: 100,
                            itemHeight: 20,
                            itemsSpacing: 2,
                            symbolSize: 20,
                            itemDirection: 'left-to-right'
                        }
                    ]}
                />
            }
        </Box>
    )
})
