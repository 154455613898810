import React, {Component} from 'react'
import {Box} from "@material-ui/core/index";
import {ReactGrid} from 'components/helpers/reactGrid/reactGrid';
import {ClientesService, VendedoresService} from "services";
import {withSnackbar} from "notistack";
import {ClientesInhabilitadosDialog} from "./dialogs/clientesInhabilitados.dialog";
import {MainContext} from "contexts/main.context";
import SubHeader from "components/helpers/subHeader/subHeader";

export class ClientesInhabilitados extends Component {
    state = {
        columns: [
            {name: 'id', title: 'ID', width: 150},
            {name: 'name', title: 'Nombre'},
        ],
        rows: [{
            id: this.context.loggedUser.id,
            name: this.context.loggedUser.name
        }],
        clientesInhabilitadosDialogOpen: false,
        clientesInhabilitados: [],
        vendedor: ''
    };

    VendedoresService = new VendedoresService(this.context);
    ClientesService = new ClientesService(this.context);

    componentDidMount() {
        this.VendedoresService.getAll(
            response => this.setState({rows: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    onOpenClientesInhabilitadosDialog = (vendedor) => {
        this.ClientesService.getInhabilitados(
            vendedor.id,
            response => {
                this.setState(prevState => {
                    prevState.vendedor = vendedor;
                    prevState.clientesInhabilitados = response.data.result;
                    prevState.clientesInhabilitadosDialogOpen = true;
                    return prevState;
                })
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'}));
    };
    onCloseClientesInhabilitadosDialog = () => this.setState({clientesInhabilitadosDialogOpen: false});

    render() {
        const {columns, rows, clientesInhabilitadosDialogOpen, clientesInhabilitados, vendedor} = this.state;
        return (
            <Box className='niquel-listados-clientesInhabilitados'>
                <SubHeader
                    title={'Clientes Inhabilitados'}
                />
                <Box className='container-xl mt-3 mb-3'>
                    <ReactGrid
                        title={'Seleccione un vendedor'}
                        height={-200}
                        columns={columns}
                        rows={rows}
                        enableSorting={true}
                        enableSearch={true}
                        enableSelection={false}
                        rowClick={true}
                        onRowClick={this.onOpenClientesInhabilitadosDialog}
                    />
                </Box>
                <ClientesInhabilitadosDialog
                    open={clientesInhabilitadosDialogOpen}
                    clientesInhabilitados={clientesInhabilitados}
                    vendedor={vendedor}
                    onConfirm={() => {
                        this.onCloseClientesInhabilitadosDialog();
                    }}
                    onCancel={this.onCloseClientesInhabilitadosDialog}
                />
            </Box>
        )
    }
}

ClientesInhabilitados.contextType = MainContext;
ClientesInhabilitados = withSnackbar(ClientesInhabilitados);
