import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import React from "react";
import CardContent from "@material-ui/core/CardContent";
import {CloseRounded, DoneRounded} from "@material-ui/icons";
import Box from "@material-ui/core/Box";
import {ButtonBase} from "@material-ui/core";

export function CardArticulo(props) {
    const {id, detalle, onClick} = props;
    let {cantidad, scanned} = props;
    cantidad = parseInt(cantidad);
    scanned = parseInt(scanned);

    const allItemsScanned = scanned === cantidad;
    const itemsRemainForScan = scanned < cantidad;
    const tooMuchItemsScanned = scanned > cantidad;

    const backgroundGreen = 'rgb(0, 150, 136,0.2)';
    const backgroundRed = 'rgba(255,3,3,0.2)';

    return (
        <>
            <Card className={'m-0 p-0'} elevation={0} style={{borderRadius: 0}}>
                <ButtonBase style={{width: '100%', display: 'block', textAlign: 'left'}} onClick={onClick}>
                    <CardContent style={{
                        padding: '12px',
                        backgroundColor: tooMuchItemsScanned ? backgroundRed : allItemsScanned ? backgroundGreen : 'white'
                    }}
                    >
                        <Box>
                            <Box className={'d-flex justify-content-between'}>
                                <Typography color="textPrimary" component={"span"}>
                                    {id}
                                </Typography>
                                {allItemsScanned && (
                                    <DoneRounded fontSize={"small"} color={"primary"} className={'ml-1'}/>
                                )}
                                {tooMuchItemsScanned && (
                                    <CloseRounded fontSize={"small"} color={"error"} className={'ml-1'}/>
                                )}
                                <Typography
                                    component={"span"}
                                    variant={"body2"}
                                    color={tooMuchItemsScanned ? "error" : allItemsScanned ? "primary" : "inherit"}
                                    style={{width: '100%'}} align={"right"}
                                >
                                    Leidos <big>{scanned || 0}</big><b> de <big>{cantidad}</big></b>
                                </Typography>
                            </Box>
                            <Typography variant="body2" color={"textSecondary"} component={"div"}>
                                {detalle}
                            </Typography>
                        </Box>
                    </CardContent>
                </ButtonBase>
            </Card>
        </>
    );
}
