import React from "react";
import PropTypes from "prop-types";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide} from "@material-ui/core";
import {formatPrice} from "assets/utils";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export class CobranzaDocumentoInfoDialog extends React.Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                fullScreen={this.props.fullScreen}
                TransitionComponent={this.props.fullScreen ? Transition : undefined}
                fullWidth
                maxWidth={"sm"}
                classes={{paperFullScreen: 'niquel-dialog-full-screen'}}
            >
                <CobranzaDocumentoInfoDialogContent {...this.props} />
            </Dialog>
        )
    }
}

class CobranzaDocumentoInfoDialogContent extends React.Component {
    getTipoDocumento = tipo => {
        switch (tipo) {
            case 'FAC':
                return 'Factura';
            case 'DEB':
                return 'Nota de Débito';
            case 'CRE':
                return 'Nota de Crédito';
            default:
                return '-';
        }
    };

    render() {
        const {documento} = this.props;

        return (
            <React.Fragment>
                <DialogTitle id="alert-dialog-title">{'Detalles del Documento'}</DialogTitle>

                <DialogContent>
                    <Grid container direction={"column"} spacing={2}>
                        <Grid item container direction={"row"}>
                            <Grid item xs={4}>
                                <Typography variant={"caption"}>{this.getTipoDocumento(documento.tipo)}</Typography>
                                <Typography variant={"body2"}>{documento.numero}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant={"caption"}>Fecha de Ingreso</Typography>
                                <Typography variant={"body2"}>{documento.ingreso} ({documento.dias} días)</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant={"caption"}>Fecha de Vencimiento</Typography>
                                <Typography variant={"body2"}>{documento.vencimiento}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item container direction={"row"}>
                            <Grid item xs={4}>
                                <Typography variant={"caption"}>Total</Typography>
                                <Typography variant={"body2"}>{formatPrice(documento.total, true)}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant={"caption"}>IVA</Typography>
                                <Typography variant={"body2"}>{formatPrice(documento.iva, true)}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography variant={"caption"}>Percepciones</Typography>
                                <Typography variant={"body2"}>{formatPrice(documento.percepciones, true)}</Typography>
                            </Grid>
                        </Grid>
                        {this.props.operation !== 'VIEW' && (
                            <Grid item container direction={"row"}>
                                <Grid item xs={4}>
                                    <Typography variant={"caption"}>Pendiente de Pago</Typography>
                                    <Typography variant={"body2"}>{formatPrice(documento.pendiente, true)}</Typography>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.onClose} color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </React.Fragment>
        );
    }
}

CobranzaDocumentoInfoDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired
};