import React, {Component} from 'react'
import {Box} from "@material-ui/core/index";
import {ClientesService, EmailService, MastersService, VendedoresService} from "services";
import {calcImporteByArticulo} from 'assets/utils';
import MomentUtils from "@date-io/moment/build/index";
import 'moment/locale/es';
import {MainContext} from "contexts/main.context";
import {withSnackbar} from "notistack";
import DownloadIcon from "@material-ui/icons/GetAppRounded";
import ShowIcon from "@material-ui/icons/DesktopWindowsRounded";
import PrintIcon from "@material-ui/icons/PrintRounded";
import EmailIcon from "@material-ui/icons/EmailRounded";
import printJS from 'print-js'
import RemitosService from "services/remitos.service";
import {RemitosOperation} from "components/ventas/remitos/operation/remitos.operation";
import {ExportOptions} from "../../../helpers/exportOptions/export.options";

export class RemitosView extends Component {
    state = {
        cliente: {},
        vendedor: {},
        documents: [],
        id: this.props.match.params.remito,
        condComerciales: [],
        vendedores: [],
        tipoasientos: [],
        talfacturas: [],
        talremitos: [],
        jurisdicciones: [],
        transportes: [],
        headerData: {
            cliente: null,
            bonificacion: 0,
            listaprecio: '1',
            condcomercial: '',
            notacompra: '',
            usuario: '',
            vendedor: '',
            comision: 0,
            ingreso: (new MomentUtils()).date(new Date()),
            entrega: (new MomentUtils()).date(new Date()),
            leyenda1: '',
            leyenda2: '',
            leyenda3: '',
            leyenda4: '',
            tipoasiento: '',
            talfactura: '',
            talremito: '',
            jurisdiccion: '',
            transporte: '',
            factura: '',
            remito: '',
            pedidos: []
        },
        selectedArticulos: [],
        generatedId: '',
        importe: 0,
        confirmationDialogOpen: false,
        successDialogOpen: false,
        pdfBlob: null,
        pdfUrl: '',
        mailDialogOpen: false,
        pdfDialogOpen: false,
        dialOpen: false,
    };

    RemitosService = new RemitosService(this.context);
    MastersService = new MastersService(this.context);
    VendedoresService = new VendedoresService(this.context);
    ClientesService = new ClientesService(this.context);
    EmailService = new EmailService(this.context);

    componentDidMount() {
        const loadClienteData = () => {
            this.ClientesService.getById(
                this.state.headerData.cliente,
                response => this.setState({cliente: response.data.result}),
                error => this.props.enqueueSnackbar(error, {variant: 'error'})
            );

            this.RemitosService.getByCliente(
                this.state.headerData.cliente,
                response => this.setState({documents: response.data.result}),
                error => this.props.enqueueSnackbar(error, {variant: 'error'})
            );

        };

        const loadVendedorData = () => {
            this.VendedoresService.getById(
                this.state.headerData.vendedor,
                response => this.setState({vendedor: response.data.result}),
                error => this.props.enqueueSnackbar(error, {variant: 'error'})
            )
        };

        this.loadData(() => {
            loadClienteData();
            loadVendedorData();
        });

        this.MastersService.getCondicionesVenta(
            response => this.setState({condComerciales: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        if (this.context.loggedUser.role === 'A' || this.context.loggedUser.role === 'O') {
            this.VendedoresService.getAll(
                response => this.setState({vendedores: response.data.result}),
                error => this.props.enqueueSnackbar(error, {variant: 'error'})
            );
        }

        this.MastersService.getTiposDeAsiento(
            response => this.setState({tipoasientos: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.MastersService.getTalonariosFactura(
            response => this.setState({talfacturas: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.MastersService.getTalonariosRemito(
            response => this.setState({talremitos: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.MastersService.getJurisdicciones(
            response => this.setState({jurisdicciones: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.MastersService.getTransportes(
            response => this.setState({transportes: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    loadData = (fnCallback) => {
        this.RemitosService.getById(
            this.state.id,
            response => {
                const {...res} = response.data.result;
                const headerData = {
                    cliente: res.cliente,
                    bonificacion: res.bonificacion || 0,
                    listaprecio: res.listaprecio || '1',
                    condcomercial: res.condcomercial || '',
                    notacompra: res.notacompra || '',
                    usuario: res.usuario || '',
                    vendedor: res.vendedor && res.vendedor > 0 ? res.vendedor : '',
                    comision: res.comision || 0,
                    ingreso: res.ingreso ? (new MomentUtils()).parse(res.ingreso, 'D/MM/Y') : '',
                    entrega: res.entrega ? (new MomentUtils()).parse(res.entrega, 'D/MM/Y') : '',
                    leyenda1: res.leyenda1 || '',
                    leyenda2: res.leyenda2 || '',
                    leyenda3: res.leyenda3 || '',
                    leyenda4: res.leyenda4 || '',
                    talfactura: res.talfactura || '',
                    talremito: res.talremito || '',
                    tipoasiento: res.tipoasiento || '',
                    jurisdiccion: res.jurisdiccion || '',
                    transporte: res.transporte || '',
                    factura: res.factura || '',
                    remito: res.remito || '',
                    pedidos: res.pedidos || [],
                    iva: res.iva || 0
                };
                this.setState(prevState => {
                    prevState.headerData = headerData;
                    prevState.importe = parseFloat(res.importe);
                    prevState.importeBonificado = parseFloat(res.importe_bonificado);
                    return prevState;
                }, fnCallback);
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.RemitosService.getArticulosById(
            this.state.id,
            response => {
                let articulos = response.data.result;
                articulos.forEach(articulo => articulo.importe = calcImporteByArticulo(articulo.cantidad, articulo.precio, articulo.bonificacion));
                this.setState({
                    selectedArticulos: articulos,
                    totalArticulos: articulos.reduce((count, articulo) => (count + parseInt(articulo.cantidad)), 0),
                    importeArticulos: articulos.reduce((count, articulo) => (count + parseFloat(articulo.importe)), 0),
                    pendienteFacturar: articulos.reduce((count, articulo) => (count + parseFloat(articulo.pendfac)), 0)
                });
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    };

    onChangeDocument = id => this.setState({id, pdfBlob: null}, () => this.loadData());

    getPDF = (fnSuccess) => {
        this.RemitosService.getPDF(
            this.state.id,
            file => fnSuccess(file),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        )
    };

    render() {
        const {
            cliente, vendedor, documents, id, condComerciales, vendedores, talfacturas, talremitos, tipoasientos, jurisdicciones, transportes, articulos,
            headerData, selectedArticulos, generatedId, importe, importeBonificado, totalArticulos, importeArticulos, pendienteFacturar,
            mailDialogOpen, pdfDialogOpen, pdfUrl, dialOpen
        } = this.state;

        return (
            <React.Fragment>
                <Box className='niquel-remitos-view'>
                    <RemitosOperation
                        operation='VIEW'
                        cliente={cliente}
                        documents={documents}
                        id={id}
                        condComerciales={condComerciales}
                        vendedores={vendedores}
                        talfacturas={talfacturas}
                        talremitos={talremitos}
                        tipoasientos={tipoasientos}
                        jurisdicciones={jurisdicciones}
                        transportes={transportes}
                        articulos={articulos}
                        headerData={headerData}
                        selectedArticulos={selectedArticulos}
                        generatedId={generatedId}
                        importe={importe}
                        totalArticulos={totalArticulos}
                        importeArticulos={importeArticulos}
                        importeBonificado={importeBonificado}
                        pendienteFacturar={pendienteFacturar}
                        onChangeDocument={this.onChangeDocument}
                    />
                </Box>
                <ExportOptions
                    enableDownload
                    enableMail
                    enablePrint
                    enableShow
                    getPDF={this.getPDF}
                    fileName={`Remito_${this.state.headerData.factura}.pdf`}
                    mailTo={cliente.email}
                    mailCc={vendedor.email}
                    mailCCEnabled={true}
                    mailSubject={`Remito ${this.state.headerData.factura}`}
                    mailBody={`Estimado cliente,  \n\nSe adjunta el remito número ${this.state.headerData.factura}.`}
                />
            </React.Fragment>
        )
    }
}

RemitosView.contextType = MainContext;
RemitosView = withSnackbar(RemitosView);
