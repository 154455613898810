import React from 'react';

export const MainContext = React.createContext({
    MIN_EXPANDED_WIDTH: 1300,
    sidebarOpen: true,
    sidebarVisible: false,
    headerTitle: '',
    loggedUser: {},
    servicesRunning: 0,
    openSidebar: () => {
    },
    closeSidebar: () => {
    },
    setSidebarVisible: visible => {
    },
    setHeaderTitle: title => {
    },
    addServiceRunning: () => {
    },
    removeServiceRunning: () => {
    },
});
