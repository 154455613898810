import BaseService from './base.service'

export default class SchedulerService extends BaseService {
    get(fnSuccess, fnError) {
        return this.getApi().get(`/scheduler`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener las citas del usuario')));
    }

    add(appointment, fnSuccess, fnError) {
        return this.getApi().post(`/scheduler`, appointment)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al guardar la cita del usuario')));
    }

    edit(id, appointment, fnSuccess, fnError) {
        return this.getApi().put(`/scheduler?id=${id}`, appointment)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al actualizar la cita del usuario')));
    }

    delete(id, fnSuccess, fnError) {
        return this.getApi().delete(`/scheduler?id=${id}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al eliminar la cita del usuario')));
    }
}
