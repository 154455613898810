import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {AfipSelection} from 'components/ventas/afip/selection/afip.selection';
import {AfipCotDOC} from 'components/ventas/afip/cot/afip.cot.doc';
import {AfipCotRUT} from 'components/ventas/afip/cot/afip.cot.rut';

const basePath = '/ventas/AFIP';

export class Afip extends Component {
    render() {
        return (
            <div className='niquel-afip'>
                <Switch>
                    <Route path={`${basePath}/COT-DOC`} component={AfipCotDOC}/>
                    <Route path={`${basePath}/COT-RUT`} component={AfipCotRUT}/>
                    <Route path={`${basePath}`} component={AfipSelection}/>
                </Switch>
            </div>
        )
    }
}
