import React from 'react';
import {useArticulosService, useClientesService, useMastersService, useProveedoresService, useVendedoresService} from "services/hooks";
import {MultipleSelect} from "components/estadisticas/settings/multiple.select";

const GenericSelect = ({title, endpoint, getData, state, dispatch, id, onChangeAll, onChangeSelected, onCancelSelection, ...rest}) => {
    const {all, selected, reload} = state;
    const _dispatch = args => dispatch({...args, type: `${endpoint}.${args.type}`});

    return (
        <MultipleSelect
            all={all}
            title={title}
            selected={selected}
            reload={reload}
            getData={getData}
            onChangeAll={onChangeAll ? onChangeAll : all => _dispatch({type: 'all', payload: all})}
            onChangeSelected={onChangeSelected ? onChangeSelected : (event, value) => _dispatch({type: 'selected', payload: value.map(_value => _value[id])})}
            id={id}
            {...rest}
        />
    )
}

GenericSelect.defaultProps = {
    id: 'id'
}

export const ClasificacionesSelect = ({state, dispatch}) => {
    const service = useMastersService();
    const getClasificaciones = service.getClasificacionesCliente.bind(service);

    return (
        <GenericSelect
            title={'Clasificaciones de Cliente'}
            endpoint={'clasificaciones'}
            state={state}
            dispatch={dispatch}
            getData={getClasificaciones}
        />
    )
}

export const VendedoresSelect = ({state, dispatch}) => {
    const service = useVendedoresService();
    const getVendedores = service.getAll.bind(service);

    return (
        <GenericSelect
            title={'Vendedores'}
            endpoint={'vendedores'}
            state={state}
            dispatch={dispatch}
            getData={getVendedores}
            chip={'name'}
        />
    )
}

export const CalidadesSelect = ({state, dispatch}) => {
    const service = useMastersService();
    const getCalidades = service.getCalidadesArticulo.bind(service);

    return (
        <GenericSelect
            title={'Calidades de Artículo'}
            endpoint={'calidades'}
            state={state}
            dispatch={dispatch}
            getData={getCalidades}
        />
    )
}

export const RubrosSelect = ({state, dispatch}) => {
    const service = useMastersService();
    const getRubros = service.getRubrosArticulo.bind(service);

    return (
        <GenericSelect
            title={'Rubros de Artículo'}
            endpoint={'rubros'}
            state={state}
            dispatch={dispatch}
            getData={getRubros}
            chip={'detalle'}
        />
    )
}

export const EnvasesSelect = ({state, dispatch}) => {
    const service = useMastersService();
    const getEnvases = service.getEnvasesArticulo.bind(service);

    return (
        <GenericSelect
            title={'Envases de Artículo'}
            endpoint={'envases'}
            state={state}
            dispatch={dispatch}
            getData={getEnvases}
            chip={'detalle'}
        />
    )
}

export const LineasSelect = ({state, dispatch}) => {
    const service = useMastersService();
    const getLineas = service.getLineasArticulo.bind(service);

    return (
        <GenericSelect
            title={'Lineas de Artículo'}
            endpoint={'lineas'}
            state={state}
            dispatch={dispatch}
            getData={getLineas}
            chip={'detalle'}
        />
    )
}

export const LocalidadesSelect = ({state, dispatch}) => {
    const service = useMastersService();
    const getLocalidades = service.getLocalidades.bind(service);

    return (
        <GenericSelect
            title={'Localidades'}
            endpoint={'localidades'}
            state={state}
            dispatch={dispatch}
            getData={getLocalidades}
            chip={'detalle'}
        />
    )
}

export const ProveedoresSelect = ({state, dispatch}) => {
    const service = useProveedoresService();
    const getProveedores = service.getAll.bind(service, ["XX"]);
    const onChangeSelected = (event, value) => {
        dispatch({type: `grupos.reload`, payload: true});
        dispatch({type: 'proveedores.selected', payload: value.map(_value => _value['id'])});
    };

    return (
        <GenericSelect
            title={'Proveedores'}
            endpoint={'proveedores'}
            onChangeSelected={onChangeSelected}
            state={state}
            dispatch={dispatch}
            getData={getProveedores}
            chip={'razsoc'}
        />
    )
}

export const GruposProveedorSelect = ({state, dispatch, proveedores}) => {
    const service = useProveedoresService();
    const getGruposProveedor = service.getGroups.bind(service, proveedores);

    return (
        <GenericSelect
            title={'Grupos proveedor'}
            endpoint={'grupos'}
            state={state}
            dispatch={dispatch}
            getData={(success, error) => {
                dispatch({type: 'grupos.reload', payload: false});
                getGruposProveedor(success, error);
            }}
            chip={'detalle'}

        />
    )
}

export const ArticulosSelect = ({state, dispatch}) => {
    const service = useArticulosService();
    const getArticulos = service.getAll.bind(service, null);

    return (
        <GenericSelect
            title={'Articulos'}
            endpoint={'articulos'}
            state={state}
            dispatch={dispatch}
            getData={getArticulos}
            chip={'detalle'}
        />
    )
}

export const ClientesSelect = ({state, dispatch}) => {
    const service = useClientesService();
    const getClientes = service.getAll.bind(service);

    return (
        <GenericSelect
            title={'Clientes'}
            endpoint={'clientes'}
            state={state}
            dispatch={dispatch}
            getData={getClientes}
            chip={'razsoc'}
        />
    )
}
