const moment = require('moment');

export const INITIAL_FILTER_SETTINGS_STATE = {
    dates: {
        from: moment().subtract(1, 'year'),
        to: moment()
    },
    vendedores: {
        selected: [],
        all: true
    },
    localidades: {
        selected: [],
        all: true
    },
    clientes: {
        selected: [],
        all: true
    },
    clasificaciones: {
        selected: [],
        all: true
    },
    articulos: {
        selected: [],
        all: true
    },
    calidades: {
        selected: [],
        all: true
    },
    rubros: {
        selected: [],
        all: true
    },
    envases: {
        selected: [],
        all: true
    },
    lineas: {
        selected: [],
        all: true
    },
    proveedores: {
        selected: [],
        all: true
    },
    grupos: {
        selected: [],
        all: true,
        reload: false
    },
    subgrupos: {
        selected: [],
        all: true
    }
}
