import React, {Component} from 'react'
import {Box, Fab, Tooltip} from "@material-ui/core/index";
import {ReactGrid} from '../../../helpers/reactGrid/reactGrid';
import AddIcon from '@material-ui/icons/Add';
import ClientesService from "services/clientes.service";
import CobranzasService from "services/cobranzas.service";
import {withSnackbar} from "notistack";
import {sortDate, sortNumeric} from 'assets/utils';
import {MainContext} from "contexts/main.context";
import SubHeader from "components/helpers/subHeader/subHeader";
import {OptionsMenu} from "components/helpers/options.menu";
import {SimpleDialog, SimpleDialog as DeleteDialog} from "components/helpers/dialogs/simple.dialog";
import NotificationsService from "services/notifications.service";
import {NotificationDialog} from "components/helpers/cobranzas/notification.dialog";
import {ChangeStatusDialog} from "components/helpers/cobranzas/changestatus.dialog";
import {CobranzaStatusFormatter} from "assets/utils"
import {AdditionalData} from "../../../helpers/cobranzas/additional.data.card";

export class CobranzasList extends Component {
    ClientesService = new ClientesService(this.context);
    CobranzasService = new CobranzasService(this.context);
    NotificationsService = new NotificationsService(this.context);

    OptionsMenuWrapper = (props) => {
        const {row, rowId} = props;
        return (
            <OptionsMenu
                row={row}
                rowId={rowId}
                onView={this.navView}
                onEdit={this.navEdit}
                onNotification={this.handleNotification}
                onChangeStatus={this.handleChangeStatus}
                onDelete={this.handleDelete}
                onAddComprobantes={this.handleComprobantes}
                context={this.context}
            />
        )
    };

    state = {
        cliente: {},
        columns: [
            {name: 'id', title: 'ID'},
            {name: 'numrec', title: 'Nro Recibo'},
            {name: 'fecharecibo', title: 'Fecha Recibo'},
            {name: 'cancelado', title: 'Cancelado'},
            {name: 'custom1', title: 'Estado'},
            {name: 'custom2', title: ' '},
        ],
        columnExtensions: [
            {columnName: 'id', compare: sortNumeric, direction: 'desc'},
            {columnName: 'fecharecibo', compare: sortDate},
            {columnName: 'cancelado', type: 'currency', compare: sortNumeric},
            {columnName: 'custom1', component: CobranzaStatusFormatter, align: 'center'},
            {columnName: 'custom2', type: 'icon', component: this.OptionsMenuWrapper, sortingEnabled: false, width: 70},
        ],
        rows: [],
        cobranzas: [],
        dialogs: {
            delete: false,
            notification: false,
            status: false
        },
        tmp: null
    };

    componentDidMount() {
        this.getCliente(this.props.match.params.cliente);
        this.getCobranzas(this.props.match.params.cliente);
    }

    getCliente = cliente => {
        this.ClientesService.getById(
            cliente,
            response => this.setState({cliente: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    getCobranzas = cliente => {
        this.CobranzasService.getByCliente(
            cliente,
            response => this.setState({rows: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    navEdit = (row, rowId) => {
        this.props.history.push(`${this.props.match.url}/edit/${row.id}`);
    };

    navView = (row) => {
        this.props.history.push(`${this.props.match.url}/view/${row.id}`);
    };

    navCreate = () => {
        this.props.history.push(`${this.props.match.url}/create`);
    };

    openDialog = (dialog) => this.setState(prevState => prevState.dialogs[dialog] = true);
    closeDialog = (dialog) => this.setState(prevState => prevState.dialogs[dialog] = false);

    handleDelete = (row, rowId) => this.setState({tmp: row}, () => this.openDialog('delete'));
    deleteCobranza = () => {
        this.CobranzasService.deleteById(
            this.state.tmp.id,
            response => {
                this.props.enqueueSnackbar(`Se eliminó correctamente la cobranza transitoria número ${this.state.tmp.id}`, {variant: 'success'});
                this.getCobranzas(this.props.match.params.cliente);
                this.closeDialog('delete');
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    handleNotification = (row, rowId) => this.setState({tmp: row}, () => this.openDialog('notification'));
    sendNotification = (msg) => {
        const options = {
            msg: msg,
            to_vendedor: this.state.tmp.idvendedor
        };

        this.NotificationsService.push(
            options,
            response => {
                this.props.enqueueSnackbar(`Se envió la notificación al vendedor`, {variant: 'success'});
                this.closeDialog('notification');
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        )
    }

    handleChangeStatus = (row, rowId) => this.setState({tmp: row}, () => this.openDialog('status'));
    changeStatus = (status) => {
        this.CobranzasService.changeStatus(
            this.state.tmp.id,
            status,
            response => {
                this.props.enqueueSnackbar(`El estado de la cobranza fue modificado correctamente`, {variant: 'success'});
                this.getCobranzas(this.props.match.params.cliente);
                this.closeDialog('status');
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        )
    }

    handleComprobantes = (row, rowId) => this.setState({tmp: row}, () => this.openDialog('comprobantes'));

    updateStatus = () => {
        this.CobranzasService.updateStatus(
            this.state.tmp.id,
            response => {
                this.getCobranzas(this.props.match.params.cliente);
                this.closeDialog('comprobantes');
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        )
    }

    render() {
        const {cliente, columns, columnExtensions, rows, dialogs, tmp} = this.state;

        return (
            <Box className='niquel-cobranzas-list'>
                <SubHeader
                    title={'Cobranzas'}
                    subtitle={`Cobranzas Transitorias`}
                    cliente={cliente}
                />
                <Box className='container-xl mt-3 mb-3'>
                    <ReactGrid
                        title={`Cobranzas de ${cliente.razsoc} (${rows.length})`}
                        height={-200}
                        columns={columns}
                        columnExtensions={columnExtensions}
                        rows={rows}
                        showFilters={false}
                        rowClick={true}
                        onRowClick={this.navView}
                    />
                    <Tooltip title={'Nueva Cobranza'}>
                        <Box className='niquel-fab-btn'>
                            <Fab color="primary" onClick={this.navCreate}>
                                <AddIcon/>
                            </Fab>
                        </Box>
                    </Tooltip>
                </Box>
                <DeleteDialog
                    open={dialogs.delete}
                    title={'¿Seguro quiere eliminar esta cobranza?'}
                    body={<div>Estás por eliminar la cobranza transitoria número <b>{tmp ? tmp.numrec : null}</b> del cliente <b>{cliente.razsoc}</b></div>}
                    onConfirm={this.deleteCobranza}
                    onCancel={() => this.closeDialog('delete')}
                />
                <NotificationDialog
                    open={dialogs.notification}
                    vendedor={tmp ? tmp.vendedor : null}
                    onConfirm={this.sendNotification}
                    onCancel={() => this.closeDialog('notification')}
                />
                <ChangeStatusDialog
                    open={dialogs.status}
                    cobranza={tmp}
                    cliente={cliente}
                    status={tmp ? tmp.status : null}
                    id={tmp ? tmp.numrec : null}
                    onConfirm={this.changeStatus}
                    onCancel={() => this.closeDialog('status')}
                />
                <SimpleDialog
                    title={"Comprobantes"}
                    open={dialogs.comprobantes}
                    body={
                        <AdditionalData idrecibo={tmp?.id} items={tmp?.items}/>
                    }
                    fullWidth={true}
                    maxWidth={'md'}
                    onConfirm={this.updateStatus}
                    onCancel={() => this.closeDialog('comprobantes')}
                />
            </Box>
        )
    }
}

CobranzasList.contextType = MainContext;
CobranzasList = withSnackbar(CobranzasList);
