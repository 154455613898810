import React, {useCallback, useEffect, useState} from "react";
import {Box, Card, Dialog, DialogContent, Divider, Fab, Grid, IconButton, Tooltip, Typography} from "@material-ui/core";
import {Map} from "components/helpers/map/map";
import CloseIcon from "@material-ui/icons/CloseRounded";
import CardContent from "@material-ui/core/CardContent";
import redPin from 'assets/img/markers/red/none.svg';
import greenPin from 'assets/img/markers/green/none.svg';
import {CancelRounded, CloseRounded, DeleteRounded, SettingsRounded, VisibilityRounded, WarningRounded} from "@material-ui/icons";
import 'components/despacho/hojasDeRuta/create/pedidos.map.dialog.scss';
import {SimpleDialog} from "components/helpers/dialogs/simple.dialog";
import {MapSettingsDialog} from "components/helpers/map/map.settings.dialog";
import Drawer from "@material-ui/core/Drawer";
import {MapDetailsPanel} from "components/helpers/map/map.details.panel";
import {
    refPinBlackColor,
    refPinDarkGreenColor, refPinDarkGreyColor,
    refPinDarkRedColor,
    refPinGreenColor,
    refPinGreyColor,
    refPinOrangeColor,
    refPinRedColor,
    refPinYellowColor
} from "assets/utils";

const RefPin = ({color}) => {
    return <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24">
                <path d="M0 0h24v24H0z" fill="none"/>
                <path id="svg_2" d="m12,2c-3.87,0 -7,3.13 -7,7c0,5.25 7,13 7,13s7,-7.75 7,-13c0,-3.87 -3.13,-7 -7,-7zm0,9.5c-1.38,0 -2.5,-1.12 -2.5,-2.5s1.12,-2.5 2.5,-2.5s2.5,1.12 2.5,2.5s-1.12,2.5 -2.5,2.5z" fill={color}/>
                <ellipse ry="3.74726" rx="3.30963" id="svg_3" cy="8.96629" cx="12.0547" stroke-width="1.5" fill={color}/>
                <path fill={color} d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/>
            </svg>
}

export const PedidosMapDialog = props => {
    return (
        <Dialog
            open={props.open}
            onClose={props.onCancel}
            fullScreen={props.fullScreen}
            // keepMounted
            classes={{
                paperFullScreen: 'niquel-dialog-full-screen'
            }}
        >
            <MapDialogContent {...props}/>
        </Dialog>
    )
}

const getPedidosSinGeolocalizar = (direcciones) => {
    let _direcciones = [];
    direcciones.forEach(direccion => {
        if (!direccion.latitud || !direccion.longitud) {
            direccion.latitud = 0;
            direccion.longitud = 0;
            _direcciones.push(direccion);
        }
    });

    return _direcciones;
}

const getPedidosPendientes = (props) => {
    let pedidosPendientes, pedidosAgregados;

    // Fix para visualización de hojas de ruta
    if (props.operation === 'VIEW')
        pedidosPendientes = props.pedidos_agregados.map(pedido => {
            pedido.agregado = true;
            return pedido;
        });
    else {
        pedidosAgregados = props.pedidos_agregados.map(pedido => pedido.id);
        pedidosPendientes = props.pedidos_pendientes.concat(props.pedidos_agregados.filter(pedido => pedido.hasOwnProperty('ORIGINAL')));
        pedidosPendientes = pedidosPendientes.map(pedido => {
            pedido.agregado = (pedidosAgregados.indexOf(pedido.id) > -1);
            return pedido;
        });
    }

    return pedidosPendientes;
}

const groupByDirentrega = (pedidos) => {
    let pedidosGrouped = {};

    pedidos.forEach(pedido => {
        const idEntrega = pedido.id_direccion_entrega;
        if (pedidosGrouped.hasOwnProperty(idEntrega))
            pedidosGrouped[idEntrega].pedidos.push(pedido);
        else
            pedidosGrouped[idEntrega] = {
                cliente: pedido.cliente,
                vendedor: pedido.vendedor,
                vendedor_nivel: pedido.vendedor_nivel,
                id_direccion_entrega: pedido.id_direccion_entrega,
                direccion_entrega: pedido.direccion_entrega,
                latitud: pedido.latitud,
                longitud: pedido.longitud,
                pedidos: [pedido]
            }
    });

    return pedidosGrouped;
}

const MapDialogContent = props => {
    const [pedidosSinGeolocalizarDialogOpen, setPedidosSinGeolocalizarDialogOpen] = useState(false);
    const [showPedidosSinGeolocalizar, setShowPedidosSinGeolocalizar] = useState(true);
    const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
    const [settings, setSettings] = useState({filterType: 'ninguno', selected: null});
    const [detailsDrawerData, setDetailsDrawerData] = useState(null);
    const [detailsDrawerOpen, setDetailsDrawerOpen] = useState(false);

    let pedidosPendientes = getPedidosPendientes(props);
    const pedidosSinGeo = getPedidosSinGeolocalizar(pedidosPendientes);
    const [direccionesPendientes, setDireccionesPendientes] = useState(() => groupByDirentrega(pedidosPendientes));

    const onConfirmFiltersDialog = filters => {
        setSettings(filters);
        setSettingsDialogOpen(false);
    };

    const onMarkerClick = useCallback((direccion) => {
        setDetailsDrawerData(direccion);
        setDetailsDrawerOpen(true);
    }, []);

    const filterPedidos = pedidos => {
        if (settings.filterType === 'nivel') {
            const selected = settings.selected;
            pedidos = pedidos.filter(pedido => pedido.vendedor_nivel === selected);
        }

        if (settings.filterType === 'vendedor') {
            const selected = settings.selected;
            pedidos = pedidos.filter(pedido => selected.indexOf(pedido.idvendedor) > -1);
        }

        return pedidos;
    }

    const removeFilters = () => setSettings(prevState => ({...prevState, filterType: 'ninguno'}));

    useEffect(() => {
        const pedidos = filterPedidos(pedidosPendientes);
        // Agrupo por dirección
        setDireccionesPendientes(groupByDirentrega(pedidos));
    }, [settings]);

    return (
        <React.Fragment>
            <DialogContent className={'pl-0 pr-0 pb-0 pt-0'}>
                <Map
                    height={'100%'}
                    width={'100%'}
                    direcciones={[
                        {
                            color: 'red',
                            data: direccionesPendientes
                        }
                    ]}
                    onMarkerClick={onMarkerClick}
                    enableClustering={false}
                    withPedidos={true}
                />
            </DialogContent>

            <Drawer
                anchor={'left'}
                open={detailsDrawerOpen}
                onClose={(e) => setDetailsDrawerOpen(false)}
                BackdropProps={{invisible: true}}
            >
                <MapDetailsPanel
                    direccion={detailsDrawerData}
                    withPedidos={true}
                    operation={props.operation}
                    onAddPedido={props.onAddPedido}
                    onRemovePedido={props.onRemovePedido}
                />
            </Drawer>

            <Card className='niquel-pedidosMap-references'>
                <CardContent className={'p-2'}>
                    <Grid container direction={"column"} spacing={1}>
                        <Grid item className={'d-flex align-items-center'}>
                            <RefPin color={refPinGreyColor}/>
                            <Typography variant={"caption"} className={'ml-1'}>Nuevos</Typography>
                        </Grid>
                        <Grid item className={'d-flex align-items-center'}>
                            <RefPin color={refPinOrangeColor}/>
                            <Typography variant={"caption"} className={'ml-1'}>En Facturación</Typography>
                        </Grid>
                        <Grid item className={'d-flex align-items-center'}>
                            <RefPin color={refPinGreenColor}/>
                            <Typography variant={"caption"} className={'ml-1'}>Facturados</Typography>
                        </Grid>
                        <Grid item className={'d-flex align-items-center'}>
                            <RefPin color={refPinBlackColor}/>
                            <Typography variant={"caption"} className={'ml-1'}>En Distribución</Typography>
                        </Grid>
                        <Grid item className={'d-flex align-items-center'}>
                            <RefPin color={refPinDarkGreenColor}/>
                            <Typography variant={"caption"} className={'ml-1'}>Entregados</Typography>
                        </Grid>
                        <Grid item className={'d-flex align-items-center'}>
                            <RefPin color={refPinRedColor}/>
                            <Typography variant={"caption"} className={'ml-1'}>Retornados</Typography>
                        </Grid>
                        <Grid item className={'d-flex align-items-center'}>
                            <RefPin color={refPinDarkRedColor}/>
                            <Typography variant={"caption"} className={'ml-1'}>Rechazados</Typography>
                        </Grid>
                        <Grid item className={'d-flex align-items-center'}>
                            <RefPin color={refPinYellowColor}/>
                            <Typography variant={"caption"} className={'ml-1'}>Parciales</Typography>
                        </Grid>
                        <Grid item className={'d-flex align-items-center'}>
                            <RefPin color={refPinDarkGreyColor}/>
                            <Typography variant={"caption"} className={'ml-1'}>Ingresados</Typography>
                        </Grid>
                        <Divider/>
                        <Grid item className={'d-flex align-items-center'}>
                            <Typography variant={"caption"} className={'ml-1'}>AGREGADOS / PENDIENTES</Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            <Box className={'niquel-pedidosMap-notifications'}>
                {settings.filterType !== 'ninguno' && (
                    <Card className={'d-inline-block ml-2 mr-2'}>
                        <CardContent className={'p-2 d-flex align-items-center'}>
                            <WarningRounded fontSize={"small"} color={"secondary"} className={'mr-1'}/>
                            <Typography variant={"caption"} component={"span"}>Filtros aplicados</Typography>
                            <Tooltip title={'Quitar filtros'}>
                                <IconButton onClick={() => removeFilters()} className={'ml-1'}>
                                    <DeleteRounded fontSize={"small"}/>
                                </IconButton>
                            </Tooltip>
                        </CardContent>
                    </Card>
                )}

                {showPedidosSinGeolocalizar && pedidosSinGeo.length > 0 && (
                    <Card className={'d-inline-block ml-2 mr-2'}>
                        <CardContent className={'p-2 d-flex align-items-center'}>
                            <WarningRounded fontSize={"small"} color={"secondary"} className={'mr-1'}/>
                            <Typography variant={"caption"} component={"span"}>Hay pedidos sin geolocalizar</Typography>
                            <Tooltip title={'Ver pedidos sin geolocalizar'}>
                                <IconButton onClick={() => setPedidosSinGeolocalizarDialogOpen(true)} className={'ml-1'}>
                                    <VisibilityRounded fontSize={"small"}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={'Cerrar'}>
                                <IconButton onClick={() => setShowPedidosSinGeolocalizar(false)}>
                                    <CloseRounded fontSize={"small"}/>
                                </IconButton>
                            </Tooltip>
                        </CardContent>
                    </Card>
                )}
            </Box>

            <Tooltip title={'Opciones del mapa'}>
                <Box className='niquel-pedidosMap-opciones'>
                    <Fab onClick={() => setSettingsDialogOpen(true)} size={"medium"} style={{backgroundColor: 'rgb(210 210 210)'}}>
                        <SettingsRounded/>
                    </Fab>
                </Box>
            </Tooltip>

            <Tooltip title={'Cerrar'}>
                <Box className='niquel-fab-btn'>
                    <Fab onClick={props.onCancel} size={"medium"} style={{backgroundColor: 'rgb(247 247 247)'}}>
                        <CloseIcon/>
                    </Fab>
                </Box>
            </Tooltip>

            <SimpleDialog
                title={'Pedidos sin geolocalizar'}
                open={pedidosSinGeolocalizarDialogOpen}
                body={pedidosSinGeo.map((pedido, index) =>
                    <Typography key={index} variant={"body2"}>Pedido: {pedido.id} -
                    Cliente: {pedido.cliente} ({pedido.idcliente}) - Dirección: {pedido.direccion_entrega} ({pedido.id_direccion_entrega})</Typography>
                )}
                onCancel={() => setPedidosSinGeolocalizarDialogOpen(false)}
            />

            <MapSettingsDialog
                title={'Opciones del mapa'}
                open={settingsDialogOpen}
                keepMounted={true}
                fullWidth={true}
                onConfirm={settings => onConfirmFiltersDialog(settings)}
                onCancel={() => setSettingsDialogOpen(false)}
            />
        </React.Fragment>
    )
}
