import {CardChart} from "../card.chart";
import React, {useEffect, useState} from "react";
import {TIPOS_GRAFICO} from "../tipos.grafico";
import {useSnackbar} from "notistack";
import {useEstadisticasService} from "../../../../../services/hooks";
import {VentasMensualesPorCalidadChart} from "../charts/ventas.mensuales.calidad.chart";
import {imageChartToPDF, tableChartToPDF} from "../../../../../assets/utils";

export const VentasMensualesPorCalidadCard = ({settingsState, printRef}) => {
    const [ventasMensuales, setVentasMensuales] = useState([]);
    const [tipoVentasMensuales, setTipoVentasMensuales] = useState(TIPOS_GRAFICO[0]);

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const EstadisticasService = useEstadisticasService();

    const chartTitle = 'Ventas Mensuales Por Calidad (solo artículos Shell)';
    const chartSubtitle = `${tipoVentasMensuales.description} (en lts)`;

    useEffect(() => {
        EstadisticasService.getVentasMensualesPorCalidad(
            settingsState,
            success => setVentasMensuales(success.data.result),
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }, [settingsState]);

    return (
        <CardChart
            title={chartTitle}
            subtitle={chartSubtitle}
            printRef={printRef}
            exportFn={imageChartToPDF.bind(null, printRef, chartTitle, chartSubtitle, settingsState.dates)}
        >
            <VentasMensualesPorCalidadChart
                data={ventasMensuales}
                height={300}
            />
        </CardChart>
    )
}
