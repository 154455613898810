import React, {Component} from 'react'
import {Box, Tooltip} from "@material-ui/core/index";
import {ReactGrid} from '../../../helpers/reactGrid/reactGrid';
import AddIcon from '@material-ui/icons/Add';
import {withSnackbar} from "notistack";
import {sortDate, sortNumeric} from 'assets/utils';
import {MainContext} from "contexts/main.context";
import SubHeader from "components/helpers/subHeader/subHeader";
import {Fab} from "@material-ui/core";
import {OptionsMenu} from "components/helpers/options.menu";
import {SimpleDialog as DeleteDialog} from "components/helpers/dialogs/simple.dialog";
import HojasDeRutaService from "services/hojasDeRuta.service";
import {ViewRecibosDialog} from "components/helpers/dialogs/view.recibos.dialog";

export class HojasDeRutaList extends Component {
    HojasDeRutaService = new HojasDeRutaService(this.context);

    OptionsMenuWrapper = (props) => {
        const {row, rowId} = props;
        return (
            <OptionsMenu
                row={row}
                rowId={rowId}
                onView={this.navView}
                onEdit={this.navEdit}
                onDownload={this.handleDownload}
                onViewRecibos={this.handleViewRecibos}
                onDelete={this.handleDelete}
                context={this.context}
            />
        )
    };

    state = {
        columns: [
            {name: 'id', title: 'ID'},
            {name: 'fecha', title: 'Fecha'},
            {name: 'vehiculo_desc', title: 'Vehículo'},
            {name: 'chofer_desc', title: 'Chofer'},
            {name: 'litros', title: 'Litros'},
            {name: 'usuario_desc', title: 'Usuario'},
            {name: 'custom1', title: ' '},
        ],
        columnExtensions: [
            {columnName: 'id', width: 120, compare: sortNumeric, direction: 'desc'},
            {columnName: 'fecha', compare: sortDate, direction: 'desc'},
            {columnName: 'custom1', type: 'icon', component: this.OptionsMenuWrapper, sortingEnabled: false, width: 70},
        ],
        rows: [],
        dialogs: {
            delete: false,
            notification: false,
            status: false,
            viewRecibos: false
        },
        tmp: null
    };

    componentDidMount() {
        this.getHojasDeRuta();
    }

    getHojasDeRuta = () => {
        this.HojasDeRutaService.getAll(
            response => this.setState({rows: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    navEdit = (row) => {
        this.props.history.push(`${this.props.match.url}/edit/${row.id}`);
    };

    navView = (row) => {
        this.props.history.push(`${this.props.match.url}/view/${row.id}`);
    };

    navCreate = () => {
        this.props.history.push(`${this.props.match.url}/create`);
    };

    openDialog = (dialog) => this.setState(prevState => prevState.dialogs[dialog] = true);
    closeDialog = (dialog) => this.setState(prevState => prevState.dialogs[dialog] = false);

    handleDelete = (row, rowId) => this.setState({tmp: row}, () => this.openDialog('delete'));
    deleteHojaDeRuta = () => {
        this.HojasDeRutaService.deleteById(
            this.state.tmp.id,
            response => {
                this.props.enqueueSnackbar(`Se eliminó correctamente la hoja de ruta número ${this.state.tmp.id}`, {variant: 'success'});
                this.getHojasDeRuta();
                this.closeDialog('delete');
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    getPDF = (id, fnSuccess) => {
        this.HojasDeRutaService.getPDF(
            id,
            file => this.setState({pdfBlob: file}, fnSuccess),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    handleDownload = (row, rowId) => this.setState({tmp: row}, () => {
        this.getPDF(
            row.id,
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = fileURL;
                a.download = `Hoja de Ruta Nro ${row.id}`;
                a.click();
                window.URL.revokeObjectURL(fileURL);
                this.props.enqueueSnackbar('El archivo se descargó correctamente. Revise su carpeta de descargas.', {variant: 'success'});
            }
        );
    });

    handleViewRecibos = (row) => this.setState({tmp: row}, () => {console.log(row); this.openDialog('viewRecibos')});

    render() {
        const {columns, columnExtensions, rows, dialogs} = this.state;

        return (
            <Box className='niquel-hojasDeRuta-list'>
                <SubHeader
                    title={'Hojas De Ruta'}
                    subtitle={`Hojas De Ruta Generadas`}
                />
                <Box className='container-xl mt-3 mb-3'>
                    <ReactGrid
                        title={`Hojas de Ruta (${rows.length})`}
                        height={-200}
                        columns={columns}
                        columnExtensions={columnExtensions}
                        rows={rows}
                        showFilters={false}
                        rowClick={true}
                        onRowClick={this.navView}
                    />
                    <Tooltip title={'Nueva Hoja de Ruta'}>
                        <Box className='niquel-fab-btn'>
                            <Fab color="primary" onClick={this.navCreate}>
                                <AddIcon/>
                            </Fab>
                        </Box>
                    </Tooltip>
                </Box>
                <DeleteDialog
                    open={dialogs.delete}
                    title={'¿Seguro quiere eliminar la hoja de ruta?'}
                    body={this.state.tmp ? <span>Estás por eliminar la hoja de ruta número <b>{this.state.tmp.id}</b></span> : ''}
                    onConfirm={this.deleteHojaDeRuta}
                    onCancel={() => this.closeDialog('delete')}
                />
                <ViewRecibosDialog
                    open={dialogs.viewRecibos}
                    title={"Remitos de entrega"}
                    hoja={this.state.tmp}
                    onCancel={() => this.closeDialog('viewRecibos')}
                />
            </Box>
        )
    }
}

HojasDeRutaList.contextType = MainContext;
HojasDeRutaList = withSnackbar(HojasDeRutaList);
