import React from 'react';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, withMobileDialog, withStyles} from '@material-ui/core';
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/CloseRounded";
import Grid from "@material-ui/core/Grid";
import {calcImporteByArticulo, InputInteger, InputPercentage} from "assets/utils";
import {MainContext} from "contexts/main.context";

const styles = {};

class ArticulosAddFastDialog extends React.Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                maxWidth={"sm"}
                fullWidth={true}
                classes={{
                    paperFullScreen: 'niquel-dialog-full-screen'
                }}
            >
                <ArticulosAddFastDialogContent {...this.props}/>
            </Dialog>
        );
    }
}

class ArticulosAddFastDialogContent extends React.Component {
    state = {
        id: '',
        cantidad: 1,
        bonificacion: 0,
        notFound: false
    };

    onChangeId = event => this.setState({id: event.target.value, notFound: false});
    onClearId = () => this.setState({id: '', notFound: false});
    onChangeCantidad = event => this.setState({cantidad: event.target.value});
    onChangeBonificacion = event => this.setState({bonificacion: event.target.value});
    keyPress = event => {
        if (event.keyCode === 13) {
            event.preventDefault();
            this.onConfirm();
        }
    };

    onConfirm = () => {
        let articulo = this.props.articulos.find(_articulo => _articulo.id.toUpperCase() === this.state.id.toUpperCase());
        if (articulo) {
            let artic = {...articulo};
            artic.cantidad = this.state.cantidad;
            artic.bonificacion = this.state.bonificacion;

            artic.importe = calcImporteByArticulo(artic.cantidad, artic.precio, artic.bonificacion);

            if (this.isValid(artic.importe, artic.detalle)) {
                this.props.setDefaultsArticulo(artic);
                this.props.onConfirm(artic);
            }
        } else
            this.setState({notFound: true});
    };

    isValid = (importe, descripcion) => {
        let valid = (!isNaN(importe)) && descripcion !== '';
        return valid;
    };

    render() {
        const {onClose, mobile} = this.props;
        const {id, cantidad, bonificacion, notFound} = this.state;

        return (
            <React.Fragment>
                <DialogTitle>Agregar artículo rápido</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={this.context.loggedUser.cmpid.startsWith('berner') ? 10 : 8}>
                            <TextField
                                fullWidth
                                autoFocus
                                label={'Código del Artículo'}
                                value={id}
                                onChange={this.onChangeId}
                                error={notFound}
                                helperText={notFound ?
                                    'No se encontró el artículo, verifique el código ingresado' :
                                    mobile ? 'Ingrese el código de un artículo y haga click en AGREGAR' :
                                        'Ingrese el código de un artículo y presione enter (o click en AGREGAR)'
                                }
                                InputProps={{
                                    onKeyDown: this.keyPress,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {!!id && (
                                                <IconButton
                                                    onClick={this.onClearId}
                                                    tabIndex={-1}
                                                >
                                                    <CloseIcon/>
                                                </IconButton>
                                            )}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <TextField
                                fullWidth
                                label="Cantidad"
                                value={cantidad}
                                margin="none"
                                onChange={this.onChangeCantidad}
                                onFocus={e => e.target.select()}
                                InputProps={{
                                    onKeyDown: this.keyPress,
                                    inputComponent: InputInteger
                                }}
                            />
                        </Grid>
                        {!this.context.loggedUser.cmpid.startsWith('berner') && (
                            <Grid item xs={2}>
                                <TextField
                                    fullWidth
                                    label="Bonificación"
                                    value={bonificacion}
                                    margin="none"
                                    onChange={this.onChangeBonificacion}
                                    onFocus={e => e.target.select()}
                                    InputProps={{
                                        onKeyDown: this.keyPress,
                                        inputComponent: InputPercentage,
                                        readOnly: (this.context.loggedUser.role !== 'A')
                                    }}
                                />
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={this.onConfirm}
                        color="primary"
                        autoFocus
                        disabled={!id || id === ''}
                    >
                        Agregar
                    </Button>
                </DialogActions>
            </React.Fragment>
        )
    }
}

ArticulosAddFastDialogContent.contextType = MainContext;
export default withMobileDialog()(withStyles(styles, {withTheme: true})(ArticulosAddFastDialog));
