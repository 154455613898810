import React from 'react';
import PropTypes from 'prop-types';
import {AppBar, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide, Toolbar, Typography, withMobileDialog, withStyles} from '@material-ui/core';
import CloseIcon from "@material-ui/icons/ArrowBack";
import CobranzaEditFields from "components/helpers/cobranzas/items/cobranza.edit.fields";
import {ChequeAddDialog} from "components/helpers/cobranzas/items/cheque.add.dialog";
import {MastersService} from "services";
import {MainContext} from "contexts/main.context";
import {withSnackbar} from "notistack";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

export class CobranzaItemsEditDialog extends React.Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                fullScreen={this.props.fullScreen}
                TransitionComponent={this.props.fullScreen ? Transition : undefined}
                fullWidth
                maxWidth={"sm"}
                classes={{paperFullScreen: 'niquel-dialog-full-screen'}}
            >
                <CobranzaItemsEditDialogContent {...this.props} />
            </Dialog>
        )
    }
}

class CobranzaItemsEditDialogContent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            item: {},
            isValid: false,
            addChequeDialogOpen: false,
            cuentasBancarias: [],
            bancos: [],
            jurisdicciones: []
        };

        if (this.props.item.index !== null)
            this.state.item = this.props.item;

        if (this.props.addChequeDialogOpen)
            this.state.addChequeDialogOpen = this.props.addChequeDialogOpen;
    }

    MastersService = new MastersService(this.context);

    componentDidMount() {
        this.MastersService.getCuentasBancarias(
            response => this.setState(prevState => prevState.cuentasBancarias = response.data.result),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.MastersService.getBancos(
            response => this.setState(prevState => prevState.bancos = response.data.result),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.MastersService.getJurisdicciones(
            response => this.setState(prevState => prevState.jurisdicciones = response.data.result),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    onClose = () => this.props.onClose();
    onConfirm = () => this.props.onConfirm(this.state.item);
    onEditItem = (item) => this.setState({item: item, isValid: this.isValid(item)});

    isValid = item => {
        if (item.importe.isNaN || (!item.tipo.startsWith('CH') && item.importe <= 0))
            return false;

        if (item.leyenda.length <= 0)
            return false;

        if (!item.tipo.startsWith('CH') && item.fecha === null)
            return false;

        if (item.tipo === 'TRF' && (/*item.transferencia.numero === '' || */item.transferencia.cuenta === ''))
            return false;

        return true;
    };

    onAddChequeDialogOpen = () => this.setState({addChequeDialogOpen: true});
    onAddChequeDialogClose = () => this.setState({addChequeDialogOpen: false});
    onAddChequeDialogConfirm = (cheque) => this.setState(
        prevState => {
            prevState.item.cheques.push(cheque);
            prevState.item.importe = prevState.item.cheques.reduce((suma, cheque) => suma + parseFloat(cheque.importe), 0);
            prevState.isValid = this.isValid(prevState.item);
        },
        () => this.onAddChequeDialogClose()
    );

    render() {
        const {fullScreen, TIPOS, cobranza, headerData} = this.props;
        const {item, isValid, addChequeDialogOpen, cuentasBancarias, bancos, jurisdicciones} = this.state;

        return (
            <React.Fragment>
                {fullScreen && (
                    <AppBar>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.onClose} aria-label="close">
                                <CloseIcon/>
                            </IconButton>
                            <Typography variant="h6">
                                Agregar item de cobranza
                            </Typography>
                        </Toolbar>
                    </AppBar>
                )}
                {!fullScreen && (
                    <DialogTitle>Editar Item</DialogTitle>
                )}
                <DialogContent className={fullScreen ? 'mt-7' : ''}>
                    <CobranzaEditFields
                        item={item}
                        _importe={parseFloat(this.props.item.importe)} // Importe Original
                        TIPOS={TIPOS}
                        cuentasBancarias={cuentasBancarias}
                        jurisdicciones={jurisdicciones}
                        fullScreen={fullScreen}
                        cobranza={cobranza}
                        headerData={headerData}
                        onEditItem={this.onEditItem}
                        onAddCheque={this.onAddChequeDialogOpen}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.onClose} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        onClick={this.onConfirm}
                        color="primary"
                        autoFocus
                        disabled={!item || isValid === false}
                    >
                        Confirmar
                    </Button>
                </DialogActions>
                <ChequeAddDialog
                    open={addChequeDialogOpen}
                    fullScreen={fullScreen}
                    tipo={item.tipo}
                    bancos={bancos}
                    cheques={item.cheques}
                    _deleted_cheques={item._deleted_cheques}
                    onConfirm={this.onAddChequeDialogConfirm}
                    onClose={this.onAddChequeDialogClose}
                />
            </React.Fragment>
        );
    }
}

CobranzaItemsEditDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired
};

CobranzaItemsEditDialog.defaultProps = {
    articulo: {}
};

CobranzaItemsEditDialogContent.contextType = MainContext;
CobranzaItemsEditDialogContent = withSnackbar(CobranzaItemsEditDialogContent);

export default withMobileDialog()(withStyles({}, {withTheme: true})(CobranzaItemsEditDialog));
