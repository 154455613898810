import React, {Component} from "react";
import {Button, Dialog, DialogContent} from "@material-ui/core";
import {Grid, Typography} from "@material-ui/core/index";

export class ItemActionsDialog extends Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onCancel}
                fullScreen={this.props.fullScreen}
                keepMounted={this.props.keepMounted}
                maxWidth={this.props.maxWidth}
                fullWidth={this.props.fullWidth}
                classes={{
                    // paper: 'niquel-dialog-green',
                    paperFullScreen: 'niquel-dialog-full-screen',
                }}
            >
                <ItemActionsDialogContent {...this.props}/>
            </Dialog>
        )
    }
}

class ItemActionsDialogContent extends Component {
    render() {
        const {articulo, onCancel, onCompleteScans, onDeleteScans} = this.props;

        const scanned = parseInt(articulo.scanned);
        const cantidad = parseInt(articulo.cantidad);
        const allItemsScanned = scanned === cantidad;
        const itemsRemainForScan = scanned < cantidad;
        const tooMuchItemsScanned = scanned > cantidad;

        return (
            <React.Fragment>
                {/*<DialogTitle id="alert-dialog-title">{title}</DialogTitle>*/}
                <DialogContent>
                    <Grid container className={'h-100'} direction={"column"} alignItems={"center"} justifyContent={"space-evenly"}>
                        {/*<Grid item className={'d-flex'}>*/}
                        {/*    <CheckCircleOutlineRounded style={{color: 'white', fontSize: '80'}}/> :*/}
                        {/*</Grid>*/}
                        <Grid item>
                            <Typography
                                align={"center"}
                                variant={"h6"}
                                // style={{color: 'white'}}
                            >
                                {articulo.id || ''}
                            </Typography>
                            <Typography
                                align={"center"}
                                variant={"body2"}
                                // style={{color: 'white'}}
                            >
                                {articulo.detalle || 'No hay detalles del artículo escaneado'}
                            </Typography>
                            <Typography
                                variant={"h6"}
                                align={"center"}
                                className={'mt-3'}
                                color={tooMuchItemsScanned ? "error" : allItemsScanned ? "primary" : "inherit"}
                            >
                                Leídos {articulo.scanned || 0} de {articulo.cantidad}
                            </Typography>
                        </Grid>
                    </Grid>
                </DialogContent>
                <Button
                    className={'mt-2 mb-2'}
                    onClick={onCompleteScans}
                    color={"primary"}
                    variant={"contained"}
                    style={{height: '3.5rem', width: '90%', margin: '0 auto'}}
                >
                    Completar lecturas
                </Button>
                <Button
                    className={'mt-2 mb-2'}
                    onClick={onDeleteScans}
                    variant={"contained"}
                    style={{height: '3.5rem', width: '90%', margin: '0 auto'}}
                >
                    Borrar lecturas
                </Button>
                <Button className={'mt-5 mb-1'} onClick={onCancel} fullWidth style={{height: '3.5rem'}}>
                    Cerrar
                </Button>

            </React.Fragment>
        )
    }
}
