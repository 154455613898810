import React from 'react';
import {Button, IconButton, List, ListItem, ListItemText, Paper, Tooltip, Typography} from "@material-ui/core";
import {formatPrice} from "assets/utils";
import Grid from "@material-ui/core/Grid";
import ConfirmedIcon from "@material-ui/icons/CheckCircleOutlineRounded";
import {FixedSizeList} from "react-window";
import InfoIcon from "@material-ui/icons/InfoRounded";
import {HighlightOffRounded} from "@material-ui/icons";

export function ItemList(props) {
    const {
        items,
        type,
        loadingText = 'Cargando resultados',
        noResultsText = 'No se encontraron resultados',
        onPrimaryAction,
        onSecondaryAction,
        onRejectItem,
        onInfoPress
    } = props;

    if (items === null)
        return (
            <div>
                <Typography
                    color={"textSecondary"}
                    align={"center"}
                >
                    {loadingText}
                </Typography>
            </div>
        )
    else if (items.length === 0)
        return (
            <div>
                <Typography
                    color={"textSecondary"}
                    align={"center"}
                >
                    {noResultsText}
                </Typography>
            </div>
        )
    else
        return (
            <FixedSizeList height={window.innerHeight - 250} width={'100%'} itemSize={148.2} itemCount={items.length}>
                {({index, style}) => (
                    <div style={style}>
                        <Item
                            item={items[index]}
                            index={index}
                            type={type}
                            onPrimaryAction={onPrimaryAction}
                            onSecondaryAction={onSecondaryAction}
                            onRejectItem={onRejectItem}
                            onInfoPress={onInfoPress}
                        />
                    </div>
                )}
            </FixedSizeList>
        )
}

export function Item(props) {
    const {item, index, onPrimaryAction, onSecondaryAction, onRejectItem, onInfoPress} = props;

    return (
        <>
            <Paper className={'mb-3'}>
                <Grid container direction={"column"}>
                    <Grid item>
                        <List>
                            <ListItem>
                                <Grid item>
                                    <Tooltip title={'Detalles del Item'}>
                                        <IconButton onClick={() => onInfoPress(item)}>
                                            <InfoIcon fontSize={"small"}/>
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                                <ListItemText
                                    primary={'Fecha'}
                                    secondary={item.fecha}
                                    primaryTypographyProps={{variant: 'caption', color: "textSecondary"}}
                                    secondaryTypographyProps={{variant: 'body2', color: "textPrimary"}}
                                    style={{
                                        flexGrow: '0',
                                        paddingRight: '2rem',
                                        minWidth: '7rem'
                                    }}
                                />
                                {/*Importe*/}
                                <ListItemText
                                    primary={'Importe'}
                                    secondary={formatPrice(item.importe, true)}
                                    primaryTypographyProps={{variant: 'caption', color: "textSecondary"}}
                                    secondaryTypographyProps={{variant: 'body2', color: "textPrimary"}}
                                    style={{
                                        flexGrow: '0',
                                        paddingRight: '2rem',
                                        minWidth: '7rem'
                                    }}
                                />
                                {item.tipo === 'TRF' && (
                                    <>
                                        {/*Numero*/}
                                        <ListItemText
                                            primary={'Número'}
                                            secondary={item.numero}
                                            primaryTypographyProps={{variant: 'caption', color: "textSecondary"}}
                                            secondaryTypographyProps={{variant: 'body2', color: "textPrimary"}}
                                            style={{
                                                flexGrow: '0',
                                                paddingRight: '2rem',
                                                minWidth: '7rem'
                                            }}
                                        />
                                        {/*Cuenta*/}
                                        <ListItemText
                                            primary={'Cuenta'}
                                            secondary={item.cuenta_desc}
                                            primaryTypographyProps={{variant: 'caption', color: "textSecondary"}}
                                            secondaryTypographyProps={{variant: 'body2', color: "textPrimary"}}
                                            style={{
                                                flexGrow: '0',
                                                paddingRight: '2rem',
                                                whiteSpace: 'nowrap',
                                            }}
                                        />
                                    </>
                                )}
                                {item.tipo === 'CEL' && (
                                    <>
                                        {/*Numero*/}
                                        <ListItemText
                                            primary={'N° Cheque'}
                                            secondary={item.numero}
                                            primaryTypographyProps={{variant: 'caption', color: "textSecondary"}}
                                            secondaryTypographyProps={{variant: 'body2', color: "textPrimary"}}
                                            style={{
                                                flexGrow: '0',
                                                paddingRight: '2rem',
                                                minWidth: '7rem'
                                            }}
                                        />
                                        {/*Banco*/}
                                        <ListItemText
                                            primary={'Banco'}
                                            secondary={item.banco_desc}
                                            primaryTypographyProps={{variant: 'caption', color: "textSecondary"}}
                                            title={item.banco_desc}
                                            secondaryTypographyProps={{
                                                variant: 'body2',
                                                color: "textPrimary",
                                                style: {
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }
                                            }}
                                            style={{
                                                flexGrow: '0',
                                                paddingRight: '2rem',
                                                maxWidth: '17rem',
                                                whiteSpace: 'nowrap'
                                            }}
                                        />
                                    </>
                                )}
                                {/*Razsoc*/}
                                <ListItemText
                                    primary={'Cliente'}
                                    secondary={item.tipo !== "PREPAGO" ? item.cliente : item.razsoc}
                                    primaryTypographyProps={{variant: 'caption', color: "textSecondary"}}
                                    title={item.cliente}
                                    secondaryTypographyProps={{
                                        variant: 'body2',
                                        color: "textPrimary",
                                        style: {
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }
                                    }}
                                    style={{
                                        flexGrow: '0',
                                        paddingRight: '20px',
                                        maxWidth: '20rem',
                                        whiteSpace: 'nowrap',
                                    }}
                                />
                                {/*Vendedor*/}
                                <ListItemText
                                    primary={'Vendedor'}
                                    secondary={item.vendedor}
                                    primaryTypographyProps={{variant: 'caption', color: "textSecondary"}}
                                    title={item.vendedor}
                                    secondaryTypographyProps={{
                                        variant: 'body2',
                                        color: "textPrimary",
                                        style: {
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis'
                                        }
                                    }}
                                    style={{
                                        flexGrow: '0',
                                        paddingRight: '20px',
                                        maxWidth: '13rem',
                                        whiteSpace: 'nowrap',
                                    }}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item>
                        <Grid container direction={"row"} alignItems={"center"} justifyContent={"space-between"}
                              className={'pt-1 pb-1 pl-3 pr-3 border-top border-light'}>
                            <div className={'d-flex align-items-center'}>
                                {item.tipo !== "PREPAGO" && (
                                    <Typography variant={"caption"} color={"textSecondary"}>
                                        {item.tipo_desc} (descuento: <b>{item.pordesc}%</b> {item.descfijo ? '(fijo)' : ''} {item.equiv_porc ? '(orig. ' + item.equiv_porc + '%)' : ''} | días
                                        ponderados: <b>{item.dpromitems}</b>)
                                    </Typography>
                                )}

                                {item.tipo === "PREPAGO" && (
                                    <Typography variant={"caption"} color={"textSecondary"}>
                                        Transferencia por cotización/pedido PREPAGO
                                    </Typography>
                                )}

                                {item.recibido === true && (
                                    <Tooltip title={'Item Confirmado'}>
                                        <ConfirmedIcon color={"primary"} className={'ml-2'}/>
                                    </Tooltip>
                                )}

                                {item.rechazado === true && (
                                    <Tooltip title={'Item Rechazado'}>
                                        <HighlightOffRounded color={"error"} className={'ml-2'}/>
                                    </Tooltip>
                                )}
                            </div>
                            <div>
                                {item.tipo === 'TRF' && onSecondaryAction && (
                                    <Button
                                        color={"primary"}
                                        className={'mr-2'}
                                        size={"small"}
                                        onClick={() => onSecondaryAction(item, index)}
                                    >
                                        Editar
                                    </Button>
                                )}
                                {item.recibido === false && (
                                    <>
                                        <Tooltip title={'Rechazar Recepción'}>
                                            <Button
                                                className={!item.rechazado ? 'text-danger' : ''}
                                                onClick={() => onRejectItem(item, index)}
                                                disabled={item.rechazado}
                                                size={"small"}
                                            >
                                                Rechazar
                                            </Button>
                                        </Tooltip>
                                        <Tooltip title={'Confirmar Recepción'}>
                                            <Button
                                                color={"primary"}
                                                onClick={() => onPrimaryAction(item, index)}
                                                disabled={item.rechazado}
                                            >
                                                Confirmar
                                            </Button>
                                        </Tooltip>
                                    </>
                                )}
                                {item.recibido === true && (
                                    <Tooltip title={'Cancelar Recepción'}>
                                        <Button
                                            className={'text-danger'}
                                            onClick={() => onPrimaryAction(item, index)}>
                                            Cancelar
                                        </Button>
                                    </Tooltip>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}
