import React, {Component} from 'react'
import {MainContext} from "contexts/main.context";
import {withSnackbar} from "notistack";
import {Box, Button, Card, Grid, IconButton, MenuItem, Switch, TextField, Tooltip, Typography} from '@material-ui/core';
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import 'moment/locale/es';
import SubHeader from "components/helpers/subHeader/subHeader";
import {CobranzaDocumentosList} from "components/helpers/cobranzas/documentos/cobranza.documentos.list";
import {CobranzaItemsList} from "components/helpers/cobranzas/items/cobranza.items.list";
import {formatPrice, getCobranzaStatusInfo, InputInteger, InputPercentage, InputPrice, isCobranzaDigital} from "assets/utils";
import {AssignmentRounded, DeleteRounded as DeleteIcon, DoneRounded as DoneIcon, EditRounded as EditIcon, ErrorOutlineRounded} from "@material-ui/icons";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import {AdditionalData} from "../../../helpers/cobranzas/additional.data.card";

export class CobranzasOperation extends Component {
    render() {
        const {loggedUser} = this.context;
        let {TIPOS} = this.props;
        const {
            operation,
            isPrepago,
            cliente,
            headerData,
            changeHeaderData,
            documentosACancelar,
            itemsDeCobranza,
            ajustesPlazo,
            cobranza,
            dateValidationError,
            notaCreditoValidationError,
            itemValidationError,
            cobranzas,
            id,
            onChangeDocument,
            descuentoFijo,
            _originalDescuento,
            _equivalenteDescuento
        } = this.props;

        const creation = operation === 'CREATE';
        TIPOS = (isPrepago && !["A"].includes(loggedUser.role)) ? TIPOS.filter(tipo => ["TRANSFERENCIA", "RETENCION"].includes(tipo.clase)) : TIPOS;

        return (
            <Box className='niquel-cobranzas-operation'>
                <SubHeader
                    title={'Cobranzas'}
                    subtitle={operation === 'VIEW' ? `Revisión de Cobranza ${id}` : operation === 'EDIT' ? `Edición de Cobranza ${id}` : 'Nueva Cobranza'}
                    cliente={cliente}
                    options={operation === 'VIEW' ? cobranzas : undefined}
                    optionsTitle={'Cobranza'}
                    optionSelectedId={id}
                    optionsDescriptionField={'numrec'}
                    optionsDescriptionSecondaryField={'fecharecibo'}
                    onChangeOption={onChangeDocument}
                />
                <Box className='container-xl mt-3 mb-3'>
                    <Grid container spacing={3}>
                        <Grid container item md={12}>
                            <Grid container item alignItems='center' spacing={3}>
                                <Grid item>
                                    {(loggedUser.role === 'A' || loggedUser.role === 'O') && (
                                        <TextField
                                            required
                                            label="Usuario"
                                            className='mr-2'
                                            value={headerData.usuario}
                                            onChange={event => changeHeaderData('usuario', event.target.value)}
                                            InputProps={{
                                                readOnly: creation === false || (loggedUser.role !== 'A' && loggedUser.role !== 'O')
                                            }}
                                        />
                                    )}
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            disableToolbar
                                            autoOk
                                            required
                                            className='mr-2'
                                            label="Fecha Recibo"
                                            format="DD/MM/Y"
                                            disablePast={creation}
                                            value={headerData.fechaRecibo}
                                            onChange={moment => changeHeaderData('fechaRecibo', moment)}
                                            readOnly={creation === false}
                                            InputProps={{
                                                style: {width: '6.8rem'},
                                                readOnly: creation === false,
                                                onClick: (e) => creation === false && e.stopPropagation()
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label="Recibo N°"
                                        className='mr-2'
                                        value={headerData.recibo}
                                        onChange={event => changeHeaderData('recibo', event.target.value)}
                                        InputProps={{
                                            inputComponent: operation !== 'VIEW' ? InputInteger : undefined,
                                            style: {width: '5rem'},
                                            readOnly: operation !== 'CREATE' && operation !== 'EDIT'
                                        }}
                                    />
                                </Grid>
                                {(loggedUser.role === 'A' || descuentoFijo.enabled) &&
                                    headerData.status !== 'G' &&
                                    headerData.status !== 'R' &&
                                    !(headerData.status === 'O' && headerData.substatus === 'G') &&
                                    // !isPrepago &&
                                    (
                                        <Grid item container spacing={1} className={'w-auto'}>
                                            <Grid item>
                                                <Typography
                                                    variant={"caption"}
                                                    component={'div'}
                                                    style={{
                                                        color: 'rgba(117, 117, 117, 1)',
                                                        lineHeight: '0.9rem'
                                                    }}
                                                >
                                                    Desc. Fijo
                                                </Typography>
                                                <Switch
                                                    disabled={operation === 'VIEW' || loggedUser.role !== 'A'}
                                                    checked={descuentoFijo.enabled}
                                                    onChange={this.props.toggleDescuentoFijo}
                                                />
                                            </Grid>
                                            {descuentoFijo.enabled && (
                                                <Grid item>
                                                    <TextField
                                                        select
                                                        label="Tipo"
                                                        className='mr-2'
                                                        value={descuentoFijo.tipo}
                                                        onChange={event => this.props.changeDescuentoTipo(event.target.value)}
                                                        InputProps={{
                                                            readOnly: operation === 'VIEW' || loggedUser.role !== 'A'
                                                        }}
                                                    >
                                                        {[{id: 'importe', tipo: '$'}, {id: 'porcentaje', tipo: '%'}].map(option => (
                                                            <MenuItem key={option.id} value={option.id}>
                                                                {option.tipo}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                    <TextField
                                                        label="Descuento"
                                                        value={descuentoFijo.tipo === 'importe' ? descuentoFijo.importe : descuentoFijo.porcentaje}
                                                        onChange={event => this.props.changeDescuentoValue(event.target.value)}
                                                        InputProps={{
                                                            inputComponent: descuentoFijo.tipo === 'importe' ? InputPrice : InputPercentage,
                                                            style: descuentoFijo.tipo === 'importe' ? {width: '6rem'} : {width: '3rem'},
                                                            readOnly: operation === 'VIEW' || loggedUser.role !== 'A'
                                                        }}
                                                    />
                                                </Grid>
                                            )}
                                        </Grid>
                                    )}
                                {(operation === 'VIEW' || operation === 'EDIT') && (
                                    <Grid item className={'align-self-baseline'}>
                                        <Typography variant={"caption"} component={"div"} color={"textSecondary"}>
                                            Estado
                                        </Typography>
                                        <Tooltip title={getCobranzaStatusInfo(headerData.status).tooltip}>
                                            <div className={'d-inline-flex align-items-center'}>
                                                {getCobranzaStatusInfo(headerData.status).icon}
                                                <Typography
                                                    className={'ml-1'}
                                                    component={"span"}
                                                    variant={"body2"}
                                                    color={getCobranzaStatusInfo(headerData.status).color}>
                                                    {getCobranzaStatusInfo(headerData.status).description}
                                                </Typography>
                                            </div>
                                        </Tooltip>
                                    </Grid>
                                )}
                                {loggedUser.role === 'A' && (operation === 'EDIT' || operation === 'CREATE') && (
                                    <Grid item className={'text-right'}>
                                        <Tooltip title={'Agregar una observación a la cobranza transitoria'}>
                                            <Button variant="outlined" color={"primary"}
                                                    onClick={this.props.openObservaDialog}>
                                                <ErrorOutlineRounded fontSize={"small"} className={'mr-1'}/> Observar
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                )}
                                {(operation === 'CREATE') && (
                                    <Grid item className={'text-right'}>
                                        <Tooltip title={'Ver documentos impagos del cliente'}>
                                            <Button variant="outlined" color={"primary"}
                                                    onClick={this.props.openImpagosDialog}>
                                                <AssignmentRounded fontSize={"small"} className={'mr-1'}/> Doc. Impagos
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                )}
                                {loggedUser.role === 'A' && operation === 'VIEW' && (
                                    <Grid item className={'flex-grow-1 text-right'}>
                                        <Tooltip title={'Eliminar Cobranza'}>
                                            <Button className={'background-error'} style={{color: 'white'}}
                                                    onClick={this.props.onDelete}>
                                                <DeleteIcon fontSize={"small"}/> ELIMINAR
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                )}
                                {operation === 'CREATE' && (
                                    <Grid item className={'flex-grow-1 text-right'}>
                                        <Tooltip title={'Generar Cobranza'}>
                                            <Button variant="contained" color={"primary"} onClick={this.props.onCreate}>
                                                <DoneIcon fontSize={"small"} className={'mr-1'}/> GENERAR
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                )}
                                {operation === 'EDIT' && (
                                    <Grid item className={'flex-grow-1 text-right'}>
                                        <Tooltip title={'Editar Cobranza'}>
                                            <Button variant="contained" color={"primary"} onClick={this.props.onEdit}>
                                                <EditIcon fontSize={"small"} className={'mr-1'}/> CONFIRMAR
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>


                    <Box className={'mt-2'}>
                        <Grid container direction={"column"} spacing={1}>
                            {headerData['observa'] && (
                                <Grid item xs={12} className={'pt-2 pb-2'}>
                                    <Typography align={"center"}
                                                color={"error"}>Atención: {headerData['observa']}</Typography>
                                </Grid>
                            )}
                            <Grid item container wrap={"nowrap"} spacing={2}>
                                <Grid item xs={6}>
                                    <Card className={'pr-1 pl-2 pt-2 pb-2 h-100'}>
                                        <CobranzaDocumentosList
                                            documentos={documentosACancelar}
                                            cobranza={cobranza}
                                            dateValidationError={dateValidationError}
                                            notaCreditoValidationError={notaCreditoValidationError}
                                            onChangeImporte={this.props.changeDocumentoImporte}
                                            onRemove={this.props.removeDocumento}
                                            onRemoveAll={this.props.removeAllDocumentos}
                                            onAdjust={this.props.adjustDocumentos}
                                            setMinImporte={this.props.setMinDocumentoImporte}
                                            setMaxImporte={this.props.setMaxDocumentoImporte}
                                            showInfoDocumento={this.props.showInfoDocumento}
                                            onAdd={this.props.openDocumentosAddDialog}
                                            operation={operation}
                                        />
                                    </Card>
                                </Grid>
                                <Grid item xs={6}>
                                    <Card className={'pr-1 pl-2 pt-2 pb-2 h-100'}>
                                        <CobranzaItemsList
                                            items={itemsDeCobranza}
                                            fechaRecibo={headerData.fechaRecibo}
                                            promedioPonderado={cobranza?.items?.promedio_ponderado}
                                            cliente={cliente}
                                            TIPOS={TIPOS}
                                            itemValidationError={itemValidationError}
                                            onChangeTipo={this.props.changeItemTipo}
                                            onChangeFecha={this.props.changeItemFecha}
                                            onChangeImporte={this.props.changeItemImporte}
                                            onAdjustItem={this.props.adjustItem}
                                            onRemove={this.props.removeItem}
                                            onRemoveAll={this.props.removeAllItems}
                                            onEdit={this.props.handleEditItem}
                                            onAddCheque={this.props.handleAddCheque}
                                            onAdd={this.props.addItem}
                                            operation={operation}
                                        />
                                    </Card>
                                </Grid>
                            </Grid>
                            <Grid item container wrap={"nowrap"} spacing={2}>
                                <Grid item xs={6}>
                                    <Card className={'pt-2 pb-2 pr-3 pl-3 h-100'}>
                                        <Grid container direction={"column"} spacing={1}>
                                            <Grid container item direction={"row"}>
                                                <Typography variant={"caption"} className={'font-weight-bold'}>Total a
                                                    Cancelar</Typography>
                                                <div style={{
                                                    flex: '1 1 auto',
                                                    borderBottom: '1px solid #e4e4e4',
                                                    margin: '0 0.3rem 0.3rem 0.3rem'
                                                }}/>
                                                <Typography variant={"caption"} align={"right"}
                                                            className={'font-weight-bold'}>
                                                    {formatPrice(cobranza.documentos.total, true)}
                                                </Typography>
                                                {(cobranza.documentos.scc > 0 && (
                                                    <Typography variant={"caption"} align={"right"}>
                                                        &nbsp;({formatPrice(cobranza.documentos.total + cobranza.documentos.scc, true)}&nbsp;
                                                        - SCC: {formatPrice(cobranza.documentos.scc, true)})
                                                    </Typography>
                                                ))}
                                            </Grid>

                                            <Grid item container justifyContent={"flex-end"}>
                                                <Tooltip title={'Agregar Ajuste Plazo'}>
                                                    <span>
                                                        <Button
                                                            variant={"outlined"}
                                                            size={"small"}
                                                            color={"primary"}
                                                            disabled={!(creation || (operation === 'EDIT' && headerData.status === 'G'))}
                                                            onClick={this.props.openAjustesPlazoAddDialog}
                                                        >
                                                            + Ajuste Plazo
                                                        </Button>
                                                    </span>
                                                </Tooltip>
                                            </Grid>

                                            <List dense>
                                                {ajustesPlazo.map(ajuste => (
                                                    <ListItem key={ajuste.id}>
                                                        <ListItemText secondary={`${ajuste.tipo} ${ajuste.numero}`}/>
                                                        <ListItemText
                                                            secondary={`${ajuste.ingreso} (${ajuste.dias} días)`}/>
                                                        <ListItemText secondary={`${formatPrice(ajuste.total, true)}`}/>
                                                        <ListItemSecondaryAction>
                                                            <Tooltip title={'Quitar Ajuste Plazo'}>
                                                                <span>
                                                                    <IconButton
                                                                        disabled={!(creation || (operation === 'EDIT' && headerData.status === 'G'))}
                                                                        onClick={() => this.props.removeAjustePlazo(ajuste.id)}
                                                                    >
                                                                        <DeleteIcon fontSize={"small"}/>
                                                                    </IconButton>
                                                                </span>
                                                            </Tooltip>
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                ))}
                                            </List>
                                        </Grid>
                                    </Card>
                                </Grid>
                                <Grid item xs={6}>
                                    <Card className={'pt-2 pb-2 pr-3 pl-3 h-100'}>
                                        <Grid container direction={"row"}>
                                            <Typography variant={"caption"} className={'font-weight-bold'}>Total a
                                                Cobrar</Typography>
                                            <div style={{
                                                flex: '1 1 auto',
                                                borderBottom: '1px solid #e4e4e4',
                                                margin: '0 0.3rem 0.3rem 0.3rem'
                                            }}/>
                                            <Typography variant={"caption"} align={"right"}
                                                        className={'font-weight-bold'}>
                                                {formatPrice(cobranza.items.total, true)}
                                            </Typography>
                                        </Grid>
                                        <Grid container direction={"row"}>
                                            <Typography
                                                variant={"caption"}
                                                color={cobranza.descuento.error ? "error" : "primary"}>
                                                Descuento
                                                ({isFinite(cobranza.descuento.porcentaje) ? `${cobranza.descuento.porcentaje}%` : '-'})
                                            </Typography>
                                            <div style={{
                                                flex: '1 1 auto',
                                                borderBottom: '1px solid #e4e4e4',
                                                margin: '0 0.3rem 0.3rem 0.3rem'
                                            }}/>
                                            <Typography variant={"caption"} align={"right"}
                                                        color={cobranza.descuento.error ? "error" : "primary"}>
                                                {formatPrice(cobranza.descuento.importe, true)}&nbsp;
                                                ({formatPrice(cobranza.descuento.importe_sin_iva, true)} +
                                                IVA: {formatPrice(cobranza.descuento.iva, true)})
                                            </Typography>
                                        </Grid>
                                        {((headerData.status === 'G' && operation === 'EDIT') || headerData.status === 'R') && (
                                            <Grid container direction={"row"}>
                                                <Typography
                                                    variant={"caption"}
                                                    style={{
                                                        color: _equivalenteDescuento.error ? 'red' : 'rgb(148 148 148)'
                                                    }}
                                                >
                                                    Descuento Equivalente ({_equivalenteDescuento.porcentaje}%)
                                                </Typography>
                                                <div style={{
                                                    flex: '1 1 auto',
                                                    borderBottom: '1px solid #e4e4e4',
                                                    margin: '0 0.3rem 0.3rem 0.3rem'
                                                }}/>
                                                <Typography
                                                    variant={"caption"}
                                                    align={"right"}
                                                    style={{
                                                        color: _equivalenteDescuento.error ? 'red' : 'rgb(148 148 148)'
                                                    }}
                                                >
                                                    {formatPrice(_equivalenteDescuento.importe, true)}&nbsp;
                                                    ({formatPrice(_equivalenteDescuento.importe_sin_iva, true)} +
                                                    IVA: {formatPrice(_equivalenteDescuento.iva, true)})
                                                </Typography>
                                            </Grid>
                                        )}
                                        {((headerData.status === 'G' && operation === 'EDIT') || headerData.status === 'R' || _originalDescuento.porcentaje /* que no sea undefined*/) && (
                                            <Grid container direction={"row"}>
                                                <Typography
                                                    variant={"caption"}
                                                    style={{color: 'rgb(148 148 148)'}}
                                                >
                                                    Descuento Original ({_originalDescuento.porcentaje}%)
                                                </Typography>
                                                <div style={{
                                                    flex: '1 1 auto',
                                                    borderBottom: '1px solid #e4e4e4',
                                                    margin: '0 0.3rem 0.3rem 0.3rem'
                                                }}/>
                                                <Typography
                                                    variant={"caption"}
                                                    align={"right"}
                                                    style={{color: 'rgb(148 148 148)'}}
                                                >
                                                    {formatPrice(_originalDescuento.importe, true)}&nbsp;
                                                    ({formatPrice(_originalDescuento.importe_sin_iva, true)} +
                                                    IVA: {formatPrice(_originalDescuento.iva, true)})
                                                </Typography>
                                            </Grid>
                                        )}
                                        {cliente.sin_descuento && (
                                            <Grid container direction={"row"}>
                                                <Typography
                                                    variant={"caption"}
                                                    style={{color: 'rgb(148 148 148)'}}
                                                >
                                                    Por ser cliente especial, no se calcula descuento.
                                                </Typography>
                                            </Grid>
                                        )}
                                        {cobranza.ajustes_plazo.total > 0 && (
                                            <Grid container direction={"row"}>
                                                <Typography variant={"caption"}
                                                            color={cobranza.saldo_total_cliente < 0 ? "error" : "textPrimary"}>
                                                    Ajuste Plazo
                                                </Typography>
                                                <div style={{
                                                    flex: '1 1 auto',
                                                    borderBottom: '1px solid #e4e4e4',
                                                    margin: '0 0.3rem 0.3rem 0.3rem'
                                                }}/>
                                                <Typography variant={"caption"} align={"right"}>
                                                    {formatPrice(cobranza.ajustes_plazo.total, true)}
                                                </Typography>
                                            </Grid>
                                        )}
                                        <Grid container direction={"row"}>
                                            <Typography variant={"caption"}>Total a Cancelar</Typography>
                                            <div style={{
                                                flex: '1 1 auto',
                                                borderBottom: '1px solid #e4e4e4',
                                                margin: '0 0.3rem 0.3rem 0.3rem'
                                            }}/>
                                            <Typography variant={"caption"} align={"right"}>
                                                {formatPrice(parseFloat(cobranza.items.total) + parseFloat(cobranza.descuento.importe) + parseFloat(cobranza.ajustes_plazo.total), true)}
                                            </Typography>
                                        </Grid>
                                        <Grid container direction={"row"}>
                                            <Typography
                                                variant={"caption"}
                                                color={cobranza.saldo_cliente < 0 ? "error" : "textPrimary"}
                                                style={{
                                                    fontWeight: "bold",
                                                    color: cobranza.saldo_cliente < 0 ?
                                                        "#ff0000" :
                                                        cobranza.saldo_cliente > 0 ?
                                                            "#004eff" :
                                                            "#212121"
                                                }}
                                            >
                                                Saldo
                                            </Typography>
                                            <div style={{
                                                flex: '1 1 auto',
                                                borderBottom: '1px solid #e4e4e4',
                                                margin: '0 0.3rem 0.3rem 0.3rem'
                                            }}/>
                                            <Typography
                                                variant={"caption"}
                                                align={"right"}
                                                style={{
                                                    fontWeight: "bold",
                                                    color: cobranza.saldo_cliente < 0 ?
                                                        "#ff0000" :
                                                        cobranza.saldo_cliente > 0 ?
                                                            "#004eff" :
                                                            "#212121"
                                                }}
                                            >
                                                {formatPrice(cobranza.saldo_cliente, true)}
                                            </Typography>
                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                            {isCobranzaDigital(itemsDeCobranza) && (
                                <Grid item container wrap={"nowrap"} spacing={2} direction={"row-reverse"}>
                                    <Grid item xs={6}>
                                        <AdditionalData items={itemsDeCobranza} idrecibo={id} editMode={operation !== "VIEW"}/>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Box>
                </Box>
            </Box>
        )
    }
}

CobranzasOperation.contextType = MainContext;
CobranzasOperation = withSnackbar(CobranzasOperation);
CobranzasOperation.defaultProps = {
    operation: 'CREATE',
    cliente: {},
    cobranzas: {
        columns: [],
        data: []
    },
    vendedores: [],
    talrecibos: [],
    headerData: {
        talrecibo: '3',
        jurisdiccion: '',
        observaciones: '',
        usuario: '',
        vendedor: '',
        ingreso: (new MomentUtils()).date(new Date()).startOf("day")
    }
};
