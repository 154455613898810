import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {DoneAllRounded} from "@material-ui/icons";
import React from "react";
import {Divider, Typography} from "@material-ui/core";
import DoneRounded from "@material-ui/icons/DoneRounded";
import Tooltip from "@material-ui/core/Tooltip";

export const NotificationsList = ({data = [], sentReceived}) => {
    console.log(data);
    return (
        <div>
            {data.length <= 0 ?
                <Typography variant={"caption"} component={"div"} align={"center"}>
                    No hay notificaciones {sentReceived === 'received' ? 'recibidas' : 'enviadas'}.
                </Typography> :
                data.map(notification => (
                    <>
                        <ListItem alignItems="flex-start">
                            <ListItemText
                                primary={
                                    <div className={'d-flex align-items-center justify-content-between'}>
                                        <Typography variant={"body2"} component={"span"}>
                                            <b>{sentReceived === 'received' ? 'de' : 'para'}</b> {notification.user_name}
                                        </Typography>
                                        <span className={'d-flex align-items-center'}>
                                <Typography variant={"body2"} component={'span'} color={"textSecondary"} className={'mr-3'}>
                                    {notification.date}
                                </Typography>
                                            {sentReceived === 'sent' && (
                                                notification.viewed ? (
                                                    <Tooltip title={'visto'}>
                                                        <DoneAllRounded fontSize={"small"} style={{color: 'rgb(64 169 255 / 1)'}}/>
                                                    </Tooltip>
                                                ) : (
                                                    <Tooltip title={'pendiente'}>
                                                        <DoneRounded fontSize={"small"} style={{color: '#9e9e9e'}}/>
                                                    </Tooltip>
                                                )
                                            )}
                                </span>
                                    </div>
                                }
                                secondary={
                                    <div className={'mt-2 p-2'} style={{background: '#EAEAEA', borderRadius: '4px'}}>
                                        <Typography component="span" variant="body2" color="textPrimary">
                                            {notification.msg.replace(/\n|\r|\t|\\n/g, '#br#').split('#br#').map((item, key) => <span key={key}>{item}<br/></span>)}
                                        </Typography>
                                    </div>
                                }
                            />
                        </ListItem>
                        <Divider/>
                    </>
                ))}
        </div>
    )
}
