import React, {useEffect, useState} from 'react';
import {AppBar, Box, Grid, Tab, Tabs, Toolbar} from "@material-ui/core";
import {Route, Switch, useHistory} from 'react-router-dom';
import SubHeader from "components/helpers/subHeader/subHeader";
import {Generar} from "./generar";
import {Revisar} from "./revisar";
import {useLocation, useRouteMatch} from "react-router";

const basePath = '/listados/rendicionCobranzas';

export const RendicionCobranzas = () => {
    const [tabActive, setTabActive] = useState('generar');

    const history = useHistory();
    const location = useLocation();
    const match = useRouteMatch();

    useEffect(() => {
        if (location.pathname.indexOf('revisar') > -1)
            setTabActive('revisar');
    }, []);

    const changeTab = (event, value) => {
        setTabActive(value);
        history.push(`${match.url}/${value}`);
    };

    return (
        <div>
            <SubHeader
                title={'Rendición de Cobranzas'}
                // subtitle={'Confirmar rendiciones de cobranza'}
            />
            <AppBar position="relative" elevation={0}>
                <Toolbar variant="dense" color='primary'>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Tabs
                                value={tabActive}
                                onChange={changeTab}
                                indicatorColor="secondary"
                                textColor="inherit"
                            >
                                <Tab label="Revisar" value='revisar'/>
                                <Tab label="Generar" value='generar'/>
                            </Tabs>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Box className='container-xl mt-3 mb-3'>
                <Switch>
                    <Route path={`${basePath}/revisar`} render={() => <Revisar key={"revisar"}/>}/>,
                    <Route path={`${basePath}`} render={() => <Generar key={"generar"}/>}/>
                </Switch>
            </Box>
        </div>
    )
}