import React, {Component} from 'react';
import {AppBar, Box, Grid, IconButton, Tab, Tabs, Toolbar, Tooltip} from "@material-ui/core";
import {MainContext} from "contexts/main.context";
import {Route, Switch} from 'react-router-dom';
import SubHeader from "components/helpers/subHeader/subHeader";
import {List} from "./list";
import Badge from "@material-ui/core/Badge";
import PedidosService from "services/pedidos.service";
import {withSnackbar} from "notistack";
import {CancelRounded, DoneRounded} from "@material-ui/icons";

const basePath = '/despacho/pedidosLibres';

export class PedidosLibres extends Component {
    state = {
        tabActive: 'facturados',
        rows: [],
        pendientes: 0
    };

    PedidosService = new PedidosService(this.context);

    componentDidMount() {
        if (this.props.location.pathname.indexOf('entregados') > -1)
            this.setState({tabActive: 'entregados'});
    }

    changeTab = (event, value) => {
        this.setState({tabActive: value});
        this.props.history.push(`${this.props.match.url}/${value}`);
    };

    handleEntregar = ({row, rowId}) => {
        this.PedidosService.changeStatus(
            row.id,
            'E',
            response => {
                // Lo muestro temporalmente como entregado.
                // row.estadio = 'E';
                this.props.enqueueSnackbar(`El pedido ${row.id} se marcó como entregado`, {variant: 'success'});
                this.getFacturados();
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    };

    handleCancelarEntrega = ({row, rowId}) => {
        this.PedidosService.changeStatus(
            row.id,
            'N',
            response => {
                this.props.enqueueSnackbar(`El pedido ${row.id} se marcó como facturado y libre`, {variant: 'success'});
                this.getEntregados();
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    };

    getFacturados = () => {
        this.PedidosService.getPedidosFacturadosSinHoja(
            response => this.setState({rows: response.data.result.filter(pedido => !pedido.cerrado)}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    getEntregados = () => {
        this.PedidosService.getPedidosEntregadosSinHoja(
            response => this.setState({rows: response.data.result.filter(pedido => !pedido.cerrado)}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    render() {
        const {tabActive, rows} = this.state;
        return (
            <div>
                <SubHeader
                    title={'Pedidos Libres'}
                    subtitle={'Pedidos sin hoja de ruta'}
                />
                <AppBar position="relative" elevation={0}>
                    <Toolbar variant="dense" color='primary'>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item md={4}>
                                <Tabs
                                    value={tabActive}
                                    onChange={this.changeTab}
                                    indicatorColor="secondary"
                                    textColor="inherit"
                                >
                                    <Tab label={<Badge color="secondary" className='pr-3'>Facturados</Badge>} value='facturados'/>
                                    <Tab label="Entregados" value='entregados'/>
                                </Tabs>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Box className='container-xl mt-3 mb-3'>
                    <Switch>
                        <Route path={`${basePath}/entregados`} render={
                            () => <List
                                key={1}
                                operation={'cancelarEntrega'}
                                actionButton={props =>
                                    <Tooltip title={'Cancelar entrega'}>
                                        <IconButton onClick={() => this.handleCancelarEntrega(props)}>
                                            <CancelRounded/>
                                        </IconButton>
                                    </Tooltip>
                                }
                                getData={this.getEntregados}
                                gridTitle={'Pedidos entregados en el último mes'}
                                gridRows={rows}
                            />
                        }/>
                        <Route path={`${basePath}`} render={
                            () => <List
                                key={2}
                                operation={'entregar'}
                                actionButton={props =>
                                    <Tooltip title={'Entregar'}>
                                        <IconButton onClick={() => this.handleEntregar(props)}>
                                            <DoneRounded/>
                                        </IconButton>
                                    </Tooltip>
                                }
                                getData={this.getFacturados}
                                gridTitle={'Pedidos libres sin entregar'}
                                gridRows={rows}
                            />
                        }/>
                    </Switch>
                </Box>
            </div>
        )
    }
}

PedidosLibres.contextType = MainContext;
PedidosLibres = withSnackbar(PedidosLibres);
