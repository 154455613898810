import React from "react";
import Box from "@material-ui/core/Box";
import {formatPrice} from "assets/utils";
import {Paper, Typography} from "@material-ui/core";
import {ResponsiveBar} from "@nivo/bar";

const moment = require('moment');

const formatId = (id, periodo = 'actual', fullYear = false) => {
    let [month, anioActual = ''] = id.split('_');
    let anioAnterior = parseInt(anioActual) - 1;

    let ret = `${moment(month, "M").format('MMM')}`;

    if (!fullYear) {
        anioAnterior = anioAnterior.toString().substr(-2);
        anioActual = anioActual.substr(-2);
    }

    if (periodo === 'todos')
        return `${ret} ${anioAnterior} | ${anioActual}`;
    else if ((periodo === 'anterior'))
        return `${ret} ${anioAnterior}`;
    else
        return `${ret} ${anioActual}`;
}

export const VentasMensualesChart = React.memo(function ({height, data, anterior, value}) {
    const colors = {actual: "rgba(0, 150, 136, 0.8)", anterior: "rgba(36, 173, 160, 0.5)"};

    return (
        <Box style={{height: `${height}px`, width: '100%'}} className={'d-flex justify-content-center align-items-center'}>
            {(data.length <= 0 || data[value].length <= 0) ?
                <Typography align={"center"} color={"textSecondary"}>
                    No hay datos para mostrar
                </Typography> :
                <ResponsiveBar
                    data={data[value]}
                    keys={anterior ? ['anterior', 'actual'] : ['actual']}
                    indexBy={'id'}
                    margin={{top: 10, right: 40, bottom: 60, left: 80}}
                    padding={0.3}
                    groupMode="grouped"
                    valueScale={{type: 'linear'}}
                    indexScale={{type: 'band', round: true}}
                    // valueFormat={{format: '', enabled: false}}
                    colors={anterior ? [colors.anterior, colors.actual] : [colors.actual]}
                    borderColor={{from: 'color', modifiers: [['darker', 1.6]]}}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Meses',
                        legendPosition: 'middle',
                        legendOffset: 40,
                        format: (id) => formatId(id, anterior ? 'todos' : 'actual')
                    }}
                    axisLeft={{
                        tickSize: 0,
                        tickPadding: 15,
                        tickRotation: 0,
                        legend: 'Total Mensual',
                        legendPosition: 'middle',
                        legendOffset: -50,
                        format: '~s'
                    }}
                    enableGridY={false}
                    labelSkipWidth={50}
                    // labelTextColor={{from: 'color', modifiers: [['darker', 2.9]]}}
                    labelTextColor={"#333333"}
                    labelFormat={value => formatPrice(value)}
                    label={d => `${formatPrice(d.value, value === 'importe')}`}
                    isInteractive={true}
                    tooltip={(props) => {
                        return (
                            <Paper elevation={1} className={'p-2'}>
                                <Typography variant={"body2"}>
                                    {formatId(props.indexValue, props.id, true)}: <b>{formatPrice(props.value, value === 'importe')}</b>
                                </Typography>
                            </Paper>
                        )
                    }}
                    pointSize={10}
                    pointColor={{theme: 'background'}}
                    pointBorderWidth={2}
                    pointBorderColor={{from: 'serieColor'}}
                    pointLabelYOffset={-12}
                    useMesh={true}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                />
            }
        </Box>
    )
})
