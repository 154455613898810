import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, Typography} from "@material-ui/core";
import {ReactGrid} from "components/helpers/reactGrid/reactGrid";
import {MainContext} from "contexts/main.context";
import {withSnackbar} from "notistack";
import {AddCircleRounded, RemoveCircleRounded} from "@material-ui/icons";
import DialogTitle from "@material-ui/core/DialogTitle";

export class DiferenciasDialog extends Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onCancel}
                fullScreen={this.props.fullScreen}
                // TransitionComponent={this.props.TransitionComponent}
                transitionDuration={200}
                maxWidth={"sm"}
                // fullWidth={true}
                classes={{
                    paperFullScreen: 'niquel-dialog-full-screen'
                }}
            >
                <DiferenciasDialogContent {...this.props}/>
            </Dialog>
        )
    }
}

function CodigoEstadoFormatter(props) {
    const {row} = props;
    return (
        <div className={'d-inline-flex align-items-center'}>
            {row.estado ? <AddCircleRounded fontSize={"small"} color={"primary"}/> : <RemoveCircleRounded fontSize={"small"} color={"error"}/>}
            <Typography
                className={'ml-1'}
                component={"span"}
                variant={"body2"}
                color={row.estado ? "Primary" : "Error"}
            >
                {row.estado ? "Nuevo" : "Eliminado"}
            </Typography>
        </div>
    )
}

class DiferenciasDialogContent extends Component {
    state = {
        table: {
            columns: [
                {name: 'hoja', title: 'Hoja'},
                {name: 'codigo', title: 'Código'},
                {name: 'custom1', title: 'Estado'}
            ],
            columnExtensions: [
                {columnName: 'custom1', width: 250, component: CodigoEstadoFormatter, align: 'center'}
            ],
            selected: [],
            all: true
        },
    }

    render() {
        const {diferencias, onConfirm, onCancel} = this.props;
        console.log(diferencias);
        const {table} = this.state;

        // Viewport Height
        const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        const tableHeight = viewportHeight - 270;

        return (
            <React.Fragment>
                <DialogTitle id="alert-dialog-title">Actualizar Plantilla</DialogTitle>
                <DialogContent>
                    <ReactGrid
                        elevation={0}
                        enableVirtualMode={true}
                        height={tableHeight}
                        columns={table.columns}
                        columnExtensions={table.columnExtensions}
                        rowExtensions={table.rowExtensions}
                        getRowId={row => row.id}
                        rows={diferencias}
                        title={`Diferencias con plantilla original`}
                        showResultCount={true}
                        enableFiltering={false}
                        enableSorting={true}
                        enableSearch={true}
                        enableSelection={false}
                        showSelectAll={false}
                    />
                </DialogContent>
                <DialogActions>
                    {/*Muestro Confirm*/}
                    {onCancel && (
                        <Button onClick={onCancel} color={"primary"}>
                            Cancelar
                        </Button>
                    )}

                    {/*Muestro Actualizar*/}
                    {onConfirm && (
                        <Button onClick={onConfirm} color={"primary"} autoFocus>
                            Confirmar
                        </Button>
                    )}
                </DialogActions>
            </React.Fragment>
        )
    }
}

DiferenciasDialogContent.contextType = MainContext;
DiferenciasDialogContent = withSnackbar(DiferenciasDialogContent);
