import React, {Component} from 'react'
import {Box, Button, Grid, Switch, Tooltip} from "@material-ui/core";
import {ReactGrid} from 'components/helpers/reactGrid/reactGrid';
import ClientesService from 'services/clientes.service';
import {withSnackbar} from "notistack";
import {MainContext} from "contexts/main.context";
import SubHeader from "components/helpers/subHeader/subHeader";
import PersonAddIcon from "@material-ui/icons/PersonAddRounded";
import {sortNumeric} from "assets/utils";
import {RoomRounded} from "@material-ui/icons";
import {ClientesMapDialog} from "components/ventas/clientes/list/clientes.map.dialog";
import {ExportOptions} from "components/helpers/exportOptions/export.options";
import {ListadosService} from "services";

export class ClientesList extends Component {
    ClientesService = new ClientesService(this.context);
    ListadosService = new ListadosService(this.context);

    state = {
        columns: [
            {name: 'id', title: 'ID'},
            {name: 'razsoc', title: 'Razón Social', style: {whiteSpace: 'break-spaces'}},
            {name: 'cuit', title: 'CUIT'},
            {name: 'localidad', title: 'Localidad'},
            {name: 'direccion', title: 'Dirección', style: {whiteSpace: 'break-spaces'}},
            {name: 'apenom', title: 'Vendedor'},
            {name: 'clasifica', title: 'Clasificación'}
        ],
        columnExtensions: [
            {columnName: 'id', width: 115},
            {columnName: 'cuit', compare: sortNumeric, width: 105},
            {columnName: 'localidad', width: 170},
            {columnName: 'apenom', width: 140},
            {columnName: 'clasifica', width: 127},
        ],
        rowExtensions: {
            color: cliente => cliente.inhabilitado ? 'rgba(255,84,84,0.22)' : cliente.inactivo ? 'rgba(255,227,84,0.22)' : ''
        },
        direcciones: [],
        direcciones_filtered: [],
        clientes: [],
        rows: [],
        mapDialogOpen: false,
        inhabilitados: false,
        inactivos: false,
        ocasionales: true
    };

    filteredRows = [];

    componentDidMount() {
        this.ClientesService.getAllWithDirecciones(
            response => {
                const direcciones = response.data.result;
                let clientes = direcciones.reduce((clientes, direccion) => {
                    (clientes[direccion['id']] = clientes[direccion['id']] || []).push(direccion);
                    return clientes;
                }, {});
                clientes = Object.keys(clientes).map(idcliente => clientes[idcliente][0]);

                this.setState(prevState => ({...prevState, direcciones: direcciones, clientes: clientes, rows: clientes}), this.filterClientes)
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'}));
    }

    filterClientes = () => {
        this.setState(prevState => ({
            ...prevState,
            rows: prevState.clientes.filter(cliente => {
                return !(
                    (cliente.inhabilitado && !prevState.inhabilitados) ||
                    (cliente.inactivo && !prevState.inactivos) ||
                    ((cliente.id[0] === 'X') && !prevState.ocasionales)
                )
            }),
            direcciones_filtered: prevState.direcciones.filter(direccion => {
                return !(
                    (direccion.inhabilitado && !prevState.inhabilitados) ||
                    (direccion.inactivo && !prevState.inactivos) ||
                    ((direccion.id[0] === 'X') && !prevState.ocasionales)
                )
            })
        }));
    }

    navCreate = (row) => {
        this.props.history.push(`${this.props.match.url}/create`);
    };

    setMapDialog = open => this.setState({mapDialogOpen: open});

    setInhabilitados = inabilitados => this.setState({inhabilitados: inabilitados}, this.filterClientes);
    setInactivos = inactivos => this.setState({inactivos: inactivos}, this.filterClientes);
    setOcasionales = ocasionales => this.setState({ocasionales: ocasionales}, this.filterClientes);

    onFilterRows = _filteredRows => this.filteredRows = _filteredRows;

    getPDF = fnSuccess => {
        this.ListadosService.clientes(
            this.filteredRows.map(row => row.id),
            file => fnSuccess(file),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        )
    };

    render() {
        const {
            columns, columnExtensions, rowExtensions, rows, mapDialogOpen, direcciones_filtered,
            inhabilitados, inactivos, ocasionales
        } = this.state;

        return (
            <Box className='niquel-clientes-list'>
                <SubHeader
                    title={'Clientes'}
                    subtitle={'Listado de clientes'}
                />
                <Box className='container-xl mt-3 mb-3'>
                    <Grid container spacing={2}>
                        <Grid item>
                            Inhabilitados
                            <Switch
                                checked={inhabilitados}
                                onChange={() => this.setInhabilitados(!inhabilitados)}
                                name="checkedB"
                                color="secondary"
                            />
                        </Grid>
                        <Grid item>
                            Inactivos
                            <Switch
                                checked={inactivos}
                                onChange={() => this.setInactivos(!inactivos)}
                                name="checkedB"
                                color="secondary"
                            />
                        </Grid>
                        <Grid item>
                            Ocasionales
                            <Switch
                                checked={ocasionales}
                                onChange={() => this.setOcasionales(!ocasionales)}
                                name="checkedB"
                                color="secondary"
                            />
                        </Grid>
                        <Grid item>
                            <Tooltip title={'Ver en mapa'}>
                                <Button
                                    color="textSecondary"
                                    onClick={() => this.setMapDialog(true)}
                                    variant={"outlined"}
                                >
                                    <RoomRounded/> Ven en Mapa
                                </Button>
                            </Tooltip>
                        </Grid>
                        <Grid item>
                            <Tooltip title={'Agregar cliente ocasional'}>
                                <Button
                                    color="primary"
                                    onClick={this.navCreate}
                                    variant={"outlined"}
                                >
                                    <PersonAddIcon/> Cliente Ocasional
                                </Button>
                            </Tooltip>
                        </Grid>
                    </Grid>
                    <Box className='mt-3'>
                        <ReactGrid
                            title={'Clientes'}
                            showResultCount
                            height={-240}
                            columns={columns}
                            columnExtensions={columnExtensions}
                            rowExtensions={rowExtensions}
                            rows={rows}
                            enableFiltering={false}
                            // showFilters={false}
                            rowClick={false}
                            onFilterRows={this.onFilterRows}
                        />
                    </Box>
                    {["A", "O", "S"].includes(this.context.loggedUser.role) && (
                        <ExportOptions
                            enableDownload
                            enableMail
                            enablePrint
                            enableShow
                            getPDF={this.getPDF}
                            fileName={"clientes.pdf"}
                            mailSubject={"Clientes"}
                            mailBody={"Estimado,  \n\nSe adjunta el listado de clientes del sistema."}
                        />
                    )}
                </Box>
                <ClientesMapDialog
                    open={mapDialogOpen}
                    fullScreen
                    direcciones={direcciones_filtered}
                    onCancel={() => this.setMapDialog(false)}
                />
            </Box>
        )
    }
}

ClientesList.contextType = MainContext;
ClientesList = withSnackbar(ClientesList);
