import React from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";

export const ConfirmationDialog = ({open, cliente, onConfirm, onCancel}) => (
    <Dialog
        open={open}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{"¿Crear cliente ocasional?"}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Estas por ingresar el cliente ocasional <b>{cliente.razsoc}</b>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onCancel} color="primary">
                Cancelar
            </Button>
            <Button onClick={onConfirm} color="primary" autoFocus>
                Confirmar
            </Button>
        </DialogActions>
    </Dialog>
);
