import Card from "@material-ui/core/Card";
import {ButtonBase, Divider, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import NavIcon from "@material-ui/icons/ArrowForwardIosRounded";
import React from "react";
import CardContent from "@material-ui/core/CardContent";

export function CardPedido(props) {
    const {id, fecha, cliente, onClick} = props;
    return (
        <>
            <Card className={'m-0 p-0'} elevation={0}>
                <ButtonBase style={{width: '100%', display: 'block', textAlign: 'left'}} onClick={onClick}>
                    <CardContent>
                        <Grid container alignItems={"center"}>
                            <Grid item xs={11}>
                                <Typography color="textSecondary" gutterBottom>
                                    Pedido {id}
                                </Typography>
                                <Typography color="textSecondary">
                                    {cliente}
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <NavIcon color={"primary"}/>
                            </Grid>
                        </Grid>
                    </CardContent>
                </ButtonBase>
            </Card>
            <Divider/>
        </>
    );
}
