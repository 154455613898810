import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {PedidosClientes} from 'components/ventas/pedidos/clientes/pedidos.clientes';
import {PedidosList} from 'components/ventas/pedidos/list/pedidos.list';
import {PedidosCreate} from 'components/ventas/pedidos/create/pedidos.create';
import {PedidosView} from "./view/pedidos.view";

const basePath = '/ventas/pedidos';

export class Pedidos extends Component {
    render() {
        return (
            <div className='niquel-pedidos niquel-view-with-bottom-margin'>
                <Switch>
                    <Route path={`${basePath}/:cliente/view/:pedido`} component={PedidosView}/>
                    <Route path={`${basePath}/:cliente/create/ref/:cotizacion`} component={PedidosCreate}/>
                    <Route path={`${basePath}/:cliente/create`} component={PedidosCreate}/>
                    <Route exact path={`${basePath}/:cliente`} component={PedidosList}/>
                    <Route path={`${basePath}`} component={PedidosClientes}/>
                </Switch>
            </div>
        )
    }
}
