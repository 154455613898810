import React, {Component} from 'react'
import {Box} from "@material-ui/core/index";
import {ReactGrid} from 'components/helpers/reactGrid/reactGrid';
import {ClientesService, NotasCreditoService} from "services";
import {withSnackbar} from "notistack";
import {sortDate, sortNumeric} from "assets/utils";
import {MainContext} from "contexts/main.context";
import SubHeader from "components/helpers/subHeader/subHeader";

export class NotasCreditoList extends Component {
    state = {
        cliente: {},
        columns: [
            {name: 'id', title: 'ID'},
            {name: 'factura', title: 'N° Documento'},
            {name: 'ingreso', title: 'Ingreso'},
            {name: 'importe', title: 'Importe'}
        ],
        columnExtensions: [
            {columnName: 'id', compare: sortNumeric, direction: 'desc'},
            {columnName: 'ingreso', compare: sortDate, direction: 'desc'},
            {columnName: 'importe', type: 'currency', compare: sortNumeric}
        ],
        rows: []
    };

    ClientesService = new ClientesService(this.context);
    NotasCreditoService = new NotasCreditoService(this.context);

    componentDidMount() {
        this.ClientesService.getById(
            this.props.match.params.cliente,
            response => this.setState({cliente: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.NotasCreditoService.getByCliente(
            this.props.match.params.cliente,
            response => this.setState({rows: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'}));
    }

    navView = (row) => {
        this.props.history.push(`${this.props.match.url}/view/${row.id}`);
    };

    render() {
        const {cliente, columns, columnExtensions, rows} = this.state;
        return (
            <Box className='niquel-notasCredito-list'>
                <SubHeader
                    title={'Notas de Crédito'}
                    subtitle={'Notas de Crédito ingresadas'}
                    cliente={cliente}
                />
                <Box className='container-xl mt-3 mb-3'>
                    <ReactGrid
                        title={`Notas de Crédito de ${cliente.razsoc} (${rows.length})`}
                        height={-200}
                        columns={columns}
                        columnExtensions={columnExtensions}
                        rows={rows}
                        showFilters={false}
                        rowClick={true}
                        onRowClick={this.navView}
                    />
                </Box>
            </Box>
        )
    }
}

NotasCreditoList.contextType = MainContext;
NotasCreditoList = withSnackbar(NotasCreditoList);
