import React, {useContext, useEffect, useState} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography} from "@material-ui/core";
import {MainContext} from "contexts/main.context";
import {useArticulosService} from "services/hooks";
import {INITIAL_FILTER_SETTINGS_STATE} from "components/helpers/initialFilterSettingsState";
import ImgNotFoundIcon from "@material-ui/icons/PhotoCameraRounded";
import {formatPrice, formatQuantity, InputInteger, sortNumeric} from "assets/utils";
import {SecurityRounded} from "@material-ui/icons";
import {useSnackbar} from "notistack";
import {ArticuloDetailsButton} from "components/helpers/articulos/articuloDetails";
import {ReactGrid} from "components/helpers/reactGrid/reactGrid";

export const ArticuloArmadoDetailsDialog = (props) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.onCancel}
            maxWidth={"md"}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className='articulo-details-dialog'
        >
            <ArticuloArmadoDetailsDialogContent {...props}/>
        </Dialog>
    );
}

const ArticuloArmadoDetailsDialogContent = ({open, articulo, onCancel}) => {
    const columns = [
        {name: 'id', numeric: false, disablePadding: false, title: 'Artículo'},
        {name: 'detalle', numeric: false, disablePadding: false, title: 'Detalle', style: {whiteSpace: 'break-spaces'}},
        {name: 'cantidad', numeric: true, title: 'Cantidad'},
        {name: 'lista1', numeric: true, title: 'Precio'},
        {name: 'custom1', title: ' '}
    ];

    const columnExtensions = [
        {columnName: 'id', width: 170},
        {columnName: 'cantidad', width: 100, align: 'right'},
        {columnName: 'lista1', width: 130, type: 'currency', compare: sortNumeric},
        {columnName: 'custom1', width: 100, component: ArticuloDetailsButton, align: 'center'}
    ];

    return (
        <>
            <DialogTitle id="alert-dialog-title">
                <Typography variant={"body1"}>{articulo.id}</Typography>
                <Typography variant={"body2"} color={"textSecondary"}>{articulo.detalle}</Typography>
            </DialogTitle>
            <DialogContent>
                <ReactGrid
                    height={280}
                    columns={columns}
                    columnExtensions={columnExtensions}
                    rows={articulo.articulos}
                    enableFiltering={false}
                    enableSorting={true}
                    enableSearch={false}
                    enableSelection={false}
                    showSelectAll={false}
                    elevation={0}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={e => {
                        e.stopPropagation();
                        onCancel();
                    }}
                    color="primary"
                >
                    Cerrar
                </Button>
            </DialogActions>
        </>
    )
}
