import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {Cobranzas} from "./cobranzas/cobranzas";
import {MainContext} from "contexts/main.context";

const basePath = '/aprobaciones/recepciones';

export class Recepciones extends Component {
    render() {
        return (
            <>
                <Switch>
                    <Route path={`${basePath}/cobranza`} render={(routeProps) => <Cobranzas key={1} {...routeProps}/>}/>
                    <Route path={`${basePath}/cotizacion`} render={(routeProps) => <Cobranzas key={2} {...routeProps}/>}/>
                </Switch>
            </>
        )
    }
}

Recepciones.contextType = MainContext;
