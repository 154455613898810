import BaseService from './base.service'

export default class VendedoresService extends BaseService {
    getAll(fnSuccess, fnError) {
        return this.getApi().get(`/vendedores`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener los vendedores')));
    }

    getById(id, fnSuccess, fnError) {
        return this.getApi().get(`/vendedores?id=${id}`)
            .then(response => {
                if (response.data.count === 1)
                    response.data.result = response.data.result[0];
                fnSuccess(response);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener el vendedor')));
    }
}
