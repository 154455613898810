import {useHistory, useRouteMatch} from "react-router";
import React, {useEffect, useState} from "react";
import {usePedidosService} from "services/hooks";
import {useSnackbar} from "notistack";
import {Button, Divider} from "@material-ui/core/index";
import {CardArticulo} from "components/picking/ValidarDespachoPedido/card.articulo";
import {CardHeader} from "components/picking/components/card.header";
import {useConfirm} from 'material-ui-confirm';
import Box from "@material-ui/core/Box";
import {ItemActionsDialog} from "components/picking/ValidarDespachoPedido/item.actions.dialog";
import {ScanErrorDialog} from "components/picking/ValidarDespachoPedido/scan.error.dialog";
import {ScanSuccessPopover} from "components/picking/ValidarDespachoPedido/scan.success.popover";
import {ClickAwayListener} from "@material-ui/core";
import {ScanReceptor} from "components/picking/components/scan.receptor";

export function ScanArticulosView() {
    const [articulos, _setArticulos] = useState([]);
    const [scanResult, setScanResult] = useState({errorDialogOpen: false, successPopoverOpen: false, found: false, articulo: undefined});
    const [itemActions, setItemActions] = useState({dialogOpen: false, articulo: undefined});


    const history = useHistory();
    const match = useRouteMatch();
    const {enqueueSnackbar} = useSnackbar();
    const confirm = useConfirm();
    const PedidosService = usePedidosService();

    const articulosRef = React.useRef(articulos);

    const setArticulos = articulos => {
        articulosRef.current = articulos;
        _setArticulos(articulos);
    }

    useEffect(() => {
        PedidosService.getArticulosById(
            match.params.idPedido,
            response => setArticulos(response.data.result),
            error => enqueueSnackbar(error, {variant: 'error'})
        );
    }, []);

    const onScanCode = (scannedCode) => {
        const scannedArticulo = articulosRef.current.find(articulo => articulo.id === scannedCode);

        if (scannedArticulo) {
            scannedArticulo.scanned = scannedArticulo.scanned ? parseInt(scannedArticulo.scanned) + 1 : 1;
            setArticulos(articulosRef.current);
        }

        setScanResult({
            errorDialogOpen: !scannedArticulo,
            successPopoverOpen: !!scannedArticulo,
            found: !!scannedArticulo,
            scannedCode: scannedCode,
            articulo: scannedArticulo
        });
    };

    const navBack = () => history.push(`/picking/validarDespachoPedido/${match.params.idHojaDeRuta}`);

    const onCancelValidation = () => {
        confirm({
            title: '¿Seguro de cancelar?',
            description: 'Si elige CANCELAR el pedido no se validará.',
            confirmationText: 'Si, cancelar',
            cancellationText: 'No, seguir'
        })
            .then(() => {
                navBack();
            })
            .catch(() => {
            });
    }

    const onFinishValidation = () => {
        const notScanned = articulos.filter(articulo => !articulo.scanned || articulo.scanned === 0);
        const notWholeScanned = articulos.filter(articulo => articulo.scanned > 0 && articulo.scanned < articulo.cantidad);
        const tooMuchScanned = articulos.filter(articulo => articulo.scanned > articulo.cantidad);
        const someError = notScanned.length > 0 || notWholeScanned.length > 0 || tooMuchScanned.length > 0;

        confirm({
            dialogProps: {fullScreen: false},
            title: someError ? 'Errores en la validación' : '¿Seguro de terminar?',
            // description: '¿Seguro de terminar la recolección?',
            content: <>
                {notScanned.length > 0 && (<Box>Hay <b>{notScanned.length}</b> artículos sin escanear.</Box>)}
                {notWholeScanned.length > 0 && (<Box>Hay <b>{notWholeScanned.length}</b> artículos con faltantes de escanear.</Box>)}
                {tooMuchScanned.length > 0 && (<Box>Hay <b>{tooMuchScanned.length}</b> artículos con demasiados escaneos.</Box>)}

                {someError ? (
                    <Box className={'mt-3'} style={{color: 'red'}}>Hay errores en la validación y no se puede terminar, revise.</Box>
                ) : (
                    <Box>El pedido se marcará como validado y podrá ser despachado.</Box>
                )}
            </>,
            confirmationText: 'Terminar',
            confirmationButtonProps: {disabled: someError},
            cancellationText: 'Cancelar'
        })
            .then(() => {
                navBack();
            })
            .catch(() => {
            });
    }

    const closeErrorDialog = () => setScanResult(prev => ({...prev, errorDialogOpen: false}));
    const closeSuccessPopover = () => setScanResult(prev => ({...prev, successPopoverOpen: false}));

    const closeItemActionsDialog = () => setItemActions(prev => ({...prev, dialogOpen: false}));

    const onClickArticulo = (articulo) => {
        setItemActions({dialogOpen: true, articulo: articulo});
    };

    const completeScans = articulo => {
        articulo.scanned = parseInt(articulo.cantidad);
        setItemActions(prevItemsActions => ({...prevItemsActions, articulo}));
        setArticulos(articulos);
    }

    const deleteScans = articulo => {
        articulo.scanned = 0;
        setItemActions(prevItemsActions => ({...prevItemsActions, articulo}));
        setArticulos(articulos);
    }

    return (
        <>
            <ScanReceptor onScanCode={onScanCode}/>
            <div style={{height: window.innerHeight - 47, overflowY: 'auto'}}>
                <CardHeader
                    textLeft={<>Hoja: <b>{match.params.idHojaDeRuta}</b></>}
                    textRight={<><b>{articulos.length}</b> artículos</>}
                    textLeftBottom={<>Pedido: <b>{match.params.idPedido}</b></>}
                />
                {articulos.map((articulo, index) =>
                    <>
                        <CardArticulo
                            key={index}
                            id={articulo.id}
                            detalle={articulo.detalle}
                            cantidad={articulo.cantidad}
                            scanned={articulo.scanned}
                            onClick={() => {
                                onClickArticulo(articulo);
                            }}
                        />
                        {index !== articulos.length - 1 && (
                            <Divider/>
                        )}
                    </>
                )}

                <Box>
                    <Button
                        size={"medium"}
                        variant={"contained"}
                        className={'mt-2 mb-2'}
                        // disableElevation
                        color={"primary"}
                        // fullWidth
                        style={{width: '90%', height: '3.5rem', margin: '0 5%'}}
                        onClick={() => {
                            onFinishValidation()
                        }}
                    >
                        Terminar
                    </Button>
                    <Button
                        size={"medium"}
                        variant={"contained"}
                        // disableElevation
                        className={'mt-2 mb-2'}
                        color={"error"}
                        // fullWidth
                        style={{width: '90%', height: '3.5rem', margin: '0 5%'}}
                        onClick={() => {
                            onCancelValidation()
                        }}
                    >
                        Cancelar
                    </Button>
                </Box>
            </div>


            {scanResult.successPopoverOpen && (
                <ClickAwayListener onClickAway={() => closeSuccessPopover()}>
                    <Box>
                        <ScanSuccessPopover
                            open={scanResult.successPopoverOpen}
                            articulo={scanResult.articulo}
                            fullScreen
                            onClick={() => onClickArticulo(scanResult.articulo)}
                            onClose={(e) => {
                                e.stopPropagation();
                                closeSuccessPopover();
                            }}
                            confirmText={'Cerrar'}
                        />
                    </Box>
                </ClickAwayListener>
            )}

            <ScanErrorDialog
                open={scanResult.errorDialogOpen}
                scanResult={scanResult}
                fullScreen
                onConfirm={() => {
                    closeErrorDialog()
                }}
                confirmText={'Cerrar'}
            />

            <ItemActionsDialog
                open={itemActions.dialogOpen}
                articulo={itemActions.articulo}
                fullScreen
                onCompleteScans={() => {
                    closeItemActionsDialog();
                    completeScans(itemActions.articulo);
                }}
                onDeleteScans={() => {
                    closeItemActionsDialog();
                    deleteScans(itemActions.articulo);
                }}
                onCancel={() => closeItemActionsDialog()}
                confirmText={'Cerrar'}
            />
        </>
    )
}
