import React, {useContext, useEffect, useState} from 'react'
import {Box} from "@material-ui/core/index";
import {ReactGrid} from 'components/helpers/reactGrid/reactGrid';
import {useSnackbar} from "notistack";
import './articulos.list.scss';
import SubHeader from "../../helpers/subHeader/subHeader";
import {useArticulosService, useListadosService} from "services/hooks";
import {OptionsMenu} from "components/helpers/options.menu";
import {useHistory} from "react-router-dom";
import {MainContext} from "contexts/main.context";
import {Grid, MenuItem, TextField, Tooltip} from "@material-ui/core";
import {useRouteMatch} from "react-router";
import DoneRounded from "@material-ui/icons/DoneRounded";
import ClearRounded from "@material-ui/icons/ClearRounded";
import {ExportOptions} from "../../helpers/exportOptions/export.options";

const filterOptions = [
    {id: 'todos', description: 'Todos'},
    {id: 'activos', description: 'Activos'},
    {id: 'inactivos', description: 'Inactivos'}
];

export function ArticulosArmadosList() {
    const [articulos, setArticulos] = useState([]);
    const [filteredArticulos, setFilteredArticulos] = useState([]);
    const [filterOption, setFilterOption] = useState('todos');
    const [orderField, setOrderField] = useState('detalle');
    const [orderDirection, setOrderDirection] = useState('asc');

    const ArticulosService = useArticulosService();
    const ListadosService = useListadosService();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const history = useHistory();
    const match = useRouteMatch();

    const context = useContext(MainContext);

    useEffect(() => {loadArticulos()}, []);
    useEffect(() => {filterArticulos()}, [articulos, filterOption]);

    const loadArticulos = () => {
        ArticulosService.getArmados(
            true,
            false,
            response => setArticulos(response.data.result),
            error => enqueueSnackbar(error, {variant: 'error'}));
    }

    const filterArticulos = () => {
        switch (filterOption) {
            case "activos":
                setFilteredArticulos(articulos.filter(articulo => articulo.activo));
                break;
            case "inactivos":
                setFilteredArticulos(articulos.filter(articulo => !articulo.activo));
                break;
            default:
                setFilteredArticulos(articulos);
        }
    };

    const navEdit = (row) => {
        history.push(`${match.url}/edit/${encodeURIComponent(row.id)}`);
    };

    const navView = (row) => {
        history.push(`${match.url}/view/${encodeURIComponent(row.id)}`);
    };

    const navCreate = () => {
        history.push(`${match.url}/create`);
    };

    const handleDelete = (row) => {
        ArticulosService.deleteArmado(
            row.id,
            response => {
                enqueueSnackbar(`Se eliminó correctamente el artículo ${row.id}`, {variant: 'success'});
                loadArticulos();
            },
            error => enqueueSnackbar(error, {variant: 'error'})
        );
    }

    const OptionsMenuWrapper = (props) => {
        const context = useContext(MainContext);
        const {row, rowId} = props;

        return (
            <OptionsMenu
                row={row}
                rowId={rowId}
                onView={navView}
                onEdit={navEdit}
                onDelete={handleDelete}
                context={context}
            />
        )
    };

    const IconActivo = ({row}) => row.activo ?
        <Tooltip title={"Activo"}><DoneRounded color={"primary"}/></Tooltip> :
        <Tooltip title={"Inactivo"}><ClearRounded color={"error"}/></Tooltip>;


    const tableConfig = {
        columns: [
            {name: 'id', title: 'Código'},
            {name: 'detalle', title: 'Descripción', style: {whiteSpace: 'break-spaces'}},
            // {name: 'bonificacion', title: 'Bonif (%)'},
            {name: 'custom1', title: 'Activo'},
            {name: 'custom2', title: ' '},
        ],
        columnExtensions: [
            {columnName: 'id', width: 150},
            // {columnName: 'bonificacion', compare: sortNumeric, align: 'right', width: 120},
            {columnName: 'custom1', component: IconActivo, align: 'center', width: 100},
            {columnName: 'custom2', type: 'icon', component: OptionsMenuWrapper, sortingEnabled: false, width: 70},
        ]
    };

    const getPDF = fnSuccess => {
        ListadosService.stockArticulosArmados(
            filteredArticulos.map(row => row.id),
            orderField,
            orderDirection,
            file => fnSuccess(file),
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    };

    return (
        <>
            <SubHeader
                title={'Artículos Armados'}
            />
            <Box className='container-xl mt-3 mb-8'>
                <Grid container>
                    <Grid item className={'d-flex align-items-end'}>
                        <TextField
                            select
                            label="Filtrar"
                            value={filterOption}
                            onChange={event => setFilterOption(event.target.value)}
                            style={{width: '9rem'}}
                        >
                            {filterOptions.map(option => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.description}
                                </MenuItem>
                            ))}
                        </TextField>
                    </Grid>
                </Grid>
                <Box className='mt-3'>
                    <ReactGrid
                        title={`Artículos Armados (${filteredArticulos.length})`}
                        enableVirtualMode={false}
                        height={-200}
                        columns={tableConfig.columns}
                        columnExtensions={tableConfig.columnExtensions}
                        // getRowId={row => row.id}
                        rows={filteredArticulos}
                        enableSorting={true}
                        enableSelection={false}
                        enableRowDetails={false}
                        rowClick={true}
                        onRowClick={navView}
                        showAddButton
                        onClickAddButton={navCreate}
                        enableFiltering={false}
                    />
                    {["A", "O", "S"].includes(context.loggedUser.role) && (
                        <ExportOptions
                            enableDownload
                            enablePrint
                            enableShow
                            getPDF={getPDF}
                            fileName={"Stock_Articulos_Armados.pdf"}
                        />
                    )}
                </Box>
            </Box>
        </>
    )
}
