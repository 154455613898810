import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {ClientesList} from 'components/ventas/clientes/list/clientes.list';
import {ClientesCreate} from 'components/ventas/clientes/create/clientes.create';

const basePath = '/ventas/clientes';

export class Clientes extends Component {
    render() {
        return (
            <div className='niquel-clientes'>
                <Switch>
                    {/*<Route path={`${basePath}/:cliente/view/:cobranza`} component={CobranzasView}/>*/}
                    <Route path={`${basePath}/create`} component={ClientesCreate}/>
                    {/*<Route path={`${basePath}/:cliente`} component={CobranzasList}/>*/}
                    <Route path={`${basePath}`} component={ClientesList}/>
                </Switch>
            </div>
        )
    }
}