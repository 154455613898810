import React, {Component} from 'react'
import {Box, Tooltip} from "@material-ui/core/index";
import {withSnackbar} from "notistack";
import {MainContext} from "contexts/main.context";
import {Fab, IconButton} from "@material-ui/core";
import {ConfirmDialog} from "./confirm.dialog";
import MomentUtils from "@date-io/moment";
import DoneIcon from "@material-ui/icons/DoneRounded";
import DeleteIcon from "@material-ui/icons/DeleteRounded";
import PDFIcon from "@material-ui/icons/PictureAsPdf";
import {HojasDeRutaOperation} from "components/despacho/hojasDeRuta/operation/hojasDeRuta.operation";
import {sortDate, sortNumeric} from "assets/utils";
import PedidosService from "services/pedidos.service";
import {HojasDeRutaService} from "services";
import MastersService from "services/masters.service";
import SpeedDial from "@material-ui/lab/SpeedDial/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction/SpeedDialAction";
import {MailDialog} from "components/helpers/dialogs/mail.dialog";
import {PDFDialog} from "components/helpers/dialogs/pdf.dialog";
import printJS from "print-js";
import EmailIcon from "@material-ui/icons/EmailRounded";
import PrintIcon from "@material-ui/icons/PrintRounded";
import DownloadIcon from "@material-ui/icons/GetAppRounded";
import ShowIcon from "@material-ui/icons/DesktopWindowsRounded";
import {SimpleDialog as DeleteDialog} from "components/helpers/dialogs/simple.dialog";
import {PedidosMapDialog} from "components/despacho/hojasDeRuta/create/pedidos.map.dialog";
import {EditDispatchedQuantityButton, InfoButton} from "components/despacho/hojasDeRuta/operation/pedido.details";
import {ReceiptRounded} from "@material-ui/icons";

export class HojasDeRutaCreate extends Component {
    HojasDeRutaService = new HojasDeRutaService(this.context);
    PedidosService = new PedidosService(this.context);
    MastersService = new MastersService(this.context);

    onDownloadRecibo = (idpedido) => {
        this.HojasDeRutaService.getRecibo('idpedido', idpedido,
            (file, filename) => {
                const fileURL = URL.createObjectURL(file);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = fileURL;
                a.download = filename;
                a.click();
                window.URL.revokeObjectURL(fileURL);
                this.props.enqueueSnackbar('El remito se descargó correctamente. Revise su carpeta de descargas.', {variant: 'success'});
            },
            error => this.props.enqueueSnackbar(error, {variant: 'success'})
        )
    }

    DeleteBtn = (props) => {
        const {row} = props;
        return (
            <Tooltip title={'Quitar Pedido'}>
                <IconButton
                    onClick={() => this.onRemovePedido(row)}
                >
                    <DeleteIcon fontSize={"small"}/>
                </IconButton>
            </Tooltip>
        )
    }

    PedidoReciboBtn = (props) => {
        return (
            <>
                <Tooltip title={'Descargar Remito'}>
                    <span>
                        <IconButton disabled={this.state.recibos.indexOf(props.row.id) === -1} onClick={(e) => {
                            e.stopPropagation();
                            this.onDownloadRecibo(props.row.id)
                        }}>
                            <ReceiptRounded fontSize={"small"}/>
                        </IconButton>
                    </span>
                </Tooltip>
            </>
        )
    }

    state = {
        operation: 'CREATE',
        mainTable: {
            columns: [
                {name: 'cliente', title: 'Cliente'},
                {name: 'id', title: 'ID'},
                {name: 'ingreso', title: 'Ingreso'},
                {name: 'direccion_entrega', title: 'Dirección de Entrega'},
                {name: 'litros', title: 'Litros'},
                {name: 'custom1', title: ' '},
                {name: 'custom2', title: ' '},
                {name: 'custom3', title: ' '},
                {name: 'custom4', title: ' '},
            ],
            columnExtensions: [
                {columnName: 'cliente', width: 0},
                {columnName: 'id', width: 100, compare: sortNumeric, direction: 'desc'},
                {columnName: 'ingreso', width: 150, compare: sortDate},
                {columnName: 'importe', width: 180, compare: sortNumeric, type: 'currency'},
                {columnName: 'litros', width: 100, compare: sortNumeric},
                {columnName: 'custom1', width: 60, component: InfoButton, sortingEnabled: false},
                {columnName: 'custom2', width: 60, type: 'icon', component: this.PedidoReciboBtn, sortingEnabled: false},
                {columnName: 'custom3', width: 60, component: EditDispatchedQuantityButton, sortingEnabled: false},
                {columnName: 'custom4', type: 'icon', component: this.DeleteBtn, sortingEnabled: false, width: 70},
            ],
            groupingExtensions: [
                {columnName: 'cliente'},
                // {columnName: 'direccion_entrega'},
            ],
            totalSummaryItemsExtensions: [
                // {columnName: 'id', type: 'count'},
                // {columnName: 'litros', type: 'sum'},
            ],
            groupSummaryItemsExtensions: [
                {columnName: 'litros', type: 'count', showInGroupFooter: false, alignByColumn: true},
                {columnName: 'litros', type: 'sum', showInGroupFooter: false, alignByColumn: true}
            ],
            summaryMessages: {count: 'Cantidad', sum: 'Litros'},
            groupMessages: {count: 'Pedidos', sum: 'Litros'},
            data: [],
            selected: [],
            all: true
        },
        headerData: {
            fecha: (new MomentUtils()).date(new Date()),
            zona: '3',
            vehiculo: '1',
            chofer: 'walterc',
            salida: (new MomentUtils()).moment('10:00', 'HH:mm'),
            llegada: '',
            tipo: 'ENV',
        },
        id: this.props.match.params.hojaDeRuta,
        hojasDeRuta: [],
        _pedidos: [],
        pedidos: [],
        zonas: [],
        vehiculos: [],
        choferes: [],
        tipos: [{
            id: 'ENV',
            description: 'Envasados'
        }, {
            id: 'GRAURE',
            description: 'Granel - Urea'
        }, {
            id: 'GRALUB',
            description: 'Granel - Lubricantes'
        }, {
            id: 'GRAREF',
            description: 'Granel - Refrigerantes'
        }],
        dialogs: {
            confirm: false,
            addPedidos: false,
            mail: false,
            pdf: false,
            delete: false,
            map: false
        },
        pdfBlob: null,
        pdfUrl: '',
        dialOpen: false,
        recibos: [],
        debounce: false
    };

    componentDidMount() {
        // Test
        const ZONAS = [
            {
                id: '1',
                description: 'CABA'
            }, {
                id: '2',
                description: 'OESTE'
            }, {
                id: '3',
                description: 'SUR'
            }, {
                id: '4',
                description: 'NORTE'
            }
        ];

        this.setState({zonas: ZONAS});

        let operation = this.getOperation();

        // Si estoy viendo o editando cargo la información de la hoja de ruta correspondiente.
        if ((operation === 'VIEW' || operation === 'EDIT') && this.props.match.params.hojaDeRuta) {
            this.setState({operation: operation, id: this.props.match.params.hojaDeRuta});
            this.loadHojaDeRuta(this.props.match.params.hojaDeRuta);
        }

        // Si estoy viendo no puedo borrar elementos ni editar las cantidades a despachar.
        if (operation === 'VIEW')
            this.setState(prevState => {
                prevState.mainTable.columns.pop();
                prevState.mainTable.columns.pop();
                prevState.mainTable.columnExtensions.pop();
                prevState.mainTable.columnExtensions.pop();
                prevState.mainTable.columns = [...prevState.mainTable.columns]
                prevState.mainTable.columnExtensions = [...prevState.mainTable.columnExtensions]
                return prevState;
            })

        this.MastersService.getVehiculos(
            response => this.setState({vehiculos: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.MastersService.getChoferes(
            response => this.setState({choferes: response.data.result}, () => {
                if (operation === 'CREATE')
                    this.setState(prevState => {
                        let chofer = prevState.choferes[0];
                        prevState.headerData.chofer = chofer !== undefined ? chofer.id : '';
                        return prevState;
                    })
            }),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.HojasDeRutaService.getRecibos(this.props.match.params.hojaDeRuta,
            success => this.setState({recibos: success.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        )

        this.HojasDeRutaService.getAll(
            success => this.setState({hojasDeRuta: success.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        )

        if (operation !== 'VIEW')
            this.fetchPedidos().then(this.filterPedidos);
    }

    getOperation = () => {
        const path = this.props.location.pathname;
        let operation = 'CREATE';

        if (path.indexOf('edit') !== -1)
            operation = 'EDIT';
        else if (path.indexOf('view') !== -1)
            operation = 'VIEW';

        return operation;
    }

    // Carga una hoja de ruta por id para visualizarla.
    loadHojaDeRuta = (id) => {
        this.HojasDeRutaService.getById(id,
            success => {
                const hojaDeRuta = success.data.result;
                this.setState(prevState => {
                    prevState.headerData = {...hojaDeRuta}
                    prevState.headerData.fecha = (new MomentUtils()).moment(hojaDeRuta.fecha, 'D/MM/Y');
                    prevState.headerData._original_fecha = (new MomentUtils()).moment(hojaDeRuta.fecha, 'D/MM/Y');
                    prevState.headerData.salida = (new MomentUtils()).moment(hojaDeRuta.salida, 'HH:mm');
                    return prevState;
                });
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.HojasDeRutaService.getPedidosById(id,
            success => {
                let pedidos = success.data.result;
                pedidos = pedidos.filter(pedido => pedido.cerrado !== true);
                pedidos.forEach(pedido => pedido['ORIGINAL'] = true);
                this.setState(prevState => prevState.mainTable.data = pedidos);
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    onChangeDocument = id => this.setState({id, pdfBlob: null}, () => this.loadHojaDeRuta(id));

    // Cargo pedidos pendientes
    fetchPedidos = () => new Promise((resolve, reject) => {
        this.PedidosService.getPending(
            this.props.zona,
            response => {
                this.setState({_pedidos: response.data.result}, () => resolve(this.state._pedidos))
            },
            error => {
                this.props.enqueueSnackbar(error, {variant: 'error'})
                reject();
            }
        );
    });

    filterPedidos = () => {
        let tipo = this.state.headerData.tipo;

        let _pedidos = this.state._pedidos.filter(pedido => pedido.tipo === tipo && !pedido.cerrado);
        this.setState({pedidos: _pedidos});

        if (_pedidos.length === 0) {
            const _tipo = this.state.tipos.find(_tipo => _tipo.id === tipo);
            this.props.enqueueSnackbar(`No hay pedidos pendientes de tipo ${_tipo.description}`);
        }
    };

    openDialog = (dialog) => this.setState(prevState => prevState.dialogs[dialog] = true);
    closeDialog = (dialog) => this.setState(prevState => prevState.dialogs[dialog] = false);

    onChangeHeaderData = (field, value) => {
        this.setState(prevState => {
            prevState.headerData[field] = value;
            return prevState;
        }, () => {
            if (field === 'tipo') {
                const tipos = this.state.mainTable.data.map(pedido => pedido.tipo);
                if (tipos.length > 0 && tipos.indexOf(value) === -1)
                    this.props.enqueueSnackbar(`Hay pedidos de otro tipo agregados a la hoja de ruta.`, {variant: 'warning'});
                this.filterPedidos();
            }
        });
    };

    onConfirmAddPedidosDialog = pedidos => {
        let MAX = 35;
        if (this.state.mainTable.data.length + pedidos.length > MAX) {
            let accepted = MAX - this.state.mainTable.data.length;
            this.props.enqueueSnackbar(`Se supera el límite máximo de pedidos permitidos en una hoja de ruta (${MAX}). Puede agregar hasta ${accepted} pedidos nuevos.`, {variant: 'error'});
            return false;
        }

        this.setState(prevState => prevState.mainTable.data = prevState.mainTable.data.concat(pedidos))

        return true;
    };
    onSelectPedidos = (selected) => this.setState(prevState => (prevState['mainTable'].selected = selected));
    onAddPedido = pedido => {
        let MAX = 35;
        if (this.state.mainTable.data.length >= MAX) {
            this.props.enqueueSnackbar(`La hoja de ruta ya contiene el máximo de pedidos permitidos (${MAX}). Para agregar el pedido elimine alguno o genere una nueva hoja de ruta.`, {variant: 'error'});
            return;
        }
        this.setState(prevState => prevState.mainTable.data = prevState.mainTable.data.concat([pedido]))
    };
    onRemovePedido = pedido => this.setState(prevState => {
        if (this.state.operation === 'EDIT' && pedido.estado === true) {
            this.props.enqueueSnackbar("Este pedido ya fue facturado y no puede ser eliminado de la hoja de ruta.", {variant: 'error'})
            return false;
        }

        // Fix para solucionar bug al quitar pedidos en edición de cobranza.
        if (!!pedido.ORIGINAL)
            prevState['_pedidos'].push(pedido);

        prevState['mainTable'].data = prevState['mainTable'].data.filter(_pedido => _pedido.id !== pedido.id);
        return prevState;
    }, this.filterPedidos);

    getVehiculo = idvehiculo => this.state.vehiculos.find(vehiculo => vehiculo.id === idvehiculo);
    getChofer = idchofer => this.state.choferes.find(chofer => chofer.id === idchofer);

    onConfirm = () => {
        if (this.state.debounce) return;

        this.setState({debounce: true}, () => {
            const data = {
                headerData: {...this.state.headerData},
                pedidos: this.state.mainTable.data
            };
            // Agrego el total de litros
            data.headerData.litros = data.pedidos.reduce((litros, pedido) => litros += pedido.litros, 0);
            data.headerData.fecha = data.headerData.fecha.format('DD/MM/Y');
            data.headerData.salida = data.headerData.salida.format('HH:mm');

            if (this.state.operation === 'CREATE')
                this.onCreate(data);
            else
                this.onEdit(this.props.match.params.hojaDeRuta, data);

            setTimeout(() => this.setState({debounce: false}), 500);
        });
    }

    onEdit = (id, data) => {
        this.HojasDeRutaService.edit(id, data,
            response => {
                this.props.enqueueSnackbar(`Se editó correctamente la hoja de ruta número ${id}`, {variant: 'success'});
                this.props.history.replace('/despacho/hojasDeRuta');
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    onCreate = (data) => {
        this.HojasDeRutaService.create(data,
            response => {
                this.props.enqueueSnackbar(`Se generó correctamente la hoja de ruta número ${response.data.id}`, {variant: 'success'});
                this.props.history.replace('/despacho/hojasDeRuta');
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    onDelete = () => {
        this.HojasDeRutaService.deleteById(
            this.props.match.params.hojaDeRuta,
            response => {
                this.props.enqueueSnackbar(`Se eliminó correctamente la hoja de ruta número ${this.props.match.params.hojaDeRuta}`, {variant: 'success'});
                this.props.history.replace('/despacho/hojasDeRuta');
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    onDialOpen = () => this.setState({dialOpen: true});
    onDialClose = () => this.setState({dialOpen: false});

    getPDF = (fnSuccess) => {
        this.HojasDeRutaService.getPDF(
            this.props.match.params.hojaDeRuta,
            file => this.setState({pdfBlob: file}, fnSuccess),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    onShowPDF = () => {
        this.getPDF(
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                this.setState({pdfUrl: fileURL}, () => this.openDialog('pdf'));
            }
        );
    };

    onPrintPDF = () => {
        this.getPDF(
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                printJS(fileURL);
            }
        );
    };

    onDownloadPdf = () => {
        this.getPDF(
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = fileURL;
                a.download = `Hoja de Ruta Nro ${this.props.match.params.hojaDeRuta}`;
                a.click();
                window.URL.revokeObjectURL(fileURL);
                this.props.enqueueSnackbar('El archivo se descargó correctamente. Revise su carpeta de descargas.', {variant: 'success'});
            }
        );
    };

    dialActions = [
        {icon: <EmailIcon/>, name: 'Enviar por Email', action: () => this.openDialog('mail')},
        {icon: <PrintIcon/>, name: 'Imprimir', action: this.onPrintPDF},
        {icon: <DownloadIcon/>, name: 'Descargar', action: this.onDownloadPdf},
        {icon: <ShowIcon/>, name: 'Ver en Pantalla', action: this.onShowPDF},
    ];

    sendMail = mailData => {
        let sendMail = () => {
            // Paso los parámetros a un formData para poder enviar el blob (attachment)
            var formData = new FormData();
            formData.append('attachment', this.state.pdfBlob);
            formData.append('data', JSON.stringify(mailData));

            this.EmailService.send(
                formData,
                response => {
                    this.closeDialog('mail')
                    this.props.enqueueSnackbar('El email se envió correctamente', {variant: 'success'});
                },
                error => this.props.enqueueSnackbar(error, {variant: 'error'})
            );
        };

        if (mailData.attachFile)
            this.getPDF(() => sendMail());
        else
            sendMail();
    };

    render() {
        const {operation, mainTable, headerData, id, hojasDeRuta, pedidos, zonas, vehiculos, choferes, tipos, dialogs, dialOpen, pdfUrl} = this.state;

        return (
            <Box className='niquel-hojasDeRuta-create'>
                <HojasDeRutaOperation
                    operation={operation}
                    fetchPedidos={this.fetchPedidos}
                    mainTable={mainTable}
                    headerData={headerData}
                    id={id}
                    hojasDeRuta={hojasDeRuta}
                    pedidos={pedidos}
                    zonas={zonas}
                    vehiculos={vehiculos}
                    choferes={choferes}
                    tipos={tipos}
                    onChangeHeaderData={this.onChangeHeaderData}
                    onSelectPedidos={this.onSelectPedidos}
                    addPedidosDialogOpen={dialogs.addPedidos}
                    onOpenAddPedidosDialog={() => this.openDialog('addPedidos')}
                    onConfirmAddPedidosDialog={pedidos => this.onConfirmAddPedidosDialog(pedidos)}
                    onCloseAddPedidosDialog={() => this.closeDialog('addPedidos')}
                    onDeleteHojaRuta={() => this.openDialog('delete')}
                    onShowMap={() => this.openDialog('map')}
                    onChangeDocument={this.onChangeDocument}
                />

                {operation !== 'VIEW' && (
                    <Tooltip title={operation === 'CREATE' ? 'Generar Hoja de Ruta' : 'Editar Hoja de Ruta'}>
                        <Box className='niquel-fab-btn'>
                            <Fab color="primary" aria-label="Add" disabled={mainTable.data.length <= 0} onClick={() => this.openDialog('confirm')}>
                                <DoneIcon/>
                            </Fab>
                        </Box>
                    </Tooltip>
                )}

                {operation === 'VIEW' && (
                    <Box className='niquel-fab-btn'>
                        <SpeedDial
                            ariaLabel="documentos_impagos"
                            icon={<PDFIcon/>}
                            onBlur={this.onDialClose}
                            onClick={this.onDialOpen}
                            onClose={this.onDialClose}
                            onFocus={this.onDialOpen}
                            onMouseEnter={this.onDialOpen}
                            onMouseLeave={this.onDialClose}
                            open={dialOpen}
                        >
                            {this.dialActions.map(action => (
                                <SpeedDialAction
                                    key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        action.action()
                                    }}
                                />
                            ))}
                        </SpeedDial>
                    </Box>
                )}

                <ConfirmDialog
                    open={dialogs.confirm}
                    operation={operation}
                    vehiculo={this.getVehiculo(headerData.vehiculo)}
                    chofer={this.getChofer(headerData.chofer)}
                    pedidos={mainTable.data}
                    onConfirm={() => {
                        this.closeDialog('confirmation');
                        this.onConfirm();
                    }}
                    onCancel={() => this.closeDialog('confirm')}
                />

                <DeleteDialog
                    open={dialogs.delete}
                    title={'¿Seguro quiere eliminar la hoja de ruta?'}
                    body={<span>Estás por eliminar la hoja de ruta número <b>{this.props.match.params.hojaDeRuta}</b></span>}
                    onConfirm={this.onDelete}
                    onCancel={() => this.closeDialog('delete')}
                />

                <MailDialog
                    open={dialogs.mail}
                    onConfirm={this.sendMail}
                    onCancel={() => this.closeDialog('mail')}
                    fileName={`Hoja_de_Ruta.pdf`}
                    attachFile={true}
                    // to={cliente.email}
                    subject={`Hoja de Ruta`}
                    body={`Estimado,  \n\nSe adjunta la hoja de ruta.`}
                />

                <PDFDialog
                    open={dialogs.pdf}
                    onClose={() => this.closeDialog('pdf')}
                    pdfUrl={pdfUrl}
                    pdfName={`Hoja_de_Ruta.pdf`}
                />

                <PedidosMapDialog
                    open={dialogs.map}
                    fullScreen
                    operation={operation}
                    pedidos_pendientes={pedidos}
                    pedidos_agregados={mainTable.data}
                    onAddPedido={pedido => this.onAddPedido(pedido)}
                    onRemovePedido={pedido => this.onRemovePedido(pedido)}
                    onCancel={() => this.closeDialog('map')}
                />
            </Box>
        )
    }
}

HojasDeRutaCreate.contextType = MainContext;
HojasDeRutaCreate = withSnackbar(HojasDeRutaCreate);
