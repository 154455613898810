import React from "react";
import {Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemSecondaryAction, ListItemText, Typography} from "@material-ui/core";

export class DireccionEntregaDialog extends React.Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onCancel}
                fullWidth
                maxWidth={'sm'}
            >
                <DireccionEntregaDialogContent {...this.props} />
            </Dialog>
        )
    }
}

class DireccionEntregaDialogContent extends React.Component {
    state = {
        selected: this.props.selected
    };

    componentDidMount() {
        this.setState({
            selected: this.props.selected,
        });
    }

    selectDireccion = direccion => this.props.editable && this.setState({selected: direccion});

    render() {
        const {onConfirm, onCancel, direccionesEntrega} = this.props;
        const {selected} = this.state;

        return (
            <React.Fragment>
                <DialogTitle id="alert-dialog-title">Dirección de entrega</DialogTitle>
                <DialogContent>
                    {direccionesEntrega.length > 0 && (
                        <List dense>
                            {direccionesEntrega.map((direccion, index) => {
                                return (
                                    <ListItem
                                        key={index}
                                        button onClick={() => this.selectDireccion(direccion)}
                                        disabled={!this.props.editable}
                                    >
                                        <ListItemText
                                            primary={`${direccion.id.padStart(5, 0)} - ${direccion.direccion}`}
                                            secondary={
                                                <React.Fragment>
                                                    <Typography variant={"body2"}>
                                                        {direccion.localidad}, {direccion.provincia}, Cod.Postal: {direccion.codigopostal}
                                                    </Typography>
                                                    {direccion.observacion !== '' && (
                                                        <Typography variant={"body2"}>
                                                            Observación: {direccion.observacion}
                                                        </Typography>
                                                    )}
                                                </React.Fragment>
                                            }
                                        />
                                        <ListItemSecondaryAction>
                                            <Checkbox
                                                edge="end"
                                                onClick={() => this.selectDireccion(direccion)}
                                                checked={direccion.id === selected.id}
                                                disabled={!this.props.editable}
                                            />
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            })}
                        </List>
                    )}
                    {direccionesEntrega.length < 1 && (
                        <Typography variant={"body2"} align={"center"} color={"textSecondary"} className='mb-2'>
                            No se encontraron direcciones de entrega disponibles
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="primary">
                        Cancelar
                    </Button>
                    <Button disabled={!this.props.editable || Object.keys(selected).length === 0} onClick={() => onConfirm(selected)} color="primary" autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </React.Fragment>
        )
    }
}
