import React, {useReducer, useRef, useState} from 'react'
import {Box, Button, Fab} from "@material-ui/core";
import SubHeader from "components/helpers/subHeader/subHeader";
import Grid from "@material-ui/core/Grid";
import {SettingsRounded} from "@material-ui/icons";
import 'components/estadisticas/estadisticas.scss';
import VentasSettings from "components/estadisticas/settings/ventas.settings";
import {generalReducer} from "components/helpers/reducers/reducers";
import {INITIAL_FILTER_SETTINGS_STATE} from "components/helpers/initialFilterSettingsState";
import {VentasMensualesCard} from "./cards/ventas.mensuales.card";
import {RankingVendedoresCard} from "./cards/ranking.vendedores.card";
import {RankingClientesCard} from "./cards/ranking.clientes.card";
import {RankingArticulosCard} from "./cards/ranking.articulos.card";
import {CompradoresMensualesCard} from "./cards/compradores.mensuales.card";
import {VentasMensualesPorCalidadCard} from "./cards/ventas.mensuales.calidad.card";
import {toJpeg} from "html-to-image";
import {jsPDF} from "jspdf";
import DownloadIcon from "@material-ui/icons/GetAppRounded";
import Tooltip from "@material-ui/core/Tooltip";

export function EstadisticasVentas(props) {
    const [_settingsState, dispatch] = useReducer(generalReducer, INITIAL_FILTER_SETTINGS_STATE);
    const [settingsState, setSettingsState] = useState(INITIAL_FILTER_SETTINGS_STATE);
    const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);

    const ventasMensualesPrintRef = useRef(null);
    const compradoresMensualesPrintRef = useRef(null);
    const ventasMensualesPorCalidadPrintRef = useRef(null);
    const rankingVendedoresPrintRef = useRef(null);
    const rankingClientesPrintRef = useRef(null);
    const rankingArticulosPrintRef = useRef(null);

    const onConfirmSettingsDialog = () => {
        setSettingsState(_settingsState);
        setSettingsDialogOpen(false);
    }
    const onCancelSettingsDialog = () => {
        dispatch({payload: settingsState, type: 'fullstatechange'});
        setSettingsDialogOpen(false);
    }

    const handleExport = async () => {
        const images = await Promise.all([
            toJpeg(ventasMensualesPrintRef.current),
            toJpeg(compradoresMensualesPrintRef.current),
            toJpeg(ventasMensualesPorCalidadPrintRef.current),
            toJpeg(rankingVendedoresPrintRef.current)
        ]);

        const pdf = new jsPDF();
        const dateFrom = settingsState.dates["from"].format("DD/MM/YYYY");
        const dateTo = settingsState.dates["to"].format("DD/MM/YYYY");

        pdf.setFontSize(9);
        pdf.text(`periodo ${dateFrom} - ${dateTo}`, 155, 10);

        let y = 15;
        for (let image of images) {
            if (y > 220) {
                pdf.addPage();
                y = 0;
            }

            const imgProperties = pdf.getImageProperties(image);
            const width = pdf.internal.pageSize.getWidth();
            const height = (imgProperties.height * width) / imgProperties.width;
            pdf.addImage(image, 'JPEG', 0, y, width, height);
            //y += pdf.getImageProperties(image).height;
            y += 10 + height;
        }

        pdf.save(`Estadisticas.pdf`)
    };

    return (
        <>
            <Box className='niquel-estadisticas-main'>
                <SubHeader
                    title={'Ventas'}
                    subtitle={'Estadísticas de ventas'}
                />
                <Box className='container mt-3 mb-3'>
                    <Grid justifyContent={"center"}>
                        <Tooltip title={"Exportar Todo"}>
                            <Button color="inherit" onClick={handleExport}>
                                Exportar todo
                                <DownloadIcon style={{color: "darkslategray"}}/>
                            </Button>
                        </Tooltip>
                    </Grid>
                    <Box className={'mt-3'}>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <VentasMensualesCard settingsState={settingsState} printRef={ventasMensualesPrintRef}/>
                            </Grid>
                            <Grid item xs={12}>
                                <CompradoresMensualesCard settingsState={settingsState} printRef={compradoresMensualesPrintRef}/>
                            </Grid>
                            <Grid item xs={12}>
                                <VentasMensualesPorCalidadCard settingsState={settingsState} printRef={ventasMensualesPorCalidadPrintRef}/>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <RankingVendedoresCard settingsState={settingsState} printRef={rankingVendedoresPrintRef}/>
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <RankingClientesCard settingsState={settingsState} printRef={rankingClientesPrintRef}/>
                            </Grid>
                            <Grid item xs={12} lg={12}>
                                <RankingArticulosCard settingsState={settingsState} printRef={rankingArticulosPrintRef}/>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className={'niquel-fab-btn'}>
                        <Fab onClick={() => setSettingsDialogOpen(true)} color={"primary"}>
                            <SettingsRounded/>
                        </Fab>
                    </Box>
                </Box>
            </Box>
            <VentasSettings
                open={settingsDialogOpen}
                title={'Filtros y confirguración'}
                onCancel={onCancelSettingsDialog}
                onConfirm={onConfirmSettingsDialog}
                fullScreen={true}
                maxWidth={'lg'}
                state={_settingsState}
                dispatch={dispatch}
            />
        </>
    )
}
