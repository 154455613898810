import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@material-ui/core";
import {HojasDeRutaService} from "services";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import {GetAppRounded, PhotoRounded, ReceiptRounded} from "@material-ui/icons";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import {MainContext} from "contexts/main.context";
import {withSnackbar} from "notistack";

export class ViewRecibosDialog extends Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onCancel}
                fullScreen={this.props.fullScreen}
                fullWidth
                maxWidth={"xs"}
                classes={{
                    paperFullScreen: 'niquel-dialog-full-screen'
                }}
            >
                <ViewRecibosDialogContent {...this.props}/>
            </Dialog>
        )
    }
}

class ViewRecibosDialogContent extends Component {
    state = {
        recibos: [],
        pedidos: []
    }

    HojasDeRutaService = new HojasDeRutaService(this.context);

    componentDidMount() {
        this.HojasDeRutaService.getPedidosById(this.props.hoja.id,
            success => this.setState({pedidos: success.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'success'}));

        this.HojasDeRutaService.getRecibos(this.props.hoja.id,
            success => this.setState({recibos: success.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'success'})
        )
    }

    onDownloadRecibo = (idpedido) => {
        this.HojasDeRutaService.getRecibo('idpedido', idpedido,
            (file, filename) => {
                const fileURL = URL.createObjectURL(file);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = fileURL;
                a.download = filename;
                a.click();
                window.URL.revokeObjectURL(fileURL);
                this.props.enqueueSnackbar('El remito se descargó correctamente. Revise su carpeta de descargas.', {variant: 'success'});
            },
            error => this.props.enqueueSnackbar(error, {variant: 'success'})
        )
    }

    render() {
        const {title, hoja, onConfirm, onCancel} = this.props;
        const {recibos, pedidos} = this.state;

        return (
            <React.Fragment>
                <DialogTitle id="alert-dialog-title">
                    {title}
                    <Typography variant={"body1"} color={"textSecondary"}>de hoja de ruta {hoja.id} ({recibos.length} remitos disponibles)</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" component={'div'}>
                        <List>
                            {pedidos.map((pedido, index) => {
                                    return (
                                        <ListItem key={index}>
                                            <ListItemAvatar>
                                                <Avatar>
                                                    <ReceiptRounded/>
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={`Pedido número ${pedido.id}`}
                                                secondary={pedido.cliente}
                                            />
                                            <ListItemSecondaryAction>
                                                <IconButton edge="end" disabled={recibos.indexOf(pedido.id) === -1} onClick={() => this.onDownloadRecibo(pedido.id)}>
                                                    <GetAppRounded/>
                                                </IconButton>
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    )
                                }
                            )}
                        </List>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    {/*Muestro Cancel*/}
                    {onCancel && (
                        <Button onClick={onCancel} color="primary">
                            Cancelar
                        </Button>
                    )}

                    {/*Muestro Confirm*/}
                    {onConfirm && (
                        <Button onClick={onConfirm} className={'color-error hover-error'} autoFocus>
                            Confirmar
                        </Button>
                    )}
                </DialogActions>
            </React.Fragment>
        )
    }
}

ViewRecibosDialogContent.contextType = MainContext;
ViewRecibosDialogContent = withSnackbar(ViewRecibosDialogContent);
