import {ReactGrid} from "components/helpers/reactGrid/reactGrid";
import React, {useEffect, useRef, useState} from "react";
import PropTypes from 'prop-types';
import {useSnackbar} from "notistack";

export const TableSelect = React.memo((props) => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [data, setData] = useState([]);

    useEffect(() => {
        if (props.all === false)
            props.getData(
                success => setData(success.data.result),
                error => enqueueSnackbar(error, {variant: 'error'})
            )

    }, [props.all]);

    return (
        <ReactGrid
            columns={props.columns}
            columnExtensions={props.columnExtensions}
            getRowId={props.getRowId}
            rows={data}
            title={props.title}
            height={props.height}
            enableFiltering={false}
            enableSorting={true}
            enableSearch={true}
            enableSelection={true}
            selectedRows={props.selected}
            onSelectionChange={selected => props.onChangeSelected(selected)}
            onDelete={selected => props.onChangeSelected([])}
            showSelectAll={props.showSelectAll}
            showSwitchAll={props.showSwitchAll}
            switchAllValue={props.all}
            onChangeSwitchAll={() => props.onChangeAll(!props.all)}
        />
    )
})

TableSelect.defaultProps = {
    title: 'TableSelect',
    height: 350,
    getData: () => {
    },
    columns: [],
    columnExtensions: [],
    onChangeAll: () => {
    },
    onChangeSelected: () => {
    }
}

TableSelect.propsTypes = {
    title: PropTypes.string,
    height: PropTypes.number,
    getData: PropTypes.func.isRequired,
    columns: PropTypes.array.isRequired,
    columnExtensions: PropTypes.array,
    onChangeAll: PropTypes.func,
    onChangeSelected: PropTypes.func,
}
