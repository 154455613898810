import React, {useEffect, useState} from 'react'
import {Box} from "@material-ui/core/index";
import 'moment/locale/es';
import {ReactGrid} from "components/helpers/reactGrid/reactGrid";
import {CobranzaStatusFormatter, sortDate, sortNumeric} from "assets/utils";
import {Fab, IconButton, Tooltip, Typography} from "@material-ui/core";
import {useSnackbar} from "notistack";
import {useCobranzasService} from "../../../../services/hooks";
import {SimpleDialog} from "../../../helpers/dialogs/simple.dialog";
import {AdditionalData} from "../../../helpers/cobranzas/additional.data.card";
import InfoIcon from "@material-ui/icons/InfoRounded";
import {CobranzasView} from "../../../ventas/cobranzas/view/cobranzas.view";
import qrIcon from "assets/img/qr.svg";
import {AddCobranzasDialog} from "./dialogs/add.cobranza.dialog";
import DeleteIcon from "@material-ui/icons/DeleteRounded";
import DoneIcon from "@material-ui/icons/Done";
import {ScanReceptor} from "../../../picking/components/scan.receptor";

export const Manual = () => {
    const [cobranzas, setCobranzas] = useState([]);
    const [selected, setSelected] = useState([]);
    const [comprobantesDialog, setComprobantesDialog] = useState({open: false});
    const [infoDialog, setInfoDialog] = useState({open: false});
    const [addCobranzasDialog, setAddCobranzasDialog] = useState({open: false});
    const [confirmDialog, setConfirmDialog] = useState({open: false});

    const cobranzasRef = React.useRef(cobranzas);

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const CobranzasService = useCobranzasService();

    useEffect(() => {
        cobranzasRef.current = cobranzas;
    }, [cobranzas]);

    const InfoBtn = ({row}) =>
        <Tooltip title={'Detalles de la cobranza'}>
            <IconButton onClick={() => setInfoDialog({open: true, ...row})}>
                <InfoIcon fontSize={"small"}/>
            </IconButton>
        </Tooltip>

    const DeleteBtn = (props) => {
        const {row} = props;
        return (
            <Tooltip title={'Quitar Pedido'}>
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation();
                        onRemovePedido(row, cobranzas)
                    }}>
                    <DeleteIcon fontSize={"small"}/>
                </IconButton>
            </Tooltip>
        )
    }

    const onRemovePedido = (cobranza, cobranzas) => {
        setCobranzas(cobranzasRef.current.filter(_cobranza => _cobranza.id !== cobranza.id))
    };

    const COBRANZAS_TABLE = {
        columns: [
            {name: 'custom1', title: ' '},
            {name: 'id', title: 'ID'},
            {name: 'numrec', title: 'Recibo'},
            {name: 'fecharecibo', title: 'Fecha'},
            {name: 'razsoc', title: 'Cliente'},
            {name: 'vendedor', title: 'Vendedor'},
            {name: 'custom2', title: 'Estado'},
            {name: 'custom3', title: ' '}
        ],
        columnExtensions: [
            {columnName: 'custom1', width: 50, component: InfoBtn, align: 'center'},
            {columnName: 'id', width: 70, compare: sortNumeric, direction: 'desc'},
            {columnName: 'numrec', width: 90, compare: sortNumeric},
            {columnName: 'fecharecibo', width: 100, compare: sortDate},
            {columnName: 'custom2', component: CobranzaStatusFormatter, align: 'center'},
            {columnName: 'custom3', width: 70, component: DeleteBtn, align: 'center'},
        ]
    };

    const onConfirmAddCobranzasDialog = (selected) => {
        setAddCobranzasDialog({open: false});
        setCobranzas(cobranzas.concat(selected));
    }

    const saveRendicion = ids => new Promise((resolve, reject) => {
        CobranzasService.saveRendicion(
            ids,
            response => resolve(),
            error => reject()
        )
    })

    const changeStatus = id => new Promise((resolve, reject) => {
        CobranzasService.changeStatus(
            id,
            "S",
            response => resolve(),
            error => reject()
        )
    })

    const onConfirm = () => {
        const cobranzaIds = cobranzas.map(cobranza => cobranza.id);
        saveRendicion(cobranzaIds)
            .then(
                () => {
                    Promise.all(cobranzaIds.map(id => changeStatus(id)))
                        .then(
                            onSuccessConfirming,
                            onErrorConfirming
                        )
                },
                onErrorConfirming
            )
    }

    const onSuccessConfirming = () => {
        setConfirmDialog({open: false});
        setCobranzas([]);
        enqueueSnackbar("Las cobranzas fueron rendidas con éxito", {variant: 'success'})
    }

    const onErrorConfirming = () => {
        enqueueSnackbar("Error al actualizar algunas cobranzas, intente nuevamente", {variant: 'error'});
        setCobranzas([]);
    }

    const findScannedCobranza = (scannedCode) => {
        return new Promise((resolve, reject) => {
            CobranzasService.getById(
                scannedCode,
                success => resolve(success.data.count > 0 ? success.data.result : undefined),
                error => enqueueSnackbar(error, {variant: 'error'})
            )
        })
    }

    const onScanCode = async (scannedCode) => {
        const scannedCodes = scannedCode.split("I");

        for(let scannedCode of scannedCodes) {
            let scannedCobranza = await findScannedCobranza(scannedCode);

            // Valido la cobranza
            if (!scannedCobranza) {
                enqueueSnackbar(`No se encontró la cobranza con id ${scannedCode}`, {variant: 'error'});
                continue;
            }

            // Valido que la cobranza no haya sido agregada antes
            const alreadyScanned = cobranzasRef.current.find(cobranza => cobranza.id === scannedCode);
            if (alreadyScanned) {
                enqueueSnackbar(`La cobranza con id ${scannedCode} ya ha sido escaneada`, {variant: 'error'});
                continue;
            }

            if (scannedCobranza.status !== "A") {
                enqueueSnackbar(`La cobranza escaneada no está en estado aprobada`, {variant: 'error'});
                continue;
            }

            setCobranzas(cobranzasRef.current.concat([scannedCobranza]))
        }
    };

    return (
        <>
            <Box>
                <Box className='container-xl mt-3 mb-3'>
                    <Box className='wrapper'>
                        <Box className={'mt-3'}>
                            <ReactGrid
                                title={`Rendiciones Manuales`}
                                height={-250}
                                columns={COBRANZAS_TABLE.columns}
                                columnExtensions={COBRANZAS_TABLE.columnExtensions}
                                getRowId={row => row.id}
                                rows={cobranzas}
                                showResultCount={true}
                                enableFiltering={false}
                                enableSorting={true}
                                enableSearch={true}
                                enableSelection={true}
                                selectedRows={selected}
                                onSelectionChange={selected => setSelected(selected)}
                                onDelete={selected => setSelected([])}
                                showSelectAll={true}
                                showAddButton={true}
                                onClickAddButton={() => setAddCobranzasDialog({open: true})}
                            />
                        </Box>
                        {!addCobranzasDialog.open && (
                            <ScanReceptor onScanCode={onScanCode}/>
                        )}
                        {cobranzas.length === 0 && (
                            <Box>
                                <img
                                    src={qrIcon}
                                    width={'135px'}
                                    className={'m-auto'}
                                    style={{
                                        margin: '0 auto',
                                        padding: '120px 0 50px 0',
                                        display: 'block'
                                    }}/>
                                <Typography variant={"body1"} align={"center"} color={"textSecondary"} className={"pb-2"}>
                                    Agregue una nueva rendición escaneando un QR o presionando el botón "Agregar"
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box className='niquel-fab-btn'>
                    <Tooltip title={'Confirmar rendición'}>
                        <Fab color="primary" aria-label="Add" disabled={cobranzas.length < 1} onClick={() => setConfirmDialog({open: true})}>
                            <DoneIcon/>
                        </Fab>
                    </Tooltip>
                </Box>
                <SimpleDialog
                    title={"Cobranzas"}
                    open={comprobantesDialog.open}
                    body={
                        <AdditionalData
                            idrecibo={comprobantesDialog?.id}
                            items={comprobantesDialog?.items}
                            editMode={false}
                        />
                    }
                    fullWidth={true}
                    maxWidth={'md'}
                    onCancel={() => setComprobantesDialog({open: false})}
                />
                <SimpleDialog
                    open={infoDialog.open}
                    onCancel={() => setInfoDialog({open: false})}
                    maxWidth={'lg'}
                    fullWidth={true}
                    title={'Cobranza'}
                    body={<CobranzasView match={{params: {cobranza: infoDialog.id, cliente: infoDialog.idcliente}}}/>}
                    cancelText={"Cerrar"}
                />
                <AddCobranzasDialog
                    open={addCobranzasDialog.open}
                    title={'Agregar'}
                    excluded={cobranzas}
                    onConfirm={onConfirmAddCobranzasDialog}
                    onCancel={() => setAddCobranzasDialog({open: false})}
                    fullScreen={true}
                />
                <SimpleDialog
                    open={confirmDialog.open}
                    onCancel={() => setConfirmDialog({open: false})}
                    maxWidth={'sm'}
                    fullWidth={true}
                    title={'Confirmar'}
                    body={
                        <>
                            <Typography>
                                Las cobranzas pasarán al estado de Rendida y no podrán ser editadas por el vendedor.
                            </Typography>
                            <Typography className={"mt-2"}>
                                ¿Estás seguro de confirmar?
                            </Typography>
                        </>}
                    onConfirm={onConfirm}
                    cancelText={"Cerrar"}
                />
            </Box>
        </>
    )
}