import React, {Component} from 'react';
import {MainContext} from "contexts/main.context";
import SubHeader from "components/helpers/subHeader/subHeader";
import Box from "@material-ui/core/Box";
import {isMobile} from "react-device-detect";
import Grid from "@material-ui/core/Grid";
import {Button, Fade, Typography} from "@material-ui/core";
import {CheckRounded, PhotoCameraRounded} from "@material-ui/icons";
import PedidosService from "services/pedidos.service";
import {HojasDeRutaService} from "services";
import {withSnackbar} from "notistack";
import IconButton from "@material-ui/core/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";

export class EntregasRetornar extends Component {
    HojasDeRutaService = new HojasDeRutaService(this.context);
    PedidosService = new PedidosService(this.context);

    state = {
        recibo: false,
        loading: false,
        articulos: [],
        status: '',
        paso: '1'
    };

    componentDidMount() {
        // const id_pedido = this.props.match.params.pedido;
        // this.loadArticulos(id_pedido);
    }

    // loadArticulos = id_pedido => {
    //     this.PedidosService.getArticulosById(
    //         id_pedido,
    //         response => this.setState({articulos: response.data.result}),
    //         error => this.props.enqueueSnackbar(error, {variant: 'error'})
    //     );
    // }

    changeStatus = status => this.setState({status: status});

    changePaso = paso => this.setState({paso: ''}, () => setTimeout(() => this.setState({paso: paso}), 300));

    retornarPedido = () => this.PedidosService.changeStatus(
        this.props.match.params.pedido,
        this.state.status,
        success => {
            this.props.enqueueSnackbar(`Se marcó el pedido ${this.props.match.params.pedido} como retornado`, {variant: 'success'});
            this.props.history.goBack();
            this.props.history.goBack();
        },
        error => this.props.enqueueSnackbar(error, {variant: 'error'})
    );

    uploadInforme = event => {
        this.setState({loading: true});

        // Paso los parámetros a un formData para poder enviar el archivo (remito)
        var formData = new FormData();
        formData.append('recibo', event.target.files[0]);
        // Limpio el input por si quieren subir varias veces.
        event.target.value = '';
        // formData.append('data', JSON.stringify(""));

        const id_pedido = this.props.match.params.pedido;
        const id_entrega = this.props.match.params.entrega;
        this.HojasDeRutaService.uploadRecibo(id_pedido, id_entrega, formData,
            success => this.setState({loading: false, recibo: true}),
            error => {
                this.setState({loading: false})
                this.props.enqueueSnackbar(error, {variant: 'error'})
            }
        );
    }

    onAddEntregaArticulo = id => this.setState(prevState => {
        let articulo = prevState.articulos.find(articulo => articulo.id === id);

        if (isNaN(articulo.entregado))
            articulo.entregado = articulo.cantidad;
        else if (articulo.entregado < articulo.cantidad)
            articulo.entregado++;

        return prevState;
    });

    onRemoveEntregaArticulo = id => this.setState(prevState => {
        let articulo = prevState.articulos.find(articulo => articulo.id === id);

        if (isNaN(articulo.entregado))
            articulo.entregado = articulo.cantidad - 1;
        else if (articulo.entregado > 0)
            articulo.entregado--;

        return prevState;
    });

    render() {
        const {loading, recibo, articulos, paso, status} = this.state;
        const viewHeight = isMobile ? 'calc(100vh - 11.5rem)' : 'calc(100vh - 11rem)';
        return (
            <div>
                <SubHeader
                    title={'Entregas'}
                />
                <Box className={'m-4'}>
                    <Grid container direction={"column"} spacing={3}>
                        <Grid item>
                            <Typography variant={"h6"} className={'font-weight-normal'} align={"center"}>
                                Entregar pedido parcial
                            </Typography>
                            <Fade in={paso === 'F' && status === 'P'} mountOnEnter unmountOnExit>
                                <Typography variant={"body1"} color={"textSecondary"} className={'font-weight-normal mt-2'} align={"center"}>
                                    Para confirmar la entrega suba una foto del remito
                                </Typography>
                            </Fade>
                            <Fade in={paso === 'F' && status !== 'P'} mountOnEnter unmountOnExit>
                                <Typography variant={"body1"} color={"textSecondary"} className={'font-weight-normal mt-2'} align={"center"}>
                                    Si dispone una foto del remito puede subirla
                                </Typography>
                            </Fade>

                        </Grid>

                        <Fade in={paso === '1'} unmountOnExit>
                            <Grid item>
                                <Button
                                    variant={"contained"}
                                    color={"primary"}
                                    className={'w-100'}
                                    style={{height: '3.5rem'}}
                                    onClick={() => {
                                        this.changeStatus('P');
                                        this.changePaso('F');
                                    }}
                                >
                                    Pude entregar algunos artículos
                                </Button>
                                <Button
                                    variant={"outlined"}
                                    color={"primary"}
                                    className={'w-100 mt-3'}
                                    style={{height: '3.5rem'}}
                                    onClick={() => this.changePaso('1.2')}
                                >
                                    No pude entregar ningún artículo
                                </Button>
                            </Grid>
                        </Fade>

                        <Fade in={paso === '1.2'} mountOnEnter unmountOnExit>
                            <Grid item>
                                <Button
                                    variant={"contained"}
                                    color={"primary"}
                                    className={'w-100'}
                                    style={{height: '3.5rem'}}
                                    onClick={() => {
                                        this.changeStatus('H');
                                        this.changePaso('F')
                                    }}
                                >
                                    El cliente rechazó el pedido
                                </Button>
                                <Button
                                    variant={"outlined"}
                                    color={"primary"}
                                    className={'w-100 mt-3'}
                                    style={{height: '3.5rem'}}
                                    onClick={() => {
                                        this.changeStatus('R');
                                        this.changePaso('F')
                                    }}
                                >
                                    No pude entregarlo por otros motivos
                                </Button>
                            </Grid>
                        </Fade>

                        {/*<Grid item>*/}
                        {/*    {articulos.map((articulo, index) =>*/}
                        {/*        <CardArticuloRetornar*/}
                        {/*            key={index}*/}
                        {/*            id={articulo.id}*/}
                        {/*            detalle={articulo.detalle}*/}
                        {/*            cantidad={articulo.cantidad}*/}
                        {/*            entregado={articulo.entregado}*/}
                        {/*            onAdd={this.onAddEntregaArticulo}*/}
                        {/*            onRemove={this.onRemoveEntregaArticulo}*/}
                        {/*        />*/}
                        {/*    )}*/}
                        {/*</Grid>*/}

                        <Fade in={paso === 'F'} mountOnEnter unmountOnExit>
                            <Grid item className={'text-center'}>
                                <input accept="image/*;capture=camera" className={'d-none'} id="recibo-file" type="file" onChange={this.uploadInforme}/>
                                <label htmlFor="recibo-file">
                                    <div className={'position-relative'}>
                                        <IconButton color="primary" aria-label="upload picture" size={"medium"} component="span">
                                            {!recibo && <PhotoCameraRounded fontSize={"large"}/>}
                                            {recibo && <CheckRounded fontSize={"large"}/>}
                                        </IconButton>
                                        {loading && <CircularProgress size={50} style={{
                                            color: 'rgba(0, 121, 107, 0.8)',
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: -25,
                                            marginLeft: -25
                                        }}/>}
                                    </div>
                                </label>
                                <Button
                                    variant={"contained"}
                                    color={"primary"}
                                    className={'w-100 mt-4'}
                                    style={{height: '3.5rem'}}
                                    disabled={status === 'P' && !recibo}
                                    onClick={this.retornarPedido}
                                >
                                    Confirmar
                                </Button>
                            </Grid>
                        </Fade>
                    </Grid>
                </Box>
            </div>
        )
    }
}

EntregasRetornar.contextType = MainContext;
EntregasRetornar = withSnackbar(EntregasRetornar);
