import React, {useEffect, useRef, useState} from "react";

export const useDidMount = (func, deps) => {
    const didMount = useRef(false);

    useEffect(() => {
        didMount.current = true;
    }, []);

    return didMount.current;
}
