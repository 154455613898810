import {useHistory, useRouteMatch} from "react-router";
import React, {useState} from "react";
import {useArticulosService} from "services/hooks";
import {useSnackbar} from "notistack";
import {Button} from "@material-ui/core/index";
import {useConfirm} from 'material-ui-confirm';
import {ScanReceptor} from "components/picking/components/scan.receptor";
import {ScanResultDialog} from "components/picking/components/scan.result.dialog";
import qrIcon from "assets/img/qr.svg";

export function ScanArticuloView() {
    const [scanResult, setScanResult] = useState({dialogOpen: false, found: false, articulo: undefined});

    const history = useHistory();
    const {enqueueSnackbar} = useSnackbar();
    const ArticulosService = useArticulosService();

    const findScannedArticulo = (scannedCode) => {
        return new Promise((resolve, reject) => {
            ArticulosService.getByBarcode(
                scannedCode,
                success => resolve(success.data.count > 0 ? success.data.result : undefined),
                error => enqueueSnackbar(error, {variant: 'error'})
            )
        })
    }

    const onScanCode = async (scannedCode) => {
        let scanResult = {
            dialogOpen: true,
            scannedCode: scannedCode,
            found: false
        }

        const scannedArticulo = await findScannedArticulo(scannedCode);

        if (!!scannedArticulo) {
            scanResult.found = true;
            scanResult.articulo = scannedArticulo;
            setScanResult(scanResult);
        } else
            setScanResult(scanResult);
    };

    const onFinishScan = () => navBack();
    const navBack = () => history.push(`/picking/operationSelection`);

    const closeResultDialog = () => setScanResult(prev => ({...prev, dialogOpen: false}));

    return (
        <>
            <div style={{height: window.innerHeight - 47, overflowY: 'auto'}}>
                <ScanReceptor onScanCode={onScanCode}/>
                <img
                    src={qrIcon}
                    width={'135px'}
                    className={'m-auto'}
                    style={{
                        margin: '0 auto',
                        padding: '120px 0',
                        display: 'block'
                    }}/>
                <Button
                    size={"medium"}
                    variant={"contained"}
                    className={'mt-2 mb-2'}
                    // disableElevation
                    color={"primary"}
                    fullWidth
                    style={{width: '90%', height: '3.5rem', margin: '0 5%'}}
                    onClick={() => {
                        onFinishScan()
                    }}
                >
                    Terminar
                </Button>
            </div>
            <ScanResultDialog
                open={scanResult.dialogOpen}
                scanResult={scanResult}
                foundText={"El artículo pertenece al stock"}
                notFoundText={"El artículo escaneado no existe"}
                fullScreen
                onConfirm={() => {
                    closeResultDialog()
                }}
                confirmText={'Cerrar'}
            />
        </>
    )
}
