import Card from "@material-ui/core/Card";
import {Divider, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import CardContent from "@material-ui/core/CardContent";
import ButtonBase from "@material-ui/core/ButtonBase";

export function CardArticulo(props) {
    const {id, detalle, cantidad, onClick} = props;
    return (
        <>
            <Card className={'m-0 p-0'} elevation={0}>
                <ButtonBase style={{width: '100%', display: 'block', textAlign: 'left'}} onClick={onClick}>
                    <CardContent>
                        <Grid container alignItems={"center"}>
                            <Grid item xs={11}>
                                <Typography color="textSecondary" variant={"body2"} gutterBottom>
                                    Artículo {id} - cantidad {cantidad}
                                </Typography>
                                <Typography variant="body1">
                                    {detalle}
                                </Typography>
                                {/*<Typography variant={"body1"} color="textSecondary" className={'mt-2'}>*/}
                                {/*    {cantidad}*/}
                                {/*</Typography>*/}
                            </Grid>
                            {/*<Grid item xs={1}>*/}
                            {/*    <NavIcon color={"primary"}/>*/}
                            {/*</Grid>*/}
                        </Grid>
                    </CardContent>
                </ButtonBase>
            </Card>
            <Divider/>
        </>

    );
}
