import React from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography
} from "@material-ui/core";
import {formatPrice} from "assets/utils";

export const ConfirmationDialog = ({operation, open, importe, cliente, isPrepago, onConfirm, onCancel}) => (
    <Dialog
        open={open}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">{`¿${operation === "CREATE" ? 'Generar nueva' : 'Editar'} cotización${isPrepago ? " PREPAGO" : ""}?`}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                Estás por ${operation === "CREATE" ? 'generar' : 'editar'} una cotización para el cliente <b>{cliente.razsoc}</b> por un total
                de <b>{formatPrice(importe, true)}</b>
            </DialogContentText>
            {isPrepago && (
                <Typography className={"color-error"}>
                    Esta cotización se utilizará para generar un pedido PREPAGO
                </Typography>
            )}
        </DialogContent>
        <DialogActions>
            <Button onClick={onCancel} color="primary">
                Cancelar
            </Button>
            <Button onClick={onConfirm} color="primary" autoFocus>
                Confirmar
            </Button>
        </DialogActions>
    </Dialog>
);
