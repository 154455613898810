import React, {Component} from 'react'
import {Box, Fab, Grid, Tooltip} from "@material-ui/core";
import {withSnackbar} from "notistack";
import {MainContext} from "contexts/main.context";
import SubHeader from "components/helpers/subHeader/subHeader";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {AfipService} from "services";
import {ReactGrid} from "components/helpers/reactGrid/reactGrid";
import DoneIcon from "@material-ui/icons/DoneRounded";
import {FilesDialog} from "components/ventas/afip/cot/dialogs/files.dialog";
import {ArbaDialog} from "components/ventas/afip/cot/dialogs/arba.dialog";
import printJS from 'print-js'
import {sortDate, sortNumeric} from "assets/utils";
import HojasDeRutaService from "services/hojasDeRuta.service";

export class AfipCotRUT extends Component {
    state = {
        fechaDesde: (new MomentUtils()).date(new Date()).subtract(1, "days"),
        fechaHasta: (new MomentUtils()).date(new Date()),
        hojasDeRuta: {
            columns: [
                {name: 'id', title: 'ID'},
                {name: 'fecha', title: 'Fecha'},
                {name: 'vehiculo_desc', title: 'Vehículo'},
                {name: 'chofer_desc', title: 'Chofer'},
                {name: 'usuario_desc', title: 'Usuario'},
            ],
            columnExtensions: [
                {columnName: 'id', width: 120, compare: sortNumeric, direction: 'desc'},
                {columnName: 'fecha', compare: sortDate, direction: 'desc'}
            ],
            data: [],
            selected: []
        },
        hojaSelected: null,
        filesDialogOpen: false,
        arbaDialogOpen: false,
        res_arba: null
    };

    AfipService = new AfipService(this.context);
    HojasDeRutaService = new HojasDeRutaService(this.context);

    onChangeDate = (field, value) => {
        this.setState({[field]: value}, () => {
            this.getHojasDeRuta();
        });
    };

    onChangeDominio = dominio => this.setState(prevState => prevState.hojaSelected.vehiculo_desc = dominio);

    onSelect = (field, selected) => {
        selected = selected.pop();
        const hojaSelected = this.state.hojasDeRuta.data.find(hoja => hoja.id === selected);
        selected = !!selected ? [selected] : [];
        this.setState(prevState => {
            prevState[field].selected = selected;
            prevState.hojaSelected = hojaSelected;
            return prevState;
        });
    };

    getHojasDeRuta = () => {
        this.AfipService.getHojasDeRutaCOT(
            this.state.fechaDesde.format('DD/MM/YY'),
            this.state.fechaHasta.format('DD/MM/YY'),
            response => this.setState(prevState => prevState.hojasDeRuta.data = response.data.result),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    // Traigo los pedidos, valido que estén todos facturados y luego traigo los documentos correspondientes.
    onConfirm = () => {
        const hojaId = this.state.hojaSelected.id;
        this.HojasDeRutaService.getPedidosById(
            hojaId,
            response => {
                let pedidos = response.data.result;
                pedidos = pedidos.filter(pedido => pedido.cerrado !== true);
                this.setState(prevState => prevState.hojaSelected.pedidos = pedidos, () => {
                    // Valido que los pedidos estén facturados
                    let pedidosSinFacturar = response.data.result.filter(pedido =>
                        pedido.estado === false && pedido.tiene_articulos_facturados === false
                    );

                    if (pedidosSinFacturar.length > 0) {
                        let ids = pedidosSinFacturar.map(pedido => pedido.id).join(", ");
                        this.props.enqueueSnackbar(`Los siguientes pedidos se encuentran sin facturar: ${ids}. No es posible generar el COT.`, {variant: 'error'})
                        return;
                    }

                    this.HojasDeRutaService.getDocumentosById(
                        hojaId,
                        response =>
                            this.setState(prevState => prevState.hojaSelected.documentos = response.data.result, () => {
                                // FIX 12/10/21 - Quito validación porque trae problemas con documentos que tienen más de un pedido cargado.
                                // if (this.state.hojaSelected.documentos.length < this.state.hojaSelected.pedidos.length) {
                                //     this.props.enqueueSnackbar("La cantidad de documentos encontrados es menor a la cantidad de pedidos de la hoja de ruta.", {variant: 'error'})
                                //     return false;
                                // }
                                this.openFilesDialog();
                            }),
                        error => this.props.enqueueSnackbar(error, {variant: 'error'})
                    )
                })
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        )
    }

    openFilesDialog = () => this.setState({filesDialogOpen: true});
    closeFilesDialog = () => this.setState({filesDialogOpen: false});

    openArbaDialog = () => this.setState({arbaDialogOpen: true});
    closeArbaDialog = () => this.setState({arbaDialogOpen: false});

    onFileAction = (action) => {
        // Busco los documentos completos seleccionados.
        const documentos = this.state.hojaSelected.documentos;
        const dominio = this.state.hojaSelected.vehiculo_desc;
        // Separo los documentos en facturas y remitos.
        let facturas = documentos;
        // let facturas = documentos.filter(documento => documento.tipo === 'FAC');
        // let remitos = documentos.filter(documento => documento.tipo === 'REM');
        // Obtengo el id de cada documento.
        facturas = facturas.map(factura => factura.id);
        // remitos = remitos.map(remito => remito.id);

        this.AfipService.generateFileCOT(
            facturas,
            [],
            dominio,
            action,
            response => {
                if (action === 'send_to_arba') {
                    if (response.data.success === true)
                        this.setState({res_arba: response.data.result}, () => this.openArbaDialog());
                    else
                        switch (response.data.result.codigoError) {
                            case "01":
                                this.props.enqueueSnackbar(`ARBA: Error interno, intente mas tarde.`, {variant: 'error'})
                                break;
                            default:
                                this.props.enqueueSnackbar(`ARBA: ${response.data.result.mensajeError}`, {variant: 'error'});
                        }
                } else {
                    const fileURL = URL.createObjectURL(response.data);
                    var a = document.createElement("a");
                    document.body.appendChild(a);
                    a.style = "display: none";
                    a.href = fileURL;
                    a.download = response.headers['n-filename'];
                    a.click();
                    window.URL.revokeObjectURL(fileURL);
                }
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    onDownloadArbaResult = () => {
        const printableData = [];
        this.state.res_arba.validacionesRemitos.remito.forEach(remito => {
            printableData.push({
                numeroUnico: 'A' + remito.numeroUnico,
                procesado: remito.procesado,
                cot: remito.cot ? remito.cot : '',
                error: remito.errores && remito.errores.error ? remito.errores.error.descripcion : ''
            });
        });

        printJS({
            printable: printableData,
            type: 'json',
            properties: [
                {field: 'numeroUnico', displayName: 'Documento'},
                {field: 'procesado', displayName: 'Procesado'},
                {field: 'cot', displayName: 'COT'},
                {field: 'error', displayName: 'Mensaje'},
            ],
            documentTitle: 'Niquel_COT_' + this.state.hojaSelected.vehiculo_desc,
            style: `
                @import url('https://fonts.googleapis.com/css?family=Roboto');
                @font-face {
                  font-family: 'Roboto';
                  font-style: normal;
                  font-weight: 400;
                  src: local('Roboto'), local('Roboto-Regular'), url(https://fonts.gstatic.com/s/roboto/v20/KFOmCnqEu92Fr1Mu72xKOzY.woff2) format('woff2');
                  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
                } 
                * {
                    font-family: "Roboto";
                }
                th,td {
                    text-align: center;
                }
                td:nth-child(4) {
                    font-size: 12px
                }
            `,
            // gridHeaderStyle: 'border: none; align: center',
            // gridStyle: 'align: center',
            header: `<div style="margin-bottom: 20px">
                <h3 align="center">COT</h3>
                <p><b>Vehículo patente:</b> ${this.state.hojaSelected.vehiculo_desc}</p>
                <p><b>Tipo vehículo:</b> ${this.state.hojaSelected.vehiculo_tipo === "T" ? 'De terceros' : 'Propio'}</p>
                </div>`,
        })
    }

    componentDidMount() {
        this.getHojasDeRuta();
    }

    render() {
        const {hojasDeRuta, hojaSelected, fechaDesde, fechaHasta, res_arba, filesDialogOpen, arbaDialogOpen} = this.state;

        return (
            <>
                <Box className='niquel-afip-main'>
                    <SubHeader
                        title={'AFIP - COT'}
                        subtitle={'Seleccionar Hoja de Ruta'}
                    />
                    <Box className='container-xl mt-3 mb-3'>
                        <Grid container spacing={2}>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DatePicker
                                        autoOk
                                        className={'niquel-date-input'}
                                        label="Desde"
                                        format="DD/MM/Y"
                                        value={fechaDesde}
                                        onChange={moment => this.onChangeDate('fechaDesde', moment)}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DatePicker
                                        autoOk
                                        className={'niquel-date-input'}
                                        label="Hasta"
                                        format="DD/MM/Y"
                                        value={fechaHasta}
                                        onChange={moment => this.onChangeDate('fechaHasta', moment)}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                        </Grid>
                        <Box className={'mt-3'}>
                            <ReactGrid
                                title={`Hojas de Ruta`}
                                height={-270}
                                columns={hojasDeRuta.columns}
                                columnExtensions={hojasDeRuta.columnExtensions}
                                rows={hojasDeRuta.data}
                                showResultCount={true}
                                enableFiltering={true}
                                enableSorting={true}
                                enableSearch={true}
                                enableSelection={true}
                                selectedRows={hojasDeRuta.selected}
                                getRowId={row => row.id}
                                onSelectionChange={selected => this.onSelect('hojasDeRuta', selected)}
                                showSelectAll={false}
                                enableClearSelection={false}
                            />
                        </Box>
                        <Tooltip title={"Confirmar"}>
                            <Box className='niquel-fab-btn'>
                                <Fab color="primary" onClick={this.onConfirm} disabled={!hojaSelected}>
                                    <DoneIcon/>
                                </Fab>
                            </Box>
                        </Tooltip>
                    </Box>
                </Box>
                <FilesDialog
                    open={filesDialogOpen}
                    dominio={hojaSelected ? hojaSelected.vehiculo_desc : ''}
                    onChangeDominio={this.onChangeDominio}
                    documentos={hojaSelected ? hojaSelected.documentos : []}
                    onDownloadTxt={() => this.onFileAction('txt')}
                    onDownloadXls={() => this.onFileAction('xls')}
                    onSendToArba={() => this.onFileAction('send_to_arba')}
                    onClose={this.closeFilesDialog}
                />
                <ArbaDialog
                    open={arbaDialogOpen}
                    result={res_arba}
                    dominio={hojaSelected ? hojaSelected.vehiculo_desc : ''}
                    tipo={hojaSelected ? hojaSelected.vehiculo_tipo : ''}
                    onDownload={this.onDownloadArbaResult}
                    onClose={this.closeArbaDialog}
                />
            </>
        )
    }
}

AfipCotRUT.contextType = MainContext;
AfipCotRUT = withSnackbar(AfipCotRUT);
