import React, {Component} from 'react'
import {Box} from "@material-ui/core";
import {ReactGrid} from 'components/helpers/reactGrid/reactGrid';
import ClientesService from 'services/clientes.service';
import {withSnackbar} from "notistack";
import {MainContext} from "contexts/main.context";
import SubHeader from "components/helpers/subHeader/subHeader";

export class NotasCreditoClientes extends Component {
    ClientesService = new ClientesService(this.context);

    state = {
        columns: [
            {name: 'id', title: 'ID Cliente'},
            {name: 'razsoc', title: 'Razón Social', style: {whiteSpace: 'break-spaces'}},
            {name: 'localidad', title: 'Localidad'},
            {name: 'direccion', title: 'Dirección', style: {whiteSpace: 'break-spaces'}},
            {name: 'apenom', title: 'Vendedor'}
        ],
        columnExtensions: [
            {columnName: 'id', width: 120},
            {columnName: 'localidad', width: 190},
            {columnName: 'vendedor', width: 170},
        ],
        rows: []
    };

    componentDidMount() {
        this.ClientesService.getAll(
            response => this.setState({rows: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        )
    }

    navList = (row) => this.props.history.push(`${this.props.match.url}/${row.id}`);

    render() {
        const {columns, columnExtensions, rows} = this.state;

        return (
            <Box className='niquel-notasCredito-clientes'>
                <SubHeader
                    title={'Notas de Crédito'}
                    subtitle={'Selección de Cliente'}
                />
                <Box className='container-xl mt-3 mb-3'>
                    <ReactGrid
                        title={'Clientes'}
                        showResultCount
                        height={-200}
                        columns={columns}
                        columnExtensions={columnExtensions}
                        rows={rows}
                        enableFiltering={true}
                        showFilters={false}
                        rowClick={true}
                        onRowClick={this.navList}
                    />
                </Box>
            </Box>
        )
    }
}

NotasCreditoClientes.contextType = MainContext;
NotasCreditoClientes = withSnackbar(NotasCreditoClientes);
