import React, {useContext} from 'react'
import {MainContext} from "contexts/main.context";
import {Box, Button, Grid, Switch, TextField, Tooltip, Typography} from '@material-ui/core';
import 'moment/locale/es';
import SubHeader from "components/helpers/subHeader/subHeader";
import {ReactGrid} from "components/helpers/reactGrid/reactGrid";
import DeleteIcon from "@material-ui/icons/Delete";

export function ArticulosArmadosOperation({operation, articulos, tableConfig, codigo, setCodigo, detalle, setDetalle, activo, setActivo, onOpenAddArticulosDialog, onDelete}) {
    const context = useContext(MainContext);
    const {loggedUser} = context;

    return (
        <Box className='niquel-articulosArmados-operation'>
            <SubHeader
                title={'Articulos Armados'}
                subtitle={
                    operation === 'VIEW' ?
                        'Revisión de Artículo Armado' :
                        operation === 'EDIT' ?
                            'Edición de Articulo Armado' :
                            'Nuevo Artículo Armado'
                }
                optionsTitle={'Artículo Armado'}
            />
            <Box className='container-xl mt-3 mb-3'>
                <Grid container alignItems='center'>
                    <Grid container item alignItems='center' spacing={2} md={10}>
                        <Grid item>
                            <TextField
                                label="Código"
                                value={codigo}
                                onChange={event => setCodigo(event.target.value.substring(0, 11))}
                                InputProps={{
                                    readOnly: (operation === 'VIEW' || operation === 'EDIT')
                                }}
                                style={{
                                    width: '8rem'
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                label="Detalle del artículo armado"
                                value={detalle}
                                onChange={event => setDetalle(event.target.value.substring(0, 60))}
                                InputProps={{
                                    readOnly: operation === 'VIEW'
                                }}
                                style={{
                                    width: '30rem'
                                }}
                            />
                        </Grid>
                        <Grid item>
                            <Switch
                                checked={activo}
                                disabled={operation === "VIEW"}
                                color={"primary"}
                                onChange={() => setActivo(!activo)}
                            />
                            <Typography
                                variant={"caption"}
                                component={"span"}
                                onClick={() => operation !== "VIEW" && setActivo(!activo)}
                                style={{cursor: 'pointer'}}
                                color={'textPrimary'}
                            >
                                {activo ? "ACTIVO" : "INACTIVO"}
                            </Typography>
                        </Grid>
                    </Grid>
                    {(operation === 'VIEW' || operation === 'EDIT') &&
                        <Grid item md={2}>
                            <Grid item className={'flex-grow-1 text-right'}>
                                <Tooltip title={'Eliminar Artículo Armado'}>
                                    <Button className={'background-error'} style={{color: 'white'}} onClick={onDelete}>
                                        <DeleteIcon fontSize={"small"}/> ELIMINAR
                                    </Button>
                                </Tooltip>
                            </Grid>
                        </Grid>
                    }
                </Grid>

                <Box className={'mt-3'}>
                    {tableConfig &&
                        <ReactGrid
                            enableVirtualMode={false}
                            columns={tableConfig.columns}
                            columnExtensions={tableConfig.columnExtensions}
                            getRowId={row => row.id}
                            rows={articulos}
                            title={`Artículos`}
                            showResultCount={true}
                            enableFiltering={false}
                            enableSorting={true}
                            enableSearch={false}
                            enableSelection={false}
                            enableGrouping={true}
                            enableSummarizing={true}
                            showAddButton={operation !== 'VIEW'}
                            onClickAddButton={onOpenAddArticulosDialog}
                            showSelectAll={true}
                        />
                    }
                </Box>
            </Box>
        </Box>
    )
}
