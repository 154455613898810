import React, {useState} from "react";
import {MenuItem, TextField, Typography} from "@material-ui/core";
import {SimpleDialog} from "components/helpers/dialogs/simple.dialog";
import {InputPercentage} from "assets/utils";
import {Grid} from "@material-ui/core/index";

const AddObjetivoDialogBody = ({tiposObjetivo, proveedoresObjetivo, tipo, proveedor, peso, onChangeTipo, onChangeProveedor, onChangePeso}) =>
    <Grid container direction={"column"} spacing={2}>
        <Grid container item justifyContent={"space-between"}>
            <TextField
                select
                className={'mb-2'}
                label={"Tipo"}
                value={tipo}
                style={{
                    width: '60%',
                }}
                onClick={e => e.stopPropagation()}
                onChange={event => onChangeTipo(event.target.value)}
            >
                {tiposObjetivo.map(option => (
                    <MenuItem key={option.tipoobj} value={option.tipoobj}>
                        {option.nombre}
                    </MenuItem>
                ))}
            </TextField>
            {tipo === 0 && (
                <TextField
                    select
                    className={'mb-2'}
                    label={"Proveedor"}
                    value={proveedor}
                    style={{
                        width: '60%',
                    }}
                    onClick={e => e.stopPropagation()}
                    onChange={event => onChangeProveedor(event.target.value)}
                >
                    {proveedoresObjetivo.map(option => (
                        <MenuItem key={option.idproveed} value={option.idproveed}>
                            {option.nombre}
                        </MenuItem>
                    ))}
                </TextField>
            )}
            <TextField
                label={"Peso"}
                value={peso}
                variant={"outlined"}
                InputProps={{
                    inputComponent: InputPercentage,
                    style: {
                        width: '6rem',
                    },
                }}
                onChange={event => onChangePeso(event.target.value)}
            />
        </Grid>
    </Grid>

export const AddObjetivoDialog = ({open, tiposObjetivo, proveedoresObjetivo, onConfirm, onCancel}
) => {
    const [tipo, setTipo] = useState('');
    const [proveedor, setProveedor] = useState('');
    const [peso, setPeso] = useState(50);

    return (
        <SimpleDialog
            open={open}
            maxWidth={'xs'}
            fullWidth
            title={'Agregar Objetivo'}
            body={
                <AddObjetivoDialogBody
                    tiposObjetivo={tiposObjetivo}
                    proveedoresObjetivo={proveedoresObjetivo}
                    tipo={tipo}
                    proveedor={proveedor}
                    peso={peso}
                    onChangeTipo={setTipo}
                    onChangeProveedor={setProveedor}
                    onChangePeso={setPeso}
                />
            }
            onConfirm={() => onConfirm(tipo, proveedor, peso)}
            onCancel={onCancel}
        />
    )
}
