import BaseService from './base.service'

export default class ClientsScheduleService extends BaseService {
    getClientes(date, idvendedor, fnSuccess, fnError) {
        return this.getApi().get(`/clientsSchedule?date=${date}&vendedor=${idvendedor}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener la agenda de clientes')));
    }

    createCheckin(idVendedor, idCliente, date, latitud, longitud, fnSuccess, fnError) {
        return this.getApi().post(`/clientsSchedule/checkin`, {idVendedor, idCliente, date, latitud, longitud})
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al guardar el checkin')));
    }

    deleteCheckin(idVendedor, idCliente, date, fnSuccess, fnError) {
        return this.getApi()({
            url: `/clientsSchedule/checkin`,
            method: 'delete',
            params: {idVendedor, idCliente, date}
        })
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al eliminar el checkin')));
    }

    downloadScheduleFile(vendedores, fnSuccess, fnError) {
        this.getApi()({
            url: '/clientsSchedule/downloadScheduleFile',
            method: 'post',
            data: {vendedores},
            responseType: 'blob'
        })
            .then(response => {
                const mimetype = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                const file = new Blob([response.data], {type: mimetype});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al descargar la agenda')));
    }

    uploadScheduleFile(data, validitydate, fnSuccess, fnError) {
        return this.getApi().post(`/clientsSchedule/uploadScheduleFile?validityDate=${validitydate}`, data)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al subir la agenda')));
    }
}
