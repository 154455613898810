import React, {Component} from 'react';
import {MainContext} from "contexts/main.context";
import SubHeader from "components/helpers/subHeader/subHeader";
import Box from "@material-ui/core/Box";
import {isMobile} from "react-device-detect";
import Grid from "@material-ui/core/Grid";
import {Button, Divider, Typography} from "@material-ui/core";
import {CardArticulo} from "components/despacho/entregas/detalles/card.articulo";
import {DetallesMap} from "components/despacho/entregas/detalles/detalles.map";
import {BusinessRounded, InfoRounded} from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse";
import PedidosService from "services/pedidos.service";
import {HojasDeRutaService} from "services";
import Skeleton from "@material-ui/lab/Skeleton";
import {withSnackbar} from "notistack";
import {formatDireccion} from "assets/utils";

export class EntregasDetalles extends Component {
    HojasDeRutaService = new HojasDeRutaService(this.context);
    PedidosService = new PedidosService(this.context);

    state = {
        pedido: {},
        articulos: [],
        articulosExpanded: false
    };

    componentDidMount() {
        const id_entrega = this.props.match.params.entrega;
        const id_pedido = this.props.match.params.pedido;
        this.loadPedido(id_entrega, id_pedido);
    }

    loadPedido(id_entrega, id_pedido) {
        // this.HojasDeRutaService.getPedidosById(id_entrega,
        //     success => this.setState({pedido: success.data.result.find(pedido => pedido.id === id_pedido)}),
        //     error => this.props.enqueueSnackbar(error, {variant: 'error'})
        // );

        this.PedidosService.getById(
            id_pedido,
            response => this.setState({pedido: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.PedidosService.getArticulosById(
            id_pedido,
            response => this.setState({articulos: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    changeTab = (event, value) => {
        this.setState({tabActive: value});
    };

    toggleExpandArticulos = () => {
        this.setState(prevState => prevState.articulosExpanded = !prevState.articulosExpanded);
    }

    entregarPedido = () => this.props.history.push(`entregar`);

    retornarPedido = () => this.props.history.push(`retornar`);

    render() {
        const {pedido, articulos, articulosExpanded} = this.state;
        const viewHeight = isMobile ? 'calc(100vh - 11.5rem)' : 'calc(100vh - 11rem)';
        return (
            <div>
                <SubHeader
                    title={'Entregas'}
                />
                <Box className={'m-4'}>
                    <Grid container direction={"column"} spacing={2}>
                        <Grid item>
                            <Typography variant={"h6"} className={'font-weight-normal'} align={"center"}>
                                {formatDireccion(pedido.direccion_entrega)}
                            </Typography>
                        </Grid>
                        <Grid item style={{height: '15rem', display: 'flex'}}>
                            {(pedido.latitud && pedido.longitud) ? (
                                <DetallesMap
                                    cliente={pedido.cliente}
                                    lat={pedido.latitud}
                                    lng={pedido.longitud}
                                    zoom={11}
                                />
                            ) : (
                                <Skeleton variant="rect" width={'100%'} height={'15rem'}/>
                            )}
                        </Grid>
                        <Grid item className={'d-flex mt-1 mb-1'}>
                            <BusinessRounded className={'mr-3'}/>
                            <Typography variant={"body1"} component={"span"} className={'font-weight-normal'}>{pedido.cliente}</Typography>
                        </Grid>
                        <Divider/>
                        <Grid item className={'d-flex justify-content-between mt-1 mb-1'}>
                            <Box className={'d-flex align-items-center'}>
                                <InfoRounded className={'mr-3'}/>
                                <Typography variant={"body1"} component={"span"} className={'font-weight-normal'}>
                                            Pedido {pedido.id} - {!pedido.estado ? '(no facturado)' : `Remito: ${pedido.numrem}`}
                                </Typography>
                            </Box>
                            <Button variant={"text"} color={"primary"} onClick={this.toggleExpandArticulos}>Ver Artículos</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant={"contained"}
                                color={"primary"}
                                className={'w-100'}
                                style={{height: '3.5rem'}}
                                onClick={this.entregarPedido}
                            >
                                Entregar Todo
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant={"outlined"}
                                color={"primary"}
                                className={'w-100'}
                                style={{height: '3.5rem'}}
                                onClick={this.retornarPedido}
                            >
                                No pude entregar todo
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
                <Collapse in={articulosExpanded} timeout="auto" unmountOnExit>
                    <Box style={{height: viewHeight}}>
                        {articulos.map((articulo, index) =>
                            <CardArticulo
                                key={index}
                                id={articulo.id}
                                detalle={articulo.detalle}
                                cantidad={articulo.cantidad}
                            />
                        )}
                    </Box>
                </Collapse>
            </div>
        )
    }
}

EntregasDetalles.contextType = MainContext;
EntregasDetalles = withSnackbar(EntregasDetalles);
