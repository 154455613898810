import React, {Component} from 'react';
import classNames from 'classnames';
import {Box, withStyles} from '@material-ui/core';
import {Route, Switch} from 'react-router-dom';
import {MainContext} from "contexts/main.context";
import {HeaderContainer} from 'components/header/header.container';
import {SideBarContainer} from "components/sideBar/sideBar.container";
import {Ventas} from "components/ventas/ventas";
import {Aprobaciones} from "components/aprobaciones/aprobaciones";
import {Despacho} from "components/despacho/despacho";
import {Stock} from "components/stock/stock";
import {Picking} from "components/picking/picking";
import {Listados} from "components/listados/listados";
import {Home} from "components/home/home";
import {Estadisticas} from "components/estadisticas/estadisticas";
import {Herramientas} from "components/herramientas/herramientas";
import {Objetivos} from "components/objetivos/objetivos";
import AuthService from 'services/auth.service';
import styles from './styles';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {NotificationsService} from "services";
import {isMobile} from "react-device-detect";

const NOTIFICATION_INTERVAL = 1; // Minutos

class Main extends Component {
    NotificationsService = new NotificationsService(this.state);

    constructor(props) {
        super(props);
        this.state = {
            clientWidth: 0,
            clientHeight: 0,
            MIN_EXPANDED_WIDTH: 1300,
            headerTitle: '',
            servicesRunning: 0,
            loggedUser: AuthService.getUser(),
            sidebarVisible: false,
            openSidebar: this.openSidebar,
            closeSidebar: this.closeSidebar,
            setSidebarVisible: this.setSidebarVisible,
            setHeaderTitle: this.setHeaderTitle,
            addServiceRunning: this.addServiceRunning,
            removeServiceRunning: this.removeServiceRunning,
            updateWindowDimensions: this.updateWindowDimensions,
            notifications: 0
        };

        this.state.sidebarOpen = isMobile || window.innerWidth >= this.state.MIN_EXPANDED_WIDTH;
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    openSidebar = () => this.setState({sidebarOpen: true});

    closeSidebar = () => this.setState({sidebarOpen: false});

    setSidebarVisible = visible => this.setState({sidebarVisible: visible});

    setHeaderTitle = title => this.setState({headerTitle: title});

    addServiceRunning = () => this.setState(prevState => (prevState.servicesRunning++));

    removeServiceRunning = () => this.setState(prevState => (prevState.servicesRunning--));

    updateWindowDimensions = () => {
        const isFullScreen = (window.innerHeight >= window.screen.height - 50);
        this.setState({clientWidth: window.innerWidth, clientHeight: window.innerHeight, isFullScreen: isFullScreen});
    };

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
        this.checkForNotifications();
    }

    checkForNotifications = () => {
        this.NotificationsService.getPending(
            response => this.setState({notifications: parseInt(response.data.result)}),
            error => console.log(error)
        );

        setTimeout(this.checkForNotifications, NOTIFICATION_INTERVAL * 60 * 1000);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    onClickAwaySidebar = () => {
        if (!isMobile && this.state.clientWidth < this.state.MIN_EXPANDED_WIDTH) {
            console.log("cierro clickSideawway");
            this.closeSidebar();
        }
    };

    render() {
        const {sidebarOpen, clientWidth} = this.state;
        const {classes} = this.props;

        return (
            <MainContext.Provider value={this.state}>
                <Box className='d-flex'>
                    <HeaderContainer/>
                    <ClickAwayListener onClickAway={this.onClickAwaySidebar}>
                        <SideBarContainer/>
                    </ClickAwayListener>
                    <div className={
                        classNames(
                            classes.niquelMain,
                            {[classes.niquelMainShift]: (sidebarOpen && clientWidth >= this.state.MIN_EXPANDED_WIDTH)},
                            'niquel-main'
                        )
                    }>
                        <Switch>
                            <Route path='/home' component={Home}/>
                            <Route path='/ventas' component={Ventas}/>
                            <Route path='/aprobaciones' component={Aprobaciones}/>
                            <Route path='/despacho' component={Despacho}/>
                            <Route path='/stock' component={Stock}/>
                            <Route path='/picking' component={Picking}/>
                            <Route path='/listados' component={Listados}/>
                            <Route path='/estadisticas' component={Estadisticas}/>
                            <Route path='/herramientas' component={Herramientas}/>
                            <Route path='/objetivos' component={Objetivos}/>
                        </Switch>
                        {this.state.servicesRunning > 0 && (
                            <div className='niquel-loading-wrapper'>
                                <div className='niquel-loading-icon'>
                                    {/*<CircularProgress />*/}
                                    <svg className="spinner" viewBox="0 0 50 50">
                                        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"/>
                                    </svg>
                                </div>
                            </div>
                        )}
                    </div>
                </Box>
            </MainContext.Provider>
        );
    }
}

export default withStyles(styles)(Main);
