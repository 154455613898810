import React from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography} from '@material-ui/core';
import {formatPrice} from "assets/utils";
import {ArticulosService} from "services";
import {MainContext} from "contexts/main.context";
import {withSnackbar} from "notistack";
import ImgNotFoundIcon from "@material-ui/icons/PhotoCameraRounded";
import 'components/helpers/articulos/articuloDetails.dialog.scss';

class ArticuloInfoDialog extends React.Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                maxWidth={"sm"}
                fullWidth={true}
                classes={{
                    paperFullScreen: 'niquel-dialog-full-screen'
                }}
            >
                <ArticuloInfoDialogContent {...this.props}/>
            </Dialog>
        );
    }
}

class ArticuloInfoDialogContent extends React.Component {
    state = {
        valoresFacturados: [],
        image: null
    };

    ArticulosService = new ArticulosService(this.context);

    componentDidMount() {
        this.ArticulosService.getValoresFacturados(
            this.props.articulo.id,
            this.props.cliente.id,
            response => this.setState({valoresFacturados: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
        this.ArticulosService.getImage(
            this.props.articulo.id,
            response => {
                if (response)
                    this.setState({image: response});
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        )
    }


    getImporteUnitarioFinal = (precio, bonificacion, bonificacionGeneral) => {
        return precio * (1 - bonificacion / 100) * (1 - bonificacionGeneral / 100);
    };

    render() {
        const {onClose, articulo, bonificacionGeneral} = this.props;
        const {valoresFacturados, image} = this.state;

        return (
            <React.Fragment>
                <DialogTitle>Información del Artículo <Typography color={"textSecondary"} component={"span"}>{articulo.id}</Typography></DialogTitle>
                <DialogContent>
                    {!articulo.armado && (
                        <Grid container className='mb-4 niquel-articulo-image'>
                            {image !== null && (
                                <Box className='img-found'>
                                    <img src={`data:image/jpeg;base64,${image}`} alt={articulo.detalle}/>
                                </Box>
                            )}
                            {image === null && (
                                <Box className='img-not-found'>
                                    <ImgNotFoundIcon/>
                                </Box>
                            )}
                        </Grid>
                    )}
                    <Typography variant={"caption"} color={"textSecondary"}>Documento Actual</Typography>
                    <Grid container spacing={1} justifyContent={"space-between"} className={'mt-1'}>
                        <Grid item>
                            <Typography variant={"caption"} color={"textSecondary"} align={"right"}>
                                Precio unit.
                            </Typography>
                            <Typography variant={"body1"} align={"right"}>
                                {formatPrice(articulo.precio)}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={"caption"} color={"textSecondary"} align={"right"}>
                                Cant.
                            </Typography>
                            <Typography variant={"body2"} align={"right"}>
                                {articulo.cantidad}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={"caption"} color={"textSecondary"} align={"right"}>
                                Bonif.
                            </Typography>
                            <Typography variant={"body1"} align={"right"}>
                                {articulo.bonificacion}%
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={"caption"} color={"textSecondary"} align={"right"}>
                                Bonif. (gral)
                            </Typography>
                            <Typography variant={"body1"} align={"right"}>
                                {bonificacionGeneral}%
                            </Typography>
                        </Grid>
                        <Grid item className={'ml-5'}>
                            <Typography variant={"caption"} color={"textSecondary"} align={"right"}>
                                Precio final unit.
                            </Typography>
                            <Typography variant={"body1"} align={"right"}>
                                <b>{formatPrice(this.getImporteUnitarioFinal(articulo.precio, articulo.bonificacion, bonificacionGeneral))}</b>
                            </Typography>
                        </Grid>
                    </Grid>
                    {!articulo.armado && (
                        <Grid container spacing={3} className={'mt-1'}>
                            <Grid item>
                                <Typography variant={"caption"} color={articulo.pendfac > 0 ? "error" : "textSecondary"} align={"right"}>
                                    Pendiente Facturar
                                </Typography>
                                <Typography variant={"body1"} align={"right"} color={articulo.pendfac > 0 ? "error" : ""}>
                                    {articulo.pendfac} de {articulo.afacturar}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant={"caption"} color={articulo.penddesc > 0 ? "error" : "textSecondary"} align={"right"}>
                                    Pendiente Descargar
                                </Typography>
                                <Typography variant={"body1"} align={"right"} color={articulo.penddesc > 0 ? "error" : ""}>
                                    {articulo.penddesc} de {articulo.adescargar}
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    <Divider className={'mt-4 mb-3'}/>
                    <Typography variant={"caption"} color={"textSecondary"}>Historial de valores facturados</Typography>
                    <Grid container direction={"column"} className={'mt-1'}>
                        {valoresFacturados.map((valorFacturado, index) => (
                            <Grid item key={index} className={'mt-2 mb-2'}>
                                <Grid item>
                                    <Typography variant={"body2"} component={'span'}>{valorFacturado.fecha}</Typography>
                                    <Typography variant={"body2"} component={'span'} color={"textSecondary"}>&nbsp;-&nbsp;{valorFacturado.documento}</Typography>
                                </Grid>
                                <Grid container spacing={1} justifyContent={"space-between"}>
                                    <Grid item>
                                        <Typography variant={"caption"} color={"textSecondary"} align={"right"}>
                                            Precio unit.
                                        </Typography>
                                        <Typography variant={"body2"} align={"right"}>
                                            {formatPrice(valorFacturado.precio)}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant={"caption"} color={"textSecondary"} align={"right"}>
                                            Cant. Fact.
                                        </Typography>
                                        <Typography variant={"body2"} align={"right"}>
                                            {valorFacturado.cantidad}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant={"caption"} color={"textSecondary"} align={"right"}>
                                            Bonif.
                                        </Typography>
                                        <Typography variant={"body2"} align={"right"}>
                                            {valorFacturado.bonificacion}%
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant={"caption"} color={"textSecondary"} align={"right"}>
                                            Bonif. (gral)
                                        </Typography>
                                        <Typography variant={"body2"} align={"right"}>
                                            {valorFacturado.bonificacion_general}%
                                        </Typography>
                                    </Grid>
                                    <Grid item className={'ml-5'}>
                                        <Typography variant={"caption"} color={"textSecondary"} align={"right"}>
                                            Precio final unit.
                                        </Typography>
                                        <Typography variant={"body2"} align={"right"}>
                                            <b>{formatPrice(this.getImporteUnitarioFinal(valorFacturado.precio, valorFacturado.bonificacion, valorFacturado.bonificacion_general))}</b>
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        ))}
                        {valoresFacturados.length < 1 && (
                            <Grid item>
                                <Typography color={"textSecondary"} align={"center"} variant={"body2"}>
                                    No se encontraron valores facturados para este artículo
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </React.Fragment>
        )
    }
}

ArticuloInfoDialogContent.contextType = MainContext;
ArticuloInfoDialogContent = withSnackbar(ArticuloInfoDialogContent);

export default ArticuloInfoDialog;
