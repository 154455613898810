import React, {Component} from 'react'
import {ClientesService, MastersService, VendedoresService} from "services";
import {MainContext} from "contexts/main.context";
import {Box, Fab, Tooltip} from "@material-ui/core";
import {ConfirmationDialog} from './dialogs/confirm.dialog';
import 'moment/locale/es';
import {withSnackbar} from "notistack";
import DoneIcon from '@material-ui/icons/Done';
import {ClientesOperation} from "components/ventas/clientes/operation/clientes.operation";

export class ClientesCreate extends Component {
    state = {
        tiposDocu: [],
        respIVA: [],
        provincias: [],
        transportes: [],
        zonas: [],
        vendedores: [],
        headerData: {
            razsoc: '',
            direccion: '',
            localidad: '',
            provincia: '',
            codpostal: '',
            transporte: '',
            zona: '',
            telefono: '',
            vendedor: this.context.loggedUser.id,
            tipodocu: '80',
            numdocu: '',
            respIVA: 'RI',
        },
        error: false,
        confirmationDialogOpen: false
    };

    MastersService = new MastersService(this.context);
    VendedoresService = new VendedoresService(this.context);
    ClientesService = new ClientesService(this.context);

    componentDidMount() {
        this.MastersService.getTiposDocu(
            response => this.setState({tiposDocu: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.MastersService.getRespIVA(
            response => this.setState({respIVA: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.MastersService.getProvincias(
            response => this.setState({provincias: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.MastersService.getTransportes(
            response => this.setState({transportes: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.MastersService.getZonas(
            response => this.setState({zonas: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.VendedoresService.getAll(
            response => this.setState({vendedores: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    onCreate = () => {
        if (this.validateData())
            this.onOpenConfirmationDialog();
    };

    validateData = () => {
        let cliente = {...this.state.headerData};
        let error = ['razsoc', 'direccion', 'tipodocu', 'numdocu', 'vendedor'].some(field => cliente[field] === '');
        this.setState({error: error});

        if (error)
            this.props.enqueueSnackbar('Debe completar los campos obligatorios', {variant: 'error'});

        return !error;
    };

    onConfirm = () => {
        let data = {...this.state.headerData};

        this.ClientesService.create(
            data,
            response => {
                this.props.enqueueSnackbar(`Se generó correctamente el cliente ocasional con id ${response.data.id}`, {variant: 'success'});
                this.props.history.goBack();
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        )
    };

    onChangeHeaderData = (field, value) => {
        this.setState(prevState => {
            prevState.headerData[field] = value;
            return prevState;
        });
    };

    onOpenConfirmationDialog = () => this.setState({confirmationDialogOpen: true});
    onCloseConfirmationDialog = () => this.setState({confirmationDialogOpen: false});

    render() {
        const {
            tiposDocu, respIVA, provincias, transportes, zonas, vendedores, headerData, confirmationDialogOpen, error
        } = this.state;

        return (
            <Box className='niquel-cotizaciones-create'>
                <ClientesOperation
                    operation='CREATE'
                    tiposDocu={tiposDocu}
                    respIVA={respIVA}
                    provincias={provincias}
                    transportes={transportes}
                    zonas={zonas}
                    vendedores={vendedores}
                    headerData={headerData}
                    onChangeHeaderData={this.onChangeHeaderData}
                    error={error}
                />

                <Box className='niquel-fab-btn'>
                    <Tooltip title={'Crear Cliente'}>
                        <Fab color="primary" aria-label="Add" onClick={this.onCreate}>
                            <DoneIcon/>
                        </Fab>
                    </Tooltip>
                </Box>

                <ConfirmationDialog
                    open={confirmationDialogOpen}
                    cliente={headerData}
                    onConfirm={() => {
                        this.onCloseConfirmationDialog();
                        this.onConfirm();
                    }}
                    onCancel={this.onCloseConfirmationDialog}
                />
            </Box>
        )
    }
}

ClientesCreate.contextType = MainContext;
ClientesCreate = withSnackbar(ClientesCreate);