export const _TIPOS = [{
    id: 1,
    tipo: 'proveedor',
    nombre: 'Shell'
},{
    id: 2,
    tipo: 'proveedor',
    nombre: 'Bosch'
},{
    id: 3,
    tipo: 'proveedor',
    nombre: 'Osram'
},{
    id: 4,
    tipo: 'proveedor',
    nombre: 'Tecfil'
}];
