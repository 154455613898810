import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {FacturasClientes} from 'components/ventas/facturas/clientes/facturas.clientes';
import {FacturasList} from 'components/ventas/facturas/list/facturas.list';
import {FacturasView} from "components/ventas/facturas/view/facturas.view";
import {FacturasSelection} from "components/ventas/facturas/selection/facturas.selection";

const basePath = '/ventas/facturas';

export class Facturas extends Component {
    render() {
        return (
            <div className='niquel-facturas niquel-view-with-bottom-margin'>
                <Switch>
                    <Route path={`${basePath}/xcliente/:cliente/view/:factura`} component={FacturasView}/>
                    <Route path={`${basePath}/xfecha/view/:factura`} component={FacturasView}/>
                    <Route exact path={`${basePath}/xcliente/:cliente`} component={FacturasList}/>
                    <Route exact path={`${basePath}/xfecha`} component={FacturasList}/>
                    <Route path={`${basePath}/xcliente`} component={FacturasClientes}/>
                    <Route path={`${basePath}`} component={FacturasSelection}/>
                </Switch>
            </div>
        )
    }
}
