import {Grid, Tooltip, Typography} from "@material-ui/core";
import {formatPrice} from "assets/utils";
import React from "react";
import {Divider} from "@material-ui/core/index";

export const Totales = (props) => {
    return <TotalesPedido {...props} />;
}

const TotalesPedido = ({importe, importeBonificado, headerData}) => (
    <Grid container item md={2} direction={"column"} spacing={1}>
        <Grid item className={"d-flex"} justifyContent={"flex-end"}>
            <Typography variant="caption" align="right" color="textSecondary" className="mr-1">Subtotal:</Typography>
            <Typography variant="body2" component="span" align='right'>{formatPrice(importe, true)}</Typography>
        </Grid>
        <Divider/>
        <Grid item>
            <Typography variant={"caption"} display="block" align="right" color={"textSecondary"}>Importe Final</Typography>
            <Typography variant="h6" align="right">{formatPrice(importeBonificado, true)}</Typography>
        </Grid>

        {headerData.isPrepago && (
            <Grid item>
                <Tooltip title={"Pedido generado a partir de una cotización prepago"}>
                    <Typography
                        variant={"body1"}
                        component={'div'}
                        align={"right"}
                        style={{
                            color: 'rgba(0, 150, 136, 1)',
                            // lineHeight: '0.9rem'
                        }}
                    >
                        <b>PEDIDO PREPAGO</b>
                    </Typography>
                </Tooltip>
            </Grid>
        )}
    </Grid>
)
