import React, {useState} from "react";
import {MenuItem, TextField} from "@material-ui/core";
import {SimpleDialog} from "components/helpers/dialogs/simple.dialog";
import {InputInteger, InputPercentage, InputPrice} from "assets/utils";
import {Grid} from "@material-ui/core/index";
import _UNIDADES from "../unidades.json";

const AddSubObjetivoDialogBody = ({
                                      tipo,
                                      codigo,
                                      valor,
                                      unidad,
                                      peso,
                                      onChangeTipo,
                                      onChangeCodigo,
                                      onChangeValor,
                                      onChangeUnidad,
                                      onChangePeso,
                                      tipoobj,
                                      tiposSubObjetivo,
                                      codigosSubObjetivo
                                  }) =>
    <Grid container direction={"column"} spacing={2}>
        <Grid item>
            <TextField
                select
                fullWidth
                className={'mb-2'}
                label={"Tipo"}
                value={tipo}
                onClick={e => e.stopPropagation()}
                onChange={event => onChangeTipo(event.target.value)}
            >
                {tiposSubObjetivo.map(option => (
                    <MenuItem key={option.tiposubobj} value={option.tiposubobj}>
                        {option.nombre}
                    </MenuItem>
                ))}
            </TextField>
        </Grid>
        {codigosSubObjetivo[tipo] && (
            <Grid item>
                <TextField
                    select
                    fullWidth
                    className={'mb-2'}
                    label={"Código"}
                    value={codigo}
                    onClick={e => e.stopPropagation()}
                    onChange={event => onChangeCodigo(event.target.value)}
                >
                    {codigosSubObjetivo[tipo].map(option => (
                        <MenuItem key={option.codigo} value={option.codigo}>
                            {option.detalle}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
        )}
        <Grid container item justifyContent={"space-between"}>
            <Grid item>
                <TextField
                    label={"Valor"}
                    value={valor}
                    onChange={event => onChangeValor(event.target.value)}
                    InputProps={{
                        inputComponent: unidad === 'IMP' ? InputPrice : InputInteger,
                        style: {
                            width: '7rem',
                            marginRight: '10px'
                        },
                    }}
                />
                <TextField
                    select
                    label={"Unidad"}
                    value={unidad}
                    onChange={event => onChangeUnidad(event.target.value)}
                >
                    {_UNIDADES.map(option => (
                        <MenuItem key={option.id} value={option.id}>
                            {option.description}
                        </MenuItem>
                    ))}
                </TextField>
            </Grid>
            <Grid item>
                <TextField
                    label={"Peso"}
                    value={peso}
                    variant={"outlined"}
                    InputProps={{
                        inputComponent: InputPercentage,
                        style: {
                            width: '6rem',
                        },
                    }}
                    onChange={event => onChangePeso(event.target.value)}
                />
            </Grid>
        </Grid>
    </Grid>

export const AddSubObjetivoDialog = ({open, tipoobj, tiposSubObjetivo, codigosSubObjetivo, onConfirm, onCancel}
) => {
    const [tipo, setTipo] = useState('');
    const [codigo, setCodigo] = useState('');
    const [valor, setValor] = useState(0);
    const [unidad, setUnidad] = useState(_UNIDADES[0].id);
    const [peso, setPeso] = useState(50);

    return (
        <SimpleDialog
            open={open}
            maxWidth={'xs'}
            fullWidth
            title={'Agregar Subobjetivo'}
            body={
                <AddSubObjetivoDialogBody
                    tipo={tipo}
                    codigo={codigo}
                    valor={valor}
                    unidad={unidad}
                    peso={peso}
                    onChangeTipo={setTipo}
                    onChangeCodigo={setCodigo}
                    onChangeValor={setValor}
                    onChangeUnidad={setUnidad}
                    onChangePeso={setPeso}
                    tipoobj={tipoobj}
                    tiposSubObjetivo={tiposSubObjetivo}
                    codigosSubObjetivo={codigosSubObjetivo}
                />
            }
            onConfirm={() => onConfirm(tipo, codigo, valor, unidad, peso)}
            onCancel={onCancel}
        />
    )
}
