import React from "react";
import {Redirect, Route} from 'react-router-dom';
import AuthService from 'services/auth.service';

export const PrivateRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={(props) => (
        AuthService.isLogged()
            ? <Component {...props} />
            : <Redirect to={{
                pathname: '/login',
                state: {from: props.location}
            }}/>
    )}/>
);

export const LoginRoute = ({component: Component, ...rest}) => (
    <Route {...rest} render={(props) => (
        AuthService.isLogged()
            ? <Redirect to={{
                pathname: './',
                state: {from: props.location}
            }}/>
            : <Component {...props} />
    )}/>
);