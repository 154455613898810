import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogTitle} from "@material-ui/core/index";
import PropTypes from "prop-types";

export class PDFDialog extends Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onCancel}
                fullScreen={this.props.fullScreen}
                maxWidth={"lg"}
                fullWidth
                classes={{
                    paperFullScreen: 'niquel-dialog-full-screen'
                }}
            >
                <PDFDialogContent {...this.props}/>
            </Dialog>
        )
    }
}

class PDFDialogContent extends Component {
    render() {
        const {onClose, pdfName, pdfUrl} = this.props;

        return (
            <React.Fragment>
                <DialogTitle id="alert-dialog-title">{pdfName}</DialogTitle>
                {/*<DialogContent>*/}
                <embed src={`${pdfUrl}#toolbar=0&navpanes=0&scrollbar=0`} width={'100%'} height={'10000px'} type={'application/pdf'}/>
                {/*</DialogContent>*/}
                <DialogActions>
                    <Button onClick={onClose} color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </React.Fragment>
        )
    };
}

PDFDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    pdfName: PropTypes.string.isRequired
};