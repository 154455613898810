import React from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {TableContainer} from "@material-ui/core";
import TableFooter from "@material-ui/core/TableFooter";

export const ClientesComprasTable = React.memo(function ({data, height}) {
    return (
        <>
            {data ? (
                <TableContainer style={{height: height}}>
                    <Table size="small" stickyHeader className={'MuiTable-stickyFooter'}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">ID</TableCell>
                                <TableCell align="left">Razsoc</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell align="left" style={{wordBreak: 'break-all'}}>{row.id}</TableCell>
                                    <TableCell align="left">{row.detalle}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : ''}
        </>
    )
})
