import React, {Component} from 'react'
import Header from "./content/header";

export class HeaderContainer extends Component {
    render() {
        return (
            <Header/>
        )
    }
}
