import React, {Component} from 'react'
import {Box, Grid, MenuItem, TextField} from "@material-ui/core/index";
import {EmailService, ListadosService} from "services";
import {withSnackbar} from "notistack";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import 'moment/locale/es';
import {MainContext} from "contexts/main.context";
import SubHeader from "../../helpers/subHeader/subHeader";
import {MailDialog} from "components/helpers/dialogs/mail.dialog";
import SpeedDial from "@material-ui/lab/SpeedDial/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction/SpeedDialAction";
import DownloadIcon from "@material-ui/icons/GetAppRounded";
import ShowIcon from "@material-ui/icons/DesktopWindowsRounded";
import PrintIcon from "@material-ui/icons/PrintRounded";
import EmailIcon from "@material-ui/icons/EmailRounded";
import printJS from 'print-js'
import {PDFDialog} from "components/helpers/dialogs/pdf.dialog";
import AscIcon from "@material-ui/icons/ArrowDownward";
import DescIcon from "@material-ui/icons/ArrowUpward";
import {FileCopyRounded} from "@material-ui/icons";

export class NotasCreditoAtrasadas extends Component {
    state = {
        fechaInicio: (new MomentUtils()).date(new Date()).subtract(30, 'days'),
        fechaFin: (new MomentUtils()).date(new Date()),
        ordenar: {
            field: 'fecha_nota',
            direction: 'desc'
        },
        type: 'PDF',
        pdfBlob: null,
        pdfUrl: '',
        mailDialogOpen: false,
        pdfDialogOpen: false,
        dialOpen: false,
    };

    ordenar_field = [
        {
            id: 'fecha_nota',
            description: 'Fecha Nota de Crédito'
        },
        {
            id: 'fecha_fac',
            description: 'Fecha Factura'
        }
    ];

    ordenar_direction = [
        {
            id: 'asc',
            description: <AscIcon fontSize={"small"}/>
        },
        {
            id: 'desc',
            description: <DescIcon fontSize={"small"}/>
        }
    ];

    types = [
        {
            id: 'PDF',
            description: "PDF"
        },
        {
            id: 'XLS',
            description: "XLS"
        }
    ];

    ListadosService = new ListadosService(this.context);
    EmailService = new EmailService(this.context);

    onChangeSelection = (field, value) => this.setState({[field]: value});
    onChangeOrdenar = (field, value) => this.setState(prevState => prevState.ordenar[field] = value);
    onChangeType = (value) => this.setState(prevState => prevState.type = value);

    getFile = (fnSuccess = () => {
    }) => {
        this.ListadosService.notasCreditoAtrasadas(
            this.state.type,
            this.state.fechaInicio.format('DD/MM/YY'),
            this.state.fechaFin.format('DD/MM/YY'),
            this.state.ordenar,
            file => this.setState({pdfBlob: file}, fnSuccess),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    };

    // Show PDF
    onOpenPdfDialog = () => this.setState({pdfDialogOpen: true});
    onClosePdfDialog = () => this.setState({pdfDialogOpen: false});

    onShowFile = () => {
        this.getFile(
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                this.setState({pdfUrl: fileURL}, this.onOpenPdfDialog);
            }
        );
    };

    // Print PDF
    onPrintFile = () => {
        this.getFile(
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                printJS(fileURL);
            }
        );
    };

    // Download
    onDownloadFile = () => {
        this.getFile(
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = fileURL;
                a.download = `Notas crédito atrasadas`;
                a.click();
                window.URL.revokeObjectURL(fileURL);
                this.props.enqueueSnackbar('El archivo se descargó correctamente. Revise su carpeta de descargas.', {variant: 'success'});
            }
        );
    };

    // Email
    onOpenMailDialog = () => this.setState({mailDialogOpen: true});

    dialActions = [
        {icon: <EmailIcon/>, name: 'Enviar por Email', action: this.onOpenMailDialog},
        {icon: <PrintIcon/>, name: 'Imprimir', action: this.onPrintFile, onlypdf: true},
        {icon: <DownloadIcon/>, name: 'Descargar', action: this.onDownloadFile},
        {icon: <ShowIcon/>, name: 'Ver en Pantalla', action: this.onShowFile, onlypdf: true},
    ];

    onCloseMailDialog = () => this.setState({mailDialogOpen: false});

    sendMail = mailData => {
        let sendMail = () => {
            // Paso los parámetros a un formData para poder enviar el blob (attachment)
            var formData = new FormData();
            formData.append('attachment', this.state.pdfBlob);
            formData.append('data', JSON.stringify(mailData));

            this.EmailService.send(
                formData,
                response => {
                    this.onCloseMailDialog();
                    this.props.enqueueSnackbar('El email se envió correctamente', {variant: 'success'});
                },
                error => this.props.enqueueSnackbar(error, {variant: 'error'})
            );
        };

        if (mailData.attachFile)
            this.getFile(() => sendMail());
        else
            sendMail();
    };

    onDialOpen = () => this.setState({dialOpen: true});
    onDialClose = () => this.setState({dialOpen: false});

    render() {
        const {fechaInicio, fechaFin, ordenar, type, mailDialogOpen, pdfDialogOpen, pdfUrl, dialOpen} = this.state;
        return (
            <React.Fragment>
                <Box className='niquel-listados-notasCredito'>
                    <SubHeader
                        title={'Notas de Crédito Atrasadas'}
                    />
                    <Box className='container-xl mt-3 mb-3'>
                        <Grid container spacing={2}>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DatePicker
                                        autoOk
                                        style={{width: '7rem'}}
                                        label="Facturas desde"
                                        format="DD/MM/Y"
                                        value={fechaInicio}
                                        onChange={moment => this.onChangeSelection('fechaInicio', moment)}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item>
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <DatePicker
                                        autoOk
                                        style={{width: '7rem'}}
                                        label="Facturas hasta"
                                        format="DD/MM/Y"
                                        value={fechaFin}
                                        onChange={moment => this.onChangeSelection('fechaFin', moment)}
                                    />
                                </MuiPickersUtilsProvider>
                            </Grid>
                            <Grid item className={'d-flex align-items-end'}>
                                <TextField
                                    select
                                    label="Ordenar"
                                    value={ordenar.field}
                                    onChange={event => this.onChangeOrdenar('field', event.target.value)}
                                >
                                    {this.ordenar_field.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.description}
                                        </MenuItem>
                                    ))}
                                </TextField>
                                <TextField
                                    select
                                    className={'ml-1'}
                                    value={ordenar.direction}
                                    onChange={event => this.onChangeOrdenar('direction', event.target.value)}
                                >
                                    {this.ordenar_direction.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.description}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item>
                                <TextField
                                    select
                                    label="Documento"
                                    value={type}
                                    onChange={event => this.onChangeType(event.target.value)}
                                >
                                    {this.types.map(option => (
                                        <MenuItem key={option.id} value={option.id}>
                                            {option.description}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box className='niquel-fab-btn'>
                        <SpeedDial
                            ariaLabel="Notas_credito"
                            icon={<FileCopyRounded/>}
                            onBlur={this.onDialClose}
                            onClick={this.onDialOpen}
                            onClose={this.onDialClose}
                            onFocus={this.onDialOpen}
                            onMouseEnter={this.onDialOpen}
                            onMouseLeave={this.onDialClose}
                            open={dialOpen}
                        >
                            {this.dialActions.map(action => (
                                this.state.type !== 'PDF' && action.onlypdf ? '' :
                                    <SpeedDialAction
                                        key={action.name}
                                        icon={action.icon}
                                        tooltipTitle={action.name}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            action.action()
                                        }}
                                    />
                            ))}
                        </SpeedDial>
                    </Box>
                </Box>
                <MailDialog
                    open={mailDialogOpen}
                    onConfirm={this.sendMail}
                    onCancel={this.onCloseMailDialog}
                    fileName={`notas_credito_atrasadas.${type.toLowerCase()}`}
                    attachFile={true}
                    // to={cliente.email}
                    subject={`Notas de Crédito Atrasadas`}
                    body={`Estimado,  \n\nSe adjunta el listado de notas de crédito atrasadas.`}
                />
                <PDFDialog
                    open={pdfDialogOpen}
                    onClose={this.onClosePdfDialog}
                    pdfUrl={pdfUrl}
                    pdfName={`notas_credito_atrasadas.${type.toLowerCase()}`}
                />
            </React.Fragment>
        )
    }
}

NotasCreditoAtrasadas.contextType = MainContext;
NotasCreditoAtrasadas = withSnackbar(NotasCreditoAtrasadas);
