import Card from "@material-ui/core/Card";
import {ButtonBase, Divider, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import NavIcon from "@material-ui/icons/ArrowForwardIosRounded";
import React from "react";
import CardContent from "@material-ui/core/CardContent";

export function CardEntrega(props) {
    const {id, fecha, vehiculo, litros, cant_pedidos, pedidos_completados, salida, onClick} = props;
    return (
        <>
            <Card className={'m-0 p-0'} elevation={0}>
                <ButtonBase style={{width: '100%', display: 'block', textAlign: 'left'}} onClick={onClick}>
                    <CardContent>
                        <Grid container alignItems={"center"}>
                            <Grid item xs={11}>
                                <Typography color="textSecondary" gutterBottom>
                                    Entrega {id}
                                </Typography>
                                <Typography variant="h6" component="h2">
                                    {fecha}
                                </Typography>
                                <Typography color="textSecondary">
                                    {vehiculo} ({litros} lts)
                                </Typography>
                                <Typography variant={"caption"} component={"div"} className={'mt-3'} color="textSecondary">
                                    {pedidos_completados} de {cant_pedidos} pedidos completados
                                </Typography>
                                <Typography variant="body2" component="p" color={"primary"}>
                                    <b>Salida: {salida} hs</b>
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <NavIcon color={"primary"}/>
                            </Grid>
                        </Grid>
                    </CardContent>
                </ButtonBase>
            </Card>
            <Divider/>
        </>
    );
}
