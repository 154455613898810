import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography} from "@material-ui/core";

export class DeleteDialog extends Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onCancel}
                fullScreen={this.props.fullScreen}
                classes={{
                    paperFullScreen: 'niquel-dialog-full-screen'
                }}
            >
                <DeleteDialogContent {...this.props}/>
            </Dialog>
        )
    }
}

class DeleteDialogContent extends Component {
    render() {
        const {cliente, id, onConfirm, onCancel} = this.props;

        return (
            <React.Fragment>
                <DialogTitle id="alert-dialog-title">¿Seguro quiere eliminar esta cobranza?</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" component={'div'}>
                        <Typography variant={"body2"}>
                            Estás por eliminar la cobranza transitoria número <b>{id}</b> del cliente <b>{cliente.razsoc}</b>
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="primary">
                        Cancelar
                    </Button>
                    <Button onClick={onConfirm} className={'color-error hover-error'} autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </React.Fragment>
        )
    }
}
