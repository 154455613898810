import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, IconButton, Tooltip, Typography} from "@material-ui/core";
import {InfoRounded, LocalShippingRounded} from "@material-ui/icons";
import {calcImporteByArticulo, formatPrice, InputInteger, PedidoStatusFormatter, sortNumeric} from "assets/utils";
import {ReactGrid} from "components/helpers/reactGrid/reactGrid";
import {MainContext} from "contexts/main.context";
import {withSnackbar} from "notistack";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import {PedidosService} from "services";
import TextField from "@material-ui/core/TextField";

export function InfoButton(props) {
    const [popupOpen, setPopupOpen] = React.useState(false);

    return (
        <>
            <Tooltip title={'Ver detalles'}>
                <IconButton onClick={(e) => {
                    e.stopPropagation();
                    setPopupOpen(!popupOpen);
                }}>
                    <InfoRounded fontSize={"small"}/>
                </IconButton>
            </Tooltip>
            <PedidoDetailsDialog
                open={popupOpen}
                onClose={() => setPopupOpen(false)}
                // fullScreen={true}
                row={props.row}
                viewType={'details'}
            />
        </>
    )
}

export function EditDispatchedQuantityButton(props) {
    const [popupOpen, setPopupOpen] = React.useState(false);

    const {row: pedido} = props;

    return (
        <>
            <Tooltip title={'Editar cantidades a despachar'}>
                <span>
                    <IconButton
                        disabled={!(pedido.estadio === 'N' || pedido.estadio === 'F' && pedido.estado === false)}
                        onClick={(e) => {
                            e.stopPropagation();
                            setPopupOpen(!popupOpen);
                        }}
                    >
                        <LocalShippingRounded fontSize={"small"}/>
                    </IconButton>
                </span>
            </Tooltip>
            <PedidoDetailsDialog
                open={popupOpen}
                onClose={() => setPopupOpen(false)}
                // fullScreen={true}
                row={props.row}
                viewType={'dispatch'}
            />
        </>
    )
}

export class PedidoDetailsDialog extends Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                fullScreen={this.props.fullScreen}
                // TransitionComponent={this.props.TransitionComponent}
                transitionDuration={200}
                maxWidth={"lg"}
                fullWidth={true}
                classes={{
                    paperFullScreen: 'niquel-dialog-full-screen'
                }}
            >
                <PedidoDetailsDialogContent {...this.props}/>
            </Dialog>
        )
    }
}

class PedidoDetailsDialogContent extends Component {
    constructor(props) {
        super(props);

        const isViewTypeDispatch = props.viewType === 'dispatch';

        this.state = {
            pedido: {},
            table: {
                columns: [
                    {name: 'id', title: 'Artículo'},
                    {name: 'detalle', title: 'Detalle'},
                    {name: 'cantidad', title: 'Pedido'},
                    {name: 'pendfac', title: 'Pendiente'},
                    {name: 'afacturar', title: 'A Despachar', visible: !isViewTypeDispatch},
                    {name: 'precio', title: 'Precio uni', visible: !isViewTypeDispatch},
                    {name: 'bonificacion', title: 'Bonif. (%)', visible: !isViewTypeDispatch},
                    {name: 'custom1', title: 'Total total', visible: !isViewTypeDispatch},
                    {name: 'custom2', title: 'A despachar', visible: isViewTypeDispatch}
                ],
                columnExtensions: [
                    {columnName: 'id', compare: sortNumeric, direction: 'desc'},
                    {columnName: 'detalle', width: 390},
                    {columnName: 'cantidad', compare: sortNumeric},
                    {columnName: 'pendiente', compare: sortNumeric},
                    {columnName: 'afacturar', compare: sortNumeric},
                    {columnName: 'precio', compare: sortNumeric, type: 'currency'},
                    {columnName: 'bonificacion', compare: sortNumeric},
                    {columnName: 'custom1', component: this.Importe},
                    {columnName: 'custom2', component: this.InputADespachar.bind(this)},
                ],
                rowExtensions: {
                    color: articulo => articulo.pendfac <= 0 ? '#00945d38' : ''
                },
                data: [],
                selected: [],
                all: true
            }
        }
    }

    PedidosService = new PedidosService(this.context);

    Importe(props) {
        const {row: articulo} = props;
        return (
            formatPrice(calcImporteByArticulo(articulo.cantidad, articulo.precio, articulo.bonificacion), true)
        );
    }

    InputADespachar(props) {
        const {row: articulo} = props;

        return (
            <TextField
                variant={"standard"}
                value={articulo.afacturar}
                onChange={event => this.changeADespachar(articulo.numrenglon, event.target.value)}
                error={(parseFloat(articulo.afacturar) < 0) || (parseFloat(articulo.afacturar) > parseFloat(articulo.pendfac))}
                InputProps={{
                    inputComponent: InputInteger
                }}
            />
        );
    }

    changeADespachar(numrenglon, cantidadADespachar) {

        this.setState(prevState => {
            let articulos = prevState.table.data;
            articulos.forEach(articulo => {
                if (articulo.numrenglon === numrenglon) {
                    articulo.afacturar = cantidadADespachar;
                    articulo.adescargar = cantidadADespachar;
                }
            });
            prevState.data = articulos;
            return prevState;
        });
    }

    validarCantidadesADespachar = () => {
        const articulos = this.state.table.data;
        for (var i in articulos) {
            const articulo = articulos[i];
            if (parseFloat(articulo.afacturar) > parseFloat(articulo.pendfac)) {
                this.props.enqueueSnackbar("Las cantidades a despachar no pueden ser mayores que la cantidad pendiente.", {variant: 'error'})
                return false;
            }

            if (parseFloat(articulo.afacturar) < 0) {
                this.props.enqueueSnackbar("Las cantidades a despachar no pueden ser negativas.", {variant: 'error'})
                return false;
            }
        }
        return true;
    }

    confirmarCantidadesADespachar = () => new Promise((resolve, reject) => {
        if (!this.validarCantidadesADespachar()) {
            reject();
            return false;
        }

        const renglones = this.state.table.data.map(articulo => ({
            numrenglon: articulo.numrenglon,
            adespachar: articulo.afacturar
        }));

        this.PedidosService.changeADespachar(
            this.props.row.id,
            renglones,
            response => this.setState(prevState => prevState.table.data = response.data.result, () => resolve()),
            error => {
                this.props.enqueueSnackbar(error, {variant: 'error'});
                reject();
            }
        );
    });

    componentDidMount() {
        this.PedidosService.getById(
            this.props.row.id,
            response => this.setState({pedido: response.data.result}, () => {
                this.PedidosService.getArticulosById(
                    this.props.row.id,
                    response => this.setState(prevState => prevState.table.data = response.data.result),
                    error => this.props.enqueueSnackbar(error, {variant: 'error'})
                );
            }),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    onSelect = (field, selected) => this.setState(prevState => (prevState[field].selected = selected));

    render() {
        const {onClose, viewType} = this.props;
        const {pedido, table} = this.state;

        const isViewTypeDispatch = viewType === 'dispatch';

        // Viewport Height
        const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
        const tableHeight = viewportHeight - 150;

        return (
            <React.Fragment>
                <DialogTitle id="alert-dialog-title">
                    Detalles <Typography className={'ml-2'} variant={"body1"} component={"span"} color={"textSecondary"}>del pedido {pedido.id}</Typography>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={3}>
                        <Grid item>
                            <Typography variant={"caption"} color={"textSecondary"} component={"div"}>Estado</Typography>
                            <PedidoStatusFormatter row={pedido}/>
                        </Grid>
                        <Grid item>
                            <Typography variant={"caption"} color={"textSecondary"}>Bonificación</Typography>
                            <Typography variant={"body1"}>{pedido.bonificacion}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={"caption"} color={"textSecondary"}>Lista de Precio</Typography>
                            <Typography variant={"body1"}>{pedido.listaprecio}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={"caption"} color={"textSecondary"}>Usuario</Typography>
                            <Typography variant={"body1"}>{pedido.usuario}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={"caption"} color={"textSecondary"}>Vendedor</Typography>
                            <Typography variant={"body1"}>{pedido.vendedor}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={"caption"} color={"textSecondary"}>Ingreso</Typography>
                            <Typography variant={"body1"}>{pedido.ingreso}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={"caption"} color={"textSecondary"}>Entrega</Typography>
                            <Typography variant={"body1"}>{pedido.entrega}</Typography>
                        </Grid>
                    </Grid>
                    <ReactGrid
                        elevation={0}
                        enableVirtualMode={false}
                        height={tableHeight}
                        columns={table.columns}
                        columnExtensions={table.columnExtensions}
                        rowExtensions={table.rowExtensions}
                        getRowId={row => row.id}
                        rows={table.data}
                        title={`Artículos del Pedido`}
                        showResultCount={true}
                        enableFiltering={false}
                        enableSorting={true}
                        enableSearch={false}
                        enableSelection={false}
                        selectedRows={table.selected}
                        onSelectionChange={selected => this.onSelect('table', selected)}
                        onDelete={selected => this.onSelect('table', [])}
                        showSelectAll={true}
                    />
                </DialogContent>
                <DialogActions>
                    {/*Muestro Close*/}
                    {onClose && (
                        <Button onClick={(e) => {
                            e.stopPropagation();
                            onClose()
                        }} color={isViewTypeDispatch ? "" : "primary"}>
                            {isViewTypeDispatch ? 'Cancelar' : 'Cerrar'}
                        </Button>
                    )}
                    {/*Muestro Close*/}
                    {isViewTypeDispatch && (
                        <Button onClick={(e) => {
                            e.stopPropagation();
                            this.confirmarCantidadesADespachar().then(() => {
                                onClose();
                            });
                        }}
                                color={"primary"}
                        >
                            Guardar
                        </Button>
                    )}
                </DialogActions>
            </React.Fragment>
        )
    }
}

PedidoDetailsDialogContent.contextType = MainContext;
PedidoDetailsDialogContent = withSnackbar(PedidoDetailsDialogContent);
