import React, {Component} from 'react';
import {UtilsService} from 'services';
import {ItemList} from "./item";
import MomentUtils from "@date-io/moment";
import {Box, Grid, IconButton, MenuItem, TextField, Tooltip} from "@material-ui/core";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {MainContext} from "contexts/main.context";
import {withSnackbar} from "notistack";
import {RefreshRounded} from "@material-ui/icons";
import {InfoDialog} from "./dialogs/info.dialog";
import {TIPOS} from "./tipos";

export class Recibidos extends Component {
    state = {
        items: null,
        fechaDesde: (new MomentUtils()).date(new Date()).subtract(2, "month"),
        fechaHasta: (new MomentUtils()).date(new Date()),
        filter_tipo: 'todo',
        tmp_item: null,
        infoDialogOpen: false,
        tipos: []
    };

    UtilsService = new UtilsService(this.context);

    componentDidMount() {
        this.getRecepciones()
            .then(this.filterTipoRecepcion)
    }

    getRecepciones = () => new Promise((resolve, reject) => {
        this.UtilsService.getRecepcionesCobranza(
            {
                filter: 'RECIBIDOS',
                desde: this.state.fechaDesde.format('DD/MM/YY'),
                hasta: this.state.fechaHasta.format('DD/MM/YY'),
                sortBy: 'fecha',
                sortDir: 'desc'
            },
            response => {
                this.setState({items: response.data.result}, resolve());
            },
            error => {
                this.props.enqueueSnackbar(error, {variant: 'error'});
                reject();
            }
        );
    });

    filterTipoRecepcion = () => new Promise((resolve, reject) => {
        if (this.props.tipoRecepcion === "cotizacion")
            var tipos = TIPOS.filter(tipo => tipo.id === "PREPAGO");
        else
            var tipos = TIPOS.filter(tipo => tipo.id !== "PREPAGO");

        // this.setState(prevState => prevState.filter_tipo = prevState.tipos[0].id);
        this.setState({tipos: tipos, filter_tipo: tipos[0].id}, resolve());
    });

    onUpdateItem = (item, anular = true, updateMessage = "") => {
        // Anulo la recepción
        item.anular = anular;
        item.updateMessage = updateMessage;

        this.UtilsService.updateRecepcionesCobranza(
            item,
            response => {
                this.setState(prevState => {
                    item.recibido = !anular;
                    return prevState;
                });
                this.props.enqueueSnackbar(`El ítem se marcó como ${anular ? 'NO' : ''} RECIBIDO`, {variant: 'success'})
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        )
    }

    onChangeDate = (field, value) => this.setState({[field]: value}, this.getRecepciones);
    onChangeFilter = (field, value) => this.setState({[field]: value});

    onInfoPress = item => this.setState({tmp_item: item, infoDialogOpen: true})
    closeInfoDialog = () => this.setState({infoDialogOpen: false});

    filterItems = (items, tipo) => {
        if (items) {
            const tipos = (tipo !== 'todo') ? [tipo] : this.state.tipos.map(tipo => tipo.id);
            items = items.filter(item => tipos.includes(item.tipo));
        }

        return items;
    }

    render() {
        const {items, fechaDesde, fechaHasta, tmp_item, filter_tipo, infoDialogOpen} = this.state;
        let filteredItems = this.filterItems(items, filter_tipo);

        return (
            <Box className='container-xl mt-3 mb-3'>
                <Grid container spacing={2} alignItems={"center"} justifyContent={"space-between"}>
                    <Grid container item spacing={2} xs={10}>
                        <Grid item>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                    autoOk
                                    className={'niquel-date-input'}
                                    label="Desde"
                                    format="DD/MM/Y"
                                    value={fechaDesde}
                                    onChange={moment => this.onChangeDate('fechaDesde', moment)}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                    autoOk
                                    className={'niquel-date-input'}
                                    label="Hasta"
                                    format="DD/MM/Y"
                                    value={fechaHasta}
                                    onChange={moment => this.onChangeDate('fechaHasta', moment)}
                                />
                            </MuiPickersUtilsProvider>
                        </Grid>
                        <Grid item>
                            <TextField
                                select
                                label="Tipo"
                                className='mr-2'
                                value={filter_tipo}
                                onChange={event => this.onChangeFilter('filter_tipo', event.target.value)}
                            >
                                {this.state.tipos.map(tipo => (
                                    <MenuItem key={tipo.id} value={tipo.id}>
                                        {tipo.name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Tooltip title={"Refrescar"}>
                            <IconButton onClick={this.getRecepciones}>
                                <RefreshRounded/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Box className={'mt-3'}>
                    <ItemList
                        items={filteredItems}
                        type={'RECIBIDO'}
                        onPrimaryAction={(item, index) => this.onUpdateItem(item, item.recibido)}
                        onInfoPress={this.onInfoPress}
                    />
                </Box>
                <InfoDialog
                    open={infoDialogOpen}
                    item={tmp_item}
                    onClose={this.closeInfoDialog}
                />
            </Box>
        )
    }
}

Recibidos.contextType = MainContext;
Recibidos = withSnackbar(Recibidos);
