import React, {useCallback, useEffect, useState} from "react";
import {Box, Card, Dialog, DialogContent, Fab, IconButton, Tooltip, Typography} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/CloseRounded";
import CardContent from "@material-ui/core/CardContent";
import {CloseRounded, DeleteRounded, SettingsRounded, VisibilityRounded, WarningRounded} from "@material-ui/icons";
import './clientes.map.dialog.scss'
import {SimpleDialog} from "components/helpers/dialogs/simple.dialog";
import {MapSettingsDialog} from "components/helpers/map/map.settings.dialog";
import {Map} from "components/helpers/map/map";
import {MapDetailsPanel} from "components/helpers/map/map.details.panel";
import Drawer from "@material-ui/core/Drawer";
import Switch from "@material-ui/core/Switch";
import {PedidoStatusFormatter, sortDate, sortNumeric} from "assets/utils";
import {InfoButton} from "components/despacho/hojasDeRuta/operation/pedido.details";
import {ReactGrid} from "components/helpers/reactGrid/reactGrid";

export const ClientesMapDialog = props => {
    return (
        <Dialog
            open={props.open}
            onClose={props.onCancel}
            fullScreen={props.fullScreen}
            // keepMounted
            classes={{
                paperFullScreen: 'niquel-dialog-full-screen'
            }}
        >
            <ClientesMapDialogContent {...props}/>
        </Dialog>
    )
}

const MemoMap = React.memo(({direcciones, onMarkerClick, enableClustering}) => {
    return <Map
        height={'100%'}
        width={'100%'}
        direcciones={[{
            color: 'lightblue',
            data: direcciones
        }]}
        onMarkerClick={onMarkerClick}
        enableClustering={enableClustering}
    />
})

const getDireccionesSinGeolocalizar = (direcciones) => {
    let _direcciones = [];
    direcciones.forEach(direccion => {
        if (!direccion.latitud || !direccion.longitud) {
            direccion.latitud = 0;
            direccion.longitud = 0;
            _direcciones.push(direccion);
        }
    });

    return _direcciones;
}

const clientesSinGeolocalizarTable = {
    columns: [
        {name: 'id', title: 'ID'},
        {name: 'razsoc', title: 'Nombre'},
        {name: 'custom1', title: 'Dirección'},
        {name: 'vendedor', title: 'Vendedor'},
    ],
    columnExtensions: [
        {columnName: 'id', width: 180},
        {columnName: 'razsoc', width: 290},
        {columnName: 'custom1', width: 240, component: props => props.row.direccion_entrega || props.row.direccion},
        {columnName: 'vendedor', width: 180}
    ]
};

const ClientesMapDialogContent = props => {
    const [clientesSinGeolocalizarDialogOpen, setClientesSinGeolocalizarDialogOpen] = useState(false);
    const [showClientesSinGeolocalizar, setShowClientesSinGeolocalizar] = useState(true);
    const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
    const [settings, setSettings] = useState({filterType: 'ninguno', selected: null});
    const [detailsDrawerData, setDetailsDrawerData] = useState(null);
    const [detailsDrawerOpen, setDetailsDrawerOpen] = useState(false);
    const [enableClustering, setEnableClustering] = useState(true);
    const [direcciones, setDirecciones] = useState(props.direcciones);

    const direccionesSinGeolocalizar = getDireccionesSinGeolocalizar(props.direcciones);

    const onConfirmSettingsDialog = filters => {
        setSettings(filters);
        setSettingsDialogOpen(false);
    };

    const filterDirecciones = direcciones => {
        if (settings.filterType === 'nivel') {
            const selected = settings.selected;
            direcciones = direcciones.filter(direccion => direccion.vendedor_nivel === selected);
        }

        if (settings.filterType === 'vendedor') {
            const selected = settings.selected;
            direcciones = direcciones.filter(direccion => selected.indexOf(direccion.vendedor) > -1);
        }

        return direcciones;
    }

    const removeFilters = () => {
        setEnableClustering(true);
        setSettings(prevState => ({...prevState, filterType: 'ninguno'}));
    }

    useEffect(() => {
        // Hay un bug en el mapa a la hora de renderizar un subconjunto de los elementos actualmente renderizados
        // (probablemente se memoizen internamente).
        // Por esa razón hay que hacer este hack: primero se setean las direcciones vacías y luego se establece la selección
        // del usuario según el filtro.
        // Si el clustering NO está activado no hay bug.
        const _enableClustering = enableClustering;

        if (enableClustering) {
            setEnableClustering(false);
            setDirecciones([]);
        }

        setTimeout(() => {
            setDirecciones(filterDirecciones(props.direcciones));
            setEnableClustering(_enableClustering);
        }, 0);
    }, [settings]);

    const onMarkerClick = useCallback((direccion) => {
        setDetailsDrawerData(direccion);
        setDetailsDrawerOpen(true);
    }, []);

    return (
        <React.Fragment>
            <DialogContent className={'pl-0 pr-0 pb-0 pt-0'}>
                <MemoMap
                    direcciones={direcciones}
                    onMarkerClick={onMarkerClick}
                    enableClustering={enableClustering}
                    withPedidos={false}
                />
            </DialogContent>

            <Drawer
                anchor={'left'}
                open={detailsDrawerOpen}
                onClose={(e) => setDetailsDrawerOpen(false)}
                BackdropProps={{invisible: true}}
            >
                <MapDetailsPanel
                    direccion={detailsDrawerData}
                    withPedidos={false}
                    // onAddPedido={this.onAddPedido}
                    // onRemovePedido={this.onRemovePedido}
                />
            </Drawer>

            <Box className={'niquel-pedidosMap-notifications'}>
                {settings.filterType !== 'ninguno' && (
                    <Card className={'d-inline-block ml-2 mr-2'}>
                        <CardContent className={'p-2 d-flex align-items-center'}>
                            <WarningRounded fontSize={"small"} color={"secondary"} className={'mr-1'}/>
                            <Typography variant={"caption"} component={"span"}>Filtros aplicados</Typography>
                            <Tooltip title={'Quitar filtros'}>
                                <IconButton onClick={() => removeFilters()} className={'ml-1'}>
                                    <DeleteRounded fontSize={"small"}/>
                                </IconButton>
                            </Tooltip>
                        </CardContent>
                    </Card>
                )}

                {showClientesSinGeolocalizar && direccionesSinGeolocalizar.length > 0 && (
                    <Card className={'d-inline-block ml-2 mr-2'}>
                        <CardContent className={'p-2 d-flex align-items-center'}>
                            <WarningRounded fontSize={"small"} color={"secondary"} className={'mr-1'}/>
                            <Typography variant={"caption"} component={"span"}>Hay clientes sin geolocalizar</Typography>
                            <Tooltip title={'Ver clientes sin geolocalizar'}>
                                <IconButton onClick={() => setClientesSinGeolocalizarDialogOpen(true)} className={'ml-1'}>
                                    <VisibilityRounded fontSize={"small"}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={'Cerrar'}>
                                <IconButton onClick={() => setShowClientesSinGeolocalizar(false)}>
                                    <CloseRounded fontSize={"small"}/>
                                </IconButton>
                            </Tooltip>
                        </CardContent>
                    </Card>
                )}
            </Box>

            {settings.filterType !== 'ninguno' && (
                <Box className={'niquel-pedidosMap-group'}>
                    <Card className={'d-inline-block ml-2 mr-2'}>
                        <CardContent className={'p-2 d-flex align-items-center'}>
                            <Typography variant={"caption"} component={"span"}>Agrupar direcciones</Typography>
                            <Switch checked={enableClustering} onChange={() => setEnableClustering(!enableClustering)}/>
                        </CardContent>
                    </Card>
                </Box>
            )}

            <Tooltip title={'Opciones del mapa'}>
                <Box className='niquel-pedidosMap-opciones'>
                    <Fab onClick={() => setSettingsDialogOpen(true)} size={"medium"} style={{backgroundColor: 'rgb(210 210 210)'}}>
                        <SettingsRounded/>
                    </Fab>
                </Box>
            </Tooltip>

            <Tooltip title={'Cerrar'}>
                <Box className='niquel-fab-btn'>
                    <Fab onClick={props.onCancel} size={"medium"} style={{backgroundColor: 'rgb(247 247 247)'}}>
                        <CloseIcon/>
                    </Fab>
                </Box>
            </Tooltip>

            <SimpleDialog
                title={'Clientes sin geolocalizar'}
                open={clientesSinGeolocalizarDialogOpen}
                fullWidth
                maxWidth={'md'}
                body={
                    // direccionesSinGeolocalizar.map((direccion, index) =>
                    //     <Typography key={index} variant={"body2"}>
                    //         Cliente: {direccion.razsoc} ({direccion.id}) - Dirección: {direccion.direccion_entrega || direccion.direccion}
                    //     </Typography>
                    // )
                    <ReactGrid
                        enableVirtualMode={false}
                        columns={clientesSinGeolocalizarTable.columns}
                        columnExtensions={clientesSinGeolocalizarTable.columnExtensions}
                        getRowId={row => row.id}
                        rows={direccionesSinGeolocalizar}
                        title={`Clientes`}
                        elevation={0}
                        showResultCount={true}
                        enableFiltering={false}
                        enableSorting={false}
                        enableSearch={false}
                        enableSelection={false}
                        enableGrouping={false}
                        enableSummarizing={false}
                        showAddButton={false}
                        showSelectAll={false}
                    />
                }
                onCancel={() => setClientesSinGeolocalizarDialogOpen(false)}
            />

            <MapSettingsDialog
                title={'Opciones del mapa'}
                open={settingsDialogOpen}
                keepMounted={true}
                fullWidth={true}
                onConfirm={settings => onConfirmSettingsDialog(settings)}
                onCancel={() => setSettingsDialogOpen(false)}
            />
        </React.Fragment>
    )
}
