import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, IconButton, Tooltip} from "@material-ui/core";
import {ReactGrid} from "components/helpers/reactGrid/reactGrid";
import {sortDate, sortNumeric} from "assets/utils";
import {MainContext} from "contexts/main.context";
import {withSnackbar} from "notistack";
import {CobranzasService} from "services";
import MomentUtils from "@date-io/moment";
import DeleteIcon from "@material-ui/icons/DeleteRounded";

export class AddCobranzasDialog extends Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onCancel}
                fullScreen={this.props.fullScreen}
                // TransitionComponent={this.props.TransitionComponent}
                transitionDuration={200}
                maxWidth={"lg"}
                fullWidth={true}
                classes={{
                    paperFullScreen: 'niquel-dialog-full-screen'
                }}
            >
                <AddCobranzasDialogContent {...this.props}/>
            </Dialog>
        )
    }
}

class AddCobranzasDialogContent extends Component {
    CobranzasService = new CobranzasService(this.context);

    componentDidMount() {
        this.getCobranzas();
    }

    filterCobranzas = (cobranzas) => {
        const excluded = this.props.excluded.map(excl => excl.id);
        return cobranzas.filter(cobranza => !excluded.includes(cobranza.id));
    }

    getCobranzas = () => {
        let vendedores = [];
        if (this.state.vendedor !== 'todos')
            vendedores.push(this.state.vendedor);

        this.CobranzasService.getFiltered({
                desde: this.state.fecha_desde.format('DD/MM/YY'),
                hasta: this.state.fecha_hasta.format('DD/MM/YY'),
                status: 'A'
            },
            response => this.setState(prevState => prevState.cobranzas.data = this.filterCobranzas(response.data.result)),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    }

    state = {
        fecha_desde: (new MomentUtils()).date(new Date()).subtract(365, 'days'),
        fecha_hasta: (new MomentUtils()).date(new Date()),
        vendedor: 'todos',
        cobranzas: {
            columns: [
                {name: 'id', title: 'ID'},
                {name: 'numrec', title: 'Recibo'},
                {name: 'fecharecibo', title: 'Fecha'},
                {name: 'razsoc', title: 'Cliente'},
                {name: 'vendedor', title: 'Vendedor'}
            ],
            columnExtensions: [
                {columnName: 'id', width: 100, compare: sortNumeric, direction: 'desc'},
                {columnName: 'numrec', width: 100, compare: sortNumeric},
                {columnName: 'fecharecibo', width: 150, compare: sortDate},
                {columnName: 'vendedor', width: 250}
            ],
            data: [],
            selected: [],
            all: true
        },
    }

    onSelect = (field, selected) => this.setState(prevState => (prevState[field].selected = selected));
    onConfirm = () => {
        let success = this.props.onConfirm(this.state.cobranzas.selected.map(id => this.state.cobranzas.data.find(cobranza => cobranza.id === id)));
        if (success)
            this.props.onCancel();
    };

    render() {
        const {onConfirm, onCancel} = this.props;
        const {cobranzas} = this.state;

        return (
            <React.Fragment>
                {/*<DialogTitle id="alert-dialog-title">{title}</DialogTitle>*/}
                <DialogContent>
                    <ReactGrid
                        elevation={0}
                        enableVirtualMode={true}
                        columns={cobranzas.columns}
                        columnExtensions={cobranzas.columnExtensions}
                        rowExtensions={cobranzas.rowExtensions}
                        getRowId={row => row.id}
                        rows={cobranzas.data}
                        title={`Cobranzas pendientes de rendir`}
                        showResultCount={true}
                        enableFiltering={true}
                        enableSorting={true}
                        enableSearch={true}
                        enableSelection={true}
                        selectedRows={cobranzas.selected}
                        onSelectionChange={selected => this.onSelect('cobranzas', selected)}
                        onDelete={selected => this.onSelect('cobranzas', [])}
                        showSelectAll={true}
                    />
                </DialogContent>
                <DialogActions>
                    {/*Muestro Confirm*/}
                    {onCancel && (
                        <Button onClick={onCancel} color={"primary"}>
                            Cancelar
                        </Button>
                    )}

                    {/*Muestro Cancel*/}
                    {onConfirm && (
                        <Button onClick={this.onConfirm} color={"primary"} autoFocus>
                            Confirmar
                        </Button>
                    )}
                </DialogActions>
            </React.Fragment>
        )
    }
}

AddCobranzasDialogContent.contextType = MainContext;
AddCobranzasDialogContent = withSnackbar(AddCobranzasDialogContent);
