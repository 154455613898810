import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import classNames from 'classnames';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/EditRounded';
import InfoIcon from '@material-ui/icons/InfoRounded';

import {ArticulosMainTableHead} from "./articulos.main.table.head";
import ArticulosMainTableToolbar from "./articulos.main.table.toolbar";
import ArticulosAddDialog from "./add/articulos.add.dialog";
import ArticulosEditDialog from "./edit/articulos.edit.dialog";
import {withSnackbar} from "notistack";
import {MastersService} from "services";
import {calcImporteByArticulo, formatPrice, formatQuantity} from "assets/utils";
import {MainContext} from "contexts/main.context";
import {isMobile, osName} from "react-device-detect";
import ArticulosAddFastDialog from "components/helpers/articulos/add/articulos.add.fast.dialog";
import ArticuloInfoDialog from "components/helpers/articulos/info/articulo.info.dialog";

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
    return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function getColumns(mode = 'C') {
    const aReturn = [
        {id: 'id', numeric: false, label: 'Artículo'},
        {id: 'detalle', numeric: false, label: 'Detalle'},
    ];

    /*
        FIX 15/07/20 - A pedido de Hugo y Facundo se quitan estos campos para
        ahorrar espacio (dicen que no los usan)
    */
    // if (mode === 'P' || mode === 'F')
    //     aReturn.push({id: 'deposito', numeric: true, label: 'Depósito'});

    aReturn.push({id: 'cantidad', numeric: true, label: 'Cantidad'});

    /*
        FIX 15/07/20 - A pedido de Hugo y Facundo se quitan estos campos para
        ahorrar espacio (dicen que no los usan)
    */
    // if (mode === 'P') {
    //     aReturn.push({id: 'afacturar', numeric: true, label: 'Facturar'});
    //     aReturn.push({id: 'adescargar', numeric: true, label: 'Descargar'});
    // }

    aReturn.push({id: 'precio', numeric: true, label: 'Precio (AR$)'});
    aReturn.push({id: 'bonificacion', numeric: true, label: 'Bonif. (%)'});
    aReturn.push({id: 'importe', numeric: true, label: 'Total (AR$)'});

    return aReturn;
}

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),
    },
    table: {
        //minWidth: 1020,
        '&.editable, &.withInfo': {
            '& tr': {
                cursor: 'pointer',
                '& th': {
                    padding: '2px 5px 2px 5px !important',
                    whiteSpace: "nowrap"
                },
                '& td': {
                    padding: '2px 5px 2px 5px !important',
                    lineHeight: '1.6'
                }
            }
        },
        '&:not(.editable)': {
            '& tr': {
                '& td:first-child': {
                    padding: '16px 9px 16px 15px !important',
                },
                '& td': {
                    padding: '16px 9px 16px 9px !important',
                    lineHeight: '1.6'
                },
                '& td:last-child': {
                    paddingRight: '15px !important',
                },
                '& th:first-child': {
                    padding: '8px 9px 8px 15px !important',
                },
                '& th': {
                    padding: '8px 9px 8px 9px !important',
                    whiteSpace: "nowrap"
                },
                '& th:last-child': {
                    padding: '8px 15px 8px 9px !important',
                },
            }
        }
    },
    tableWrapper: {
        overflowX: 'auto',
    }
});

class ArticulosMainTable extends React.Component {
    state = {
        order: 'asc',
        orderBy: 'calories',
        checkedArticulos: [],
        bAddDialogOpened: false,
        bAddFastDialogOpened: false,
        bEditDialogOpened: false,
        bInfoDialogOpened: false,
        articuloInEdition: null,
        articuloInInfo: null,
        depositos: []
    };

    MastersService = new MastersService(this.context);

    componentDidMount() {
        // FIX 16/07 - No se usan.
        // if (this.props.mode === 'P')
        //     this.MastersService.getDepositos(
        //         response => this.setState({depositos: response.data.result}),
        //         error => this.props.enqueueSnackbar(error, {variant: 'error'})
        //     );
    }

    handleSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc')
            order = 'asc';

        this.setState({order, orderBy});
    };

    handleSelectAll = event => {
        const {selected} = this.props;
        if (event.target.checked)
            this.setState({checkedArticulos: selected.map(articulo => articulo.key)});
        else
            this.setState({checkedArticulos: []});
    };

    handleCheck = (event, key) => {
        const {checkedArticulos} = this.state;
        const selectedIndex = checkedArticulos.indexOf(key);
        let newSelected = [];

        if (selectedIndex === -1)
            newSelected = newSelected.concat(checkedArticulos, key);
        else if (selectedIndex === 0)
            newSelected = newSelected.concat(checkedArticulos.slice(1));
        else if (selectedIndex === checkedArticulos.length - 1)
            newSelected = newSelected.concat(checkedArticulos.slice(0, -1));
        else if (selectedIndex > 0)
            newSelected = newSelected.concat(
                checkedArticulos.slice(0, selectedIndex),
                checkedArticulos.slice(selectedIndex + 1),
            );

        this.setState({checkedArticulos: newSelected});
    };

    onRemove = () => {
        this.props.onRemove(this.state.checkedArticulos);
        this.setState({checkedArticulos: []});
    };

    onOpenAddDialog = () => this.setState({bAddDialogOpened: true});
    onCloseAddDialog = () => this.setState({bAddDialogOpened: false});
    onConfirmAddDialog = (selected, fnSuccess) => {
        selected.forEach(item => {
            // 03/09/19 - Fix para bonificaciones borradas. Las paso a 0 para que no rompa validación en backend.
            if (isNaN(parseInt(item.bonificacion)))
                item.bonificacion = 0;
        });

        this.props.onAdd(selected, () => {
            this.onCloseAddDialog();
            fnSuccess();
        });
    };

    onOpenAddFastDialog = () => this.setState({bAddFastDialogOpened: true});
    onCloseAddFastDialog = () => this.setState({bAddFastDialogOpened: false});
    onConfirmAddFastDialog = articulo => this.props.onAdd([articulo], this.onCloseAddFastDialog);

    onOpenEditDialog = (articuloInEdition) => this.setState({articuloInEdition}, () => this.setState({bEditDialogOpened: true}));
    onCloseEditDialog = () => this.setState({bEditDialogOpened: false});
    onConfirmEditDialog = () => {
        // 03/09/19 - Fix para bonificaciones borradas. Las paso a 0 para que no rompa validación en backend.
        if (isNaN(parseInt(this.state.articuloInEdition.bonificacion))) {
            this.setState(prevState => prevState.articuloInEdition.bonificacion = 0, () => {
                this.props.onEdit(this.state.articuloInEdition, () => this.onCloseEditDialog());
            });
        } else
            this.props.onEdit(this.state.articuloInEdition, () => this.onCloseEditDialog());
    };

    onEdit = articuloInEdition => this.setState({articuloInEdition});
    isSelected = id => this.state.checkedArticulos.indexOf(id) !== -1;

    onOpenInfoDialog = (articuloInInfo) => this.setState({articuloInInfo}, () => this.setState({bInfoDialogOpened: true}));
    onCloseInfoDialog = () => this.setState({bInfoDialogOpened: false});

    setDefaultsArticulo = articulo => {
        articulo.cantidad = (articulo.cantidad) ? articulo.cantidad : 1;
        articulo.precio = parseFloat(articulo[this.props.listaprecio]).toFixed(2);
        articulo.bonificacion = (!isNaN(parseInt(articulo.bonificacion_cliente))) ? articulo.bonificacion_cliente : (articulo.bonificacion) ? articulo.bonificacion : 0;
        articulo.deposito = (articulo.deposito) ? articulo.deposito : 2;
        articulo.afacturar = (articulo.afacturar) ? articulo.afacturar : articulo.cantidad;
        articulo.adescargar = (articulo.adescargar) ? articulo.adescargar : articulo.cantidad;
        articulo.pendfac = (articulo.pendfac) ? articulo.pendfac : 0;
        articulo.penddesc = (articulo.penddesc) ? articulo.penddesc : 0;
        articulo.importe = (articulo.importe) ? articulo.importe : calcImporteByArticulo(articulo.cantidad, articulo.precio, articulo.bonificacion);
        articulo.isValid = (articulo.armado || !isNaN(articulo.importe) && articulo.importe !== 0);
    };

    getImporteUnitarioFinal = articulo => {
        let precioBonificado = articulo.precio * (1 - articulo.bonificacion / 100);
        return precioBonificado * (1 - this.props.bonificacionGeneral / 100)
    };

    render() {
        const {
            classes, articulos, articulosArmados, selected, editable, maxArticulos, listaprecio, bonificacionesLitros, totalArticulos, importeArticulos,
            totalLitros, mode, bonificacionGeneral, cliente
        } = this.props;
        const {
            order, orderBy, checkedArticulos, rowsPerPage, page, bAddDialogOpened, bAddFastDialogOpened, bEditDialogOpened, bInfoDialogOpened,
            articuloInEdition, articuloInInfo, depositos
        } = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, selected.length - page * rowsPerPage);

        return (
            <div>
                <Paper className={classes.root}>
                    <ArticulosMainTableToolbar
                        articulos={selected}
                        numSelected={checkedArticulos.length}
                        editable={editable}
                        totalArticulos={totalArticulos}
                        totalLitros={totalLitros}
                        importeArticulos={importeArticulos}
                        maxArticulos={maxArticulos}
                        onAdd={this.onOpenAddDialog}
                        onAddFast={this.onOpenAddFastDialog}
                        onRemove={this.onRemove}
                    />
                    <div className={classes.tableWrapper} hidden={selected.length < 1}>
                        <Table className={classNames(classes.table, {'editable': editable, 'withInfo': true})} aria-labelledby="tableTitle">
                            <ArticulosMainTableHead
                                columns={getColumns(mode)}
                                numSelected={checkedArticulos.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={this.handleSelectAll}
                                onRequestSort={this.handleSort}
                                rowCount={selected.length}
                                enableEdit={editable}
                            />
                            <TableBody>
                                {stableSort(selected, getSorting(order, orderBy))
                                    .map((articulo, index) => {
                                        const isSelected = this.isSelected(articulo.key);

                                        // Si el articulo está pendiente lo pinto de rojo (solo debería ocurrir en modo visualización).
                                        const pending = articulo.penddesc > 0 || articulo.pendfac > 0;
                                        const infoIconColor = pending ? 'error' : '';

                                        let rowColor = 'white';

                                        if (pending)
                                            rowColor = 'rgba(255, 0, 0, 0.08)';

                                        // Si el artículo pertenece a un armado lo pinto del mismo color que sus compañeros.
                                        if (articulo.fromArmado)
                                            rowColor = `hsl(${articulo.fromArmado % 360}, 65%, 45%, 0.1)`;

                                        articulo.key = index;
                                        return (
                                            <TableRow
                                                hover
                                                role="checkbox"
                                                aria-checked={isSelected}
                                                tabIndex={-1}
                                                key={index}
                                                selected={isSelected}
                                                onClick={event => editable && this.handleCheck(event, articulo.key)}
                                                style={{backgroundColor: rowColor}}
                                            >
                                                {editable && (
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            onChange={event => this.handleCheck(event, articulo.key)}
                                                            checked={isSelected}
                                                        />
                                                    </TableCell>
                                                )}
                                                <TableCell>{articulo.id}</TableCell>
                                                <TableCell>{articulo.detalle}</TableCell>
                                                {/*
                                                    FIX 15/07 - A pedido de Hugo y Facundo se quita este campo para
                                                    ahorrar espacio (dicen que no lo usan)
                                                */}
                                                {/*{(mode === 'P' || mode === 'F') && (*/}
                                                {/*    <TableCell align="right">{formatQuantity(articulo.deposito)}</TableCell>*/}
                                                {/*)}*/}
                                                <TableCell align="right">{formatQuantity(articulo.cantidad)}</TableCell>
                                                {/*
                                                    FIX 15/07 - A pedido de Hugo y Facundo se quitan estos campos para
                                                    ahorrar espacio (dicen que no los usan)
                                                */}
                                                {/*{mode === 'P' && (*/}
                                                {/*    <TableCell align="right">{formatQuantity(articulo.afacturar)}</TableCell>*/}
                                                {/*)}*/}
                                                {/*{mode === 'P' && (*/}
                                                {/*    <TableCell align="right">{formatQuantity(articulo.adescargar)}</TableCell>*/}
                                                {/*)}*/}
                                                <Tooltip
                                                    title={
                                                        formatPrice(this.getImporteUnitarioFinal(articulo), true) + ' (precio unitario final)'
                                                    }
                                                    placement={"top"}
                                                >
                                                    <TableCell align="right">{formatPrice(articulo.precio)}</TableCell>
                                                </Tooltip>
                                                <TableCell align="right">{formatPrice(articulo.bonificacion)}</TableCell>
                                                <TableCell align="right">{formatPrice(articulo.importe)}</TableCell>
                                                {editable && (
                                                    <TableCell className={classes.editCell} align="center">
                                                        <Tooltip title="Editar">
                                                            <IconButton onClick={(e) => {
                                                                e.stopPropagation();
                                                                this.onOpenEditDialog(articulo)
                                                            }}>
                                                                <EditIcon fontSize={"small"}/>
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                )}
                                                <TableCell className={classes.infoCell} align="center">
                                                    <Tooltip title="Info del Artículo">
                                                        <IconButton onClick={(e) => {
                                                            e.stopPropagation();
                                                            this.onOpenInfoDialog(articulo)
                                                        }}>
                                                            <InfoIcon color={infoIconColor} fontSize={"small"}/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{height: 49 * emptyRows}}>
                                        <TableCell colSpan={8}/>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </div>
                </Paper>
                <ArticulosAddDialog
                    articulos={articulos}
                    // articulosArmados={articulosArmados}
                    bonificacionesLitros={bonificacionesLitros}
                    totalLitros={totalLitros}
                    disabledArticulos={selected}
                    listaprecio={listaprecio}
                    mode={mode}
                    depositos={depositos}
                    open={bAddDialogOpened}
                    setDefaultsArticulo={this.setDefaultsArticulo}
                    onClose={this.onCloseAddDialog}
                    onConfirm={this.onConfirmAddDialog}
                    fullScreen={(isMobile || osName === 'Android')}
                />
                <ArticulosAddFastDialog
                    open={bAddFastDialogOpened}
                    articulos={articulos}
                    articulosArmados={articulosArmados}
                    setDefaultsArticulo={this.setDefaultsArticulo}
                    onClose={this.onCloseAddFastDialog}
                    onConfirm={this.onConfirmAddFastDialog}
                    mobile={(isMobile || osName === 'Android')}
                />
                <ArticulosEditDialog
                    open={bEditDialogOpened}
                    bonificacionesLitros={bonificacionesLitros}
                    articulo={articuloInEdition}
                    mode={mode}
                    depositos={depositos}
                    onClose={this.onCloseEditDialog}
                    onEdit={this.onEdit}
                    onConfirm={this.onConfirmEditDialog}
                    fullScreen={(isMobile || osName === 'Android')}
                />
                <ArticuloInfoDialog
                    open={bInfoDialogOpened}
                    cliente={cliente}
                    bonificacionGeneral={bonificacionGeneral}
                    articulo={articuloInInfo}
                    onClose={this.onCloseInfoDialog}
                    fullScreen={(isMobile || osName === 'Android')}
                />
            </div>
        );
    }
}

ArticulosMainTable.propTypes = {
    classes: PropTypes.object.isRequired,
    selected: PropTypes.array,
    editable: PropTypes.bool,
    onAdd: PropTypes.func,
    onEdit: PropTypes.func,
    onRemove: PropTypes.func
};

ArticulosMainTable.contextType = MainContext;
ArticulosMainTable = withSnackbar(ArticulosMainTable);

export default withStyles(styles)(ArticulosMainTable);
