import React, {useState} from "react";
import MoreVertIcon from '@material-ui/icons/MoreVertRounded';
import ViewIcon from '@material-ui/icons/VisibilityRounded';
import EditIcon from '@material-ui/icons/EditRounded';
import ChangeStatusIcon from '@material-ui/icons/LabelRounded';
import NotificationIcon from '@material-ui/icons/AddAlertRounded';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import SaveIcon from '@material-ui/icons/SaveAltRounded';
import EntregarIcon from '@material-ui/icons/DoneRounded';
import ViewRecibosIcon from '@material-ui/icons/CollectionsRounded';
import FormatListBulletedRounded from '@material-ui/icons/FormatListBulletedRounded';
import PermMediaRounded from '@material-ui/icons/PermMediaRounded';
import {IconButton, Tooltip} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

export const OptionsMenu = (props) => {
    const {row, rowId} = props;
    const {onView, onEdit, onNotification, onChangeStatus, onDownload, onViewRecibos, onDelete, onEntregar, onCancelarEntrega, onAddComprobantes} = props;
    const {loggedUser} = props.context;

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleOpen = (e) => {
        e.stopPropagation();
        setAnchorEl(e.currentTarget);
    };

    const handleClose = (e) => {
        if (!!e)
            e.stopPropagation();
        setAnchorEl(null);
    };

    const handleOption = (e, fnAction) => {
        e.stopPropagation();
        fnAction(row, rowId);
        handleClose();
    }

    const Item = React.forwardRef((props, ref) => {
        const Icon = props.icon;
        if (props.fn) {
            return (
                <MenuItem ref={ref} onClick={(e) => handleOption(e, props.fn)}>
                    <Icon fontSize={"small"} color={props.color ? props.color : "action"}/>
                    <Typography variant="body2" noWrap color={props.color} className={'ml-2'}>
                        {props.title}
                    </Typography>
                </MenuItem>
            )
        } else
            return null;
    })

    return (
        <>
            <Tooltip title={'Opciones'}>
                <IconButton aria-controls="fade-menu" aria-haspopup="true" onClick={handleOpen}>
                    <MoreVertIcon fontSize={"small"}/>
                </IconButton>
            </Tooltip>
            <Menu
                id="fade-menu"
                anchorEl={anchorEl}
                // keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
            >
                <Item ref={React.createRef()} fn={onView} icon={ViewIcon} title={"Visualizar"}/>
                {/*
                    Se puede editar una cobranza si NO ocurre que:
                    - Está Ingresada
                    - Tiene status R (y el usuario no es admin)
                    - Tiene status A y substatus G (y el usuario no es admin)
                */}

                {!(row.status === 'I' || row.status === 'S' || (loggedUser.role !== "A" && (row.status === 'R' || (row.status === 'A' && row.substatus === 'G')))) && (
                    <Item fn={onEdit} icon={EditIcon} title={"Editar"}/>
                )}

                <Item ref={React.createRef()} fn={onViewRecibos} icon={ViewRecibosIcon} title={"Ver Remitos"}/>
                <Item fn={onDownload} icon={SaveIcon} title={"Descargar"}/>

                {loggedUser.role === "A" && (
                    [
                        <Item key={1} fn={onNotification} icon={NotificationIcon} title={"Notificar al vendedor"}/>,
                        <Item key={3} fn={onDelete} icon={DeleteIcon} color={"error"} title={"Eliminar"}/>
                    ]
                )}

                {(loggedUser.role === "A" || loggedUser.username === "yesicap") && (
                    [
                        <Item key={2} fn={onChangeStatus} icon={ChangeStatusIcon} title={"Modificar estado"}/>
                    ]
                )}

                {(row.tipocob === 'D' && row.status !== 'S') && (
                    <Item fn={onAddComprobantes} icon={PermMediaRounded} title={"Adjuntar comprobantes"}/>
                )}

                <Item ref={React.createRef()} fn={onEntregar} icon={EntregarIcon} title={"Entregar"}/>
                <Item ref={React.createRef()} fn={onCancelarEntrega} icon={EntregarIcon} title={"Cancelar entrega"}/>
            </Menu>
        </>
    )
};
