import React, {useEffect, useState} from 'react'
import {Box, Button, IconButton, Tooltip, Typography} from "@material-ui/core";
import {ReactGrid} from 'components/helpers/reactGrid/reactGrid';
import {useSnackbar} from "notistack";
import {useCotizacionesService} from "../../../services/hooks";
import {CotizacionStatusFormatter, sortDate, sortNumeric} from "../../../assets/utils";
import InfoIcon from "@material-ui/icons/InfoRounded";
import {SimpleDialog} from "../../helpers/dialogs/simple.dialog";
import {CotizacionesView} from "../../ventas/cotizaciones/view/cotizaciones.view";

export const CotizacionesList = ({filter}) => {
    const [cotizaciones, setCotizaciones] = useState();
    const [infoDialog, setInfoDialog] = useState({open: false});

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const CotizacionesService = useCotizacionesService();

    switch (filter) {
        case "approved":
            filter = "A";
            break;
        case "rejected":
            filter = "R";
            break;
        default:
            filter = "";
    }

    const ApproveBtn = ({id}) =>
        <Button variant={"outlined"} color={"primary"} onClick={() => editCotizacion(id, "A")} size={"small"}>
            Aprobar
        </Button>;

    const RejectBtn = ({id}) =>
        <Button variant={"outlined"} className={'border-error ml-3'} style={{color: 'red'}} onClick={() => editCotizacion(id, "R")} size={"small"}>
            Rechazar
        </Button>;

    const CancelBtn = ({id}) =>
        <Button variant={"outlined"} className={'ml-3'} onClick={() => editCotizacion(id, "")} size={"small"}>
            Cancelar
        </Button>;

    const InfoBtn = ({row}) =>
        <Tooltip title={'Detalles de la cotización PREPAGO'}>
            <IconButton onClick={() => setInfoDialog({open: true, ...row})}>
                <InfoIcon fontSize={"small"}/>
            </IconButton>
        </Tooltip>

    const Custom = ({row}) => {
        switch (row.aprob_coti) {
            case 'A':
                return <>
                    <Typography color={"primary"} variant={"body2"} className={'font-weight-bold'}>
                        Aprobada <CancelBtn id={row.id}/>
                    </Typography>
                </>
            case 'R':
                return <Typography color={"error"} variant={"body2"} className={'font-weight-bold'}>
                    Rechazada
                </Typography>
            default:
                return <><ApproveBtn id={row.id}/><RejectBtn id={row.id}/></>
        }
    }

    const COTIZACIONES_TABLE = {
        columns: [
            {name: 'custom1', title: ' '},
            {name: 'id', title: 'ID Cotiza'},
            {name: 'custom3', title: 'Estado'},
            {name: 'ingreso', title: 'Ingreso', style: {whiteSpace: 'break-spaces'}},
            {name: 'razsoc', title: 'Cliente'},
            {name: 'impprepago', title: 'Importe', style: {whiteSpace: 'break-spaces'}},
            {name: 'custom2', title: ' '},
        ],
        columnExtensions: [
            {columnName: 'custom1', width: 50, component: InfoBtn, align: 'center'},
            {columnName: 'id', width: 100, compare: sortNumeric, direction: 'desc'},
            {columnName: 'custom3', component: CotizacionStatusFormatter, align: 'center'},
            {columnName: 'ingreso', width: 100, compare: sortDate},
            {columnName: 'razsoc', compare: sortDate},
            {columnName: 'impprepago', width: 150, type: 'currency', compare: sortNumeric},
            {columnName: 'custom2', width: 220, component: Custom, align: 'center'},
        ]
    }

    useEffect(function () {
        loadCotizaciones();
    }, []);

    const loadCotizaciones = () =>
        CotizacionesService.getPrepago(
            response => {
                let cotizaciones = response.data.result.filter(cotizacion => cotizacion.aprob_coti === filter);
                setCotizaciones(cotizaciones);
            },
            error => enqueueSnackbar("Error al obtener las cotizaciones PREPAGO", {variant: 'error'})
        );

    const editCotizacion = (idcotiza, action = "") => {
        CotizacionesService.editPrepago(
            {idcotiza, action}, // action = A(approve)/R(reject)/C(cancel)
            response => {
                loadCotizaciones()
            },
            error => enqueueSnackbar("Error al actualizar la cotización PREPAGO", {variant: 'error'})
        );
    }

    return (
        <Box className='container-xl mt-3 mb-3'>
            <ReactGrid
                title={'Cotizaciones'}
                showResultCount
                height={-200}
                columns={COTIZACIONES_TABLE.columns}
                columnExtensions={COTIZACIONES_TABLE.columnExtensions}
                rows={cotizaciones}
                enableFiltering={true}
                showFilters={false}
            />
            <SimpleDialog
                open={infoDialog.open}
                onCancel={() => setInfoDialog({open: false})}
                maxWidth={'lg'}
                fullWidth={true}
                title={'Cotización PREPAGO'}
                body={<CotizacionesView match={{params: {cotizacion: infoDialog.id, cliente: infoDialog.cliente}}}/>}
                cancelText={"Cerrar"}
            />
        </Box>
    )
}