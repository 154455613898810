import Card from "@material-ui/core/Card";
import {Button, Divider, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import React from "react";
import CardContent from "@material-ui/core/CardContent";
import ButtonBase from "@material-ui/core/ButtonBase";
import {formatDireccion} from "assets/utils";
import {RoomRounded, DoneRounded, CheckCircleRounded, CheckCircleOutlineRounded} from "@material-ui/icons";

export function CardCliente(props) {
    const {id, cliente, onClick} = props;
    return (
        <>
            <Card className={'m-0 p-0'} elevation={0}>
                <ButtonBase style={{width: '100%', display: 'block', textAlign: 'left'}} onClick={onClick}>
                    <CardContent>
                        <Grid container direction={"column"}>
                            <Grid container item alignItems={"flex-end"}>
                                <Grid item xs={7}>
                                    <Typography
                                        color="textSecondary"
                                        variant={"body2"}
                                        className={'align-items-center d-flex'}
                                        gutterBottom
                                    >
                                        <span className={'mr-2'}>{cliente.id}</span>
                                    </Typography>
                                </Grid>
                                <Grid item xs={5} justifyContent={"flex-end"} className={"mb-2 d-flex"}>
                                    <Button
                                        variant={"outlined"}
                                        color={cliente.visitado ? "primary" : "default"}
                                        disableRipple
                                        disableFocusRipple
                                        disableTouchRipple
                                        disableElevation
                                        size={"small"}
                                        // onClick={(e) => e.stopPropagation()}
                                        style={{borderRadius: '0px'}}
                                    >
                                        {cliente.visitado ? <><CheckCircleOutlineRounded className={"mr-1"}/> Visitado</> : "Visita Pendiente"}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Typography variant={"body1"}>
                                    {cliente.razsoc}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" color="textSecondary">
                                    {formatDireccion(cliente.direvisita)}
                                </Typography>
                            </Grid>
                            {/*<Grid item>*/}
                            {/*    <Typography variant="body2" color="textSecondary">*/}
                            {/*        {formatDireccion(cliente.localidad)}*/}
                            {/*    </Typography>*/}
                            {/*</Grid>*/}
                        </Grid>
                    </CardContent>
                </ButtonBase>
            </Card>
            <Divider/>
        </>
    );
}
