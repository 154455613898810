import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {Recepciones} from "./recepciones/recepciones";
import {Cotizaciones} from "./cotizaciones/cotizaciones";
import {Rendiciones} from "./rendiciones/rendiciones";
import {MainContext} from "contexts/main.context";

const basePath = '/aprobaciones';

export class Aprobaciones extends Component {
    componentDidMount() {
        this.context.setHeaderTitle('Aprobaciones');
    }

    render() {
        return (
            <div className='niquel-ventas'>
                <Switch>
                    <Route path={`${basePath}/recepciones`} component={Recepciones}/>
                    <Route path={`${basePath}/cotizaciones`} component={Cotizaciones}/>
                    <Route path={`${basePath}/rendiciones`} component={Rendiciones}/>
                </Switch>
            </div>
        )
    }
}

Aprobaciones.contextType = MainContext;
