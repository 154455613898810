import React, {Component} from 'react'
import {ClientesService, CobranzasService, EmailService, MastersService, VendedoresService} from "services";
import {MainContext} from "contexts/main.context";
import 'moment/locale/es';
import {withSnackbar} from "notistack";
import MomentUtils from "@date-io/moment";
import {Box} from "@material-ui/core";
import {CobranzasOperation} from "components/ventas/cobranzas/operation/cobranzas.operation";
import {CobranzaDocumentoInfoDialog} from "components/helpers/cobranzas/documentos/cobranza.documento.info.dialog";
import {MailDialog} from "components/helpers/dialogs/mail.dialog";
import SpeedDial from "@material-ui/lab/SpeedDial/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction/SpeedDialAction";
import PDFIcon from "@material-ui/icons/PictureAsPdf";
import DownloadIcon from "@material-ui/icons/GetAppRounded";
import ShowIcon from "@material-ui/icons/DesktopWindowsRounded";
import PrintIcon from "@material-ui/icons/PrintRounded";
import EmailIcon from "@material-ui/icons/EmailRounded";
import printJS from 'print-js'
import {PDFDialog} from "components/helpers/dialogs/pdf.dialog";
import {DeleteDialog} from "components/helpers/cobranzas/delete.dialog";
import {TIPOS} from "components/helpers/cobranzas/tipos";
import {getCobranzaStatusInfo} from "assets/utils";

export class CobranzasView extends Component {
    state = {
        cliente: {},
        vendedor: {},
        id: this.props.match.params.cobranza,
        _cobranzas: [],
        headerData: {
            recibo: '',
            usuario: this.context.loggedUser.name,
            fechaRecibo: (new MomentUtils()).date(new Date()).startOf("day"),
        },
        documentosACancelar: [],
        itemsDeCobranza: [],
        ajustesPlazo: [],
        cobranza: {
            documentos: {
                total: 0,
                dias: {
                    total: 0,
                    promedio: 0
                }
            },
            items: {
                total: 0,
                retenciones: 0,
                dias: {
                    total: 0,
                    promedio: 0
                }
            },
            ajustes_plazo: {
                total: 0,
            },
            descuento: {
                porcentaje: 0,
                importe: 0
            }
        },
        documentoInfoDialogOpen: false,
        pdfBlob: null,
        pdfUrl: '',
        mailDialogOpen: false,
        pdfDialogOpen: false,
        dialOpen: false,
        deleteDialogOpen: false,
        descuentoFijo: {
            enabled: false,
            tipo: 'porcentaje',
            importe: 0,
            porcentaje: 0
        },
        _originalDescuento: {},
        _equivalenteDescuento: {}
    };

    MastersService = new MastersService(this.context);
    ClientesService = new ClientesService(this.context);
    CobranzasService = new CobranzasService(this.context);
    EmailService = new EmailService(this.context);
    VendedoresService = new VendedoresService(this.context);

    componentDidMount() {
        this.ClientesService.getById(
            this.props.match.params.cliente,
            response => {
                this.setState(prevState => {
                    prevState.cliente = response.data.result;
                    prevState.headerData.vendedor = response.data.result.vendedor;
                    return prevState;
                })
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.CobranzasService.getByCliente(
            this.props.match.params.cliente,
            response => this.setState({_cobranzas: response.data.result}),
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );

        this.loadCobranzaData();
    }

    loadCobranzaData = () => {
        this.CobranzasService.getById(
            this.state.id,
            response => {
                const {...res} = response.data.result;
                this.setState(prevState => {
                    prevState.headerData = {
                        recibo: res.numrec,
                        usuario: res.usuario,
                        fechaRecibo: (new MomentUtils()).parse(res.fecharecibo, 'D/MM/Y'),
                        status: res.status,
                        substatus: res.substatus,
                        observa: res.observa
                    };
                    prevState.documentosACancelar = res.documentos.filter(documento => documento.tipo !== 'AP');
                    prevState.itemsDeCobranza = res.items;
                    for (let i in prevState.itemsDeCobranza) {
                        let item = prevState.itemsDeCobranza[i];
                        // 26/05/2020 -> En la visualización muestro las fechas originales.
                        if (item.observado) {
                            item.fecha = item._fecha;
                            item.importe = item._importe;
                        }

                        item.fecha = (new MomentUtils()).parse(item.fecha, 'D/MM/Y');
                    }
                    prevState.ajustesPlazo = res.documentos.filter(documento => documento.tipo === 'AP');
                    prevState.ajustesPlazo.forEach(ajuste => {
                        ajuste.total *= -1;
                        ajuste.importe *= -1;
                        ajuste.pendiente *= -1;
                    });

                    let totalAjustesPlazo = prevState.ajustesPlazo.reduce((count, ajuste) => count + ajuste.total, 0);

                    let itemSCC = prevState.itemsDeCobranza.find(item => item.tipo === 'SCC');

                    prevState.cobranza = {
                        saldo_cliente: res.saldo,
                        saldo_total_cliente: res.saldototal,
                        documentos: {
                            _array: prevState.documentosACancelar,
                            total: parseFloat(res.totdocs) + parseFloat(totalAjustesPlazo),
                            scc: itemSCC ? parseFloat(itemSCC.importe) : 0,
                            promedio_ponderado: res.dpromdocs,
                            // fecha_ponderada: fechaPonderadaDocumentos
                        },
                        items: {
                            _array: prevState.itemsDeCobranza,
                            total: res.totitems,
                            // retenciones: totalRetenciones,
                            promedio_ponderado: res.dpromitems
                        },
                        ajustes_plazo: {
                            _array: prevState.ajustesPlazo,
                            total: totalAjustesPlazo
                        },
                        descuento: {
                            porcentaje: res.pordesc,
                            importe: res.impdesc,
                            importe_sin_iva: res.impdesc / 1.21,
                            iva: (res.impdesc / 1.21) * 0.21
                        }
                    };

                    prevState.descuentoFijo = {
                        enabled: res.descfijo,
                        tipo: res.tipodesc === 'I' ? 'importe' : 'porcentaje',
                        importe: res.impdesc,
                        porcentaje: res.pordesc
                    };

                    // FIX 29/09: Agrego equivalente descuento para cobranzas R.
                    // FIX 24/02: Agrego equivalente descuento en los casos de cobranzas
                    // guardadas y pendientes.
                    if (prevState.headerData.status === 'R') {
                        prevState._equivalenteDescuento = {
                            porcentaje: res.equiv_porc,
                            importe: res.equiv_imp,
                            importe_sin_iva: res.equiv_imp / 1.21,
                            iva: (res.equiv_imp / 1.21) * 0.21,
                            error: true
                        };

                        prevState._originalDescuento = prevState.cobranza.descuento;
                        prevState.cobranza.descuento = {
                            porcentaje: 0,
                            importe: 0,
                            importe_sin_iva: 0,
                            iva: 0
                        };
                    } else if (res.equiv_porc && res.equiv_imp){
                        // Si el descuento equivalente fue alterado entonces la cobranza fue guardada. Lo muestro como original.
                        prevState._originalDescuento = {
                            porcentaje: res.equiv_porc,
                            importe: res.equiv_imp,
                            importe_sin_iva: res.equiv_imp / 1.21,
                            iva: (res.equiv_imp / 1.21) * 0.21,
                            error: true
                        };
                    }

                    return prevState;
                });

                this.VendedoresService.getById(
                    res.idvendedor,
                    response => this.setState({vendedor: response.data.result}),
                    error => this.props.enqueueSnackbar(error, {variant: 'error'})
                )
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    };

    showDocumentoInfoDialog = id => {
        let documento = this.state.documentosACancelar.find(_documento => _documento.id === id);
        this.setState({documentoInfoDialogOpen: true, showingDocumento: documento});
    };
    closeDocumentoInfoDialog = () => this.setState({documentoInfoDialogOpen: false});

    onChangeDocument = id => this.setState({id, pdfBlob: null}, () => this.loadCobranzaData());

    openDeleteDialog = () => this.setState({deleteDialogOpen: true});
    closeDeleteDialog = () => this.setState({deleteDialogOpen: false});

    deleteCobranza = () => {
        this.CobranzasService.deleteById(
            this.state.id,
            response => {
                this.props.enqueueSnackbar(`Se eliminó correctamente la cobranza transitoria número ${this.state.id}`, {variant: 'success'});
                this.props.history.push(`/ventas/cobranzas/${this.props.match.params.cliente}`);
            },
            error => this.props.enqueueSnackbar(error, {variant: 'error'})
        );
    };

    getPDF = (fnSuccess = () => {
    }) => {
        if (this.state.pdfBlob != null)
            fnSuccess();
        else
            this.CobranzasService.getPDF(
                this.state.id,
                file => this.setState({pdfBlob: file}, fnSuccess),
                error => this.props.enqueueSnackbar(error, {variant: 'error'})
            )
    };

    // Show PDF
    onOpenPdfDialog = () => this.setState({pdfDialogOpen: true});
    onClosePdfDialog = () => this.setState({pdfDialogOpen: false});

    onShowPDF = () => {
        this.getPDF(
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                this.setState({pdfUrl: fileURL}, this.onOpenPdfDialog);
            }
        );
    };

    // Print PDF
    onPrintPDF = () => {
        this.getPDF(
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                printJS(fileURL);
            }
        );
    };

    // Download PDF
    onDownloadPdf = () => {
        this.getPDF(
            () => {
                const fileURL = URL.createObjectURL(this.state.pdfBlob);
                var a = document.createElement("a");
                document.body.appendChild(a);
                a.style = "display: none";
                a.href = fileURL;
                a.download = `Cobranza ${this.state.id}`;
                a.click();
                window.URL.revokeObjectURL(fileURL);
                this.props.enqueueSnackbar(`El archivo se descargó correctamente. Revise su carpeta de descargas.`, {variant: 'success'});
            }
        );
    };

    // Email
    onOpenMailDialog = () => this.setState({mailDialogOpen: true});

    dialActions = [
        {icon: <EmailIcon/>, name: 'Enviar por Email', action: this.onOpenMailDialog},
        {icon: <PrintIcon/>, name: 'Imprimir', action: this.onPrintPDF},
        {icon: <DownloadIcon/>, name: 'Descargar', action: this.onDownloadPdf},
        {icon: <ShowIcon/>, name: 'Ver en Pantalla', action: this.onShowPDF},
    ];

    onCloseMailDialog = () => this.setState({mailDialogOpen: false});

    sendMail = mailData => {
        let sendMail = () => {
            // Paso los parámetros a un formData para poder enviar el blob (attachment)
            var formData = new FormData();
            formData.append('attachment', this.state.pdfBlob);
            formData.append('data', JSON.stringify(mailData));

            this.EmailService.send(
                formData,
                response => {
                    this.onCloseMailDialog();
                    this.props.enqueueSnackbar(`El email se envió correctamente`, {variant: 'success'});
                },
                error => this.props.enqueueSnackbar(error, {variant: 'error'})
            );
        };

        if (mailData.attachFile)
            this.getPDF(() => sendMail());
        else
            sendMail();
    };

    onDialOpen = () => this.setState({dialOpen: true});
    onDialClose = () => this.setState({dialOpen: false});

    render() {
        const {
            cliente, vendedor, _cobranzas, id, headerData, documentosACancelar, itemsDeCobranza, ajustesPlazo, cobranza, documentoInfoDialogOpen,
            mailDialogOpen, pdfDialogOpen, pdfUrl, dialOpen, deleteDialogOpen, showingDocumento, descuentoFijo,
            _originalDescuento, _equivalenteDescuento
        } = this.state;

        return (
            <React.Fragment>
                <Box className='niquel-cobranzas-create'>
                    <CobranzasOperation
                        operation='VIEW'
                        cliente={cliente}
                        headerData={headerData}
                        documentosACancelar={documentosACancelar}
                        itemsDeCobranza={itemsDeCobranza}
                        ajustesPlazo={ajustesPlazo}
                        cobranza={cobranza}
                        _originalDescuento={_originalDescuento}
                        _equivalenteDescuento={_equivalenteDescuento}
                        showInfoDocumento={this.showDocumentoInfoDialog}
                        TIPOS={TIPOS}
                        id={id}
                        cobranzas={_cobranzas}
                        descuentoFijo={descuentoFijo}
                        onChangeDocument={this.onChangeDocument}
                        onDelete={this.openDeleteDialog}
                    />
                    <Box className='niquel-fab-btn'>
                        <SpeedDial
                            ariaLabel="crear_cotizacion"
                            icon={<PDFIcon/>}
                            onBlur={this.onDialClose}
                            onClick={this.onDialOpen}
                            onClose={this.onDialClose}
                            onFocus={this.onDialOpen}
                            onMouseEnter={this.onDialOpen}
                            onMouseLeave={this.onDialClose}
                            open={dialOpen}
                        >
                            {this.dialActions.map(action => (
                                <SpeedDialAction
                                    key={action.name}
                                    icon={action.icon}
                                    tooltipTitle={action.name}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        action.action()
                                    }}
                                />
                            ))}
                        </SpeedDial>
                    </Box>
                </Box>

                <CobranzaDocumentoInfoDialog
                    open={documentoInfoDialogOpen}
                    documento={showingDocumento}
                    onClose={this.closeDocumentoInfoDialog}
                    operation={'VIEW'}
                />
                <MailDialog
                    open={mailDialogOpen}
                    onConfirm={this.sendMail}
                    onCancel={this.onCloseMailDialog}
                    fileName={`Cobranza_${this.state.id}.pdf`}
                    attachFile={true}
                    to={cliente.email}
                    cc={vendedor.email}
                    ccEnabled={true}
                    subject={`Cobranza ${this.state.id}`}
                    body={`Estimado cliente,  \n\nSe adjunta la cobranza número ${this.state.id}.`}
                    hidden_body={
                        `La cobranza ${this.state.id} está <b>${getCobranzaStatusInfo(headerData.status).description}</b>`
                    }
                />
                <PDFDialog
                    open={pdfDialogOpen}
                    onClose={this.onClosePdfDialog}
                    pdfUrl={pdfUrl}
                    pdfName={`Cobranza_${this.state.id}.pdf`}
                />
                <DeleteDialog
                    open={deleteDialogOpen}
                    cliente={cliente}
                    id={id}
                    onCancel={this.closeDeleteDialog}
                    onConfirm={this.deleteCobranza}
                />
            </React.Fragment>
        )
    }
}

CobranzasView.contextType = MainContext;
CobranzasView = withSnackbar(CobranzasView);
CobranzasView = withSnackbar(CobranzasView);
