import React from "react";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {TableContainer} from "@material-ui/core";
import TableFooter from "@material-ui/core/TableFooter";

export const CompradoVendidoTable = React.memo(function ({data}) {
    return (
        <>
            {data ? (
                <TableContainer style={{height: '28rem'}}>
                    <Table size="small" stickyHeader className={'MuiTable-stickyFooter'}>
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Detalle</TableCell>
                                <TableCell align="right">Comprado</TableCell>
                                <TableCell align="right">Vendido</TableCell>
                                <TableCell align="right">Diferencia</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableFooter>
                            <TableRow>
                                <TableCell align="left">Total</TableCell>
                                <TableCell align="right">{data.total.compras}</TableCell>
                                <TableCell align="right">{data.total.ventas}</TableCell>
                                <TableCell align="right">
                                    <span style={{
                                        fontWeight: 'bold',
                                        color: data.total.diferencia < 0 ? 'red' : 'green'
                                    }}>
                                            {data.total.diferencia}
                                    </span>
                                </TableCell>
                            </TableRow>
                        </TableFooter>
                        <TableBody>
                            {data.articulos.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell align="left" style={{wordBreak: 'break-all'}}>{row.detalle}</TableCell>
                                    <TableCell align="right">{row.compra}</TableCell>
                                    <TableCell align="right">{row.venta}</TableCell>
                                    <TableCell align="right">
                                        <span style={{
                                            fontWeight: 'bold',
                                            color: row.diferencia < 0 ? 'red' : 'green'
                                        }}>
                                            {row.diferencia}
                                        </span>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : ''}
        </>
    )
})
