import React, {Component} from 'react'
import {MainContext} from "contexts/main.context";
import {withStyles} from '@material-ui/core/styles';
import {withSnackbar} from "notistack";
import {Box, Button, Grid, MenuItem, TextField, Tooltip, Typography} from '@material-ui/core';
import {DatePicker, MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import 'moment/locale/es';
import SubHeader from "components/helpers/subHeader/subHeader";
import {ReactGrid} from "components/helpers/reactGrid/reactGrid";
import {AddPedidosDialog} from "components/despacho/hojasDeRuta/operation/add.dialog";
import Slide from "@material-ui/core/Slide";
import LinearProgress from "@material-ui/core/LinearProgress";
import TruckIcon from "assets/img/truck3.svg";
import memoize from 'memoize-one';
import {DeleteRounded as DeleteIcon, RoomRounded as MapIcon} from "@material-ui/icons";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const styles = props => ({
    barColorPrimary: {
        backgroundColor: 'red',
    }
});

export class HojasDeRutaOperation extends Component {
    // Memoize recuerda el último llamado y si no cambian sus parámetros devuelve el dato en cache.
    getLitros = memoize(
        pedidos => pedidos.reduce((tot_litros, pedido) => tot_litros += pedido.litros, 0)
    );

    getCapacidad = memoize(
        (idvehiculo, vehiculos) => {
            const vehiculo = vehiculos.find(vehiculo => vehiculo.id === idvehiculo);
            return vehiculo ? vehiculo.capacidad : 0;
        }
    );

    render() {
        const {loggedUser} = this.context;

        const {
            classes, operation, mainTable, headerData, id, hojasDeRuta, pedidos, zonas, vehiculos, choferes,
            addPedidosDialogOpen, tipos, onChangeDocument
        } = this.props;

        const litros = this.getLitros(mainTable.data);
        const capacidad = this.getCapacidad(headerData.vehiculo, vehiculos);
        const porcentaje = capacidad > 0 ? Math.round(litros / parseInt(capacidad) * 100) : 0;

        return (
            <Box className='niquel-hojasDeRuta-operation'>
                <SubHeader
                    title={'Hojas de Ruta'}
                    subtitle={
                        operation === 'VIEW' ?
                            'Revisión de Hoja de Ruta' :
                            operation === 'EDIT' ?
                                'Edición de Hoja de Ruta' :
                                'Nueva Hoja de Ruta'
                    }
                    options={operation === 'VIEW' ? hojasDeRuta : undefined}
                    optionsTitle={'Hoja de Ruta'}
                    optionSelectedId={id}
                    optionsDescriptionSecondaryField={'fecha'}
                    onChangeOption={onChangeDocument}
                />
                <Box className='container-xl mt-3 mb-3'>
                    <Grid container spacing={3}>
                        <Grid container item md={10}>
                            <Grid container item alignItems='center' spacing={2}>
                                <Grid item>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            disableToolbar
                                            autoOk
                                            label="Fecha"
                                            format="DD/MM/Y"
                                            // disablePast={operation === 'CREATE'}
                                            // minDate={operation === 'CREATE' ? headerData._original_fecha : undefined}
                                            value={headerData.fecha}
                                            onChange={moment => this.props.onChangeHeaderData('fecha', moment)}
                                            InputProps={{
                                                readOnly: operation === 'VIEW',
                                                onClick: (e) => operation === 'VIEW' && e.stopPropagation()
                                            }}
                                            style={{width: '6rem'}}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        select
                                        label="Zona"
                                        value={headerData.zona}
                                        onChange={event => this.props.onChangeHeaderData('zona', event.target.value)}
                                        InputProps={{
                                            readOnly: operation === 'VIEW'
                                        }}
                                    >
                                        {zonas.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.description}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        select
                                        label="Vehículo"
                                        value={headerData.vehiculo}
                                        onChange={event => this.props.onChangeHeaderData('vehiculo', event.target.value)}
                                        InputProps={{
                                            readOnly: operation === 'VIEW'
                                        }}
                                    >
                                        {vehiculos.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.description} | {option.capacidad} litros {option.tipo === 'T' ?
                                                <Typography variant={"caption"} color={"primary"} className={'ml-2 font-weight-bold'}>Transporte de
                                                    terceros</Typography> : ''}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        select
                                        label="Chofer"
                                        value={headerData.chofer}
                                        onChange={event => this.props.onChangeHeaderData('chofer', event.target.value)}
                                        InputProps={{
                                            readOnly: operation === 'VIEW'
                                        }}
                                    >
                                        {choferes.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.description}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <TimePicker
                                            ampm={false}
                                            autoOk
                                            minutesStep={10}
                                            label="Salida"
                                            value={headerData.salida}
                                            onChange={moment => this.props.onChangeHeaderData('salida', moment)}
                                            InputProps={{
                                                readOnly: operation === 'VIEW',
                                                onClick: (e) => operation === 'VIEW' && e.stopPropagation()
                                            }}
                                            style={{width: '3rem'}}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        select
                                        label="Tipo Pedidos"
                                        value={headerData.tipo}
                                        onChange={event => this.props.onChangeHeaderData('tipo', event.target.value)}
                                        InputProps={{
                                            readOnly: operation === 'VIEW'
                                        }}
                                    >
                                        {tipos.map(option => (
                                            <MenuItem key={option.id} value={option.id}>
                                                {option.description}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>

                                <Grid item>
                                    <Grid item className={'flex-grow-1 text-right'}>
                                        <Tooltip title={'Ver pedidos pendientes en el mapa'}>
                                            <Button variant={"contained"} color={"primary"} onClick={this.props.onShowMap}>
                                                <MapIcon fontSize={"small"} className={'mr-1'}/> MAPA
                                            </Button>
                                        </Tooltip>
                                    </Grid>
                                </Grid>

                                {(loggedUser.role === 'A' || loggedUser.role === 'O' || loggedUser.role === 'D') && (operation === 'VIEW' || operation === 'EDIT') && (
                                    <Grid item>
                                        <Grid item className={'flex-grow-1 text-right'}>
                                            <Tooltip title={'Eliminar Hoja de Ruta'}>
                                                <Button className={'background-error'} style={{color: 'white'}} onClick={this.props.onDeleteHojaRuta}>
                                                    <DeleteIcon fontSize={"small"}/> ELIMINAR
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <Grid container item md={2} direction={"column"} spacing={0}>
                            <Grid item container justifyContent={"space-between"} alignItems={"center"}>
                                <img src={TruckIcon} width={'60px'}/>
                                <Typography variant={"body1"} align={"center"} component={"span"}>{litros} / {capacidad} lts</Typography>
                            </Grid>
                            <Grid item className={'mt-1'}>
                                <LinearProgress
                                    variant="determinate"
                                    value={Math.min(porcentaje, 100)}
                                    classes={{
                                        barColorPrimary: porcentaje > 100 ? classes.barColorPrimary : null
                                    }}
                                />
                                <Typography
                                    variant={"caption"}
                                    color={porcentaje > 100 ? "error" : 'textPrimary'}
                                    component={"div"}
                                    align={"right"}
                                >
                                    {porcentaje}%
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Box className={'mt-3'}>
                        <ReactGrid
                            enableVirtualMode={false}
                            columns={mainTable.columns}
                            columnExtensions={mainTable.columnExtensions}
                            groupingExtensions={mainTable.groupingExtensions}
                            totalSummaryItemsExtensions={mainTable.totalSummaryItemsExtensions}
                            groupSummaryItemsExtensions={mainTable.groupSummaryItemsExtensions}
                            summaryMessages={mainTable.summaryMessages}
                            groupMessages={mainTable.groupMessages}
                            getRowId={row => row.id}
                            rows={mainTable.data}
                            title={`Pedidos a entregar`}
                            showResultCount={true}
                            enableFiltering={false}
                            enableSorting={true}
                            enableSearch={false}
                            enableSelection={false}
                            enableGrouping={true}
                            enableSummarizing={true}
                            selectedRows={mainTable.selected}
                            onSelectionChange={selected => this.props.onSelectPedidos(selected)}
                            onDelete={selected => this.props.onSelectPedidos([])}
                            showAddButton={operation !== 'VIEW'}
                            addButtonDisabled={pedidos.length <= 0}
                            onClickAddButton={this.props.onOpenAddPedidosDialog}
                            showSelectAll={true}
                        />
                    </Box>
                </Box>
                <AddPedidosDialog
                    open={addPedidosDialogOpen}
                    title={'Agregar'}
                    // fetchPedidos={this.props.fetchPedidos}
                    pedidos={this.props.pedidos}
                    excluded={mainTable.data}
                    zona={headerData.zona}
                    onConfirm={this.props.onConfirmAddPedidosDialog}
                    onCancel={this.props.onCloseAddPedidosDialog}
                    // fullScreen={(isMobile || osName === 'Android')}
                    fullScreen={true}
                    TransitionComponent={Transition}
                />
            </Box>
        )
    }
}

HojasDeRutaOperation.contextType = MainContext;
HojasDeRutaOperation = withSnackbar(HojasDeRutaOperation);
HojasDeRutaOperation = withStyles(styles)(HojasDeRutaOperation);
HojasDeRutaOperation.defaultProps = {
    operation: 'CREATE',
    headerData: {
        fecha: (new MomentUtils()).date(new Date()),
        zona: 1,
        vehiculo: 1,
        chofer: 1
    },
    zonas: [],
    vehiculos: [],
    choferes: []
};
