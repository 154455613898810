const getGeolocationErrorMsg = (error) => {
    switch (error.code) {
        case error.PERMISSION_DENIED:
            return "User denied the request for Geolocation."
        case error.POSITION_UNAVAILABLE:
            return "Location information is unavailable."
        case error.TIMEOUT:
            return "The request to get user location timed out."
        default:
            return "An unknown error occurred."
    }
}

export const validateGeolocationAPI = async () => {
    if (!navigator.geolocation) return false;

    const defaultResolveData = {isValid: true, errorMsg: ""};
    const canGetPosition = () => new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(
            () => resolve(defaultResolveData),
            (error) => {
                defaultResolveData.isValid = false;
                defaultResolveData.errorMsg = getGeolocationErrorMsg(error);
                resolve(defaultResolveData);
            }
        )
    );

    return await canGetPosition();
}
