import React, {Component} from 'react';
import {Route, Switch} from 'react-router-dom';
import {CobranzasClientes} from 'components/ventas/cobranzas/clientes/cobranzas.clientes';
import {CobranzasList} from 'components/ventas/cobranzas/list/cobranzas.list';
import {CobranzasCreate} from 'components/ventas/cobranzas/create/cobranzas.create';
import {CobranzasView} from 'components/ventas/cobranzas/view/cobranzas.view';

const basePath = '/ventas/cobranzas';

export class Cobranzas extends Component {
    render() {
        return (
            <div className='niquel-cobranzas'>
                <Switch>
                    <Route path={`${basePath}/:cliente/view/:cobranza`} component={CobranzasView}/>
                    <Route path={`${basePath}/:cliente/create`} component={CobranzasCreate}/>
                    <Route path={`${basePath}/:cliente/edit/:cobranza`} component={CobranzasCreate}/>
                    <Route path={`${basePath}/:cliente`} component={CobranzasList}/>
                    <Route path={`${basePath}`} component={CobranzasClientes}/>
                </Switch>
            </div>
        )
    }
}