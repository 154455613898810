import React, {Component} from 'react'
import {MainContext} from "contexts/main.context";
import {withSnackbar} from "notistack";
import {Box, Button, Grid, InputAdornment, MenuItem, Paper, Switch, TextField, Tooltip, Typography} from '@material-ui/core';
import ListIcon from '@material-ui/icons/List';
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from '@date-io/moment';
import 'moment/locale/es';
import ArticulosMainTable from "components/helpers/articulos/articulos.main.table";
import {LeyendasDialog} from 'components/helpers/dialogs/leyendas.dialog';
import SubHeader from "components/helpers/subHeader/subHeader";
import './cotizaciones.operation.scss';
import {isMobile, osName} from "react-device-detect";
import {Totales} from "components/ventas/cotizaciones/operation/totales";
import {CobranzaItemsList} from "../../../helpers/cobranzas/items/cobranza.items.list";
import {getCotizacionStatusInfo} from "../../../../assets/utils";

export class CotizacionesOperation extends Component {
    state = {
        leyendasDialogOpen: false
    };
    onOpenLeyendasDialog = () => this.setState({leyendasDialogOpen: true});
    onCloseLeyendasDialog = () => this.setState({leyendasDialogOpen: false});
    onConfirmLeyendasDialog = (leyenda1, leyenda2, leyenda3, leyenda4) => {
        this.props.onChangeHeaderData('leyenda1', leyenda1);
        this.props.onChangeHeaderData('leyenda2', leyenda2);
        this.props.onChangeHeaderData('leyenda3', leyenda3);
        this.props.onChangeHeaderData('leyenda4', leyenda4);
        this.onCloseLeyendasDialog();
    };

    render() {
        const {loggedUser} = this.context;
        const {leyendasDialogOpen} = this.state;
        const {
            operation,
            cliente,
            cotizaciones,
            id,
            articulos,
            bonificacionesLitros,
            talremitos,
            onOpenDireccionEntregaDialog,
            headerData,
            selectedArticulos,
            importes,
            totalArticulos,
            totalLitros,
            maxArticulos,
            onChangeHeaderData,
            onAddArticulos,
            onEditArticulo,
            onRemoveArticulos,
            onChangeDocument
        } = this.props;
        const editable = operation === 'CREATE';

        return (
            <Box className='niquel-cotizaciones-operation'>
                <SubHeader
                    title={'Cotizaciones'}
                    subtitle={operation === 'VIEW' ? 'Revisión de Cotización' : operation === 'EDIT' ? 'Edición de Cotización' : 'Nueva Cotización'}
                    cliente={cliente}
                    options={operation === 'VIEW' ? cotizaciones : undefined}
                    optionsTitle={'Cotización'}
                    optionSelectedId={id}
                    optionsDescriptionSecondaryField={'ingreso'}
                    onChangeOption={onChangeDocument}
                />
                <Box className='container-xl mt-3 mb-3'>
                    <Grid container spacing={3}>
                        <Grid container item md={10}>
                            <Grid container item alignItems='center' spacing={3}>
                                <Grid item>
                                    <TextField
                                        label="Bonificación"
                                        className='mr-2 inputBonificacion'
                                        value={headerData.bonificacion}
                                        onChange={event => onChangeHeaderData('bonificacion', event.target.value)}
                                        InputProps={{
                                            endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                            // Bonificación es temporalmente readonly (03/11/19)
                                            // readOnly: editable === false || (loggedUser.role !== 'A' && loggedUser.role !== 'O'),
                                            readOnly: true
                                        }}
                                    />
                                    <TextField
                                        select
                                        required
                                        label="Lista de Precio"
                                        className='mr-2 select'
                                        value={headerData.listaprecio}
                                        onChange={event => {
                                            onChangeHeaderData('listaprecio', event.target.value);
                                            if (selectedArticulos.length > 0)
                                                this.props.enqueueSnackbar('El cambio de lista de precios solo afectará a los nuevos items que se agreguen a la cotización.')
                                        }}
                                        InputProps={{
                                            readOnly: editable === false
                                        }}
                                    >
                                        {['1', '2', '3', '4', '5', '6'].map(value => (
                                            <MenuItem key={value} value={value}>
                                                Lista {value}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                <Grid item>
                                    <TextField
                                        label="Nota de Compra"
                                        className='mr-2 input'
                                        value={headerData.notacompra}
                                        onChange={event => onChangeHeaderData('notacompra', event.target.value)}
                                        InputProps={{
                                            readOnly: editable === false
                                        }}
                                    />
                                    {(loggedUser.role === 'A' || loggedUser.role === 'S' || loggedUser.role === 'O') && (
                                        <TextField
                                            required
                                            label="Usuario"
                                            className='mr-2 input'
                                            value={headerData.usuario}
                                            onChange={event => onChangeHeaderData('usuario', event.target.value)}
                                            InputProps={{
                                                readOnly: editable === false || (loggedUser.role !== 'A' && loggedUser.role !== 'O')
                                            }}
                                        />
                                    )}
                                    {editable === false && (loggedUser.role === 'A' || loggedUser.role === 'S' || loggedUser.role === 'O') && (
                                        <TextField
                                            required
                                            label="Vendedor"
                                            className='mr-2 input'
                                            value={headerData.vendedor}
                                            InputProps={{
                                                readOnly: true
                                            }}
                                        />
                                    )}
                                </Grid>
                                <Grid item>
                                    <MuiPickersUtilsProvider utils={MomentUtils}>
                                        <DatePicker
                                            disableToolbar
                                            autoOk
                                            required
                                            className='mr-2 date'
                                            label="Ingreso"
                                            format="DD/MM/Y"
                                            disablePast={editable}
                                            value={headerData.ingreso}
                                            onChange={moment => onChangeHeaderData('ingreso', moment)}
                                            readOnly={!editable}
                                            InputProps={{
                                                readOnly: !editable,
                                                onClick: (e) => !editable && e.stopPropagation()
                                            }}
                                        />
                                        <DatePicker
                                            disableToolbar
                                            autoOk
                                            required
                                            className='mr-2 date'
                                            label="Entrega"
                                            format="DD/MM/Y"
                                            disablePast={editable}
                                            value={headerData.entrega}
                                            onChange={moment => onChangeHeaderData('entrega', moment)}
                                            readOnly={!editable}
                                            InputProps={{
                                                readOnly: editable === false,
                                                onClick: (e) => editable === false && e.stopPropagation()
                                            }}
                                        />
                                    </MuiPickersUtilsProvider>
                                </Grid>
                                <Tooltip title={"Incluir impuestos en la cotización"}>
                                    <Grid item>
                                        <Typography
                                            variant={"caption"}
                                            component={'div'}
                                            style={{
                                                color: 'rgba(117, 117, 117, 1)',
                                                lineHeight: '0.9rem'
                                            }}
                                        >
                                            Impuestos
                                        </Typography>
                                        <Switch
                                            disabled={operation !== 'CREATE'}
                                            checked={headerData.isProforma}
                                            onChange={() => onChangeHeaderData('isProforma', !headerData.isProforma)}
                                        />
                                    </Grid>
                                </Tooltip>
                                <Tooltip title='Leyendas'>
                                    <Button color="primary" variant='outlined' className='ml-2'
                                            onClick={this.onOpenLeyendasDialog}>
                                        <ListIcon fontSize={"default"} className='mr-1'/>
                                        Leyendas
                                    </Button>
                                </Tooltip>

                                <Grid item>
                                    <Tooltip title={"Cotización para un pedido prepago"}>
                                        <Grid item>
                                            <Typography
                                                variant={"caption"}
                                                component={'div'}
                                                style={{
                                                    color: 'rgba(117, 117, 117, 1)',
                                                    lineHeight: '0.9rem'
                                                }}
                                            >
                                                PREPAGO
                                            </Typography>
                                            <Switch
                                                disabled={operation !== 'CREATE'}
                                                checked={headerData.isPrepago}
                                                onChange={() => onChangeHeaderData('isPrepago', !headerData.isPrepago)}
                                            />
                                        </Grid>
                                    </Tooltip>
                                </Grid>

                                {headerData.isPrepago && (
                                    <>
                                        <Grid item>
                                            <Box style={{maxWidth: '9rem', display: 'inline-block'}} className={'mr-2'}>
                                                <Typography variant={"caption"} component={"div"} color={"textSecondary"}>
                                                    Estado
                                                </Typography>
                                                <Tooltip title={getCotizacionStatusInfo(headerData).tooltip}>
                                                    <div className={'d-inline-flex align-items-center'}>
                                                        {getCotizacionStatusInfo(headerData).icon}
                                                        <Typography
                                                            className={'ml-1'}
                                                            component={"span"}
                                                            variant={"body2"}
                                                            color={getCotizacionStatusInfo(headerData).color}>
                                                            {getCotizacionStatusInfo(headerData).description}
                                                        </Typography>
                                                    </div>
                                                </Tooltip>
                                            </Box>
                                        </Grid>
                                        <Grid item alignItems={"center"}>
                                            <TextField
                                                select
                                                required
                                                label="Tal. Remito"
                                                className='mr-2 select'
                                                style={{maxWidth: '13.5rem'}}
                                                value={headerData.talremito}
                                                onChange={event => onChangeHeaderData('talremito', event.target.value)}
                                                InputProps={{
                                                    readOnly: editable === false
                                                }}
                                            >
                                                {talremitos.map(option => (
                                                    <MenuItem key={option.id} value={option.id}>
                                                        {option.description}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        </Grid>
                                        <Grid item>
                                            <Tooltip title='Seleccionar dirección de entrega'>
                                                <Button color="primary" variant='outlined' className='ml-2' onClick={onOpenDireccionEntregaDialog}>
                                                    {/*<ShippingIcon fontSize={"default"} className='mr-2'/>*/}
                                                    Entrega
                                                </Button>
                                            </Tooltip>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Grid>
                        <Totales {...this.props} />
                    </Grid>

                    {headerData.isPrepago && (
                        <Box>
                            <Paper className={'p-3 mt-4'}>
                                <CobranzaItemsList
                                    items={this.props.itemsDeCobranza}
                                    fechaRecibo={headerData.ingreso}
                                    cliente={cliente}
                                    TIPOS={this.props.tipos}
                                    onChangeTipo={this.props.changeItemTipo}
                                    onChangeFecha={this.props.changeItemFecha}
                                    onChangeImporte={this.props.changeItemImporte}
                                    onAdjustItem={this.props.adjustItem}
                                    onRemove={this.props.removeItem}
                                    onRemoveAll={this.props.removeAllItems}
                                    onAdd={this.props.addItem}
                                    operation={operation}
                                    fullWidth
                                />
                            </Paper>
                        </Box>
                    )}

                    <Box className='coti-articulos'>
                        <ArticulosMainTable
                            articulos={articulos}
                            selected={selectedArticulos}
                            editable={editable}
                            maxArticulos={maxArticulos}
                            listaprecio={`lista${headerData.listaprecio}`}
                            bonificacionesLitros={bonificacionesLitros}
                            importe={importes.subtotal}
                            totalArticulos={totalArticulos}
                            importeArticulos={importes.subtotal}
                            totalLitros={totalLitros}
                            bonificacionGeneral={headerData.bonificacion}
                            cliente={cliente}
                            onAdd={onAddArticulos}
                            onEdit={onEditArticulo}
                            onRemove={onRemoveArticulos}
                        />
                    </Box>

                    <LeyendasDialog
                        open={leyendasDialogOpen}
                        editable={editable}
                        leyenda1={headerData.leyenda1}
                        leyenda2={headerData.leyenda2}
                        leyenda3={headerData.leyenda3}
                        leyenda4={headerData.leyenda4}
                        onConfirm={this.onConfirmLeyendasDialog}
                        onCancel={this.onCloseLeyendasDialog}
                        fullScreen={(isMobile || osName === 'Android')}
                    />
                </Box>
            </Box>
        )
    }
}

CotizacionesOperation.contextType = MainContext;
CotizacionesOperation = withSnackbar(CotizacionesOperation);
CotizacionesOperation.defaultProps = {
    operation: 'CREATE',
    cliente: {},
    cotizaciones: [],
    condComerciales: [],
    vendedores: [],
    articulos: [],
    headerData: {
        bonificacion: 0,
        listaprecio: '1',
        condcomercial: '',
        notacompra: '',
        usuario: '',
        vendedor: '',
        ingreso: (new MomentUtils()).date(new Date()),
        entrega: (new MomentUtils()).date(new Date()),
        leyenda1: '',
        leyenda2: '',
        leyenda3: '',
        leyenda4: ''
    },
    selectedArticulos: [],
    importes: {},
};
