import React, {Component} from "react";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from "@material-ui/core";

export class NotificationDialog extends Component {
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onCancel}
                fullScreen={this.props.fullScreen}
                disableBackdropClick
                disableEscapeKeyDown
                fullWidth
                classes={{
                    paperFullScreen: 'niquel-dialog-full-screen'
                }}
            >
                <NotificationDialogContent {...this.props}/>
            </Dialog>
        )
    }
}

class NotificationDialogContent extends Component {
    MAX_CHARS = 140;

    state = {
        msg: '',
        chars_left: this.MAX_CHARS
    }

    onChangeMsg = e => {
        const chars_left = this.MAX_CHARS - e.target.value.length;

        if (chars_left >= 0)
            this.setState({msg: e.target.value, chars_left: chars_left});
    }

    onConfirm = () => {
        if (this.state.msg !== '')
            this.props.onConfirm(this.state.msg);
    }

    render() {
        const {vendedor, onCancel} = this.props;
        const {msg, chars_left} = this.state;

        return (
            <React.Fragment>
                <DialogTitle id="alert-dialog-title">Notificar al vendedor</DialogTitle>
                <DialogContent>
                    {/*<DialogContentText id="alert-dialog-description" component={'div'}>*/}
                    {/*    <Typography variant={"body2"}>*/}
                    {/*        Ingrese un mensaje para enviarle al vendedor {vendedor}*/}
                    {/*    </Typography>*/}
                    {/*</DialogContentText>*/}
                    <TextField
                        fullWidth
                        autoFocus
                        label={"Notificación"}
                        value={msg}
                        onChange={this.onChangeMsg}
                        helperText={`Ingrese un mensaje para enviarle al vendedor ${vendedor} (${chars_left})`}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={onCancel} color="primary">
                        Cancelar
                    </Button>
                    <Button
                        color={"primary"}
                        onClick={this.onConfirm}
                        disabled={this.state.msg === ''}
                    >
                        Confirmar
                    </Button>
                </DialogActions>
            </React.Fragment>
        )
    }
}
