import {MapContainer as LeafletMap, Marker, TileLayer, Tooltip} from "react-leaflet";
import React from "react";
import L from "leaflet";

const markerBasePath = '/markers';
const NMarkerIcon = (color, value) => new L.Icon({
    iconUrl: `${markerBasePath}/${color}/${value}.svg`,
    iconRetinaUrl: `${markerBasePath}/${color}/${value}.svg`,
    iconAnchor: [5, 55],
    popupAnchor: [18, -40],
    iconSize: [45, 75],
    shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
    shadowSize: [21, 21],
    shadowAnchor: [-11, 19],
    tooltipAnchor: [18, -40]
})

DetallesMap.defaultProps = {
    lat: -34.6577037,
    lng: -58.5820609,
    zoom: 11,
    cliente: ''
}

export function DetallesMap(props) {
    const {cliente, lat, lng, zoom} = props;
    const center = {lat, lng};

    return (
        <LeafletMap center={center} zoom={zoom} style={{flex: 1}} attributionControl={false} zoomControl={false}>
            <TileLayer
                attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                url="https://tiles.stadiamaps.com/tiles/osm_bright/{z}/{x}/{y}{r}.png"
                // url="https://{s}.tile.jawg.io/jawg-sunny/{z}/{x}/{y}{r}.png"
                // url="https://a.tiles.mapbox.com/v3/mapbox.world-bright/{z}/{x}/{y}.png"
                // url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
                // url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                // url="https://stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.png"
            />
            {/* Agrego a la dirección de entrega en negro*/}
            <Marker icon={NMarkerIcon('black', 'none')} position={center}>
                <Tooltip direction="top" opacity={0.9}>{cliente}</Tooltip>
            </Marker>

        </LeafletMap>
    )
}
