import React, {Component} from 'react'
import {Box, Typography} from "@material-ui/core";
import {withSnackbar} from "notistack";
import {MainContext} from "contexts/main.context";
import SubHeader from "components/helpers/subHeader/subHeader";
import '../estadisticas.scss';

export class EstadisticasGenerales extends Component {
    navCot = (tipo) => {
        this.props.history.push(`${this.props.match.url}/COT-${tipo}`);
    };

    render() {
        return (
            <Box className='niquel-estadisticas-main'>
                <SubHeader
                    title={'Generales'}
                    subtitle={'Estadísticas generales'}
                />

                <Box className='container mt-3 mb-3'>
                    <Typography align={"center"} className={'mt-6'} color={"textSecondary"}>En construcción</Typography>
                </Box>
            </Box>
        )
    }
}

EstadisticasGenerales.contextType = MainContext;
EstadisticasGenerales = withSnackbar(EstadisticasGenerales);
