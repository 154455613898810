import React, {Component} from 'react';
import {AppBar, Box, Grid, Tab, Tabs, Toolbar} from "@material-ui/core";
import {MainContext} from "contexts/main.context";
import {Route, Switch} from 'react-router-dom';
import SubHeader from "components/helpers/subHeader/subHeader";
import {Pendientes} from "./pendientes";
import {Recibidos} from "./recibidos";
import {Rechazados} from "./rechazados";
import Badge from "@material-ui/core/Badge";

const tiposRecepcion = ["cobranza", "cotizacion"];
const basePath = '/aprobaciones/recepciones';

export class Cobranzas extends Component {
    state = {
        tipoRecepcion: tiposRecepcion[0],
        tabActive: 'pendientes',
        pendientes: 0,
        fechaDialogOpened: false,
        itemEditing: null
    };

    componentDidMount() {
        this.setState({tipoRecepcion: (this.props.location.pathname.indexOf(tiposRecepcion[1]) > -1) ? tiposRecepcion[1] : tiposRecepcion[0]});

        if (this.props.location.pathname.indexOf('recibidos') > -1)
            this.setState({tabActive: 'recibidos'});
    }

    changeTab = (event, value) => {
        this.setState({tabActive: value});
        this.props.history.push(`${this.props.match.url}/${value}`);
    };

    setPendientes = pendientes => this.setState({pendientes: pendientes})

    render() {
        const {tabActive, pendientes} = this.state;
        return (
            <div>
                <SubHeader
                    title={'Recepciones'}
                    subtitle={this.state.tipoRecepcion === tiposRecepcion[0] ? 'Confirmar recepción de cheques y transferencias' : 'Confirmar recepción de transferencias prepago'}
                />
                <AppBar position="relative" elevation={0}>
                    <Toolbar variant="dense" color='primary'>
                        <Grid container justifyContent="space-between" alignItems="center">
                            <Grid item>
                                <Tabs
                                    value={tabActive}
                                    onChange={this.changeTab}
                                    indicatorColor="secondary"
                                    textColor="inherit"
                                >
                                    <Tab label={<Badge color="secondary" className='pr-3' badgeContent={pendientes}>Pendientes</Badge>} value='pendientes'/>
                                    <Tab label="Confirmados" value='recibidos'/>
                                    <Tab label="Rechazados" value='rechazados'/>
                                </Tabs>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Box className='container-xl mt-3 mb-3'>
                    <Switch>
                        {tiposRecepcion.map(tipoRecepcion =>
                            ([
                                <Route path={`${basePath}/${tipoRecepcion}/recibidos`} render={() => <Recibidos key={tipoRecepcion} tipoRecepcion={tipoRecepcion}/>}/>,
                                <Route path={`${basePath}/${tipoRecepcion}/rechazados`} render={() => <Rechazados key={tipoRecepcion} tipoRecepcion={tipoRecepcion}/>}/>,
                                <Route path={`${basePath}/${tipoRecepcion}`}
                                       render={() => <Pendientes key={tipoRecepcion} tipoRecepcion={tipoRecepcion} setPendientes={this.setPendientes}/>}/>
                            ])
                        )}
                    </Switch>
                </Box>
            </div>
        )
    }
}

Cobranzas.contextType = MainContext;