import React, {useEffect, useState} from 'react'
import {Box} from "@material-ui/core/index";
import {useSnackbar} from "notistack";
import './articulos.list.scss';
import {useArticulosService} from "services/hooks";
import {useHistory} from "react-router-dom";
import {Fab, IconButton, MenuItem, TextField, Tooltip} from "@material-ui/core";
import {useLocation, useRouteMatch} from "react-router";
import {ArticulosArmadosOperation} from "components/stock/articulosArmados/articulosArmados.operation";
import DoneIcon from "@material-ui/icons/DoneRounded";
import {SimpleDialog} from "components/helpers/dialogs/simple.dialog";
import {AddArticulosDialog} from "components/stock/articulosArmados/add.articulos.dialog";
import {InputPercentage, sortNumeric} from "assets/utils";
import {useDidMount} from "components/helpers/hooks/custom.hooks";
import RemoveIcon from "@material-ui/icons/DeleteRounded";

export function ArticulosArmadosCreate() {
    const [operation, setOperation] = useState();
    const [id, setId] = useState();
    const [articulos, setArticulos] = useState([]);
    const [codigo, setCodigo] = useState('');
    const [detalle, setDetalle] = useState('');
    const [activo, setActivo] = useState(true);
    const [articulosSelected, setArticulosSelected] = useState([]);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [addArticulosDialogOpen, setAddArticulosDialogOpen] = useState(false);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

    const ArticulosService = useArticulosService();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const history = useHistory();
    const match = useRouteMatch();
    const location = useLocation();
    const didMount = useDidMount();

    useEffect(() => setOperation(getOperation()), []);
    useEffect(() => didMount && operation !== "VIEW" && loadArticulos(), [operation]);
    useEffect(() => didMount && operation !== "CREATE" && setId(getId()), [operation]);
    useEffect(() => didMount && loadArticuloArmado(), [id]);

    const getOperation = () => {
        if (location.pathname.includes('edit')) return "EDIT";
        if (location.pathname.includes('view')) return "VIEW";
        return "CREATE";
    }

    const loadArticulos = () => {
        ArticulosService.getAll(
            null,
            response => setArticulos(response.data.result),
            error => enqueueSnackbar(error, {variant: 'error'}));
    }

    const getId = () => match.params.idarticulo;

    const loadArticuloArmado = () => {
        ArticulosService.getArmadoById(
            id,
            true,
            response => {
                setCodigo(response.data.result.id);
                setDetalle(response.data.result.detalle);
                setActivo(response.data.result.activo);
                setArticulosSelected(response.data.result.articulos);
            },
            error => enqueueSnackbar(error, {variant: 'error'}));
    }

    const InputCantidad = ({row, rowId}) => {
        return <TextField
            select
            variant={"outlined"}
            size={"small"}
            value={row.cantidad}
            onChange={event => setRowValue(row.id, "cantidad", event.target.value)}
        >
            {[...Array(11).keys()].slice(1).map(value => (
                <MenuItem key={value} value={value}>
                    {value}
                </MenuItem>
            ))}
        </TextField>
    }

    const TextCantidad = ({row, rowId}) => <div>{row.cantidad}</div>;

    const InputBonificacion = ({row, rowId}) => {
        return <TextField
            // label="Bonif"
            variant={"outlined"}
            size={"small"}
            value={row.bonificacion}
            onChange={event => setRowValue(row.id, "bonificacion", event.target.value)}
            InputProps={{
                inputComponent: InputPercentage,
                // endAdornment: <InputAdornment position="end">%</InputAdornment>,
                readOnly: operation === 'VIEW'
            }}
            style={{
                width: '5rem'
            }}
        />
    }

    const TextBonificacion = ({row, rowId}) => <div>{row.bonificacion}</div>;

    const BtnRemoveArticulo = ({row, rowId}) => {
        return <IconButton
            onClick={() => removeArticulo(row.id)}
        >
            <RemoveIcon/>
        </IconButton>;
    };

    const confirmAddArticulos = articulos => {
        articulos = articulos.map(articulo => ({...articulo, cantidad: 1}));
        setArticulosSelected(prevArticulosSelected => {
            for (let i = articulos.length - 1; i >= 0; i--) {
                let articulo = articulos[i];
                for (let prevArticuloSelected of prevArticulosSelected)
                    if (prevArticuloSelected.id === articulo.id) {
                        prevArticuloSelected.cantidad++;
                        articulos.splice(i, 1);
                    }
            }
            return prevArticulosSelected.concat(articulos);
        });
    }

    const setRowValue = (rowId, rowKey, rowValue) =>
        setArticulosSelected(prev => prev.map(articulo => articulo.id === rowId ? {...articulo, [rowKey]: rowValue} : articulo));

    const removeArticulo = (rowId, cantidad) =>
        setArticulosSelected(prev => prev.filter(articulo => articulo.id !== rowId));

    const confirmCreate = () => {
        ArticulosService.createArmado(
            codigo,
            detalle,
            activo,
            articulosSelected.map(articulo => ({id: articulo.id, cantidad: articulo.cantidad, bonificacion: articulo.bonificacion})),
            response => {
                enqueueSnackbar(`Se generó correctamente el artículo ${codigo}`, {variant: 'success'});
                history.replace(`/stock/articulosArmados`);
            },
            error => enqueueSnackbar(error, {variant: 'error'})
        );
    }

    // TODO: esta función es idéntica a create, abstraer.
    const confirmEdit = () => {
        ArticulosService.editArmado(
            codigo,
            detalle,
            activo,
            articulosSelected.map(articulo => ({id: articulo.id, cantidad: articulo.cantidad, bonificacion: articulo.bonificacion})),
            response => {
                enqueueSnackbar(`Se editó correctamente el artículo ${codigo}`, {variant: 'success'});
                history.replace(`/stock/articulosArmados`);
            },
            error => enqueueSnackbar(error, {variant: 'error'})
        );
    }

    const confirmDelete = () => {
        ArticulosService.deleteArmado(
            codigo,
            response => {
                enqueueSnackbar(`Se eliminó correctamente el artículo ${id}`, {variant: 'success'});
                history.replace('/stock/articulosArmados');
            },
            error => enqueueSnackbar(error, {variant: 'error'})
        );
    }

    let tableConfig;

    if (didMount)
        tableConfig = {
            columns: [
                {name: 'id', title: 'Código'},
                {name: 'detalle', title: 'Descripción', style: {whiteSpace: 'break-spaces'}},
                {name: 'lista1', title: 'Precio Unit.'},
                {name: 'custom1', title: 'Cantidad'},
                {name: 'custom2', title: 'Bonif (%)'},
                {name: 'custom3', title: ' '},
            ],
            columnExtensions: [
                {columnName: 'id', width: 150},
                {columnName: 'lista1', width: 130, type: 'currency', compare: sortNumeric},
                {
                    columnName: 'custom1',
                    type: 'icon',
                    component: operation === "VIEW" ? TextCantidad : InputCantidad,
                    sortingEnabled: false,
                    width: 120,
                    align: 'center'
                },
                {
                    columnName: 'custom2',
                    type: 'icon',
                    component: operation === "VIEW" ? TextBonificacion : InputBonificacion,
                    sortingEnabled: false,
                    width: 120,
                    align: 'center'
                },
                {
                    columnName: 'custom3',
                    type: 'icon',
                    component: operation !== "VIEW" ? BtnRemoveArticulo : () => <></>,
                    sortingEnabled: false,
                    width: 60,
                    align: 'center'
                },
            ]
        };

    return (
        <>
            <Box>
                <ArticulosArmadosOperation
                    operation={operation}
                    id={id}
                    tableConfig={tableConfig}
                    articulos={articulosSelected}
                    codigo={codigo}
                    setCodigo={setCodigo}
                    detalle={detalle}
                    setDetalle={setDetalle}
                    activo={activo}
                    setActivo={setActivo}
                    onOpenAddArticulosDialog={() => setAddArticulosDialogOpen(true)}
                    onDelete={() => setDeleteDialogOpen(true)}
                />
                {operation !== 'VIEW' &&
                    <Tooltip title={operation === 'CREATE' ? 'Generar Artículo Armado' : 'Editar Artículo Armado'}>
                        <Box className='niquel-fab-btn'>
                            <Fab color="primary" aria-label="Add" disabled={articulosSelected.length <= 0 || codigo === ''} onClick={() => setConfirmDialogOpen(true)}>
                                <DoneIcon/>
                            </Fab>
                        </Box>
                    </Tooltip>
                }

                {operation !== 'VIEW' &&
                    <SimpleDialog
                        open={confirmDialogOpen}
                        maxWidth={'xs'}
                        fullWidth
                        title={'Confirmar'}
                        body={<div>Seguro quiere {operation === "CREATE" ? "crear" : "editar"} el artículo: <b>{codigo}</b> - {detalle}</div>}
                        onConfirm={operation === "CREATE" ? confirmCreate : confirmEdit}
                        onCancel={() => setConfirmDialogOpen(false)}
                    />
                }

                {operation !== 'VIEW' &&
                    <AddArticulosDialog
                        open={addArticulosDialogOpen}
                        articulos={articulos}
                        title={"Agregar Artículos"}
                        onClose={() => setAddArticulosDialogOpen(false)}
                        onConfirm={confirmAddArticulos}
                    />
                }

                {operation !== 'CREATE' &&
                    <SimpleDialog
                        open={deleteDialogOpen}
                        maxWidth={'xs'}
                        fullWidth
                        title={'Eliminar'}
                        body={<div>Seguro quiere eliminar el artículo: <b>{codigo}</b> - {detalle}</div>}
                        onConfirm={confirmDelete}
                        onCancel={() => setDeleteDialogOpen(false)}
                    />
                }
            </Box>
        </>
    )
}
