import BaseService from './base.service'

export default class ArticulosService extends BaseService {
    getAll(cliente = null, fnSuccess, fnError) {
        return this.getApi().get(`/articulos?cliente=${cliente}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getAllWithFilters(filters, fnSuccess, fnError) {
        return this.getApi().post(`/articulos`, filters)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getById(id, cliente = null, fnSuccess, fnError) {
        return this.getApi().get(`/articulos?id=${id}&cliente=${cliente}`)
            .then(response => {
                if (response.data.count === 1)
                    response.data.result = response.data.result[0];
                fnSuccess(response);
            })
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getByBarcode(barcode, fnSuccess, fnError) {
        return this.getApi().get(`/articulos?barcode=${barcode}`)
            .then(response => {
                if (response.data.count === 1)
                    response.data.result = response.data.result[0];
                fnSuccess(response);
            })
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    editBarcode(id, barcode, fnSuccess, fnError) {
        return this.getApi().post(`/articulos/editBarcode?id=${id}`, {value: barcode})
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    checkIfBarcodeExists(id, barcode, fnSuccess, fnError) {
        return this.getApi().get(`/articulos/checkBarcodeExists?id=${id}&barcode=${barcode}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getImage(id, fnSuccess, fnError) {
        return this.getApi().get(`/articulos/image?id=${id}`)
            .then(response => {
                if (response.status === 200)
                    fnSuccess(response.data);
                else
                    fnSuccess(null);
            })
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getValoresFacturados(id, cliente, fnSuccess, fnError) {
        return this.getApi().get(`/articulos/valoresFacturados?id=${id}&cliente=${cliente}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getAverageSells(settings, quantityOfMonths, fnSuccess, fnError) {
        return this.getApi().post(`/articulos/averageSells?quantityOfMonths=${quantityOfMonths}`, settings)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    editSecurityStock(id, securityStock, fnSuccess, fnError) {
        return this.getApi().post(`/articulos/editSecurityStock?id=${id}`, {value: securityStock})
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getArmados(withInactivos = false, cliente = false, fnSuccess, fnError) {
        const paramWith = withInactivos ? "withInactivos" : "";
        const paramCliente = cliente ? cliente : "";
        return this.getApi().get(`/articulos/armados?${paramWith}&cliente=${paramCliente}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getArmadoById(id, withInactivos = false, fnSuccess, fnError) {
        id = encodeURIComponent(id);
        const paramWith = withInactivos ? "withInactivos" : "";
        return this.getApi().get(`/articulos/armados?id=${id}&${paramWith}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    createArmado(id, detalle, activo, articulos, fnSuccess, fnError) {
        return this.getApi().post(`/articulos/armados`, {id, detalle, articulos, activo})
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    editArmado(id, detalle, activo, articulos, fnSuccess, fnError) {
        return this.getApi().put(`/articulos/armados`, {id, detalle, articulos, activo})
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    deleteArmado(id, fnSuccess, fnError) {
        id = encodeURIComponent(id);
        return this.getApi().delete(`/articulos/armados?id=${id}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }
}
