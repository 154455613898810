import {Button, Divider, Tooltip, Typography} from "@material-ui/core";
import {PedidoStatusFormatter, sortDate, sortNumeric} from "assets/utils";
import {InfoButton} from "components/despacho/hojasDeRuta/operation/pedido.details";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import {MyLocationRounded, PersonRounded, PlaceRounded} from "@material-ui/icons";
import ListItemText from "@material-ui/core/ListItemText";
import React from "react";
import {ReactGrid} from "components/helpers/reactGrid/reactGrid";

export function MapDetailsPanel(props) {
    const {direccion, withPedidos} = props;

    function AddButton(_props) {
        return (
            _props.row.agregado ?
                <Tooltip title={'Agregar a la Hoja de Ruta'}>
                    <Button variant={"outlined"} className={'color-error hover-error'} size={"small"}
                            onClick={() => props.onRemovePedido(_props.row)}>Eliminar</Button>
                </Tooltip> :
                <Tooltip title={'Remover de la hoja de ruta'}>
                    <Button variant={"outlined"} color={"primary"} size={"small"} onClick={() => props.onAddPedido(_props.row)}>Agregar</Button>
                </Tooltip>
        );
    }

    const pedidosTable = {
        columns: [
            {name: 'id', title: 'ID'},
            {name: 'ingreso', title: 'Ingreso'},
            {name: 'custom1', title: ' '},
            {name: 'custom2', title: ' '}
        ],
        columnExtensions: [
            {columnName: 'id', width: 80, compare: sortNumeric, direction: 'desc'},
            {columnName: 'ingreso', width: 100, compare: sortDate},
            {columnName: 'custom1', width: 150, component: PedidoStatusFormatter, align: 'center'},
            {columnName: 'custom2', width: 50, component: InfoButton, align: 'center', sortingEnabled: false}
        ]
    };

    if (props.operation !== 'VIEW') {
        pedidosTable.columns.push({name: 'custom3', title: ' '});
        pedidosTable.columnExtensions.push({columnName: 'custom3', width: 100, component: AddButton, align: 'center', sortingEnabled: false});
    }

    return (
        <Box style={{width: '30rem'}}>
            <Typography
                variant={"h6"}
                align={"center"}
                className={'pt-4 pr-4 pb-3 pl-4'}
            >
                {direccion.cliente || direccion.razsoc}
            </Typography>
            <List component="nav" dense>
                <ListItem>
                    <ListItemIcon style={{minWidth: '2.5rem'}}>
                        <PlaceRounded/>
                    </ListItemIcon>
                    <ListItemText primary={direccion.direccion_entrega}/>
                </ListItem>
                <ListItem>
                    <ListItemIcon style={{minWidth: '2.5rem'}}>
                        <PersonRounded/>
                    </ListItemIcon>
                    <ListItemText primary={direccion.vendedor} secondary={`Vendedor Nivel ${direccion.vendedor_nivel || 'Ninguno'}`}/>
                </ListItem>
                <ListItem>
                    <ListItemIcon style={{minWidth: '2.5rem'}}>
                        <MyLocationRounded/>
                    </ListItemIcon>
                    <ListItemText primary={`[${direccion.latitud}, ${direccion.longitud}]`}/>
                </ListItem>
            </List>
            <Divider/>
            {withPedidos && (
                <ReactGrid
                    enableVirtualMode={false}
                    columns={pedidosTable.columns}
                    columnExtensions={pedidosTable.columnExtensions}
                    getRowId={row => row.id}
                    rows={direccion.pedidos}
                    title={direccion.agregada ? `Pedidos Agregados` : `Pedidos Pendientes`}
                    elevation={0}
                    showResultCount={true}
                    enableFiltering={false}
                    enableSorting={false}
                    enableSearch={false}
                    enableSelection={false}
                    enableGrouping={false}
                    enableSummarizing={false}
                    showAddButton={false}
                    showSelectAll={false}
                />
            )}
        </Box>
    )
}

MapDetailsPanel.defaultProps = {
    direccion: {},
    withPedidos: true
}
