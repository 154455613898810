import React, {useState} from "react";
import {MenuItem, TextField, Typography} from "@material-ui/core";
import {SimpleDialog} from "components/helpers/dialogs/simple.dialog";
import {Grid} from "@material-ui/core/index";

const CopyObjetivosDialogBody = ({vendedor, vendedorActual, vendedores, onChangeVendedor}) =>
    <Grid container direction={"column"} spacing={2}>
        <Grid item>
            <Typography><b>ATENCIÓN:</b> se reemplazarán todos los objetivos de {vendedorActual.name} por los del
                vendedor elegido.</Typography>
        </Grid>
        <Grid container item justifyContent={"space-between"}>
            <TextField
                select
                className={'mb-2'}
                label={"del vendedor"}
                value={vendedor}
                style={{
                    width: '100%',
                }}
                onClick={e => e.stopPropagation()}
                onChange={event => onChangeVendedor(event.target.value)}
            >
                {vendedores.map(option => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
            </TextField>
        </Grid>
    </Grid>

export const CopyObjetivosDialog = ({open, vendedorActual, vendedores, onConfirm, onCancel}
) => {
    const [vendedor, setVendedor] = useState('');

    return (
        <SimpleDialog
            open={open}
            maxWidth={'xs'}
            fullWidth
            title={'Copiar Objetivos'}
            body={
                <CopyObjetivosDialogBody
                    vendedor={vendedor}
                    vendedorActual={vendedorActual}
                    vendedores={vendedores}
                    onChangeVendedor={vendedor => setVendedor(vendedor)}
                />
            }
            onConfirm={() => onConfirm(vendedor)}
            onCancel={onCancel}
        />
    )
}
