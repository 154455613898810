import {ReactGrid} from "components/helpers/reactGrid/reactGrid";
import React, {useContext, useEffect, useRef, useState} from "react";
import VendedoresService from "services/vendedores.service";
import {MainContext} from "contexts/main.context";
import PropTypes from 'prop-types';
import {useSnackbar} from "notistack";

const COLUMNS = [
    {name: 'id', title: 'ID'},
    {name: 'name', title: 'Nombre'}
];

const COLUMN_EXTENSIONS = [
    // {columnName: 'id', width: 150},
];

const useDidMountEffect = (func, deps) => {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) func();
        else didMount.current = true;
    }, deps);
}

export function VendedoresSelect(props) {
    const context = useContext(MainContext);
    const vendedoresService = new VendedoresService(context);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();

    const [all, setAll] = useState(false);
    const [selected, setSelected] = useState([]);
    const [data, setData] = useState([]);

    useEffect(() => {
        if (all === false)
            vendedoresService.getAll(
                success => setData(success.data.result),
                error => enqueueSnackbar(error, {variant: 'error'})
            )

        props.onChangeAll(all);
    }, [all]);

    useDidMountEffect(() => {
        props.onChangeSelected(selected);
    }, [selected])

    return (
        <ReactGrid
            columns={COLUMNS}
            columnExtensions={COLUMN_EXTENSIONS}
            getRowId={vendedor => vendedor.id}
            rows={data}
            title={props.title}
            height={props.height}
            enableFiltering={props.enableFiltering}
            enableSorting={props.enableSorting}
            enableSearch={props.enableSearch}
            enableSelection={props.enableSelection}
            selectedRows={selected}
            onSelectionChange={selected => setSelected(selected)}
            onDelete={selected => setSelected([])}
            showSelectAll={props.showSelectAll}
            showSwitchAll={props.showSwitchAll}
            switchAllValue={all}
            onChangeSwitchAll={() => setAll(prevAll => !prevAll)}
        />
    )
}

VendedoresSelect.defaultProps = {
    title: 'Vendedores',
    height: 300,
    onChangeAll: () => {
    },
    onChangeSelected: () => {
    },
    enableFiltering: false,
    enableSorting: true,
    enableSearch: true,
    enableSelection: true,
    showSelectAll: false,
    showSwitchAll: true
}

VendedoresSelect.propsTypes = {
    title: PropTypes.string,
    height: PropTypes.number,
    onChangeAll: PropTypes.func,
    onChangeSelected: PropTypes.func,
    enableFiltering: PropTypes.bool,
    enableSorting: PropTypes.bool,
    enableSearch: PropTypes.bool,
    enableSelection: PropTypes.bool,
    showSelectAll: PropTypes.bool,
    showSwitchAll: PropTypes.bool
}
