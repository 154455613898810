import React from "react";
import {Box, Typography} from "@material-ui/core";
import {SimpleDialog} from "components/helpers/dialogs/simple.dialog";

const DeleteObjetivoDialogBody = ({tipoobj_nombre, nombre, subobjetivos}) =>
    <Box>
        <Typography>Estás por eliminar el objetivo <b>{nombre}</b> ({tipoobj_nombre}) y
            sus <b>{subobjetivos.length} subobjetivos.</b></Typography>
    </Box>

export const DeleteObjetivoDialog = ({tipoobj_nombre, nombre, subobjetivos, open, onDelete, onCancel}) => {
    return (
        <SimpleDialog
            open={open}
            maxWidth={'xs'}
            fullWidth
            title={'Eliminar Objetivo'}
            body={
                <DeleteObjetivoDialogBody
                    tipoobj_nombre={tipoobj_nombre}
                    nombre={nombre}
                    subobjetivos={subobjetivos}
                />
            }
            onDelete={() => onDelete()}
            onCancel={onCancel}
        />
    )
}
