import React from "react";
import classNames from 'classnames';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import PropTypes from "prop-types";
import {Box, Button, Grid, IconButton, Toolbar, Tooltip, Typography, withStyles} from "@material-ui/core";
import {lighten} from "@material-ui/core/styles/colorManipulator";
import {formatPrice, formatQuantity} from "assets/utils";

const toolbarStyles = theme => ({
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: '1 1 100%',
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: '0 0 auto',
    },
});

class ArticulosMainTableToolbar extends React.Component {
    onAdd = () => this.props.onAdd();
    onAddFast = () => this.props.onAddFast();
    onRemove = () => this.props.onRemove();

    render() {
        const {articulos, numSelected, editable, totalArticulos, totalLitros, importeArticulos, maxArticulos, classes} = this.props;

        return (
            <Toolbar
                className={classNames(classes.root, {
                    [classes.highlight]: numSelected > 0,
                })}
            >
                <div className={classes.title}>
                    {numSelected > 0 ? (
                        <Typography color="inherit" variant="subtitle1">
                            {numSelected} artículos seleccionados
                        </Typography>
                    ) : (
                        <Grid container alignItems="center">
                            <Typography variant="subtitle1" color={"textSecondary"}>Artículos</Typography>
                            <Typography variant="caption" className='ml-2'>
                                (Cantidad: {formatQuantity(totalArticulos)} - {!isNaN(totalLitros) ? `Litros: ${formatQuantity(totalLitros)} -` : ''} Total: {formatPrice(importeArticulos, true)})
                            </Typography>
                        </Grid>
                    )}
                </div>
                <div className={classes.spacer}/>
                {/*<div className={classes.actions}>*/}
                <Grid container direction={"row"} spacing={2} justifyContent={"flex-end"}>
                    {numSelected > 0 ? (
                        <Grid item>
                            <Tooltip title="Quitar Artículos">
                                <Box>
                                    <IconButton aria-label="Delete" onClick={this.onRemove} disabled={editable === false}>
                                        <DeleteIcon/>
                                    </IconButton>
                                </Box>
                            </Tooltip>
                        </Grid>
                    ) : (
                        <React.Fragment>
                            <Grid item>
                                <Tooltip title="Agregar Rápido">
                                    <Box>
                                        <Button aria-label="Add Articulo Fast"
                                                variant="outlined"
                                                size='small'
                                                color='primary'
                                                disabled={editable === false || articulos.length >= maxArticulos}
                                                onClick={this.onAddFast}>
                                            Agregar Rápido
                                        </Button>
                                    </Box>
                                </Tooltip>
                            </Grid>
                            <Grid item>
                                <Tooltip title="Agregar Artículos">
                                    <Box>
                                        <Button aria-label="Add Articulo"
                                                variant="outlined"
                                                size='small'
                                                color='primary'
                                                disabled={editable === false || articulos.length >= maxArticulos}
                                                onClick={this.onAdd}>
                                            Agregar
                                        </Button>
                                    </Box>
                                </Tooltip>
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            </Toolbar>
        );
    }
}

ArticulosMainTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

export default withStyles(toolbarStyles)(ArticulosMainTableToolbar);
