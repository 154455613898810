import {useContext} from "react";
import {MainContext} from "contexts/main.context";
import {
    ArticulosService,
    ClientesService,
    ClientsScheduleService,
    CotizacionesService,
    EmailService,
    EstadisticasService,
    HojasDeRutaService,
    ListadosService,
    MastersService,
    NotificationsService,
    ObjetivosService,
    PedidosService,
    ProveedoresService,
    SchedulerService,
    UtilsService,
    VendedoresService,
    CobranzasService
} from "services/index";

export const useVendedoresService = () => new VendedoresService(useContext(MainContext))
export const useClientesService = () => new ClientesService(useContext(MainContext))
export const useEstadisticasService = () => new EstadisticasService(useContext(MainContext))
export const useHojasDeRutaService = () => new HojasDeRutaService(useContext(MainContext))
export const useCotizacionesService = () => new CotizacionesService(useContext(MainContext))
export const usePedidosService = () => new PedidosService(useContext(MainContext))
export const useMastersService = () => new MastersService(useContext(MainContext))
export const useArticulosService = () => new ArticulosService(useContext(MainContext))
export const useProveedoresService = () => new ProveedoresService(useContext(MainContext))
export const useNotificationsService = () => new NotificationsService(useContext(MainContext))
export const useSchedulerService = () => new SchedulerService(useContext(MainContext))
export const useEmailService = () => new EmailService(useContext(MainContext))
export const useListadosService = () => new ListadosService(useContext(MainContext))
export const useObjetivosService = () => new ObjetivosService(useContext(MainContext))
export const useUtilsService = () => new UtilsService(useContext(MainContext))
export const useClientsScheduleService = () => new ClientsScheduleService(useContext(MainContext))
export const useCobranzasService = () => new CobranzasService(useContext(MainContext))
