import {CardChart} from "../card.chart";
import {CompradoresMensualesChart} from "../charts/compradores.mensuales.chart";
import React, {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {useEstadisticasService} from "../../../../../services/hooks";
import {imageChartToPDF, tableChartToPDF} from "../../../../../assets/utils";

export const CompradoresMensualesCard = ({settingsState, printRef}) => {
    const [compradoresMensuales, setCompradoresMensuales] = useState([]);

    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const EstadisticasService = useEstadisticasService();

    const chartTitle = 'Compradores y Base de Datos';
    const chartSubtitle = 'por cantidad de clientes';

    useEffect(() => {
        EstadisticasService.getCompradoresMensuales(
            settingsState,
            success => setCompradoresMensuales(success.data.result),
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    }, [settingsState]);

    return (
        <CardChart
            title={chartTitle}
            subtitle={chartSubtitle}
            printRef={printRef}
            exportFn={imageChartToPDF.bind(null, printRef, chartTitle, chartSubtitle, settingsState.dates)}
        >
            <CompradoresMensualesChart
                data={compradoresMensuales}
                height={300}
                value={''}
            />
        </CardChart>
    )
}
