import React, {useContext, useEffect, useState} from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography} from "@material-ui/core";
import {MainContext} from "contexts/main.context";
import {useArticulosService} from "services/hooks";
import {INITIAL_FILTER_SETTINGS_STATE} from "components/helpers/initialFilterSettingsState";
import ImgNotFoundIcon from "@material-ui/icons/PhotoCameraRounded";
import {formatPrice, formatQuantity, InputInteger} from "assets/utils";
import {SecurityRounded} from "@material-ui/icons";
import {useSnackbar} from "notistack";

export const ArticuloDetailsDialog = (props) => {
    return (
        <Dialog
            open={props.open}
            onClose={props.onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className='articulo-details-dialog'
        >
            <ArticuloDetailsDialogContent {...props}/>
        </Dialog>
    );
}

const ArticuloDetailsDialogContent = ({open, id, onCancel}) => {
    const [image, setImage] = useState();
    const [averageSells, setAverageSells] = useState(0);
    const [securityStockEditing, setSecurityStockEditing] = useState(false);
    const [articulo, setArticulo] = useState({});
    const [securityStockValue, setSecurityStockValue] = useState(articulo.stocksval);

    const context = useContext(MainContext);
    const ArticulosService = useArticulosService();
    const {enqueueSnackbar} = useSnackbar();
    const moment = require('moment');
    const quantityOfMonths = 3;

    useEffect(() => {
        ArticulosService.getById(
            id,
            null,
            response => setArticulo(response.data.result),
            error => enqueueSnackbar(error, {variant: 'error'})
        );
    }, []);

    useEffect(() => {
        if (!articulo.id)
            return;

        ArticulosService.getImage(
            articulo.id,
            response => setImage(response),
            error => enqueueSnackbar(error, {variant: 'error'})
        );
    }, [articulo]);

    useEffect(() => {
        if (!articulo.id)
            return;

        const articulos = {all: false, selected: [articulo.id]};
        const dateFrom = moment().subtract(quantityOfMonths, 'month');

        const settings = {...INITIAL_FILTER_SETTINGS_STATE};
        settings.articulos = articulos;
        settings.dates = {...settings.dates, from: dateFrom};

        ArticulosService.getAverageSells(
            settings,
            quantityOfMonths,
            response => {
                if (!!response.data.result[articulo.id])
                    setAverageSells(response.data.result[articulo.id]);
                else
                    setAverageSells(0);
            },
            error => enqueueSnackbar(error, {variant: 'error'})
        );
    }, [articulo]);

    useEffect(() => {
        setSecurityStockValue(articulo.stocksval);
    }, [articulo]);

    const securityStockStartEditing = () => setSecurityStockEditing(true);
    const securityStockEndEditing = () => setSecurityStockEditing(false);

    const onConfirmSecurityStock = () => {
        ArticulosService.editSecurityStock(
            articulo.id,
            securityStockValue,
            response => {
                setArticulo(response.data.result.articulo);
                enqueueSnackbar("Stock de seguridad editado correctamente", {variant: 'success'});
                securityStockEndEditing();
            },
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    };
    const onCancelSecurityStock = () => {
        setSecurityStockValue(articulo.stocksval);
        securityStockEndEditing();
    };

    return (
        <>
            <DialogTitle id="alert-dialog-title">
                <Typography variant={"body1"}>{articulo.id}</Typography>
                <Typography variant={"body2"} color={"textSecondary"}>{articulo.detalle}</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container className='mb-4 niquel-articulo-image'>
                    {image !== null && (
                        <Box className='img-found'>
                            <img src={`data:image/jpeg;base64,${image}`} alt={articulo.detalle}/>
                        </Box>
                    )}
                    {image === null && (
                        <Box className='img-not-found'>
                            <ImgNotFoundIcon/>
                        </Box>
                    )}
                </Grid>
                <Grid container justifyContent={"space-between"}>
                    <Grid item container sm={4} direction={"column"}>
                        <Grid item>
                            <Typography variant={"body2"} color={"textSecondary"} component={'span'}>Lista 1: </Typography>
                            <Typography variant={"body2"} component={'span'}>{formatPrice(articulo.lista1, true)}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={"body2"} color={"textSecondary"} component={'span'}>Lista 2: </Typography>
                            <Typography variant={"body2"} component={'span'}>{formatPrice(articulo.lista2, true)}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={"body2"} color={"textSecondary"} component={'span'}>Lista 3: </Typography>
                            <Typography variant={"body2"} component={'span'}>{formatPrice(articulo.lista3, true)}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container sm={3} direction={"column"}>
                        <Grid item>
                            <Typography variant={"body2"} color={"textSecondary"} component={'span'}>Lista 4: </Typography>
                            <Typography variant={"body2"} component={'span'}>{formatPrice(articulo.lista4, true)}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={"body2"} color={"textSecondary"} component={'span'}>Lista 5: </Typography>
                            <Typography variant={"body2"} component={'span'}>{formatPrice(articulo.lista5, true)}</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={"body2"} color={"textSecondary"} component={'span'}>Lista 6: </Typography>
                            <Typography variant={"body2"} component={'span'}>{formatPrice(articulo.lista6, true)}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item container sm={5} direction={"column"} alignItems={"flex-end"} className='column-cantidad'>
                        <Grid item>
                            <Typography variant={"body1"} color={"textSecondary"} component={'span'}>Stock Total: </Typography>
                            <Typography variant={"body1"} component={'span'}>{formatQuantity(articulo.total)}</Typography>
                            <Typography variant={"caption"} component={'span'}> unid</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={"body1"} color={"textSecondary"} component={'span'}>Reservado: </Typography>
                            <Typography variant={"body1"} component={'span'}>{formatQuantity(articulo.reservado)}</Typography>
                            <Typography variant={"caption"} component={'span'}> unid</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={"body1"} color={"textSecondary"} component={'span'}>Disponible: </Typography>
                            <Typography variant={"body1"} component={'span'}>{formatQuantity(articulo.disponible)}</Typography>
                            <Typography variant={"caption"} component={'span'}> unid</Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant={"body1"} color={"textSecondary"} component={'span'}>Orden Compra: </Typography>
                            <Typography variant={"body1"} component={'span'}>{formatQuantity(articulo.ordencompra)}</Typography>
                            <Typography variant={"caption"} component={'span'}> unid</Typography>
                        </Grid>
                    </Grid>
                    <Grid container direction={"column"} className={'mt-5'}>
                        {(["A", "O"].includes(context.loggedUser.role)) && (
                            <Grid item>
                                <Typography variant={"body2"} color={"textSecondary"} component={'span'}>
                                    <SecurityRounded fontSize={"small"} style={{position: "relative", top: "3px"}} className={'mr-1'}/>
                                    Stock de Seguridad:&nbsp;
                                </Typography>
                                {securityStockEditing ?
                                    <>
                                        <TextField
                                            className={'ml-2'}
                                            style={{width: '60px'}}
                                            value={securityStockValue}
                                            onChange={e => setSecurityStockValue(e.target.value)}
                                            InputProps={{
                                                inputComponent: InputInteger
                                            }}
                                        />
                                        <Button variant={"text"} color={"primary"} size={"small"} onClick={onConfirmSecurityStock}>Guardar</Button>
                                        <Button variant={"text"} size={"small"} onClick={onCancelSecurityStock}>Cancelar</Button>
                                    </> :
                                    <>
                                        <Typography variant={"body1"} component={"span"}>{formatQuantity(securityStockValue)}</Typography>
                                        <Typography variant={"caption"} component={'span'}> unid</Typography>
                                        {["A"].includes(context.loggedUser.role) &&
                                            <Button variant={"text"} size={"small"} onClick={securityStockStartEditing}>Editar</Button>
                                        }
                                    </>
                                }
                            </Grid>
                        )}
                        <Grid item>
                            <Typography variant={"body2"} color={"textSecondary"} component={'span'}>
                                Ventas promedio (últimos {quantityOfMonths} meses):
                            </Typography>
                            <Typography variant={"body2"} component={'span'}> {formatPrice(averageSells)}</Typography>
                            <Typography variant={"caption"} component={'span'}> unid</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={e => {
                        e.stopPropagation();
                        onCancel();
                    }}
                    color="primary"
                >
                    Cerrar
                </Button>
            </DialogActions>
        </>
    )
}
