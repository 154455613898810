import Typography from "@material-ui/core/Typography";
import React from "react";
import Box from "@material-ui/core/Box";
import {ButtonBase, IconButton} from "@material-ui/core/index";
import CloseIcon from "@material-ui/icons/Close";
import {Collapse, Slide} from "@material-ui/core";

export function ScanSuccessPopover(props) {
    const {open, articulo, onClick, onClose} = props;

    const allItemsScanned = articulo.scanned === articulo.cantidad;
    const itemsRemainForScan = articulo.scanned < articulo.cantidad;
    const tooMuchItemsScanned = articulo.scanned > articulo.cantidad;

    const backgroundGreen = 'rgb(0, 150, 136, 1)';
    const backgroundRed = 'rgb(255,181,181)';

    return (
        <Slide direction="up" in={open} mountOnEnter unmountOnExit>
            <Box style={{
                backgroundColor: tooMuchItemsScanned ? backgroundRed : backgroundGreen,
                display: 'block',
                height: '7rem',
                position: "fixed",
                bottom: '0',
                width: '100%',
                borderTop: '1px solid #BBB'
            }}>
                <ButtonBase style={{width: '100%', height: '100%', display: 'block'}} onClick={onClick}>
                    <IconButton
                        style={{
                            position: 'absolute',
                            right: '-5px',
                            top: '-5px',
                            color: tooMuchItemsScanned ? 'inherit' : 'white'
                        }}
                        onClick={onClose}
                    >
                        <CloseIcon/>
                    </IconButton>

                    <Box style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        height: '100%'
                    }}>
                        <Typography
                            align={"center"}
                            variant={"body1"}
                            style={{color: tooMuchItemsScanned ? 'inherit' : 'white'}}
                        >
                            <b>{articulo.id || ''}</b>
                        </Typography>
                        <Typography
                            align={"center"}
                            variant={"body2"}
                            style={{color: tooMuchItemsScanned ? 'inherit' : 'white'}}
                        >
                            {articulo.detalle || 'No hay detalles del artículo escaneado'}
                        </Typography>
                        <Typography
                            align={"center"}
                            variant={"body1"}
                            style={{color: tooMuchItemsScanned ? 'red' : 'white'}}
                            color={tooMuchItemsScanned ? "error" : allItemsScanned ? "primary" : "inherit"}
                        >
                            Leídos {articulo.scanned || 0} <b>de {articulo.cantidad}</b>
                        </Typography>
                    </Box>
                </ButtonBase>
            </Box>
        </Slide>
    );
}
