import React, {useContext, useEffect, useReducer, useState} from 'react'
import {Box, Fab, Tooltip} from "@material-ui/core/index";
import {ReactGrid} from 'components/helpers/reactGrid/reactGrid';
import {useSnackbar} from "notistack";
import './articulos.list.scss';
import SubHeader from "../../helpers/subHeader/subHeader";
import {ArticuloDetailsButton} from "components/helpers/articulos/articuloDetails";
import VentasSettings from "components/estadisticas/settings/ventas.settings";
import {useArticulosService, useListadosService} from "services/hooks";
import {generalReducer} from "components/helpers/reducers/reducers";
import {INITIAL_FILTER_SETTINGS_STATE} from "components/helpers/initialFilterSettingsState";
import {SettingsRounded} from "@material-ui/icons";
import {sortNumeric} from "assets/utils";
import {MainContext} from "contexts/main.context";
import {BarcodeWithEdit} from "components/helpers/articulos/articulo.barcode";
import AscIcon from "@material-ui/icons/ArrowUpward";
import DescIcon from "@material-ui/icons/ArrowDownward";
import {Grid, MenuItem, TextField} from "@material-ui/core";
import {ExportOptions} from "components/helpers/exportOptions/export.options";

const getTableArticulos = (role, callbackEditFn) => {
    let tableArticulos = {
        columns: [
            {name: 'id', title: 'Código'},
            {name: 'detalle', title: 'Descripción', style: {whiteSpace: 'break-spaces'}},
            {name: 'lista1', title: 'Lista 1'},
            {name: 'total', title: 'Total'},
            {name: 'ubicacion', title: 'Ubic.'},
            {name: 'custom1', title: 'Código Barras'},
            {name: 'custom2', title: ' '},
            {name: 'codigobarr', title: 'Código Barras (solo búsqueda)'}
        ],
        columnExtensions: [
            {columnName: 'id', width: 140},
            {columnName: 'lista1', width: 130, type: 'currency', compare: sortNumeric},
            {columnName: 'total', width: 80, compare: sortNumeric},
            {columnName: 'ubicacion', width: 80},
            {columnName: 'custom1', width: 150, component: (row) => BarcodeWithEdit(row, callbackEditFn)},
            {columnName: 'custom2', width: 100, component: ArticuloDetailsButton, align: 'center'},
            {columnName: 'codigobarr', width: 0}
        ]
    };

    // Quito columnas de precios si el usuario es operador logístico
    if (role === 'L') {
        tableArticulos.columns = tableArticulos.columns.filter(column => !['lista1', 'custom1'].includes(column.name));
        tableArticulos.columnExtensions = tableArticulos.columnExtensions.filter(columnExtension => !['lista1', 'custom1'].includes(columnExtension.columnName));
    }

    return tableArticulos;
};

const orderFields = [
    {
        id: 'codigo',
        description: 'Codigo'
    },
    {
        id: 'detalle',
        description: 'Detalle'
    }
];

const orderDirections = [
    {
        id: 'asc',
        description: <AscIcon fontSize={"small"}/>
    },
    {
        id: 'desc',
        description: <DescIcon fontSize={"small"}/>
    }
];

export function ArticulosList() {
    const [_settingsState, dispatch] = useReducer(generalReducer, INITIAL_FILTER_SETTINGS_STATE);
    const [settingsState, setSettingsState] = useState(INITIAL_FILTER_SETTINGS_STATE);
    const [settingsDialogOpen, setSettingsDialogOpen] = useState(false);
    const [articulos, setArticulos] = useState([]);
    const [orderField, setOrderField] = useState('detalle');
    const [orderDirection, setOrderDirection] = useState('asc');

    let filteredRows = [];

    const ArticulosService = useArticulosService();
    const ListadosService = useListadosService();
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const context = useContext(MainContext);

    const userRole = context.loggedUser.role;
    const [tableArticulos, setTableArticulos] = useState(getTableArticulos(userRole, () => loadArticulos(settingsState)));

    useEffect(() => {
        loadArticulos(settingsState);
    }, [settingsState]);

    const loadArticulos = settingsState => {
        ArticulosService.getAllWithFilters(
            settingsState,
            response => setArticulos(response.data.result),
            error => enqueueSnackbar(error, {variant: 'error'}));
    }

    const onConfirmSettingsDialog = () => {
        setSettingsState(_settingsState);
        setSettingsDialogOpen(false);
    }

    const onCancelSettingsDialog = () => {
        dispatch({payload: settingsState, type: 'fullstatechange'});
        setSettingsDialogOpen(false);
    }

    const getPDF = fnSuccess => {
        // Armo los filtros para que busque por fechas de los últimos 3 meses (solo afecta al promedio de ventas menssual).
        const moment = require('moment');
        const quantityOfMonths = 3;

        const dateFrom = moment().subtract(quantityOfMonths, 'month');
        settingsState.dates = {...settingsState.dates, from: dateFrom};

        ListadosService.stockArticulos(
            settingsState,
            filteredRows.map(row => row.id),
            quantityOfMonths,
            orderField,
            orderDirection,
            file => fnSuccess(file),
            error => enqueueSnackbar(error, {variant: 'error'})
        )
    };

    const onFilterRows = _filteredRows => filteredRows = _filteredRows;

    const {columns, columnExtensions} = tableArticulos;

    return (
        <>
            {/*<Grid className='niquel-articulos-list'>*/}
            <SubHeader title={'Artículos'}/>
            <Box className='container-xl mt-3 mb-3'>
                <Grid container>
                    {["A", "O", "S"].includes(context.loggedUser.role) && (
                        <Grid item className={'d-flex align-items-end'}>
                            <TextField
                                select
                                label="Ordenar (solo PDF)"
                                value={orderField}
                                onChange={event => setOrderField(event.target.value)}
                                style={{width: '9rem'}}
                            >
                                {orderFields.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.description}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                select
                                className={'ml-1'}
                                value={orderDirection}
                                onChange={event => setOrderDirection(event.target.value)}
                            >
                                {orderDirections.map(option => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.description}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    )}
                </Grid>
                <Box className='mt-3'>
                    <ReactGrid
                        title={`Artículos (${articulos.length})`}
                        columns={columns}
                        columnExtensions={columnExtensions}
                        rows={articulos}
                        height={-200}
                        enableFiltering={false}
                        onFilterRows={onFilterRows}
                    />
                    <Tooltip title={'Configurar filtros'}>
                        <Box className={'niquel-fab-top-btn'}>
                            <Fab onClick={() => setSettingsDialogOpen(true)} color={"default"}>
                                <SettingsRounded/>
                            </Fab>
                        </Box>
                    </Tooltip>
                </Box>
                {["A", "O", "S"].includes(context.loggedUser.role) && (
                    <ExportOptions
                        enableDownload
                        enableMail
                        enablePrint
                        enableShow
                        getPDF={getPDF}
                        fileName={"Stock_Articulos.pdf"}
                        mailSubject={"Stock Artículos"}
                        mailBody={"Estimado,  \n\nSe adjunta el listado de artículos en stock."}
                    />
                )}
            </Box>
            <VentasSettings
                open={settingsDialogOpen}
                title={'Filtros y confirguración'}
                onCancel={onCancelSettingsDialog}
                onConfirm={onConfirmSettingsDialog}
                fullScreen={true}
                maxWidth={'lg'}
                state={_settingsState}
                dispatch={dispatch}
                enabledOnlyTabs={['articulos', 'proveedores']}
            />
        </>
    )
}
