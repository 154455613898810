import BaseService from './base.service'

export default class NotificationsService extends BaseService {
    get(sentReceived, fnSuccess, fnError) {
        return this.getApi().get(`/notifications?${sentReceived}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener las notificaciones del usuario')));
    }

    getPending(fnSuccess, fnError) {
        return this.getApi().get(`/notifications/pending`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener las notificaciones del usuario')));
    }

    getTop(fnSuccess, fnError) {
        return this.getApi().get(`/notifications?top`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener las notificaciones del usuario')));
    }

    push(options, fnSuccess, fnError) {
        return this.getApi().post(`/notifications`, options)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al enviar la notificación')));
    }
}
