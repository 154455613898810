import BaseService from './base.service'

export default class HojasDeRutaService extends BaseService {
    getAll(fnSuccess, fnError) {
        return this.getApi().get(`/hojasDeRuta`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getFromLastMonth(fnSuccess, fnError) {
        return this.getApi().get(`/hojasDeRuta?fromLastMonth`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getEntregas(skip, fnSuccess, fnError) {
        return this.getApi().get(`/hojasDeRuta?entregas&skip=${skip}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getById(id, fnSuccess, fnError) {
        return this.getApi().get(`/hojasDeRuta?id=${id}`)
            .then(response => {
                if (response.data.count === 1)
                    response.data.result = response.data.result[0];
                fnSuccess(response);
            })
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    create(data, fnSuccess, fnError) {
        return this.getApi().post(`/hojasDeRuta`, data)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    edit(id, data, fnSuccess, fnError) {
        return this.getApi().put(`/hojasDeRuta?id=${id}`, data)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getPedidosById(id, fnSuccess, fnError) {
        return this.getApi().get(`/hojasDeRuta/pedidos?id=${id}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getArticulosById(id, fnSuccess, fnError) {
        return this.getApi().get(`/hojasDeRuta/articulos?id=${id}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getDocumentosById(id, fnSuccess, fnError) {
        return this.getApi().get(`/hojasDeRuta/documentos?id=${id}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    deleteById(id, fnSuccess, fnError) {
        return this.getApi().delete(`/hojasDeRuta?id=${id}`)
            .then(response => {
                if (response.data.count === 1)
                    response.data.result = response.data.result[0];
                fnSuccess(response);
            })
            .catch(error => fnError(this.getMessageFromError(error)));
    }

    getPDF(id, fnSuccess, fnError) {
        this.getApi()({
            url: '/hojasDeRuta/pdf',
            method: 'get',
            params: {
                id: id
            },
            responseType: 'blob'
        })
            .then(response => {
                const file = new Blob(
                    [response.data],
                    {type: 'application/pdf'});
                fnSuccess(file);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al generar el PDF de la hoja de ruta')));
    }

    uploadRecibo(idpedido, identrega, data, fnSuccess, fnError) {
        return this.getApi().post(`/hojasDeRuta/recibo?idpedido=${idpedido}&idhoja=${identrega}`, data)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al subir el recibo')));
    }

    getRecibos(idhoja = "", fnSuccess, fnError) {
        return this.getApi().get(`/hojasDeRuta/recibo?idhoja=${idhoja}`)
            .then(response => fnSuccess(response))
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener los recibos')));
    }

    getRecibo(by = 'idpedido', id, fnSuccess, fnError) {
        return this.getApi()({
            url: '/hojasDeRuta/recibo',
            method: 'get',
            params: {
                by: by,
                id: id
            },
            responseType: 'blob'
        })
            .then(response => {
                const cd_header = response.headers['content-disposition'];
                const filename = cd_header.slice(cd_header.indexOf('filename=') + 9);
                const file = new Blob(
                    [response.data],
                    {type: 'image/png'});
                fnSuccess(file, filename);
            })
            .catch(error => fnError(this.getMessageFromError(error, 'Error al obtener el recibo')));
    }
}
