import React from "react";
import {DatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const _DateSelects = ({state, dispatch}) => {
    const endpoint = 'dates';
    const {from, to} = state;
    const _dispatch = args => dispatch({...args, type: `${endpoint}.${args.type}`});
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <DatePicker
                // disableToolbar
                autoOk
                className='mr-3'
                label="Fecha Desde"
                format="DD/MM/Y"
                value={from}
                onChange={moment => _dispatch({type: 'from', payload: moment})}
                InputProps={{
                    style: {width: '6.5rem'},
                }}
            />
            <DatePicker
                // disableToolbar
                autoOk
                className='mr-3'
                label="Fecha Hasta"
                format="DD/MM/Y"
                value={to}
                onChange={moment => _dispatch({type: 'to', payload: moment})}
                InputProps={{
                    style: {width: '6.5rem'},
                }}
            />
        </MuiPickersUtilsProvider>
    )
}

export const DateSelects = React.memo(_DateSelects);
